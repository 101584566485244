/* eslint-disable max-lines-per-function */
import React from "react";
import { InfiniteScroller } from "Components/InfiniteScroller";
import PersonCard from "Components/PersonCard";

import { Person, TeamMemberListQuery } from "_graphql-types/graphql";
import { ApolloQueryResult } from "@apollo/client";
import { Routes, Route } from "react-router-dom";
import PersonProfileModal from "Components/PersonCard/PersonModal/index";
import TeamStructure from "../TeamStructure";

function InvestmentTeam({
  firmId,
  loading,
  people,
  hasMore,
  fetchMore,
}: {
  people: TeamMemberListQuery["teamMemberList"]["items"];
  firmId?: number;
  loading: boolean;
  hasMore: boolean;
  fetchMore: () => Promise<ApolloQueryResult<TeamMemberListQuery>>;
}): JSX.Element {
  return (
    <div
      id="team-body"
      className="main-content main-content--lg"
      data-cy="team-body"
    >
      {firmId && <TeamStructure firmId={firmId} />}
      <h2 className="invt-tab__title" data-cy="team-header">
        Personnel
      </h2>
      <div data-cy="team-list" className="team-member-list">
        {people?.map(person => (
          <PersonCard key={`${person.id}::person::card`} person={person} />
        ))}
        <InfiniteScroller
          hasMore={hasMore}
          isLoading={loading}
          loadMore={fetchMore}
        />
      </div>
      <Routes>
        <Route path=":personId" element={<PersonProfileModal />} />
      </Routes>
    </div>
  );
}

export default InvestmentTeam;
