import { gql } from "_graphql-types/gql";

export const SEND_LEX_MESSAGE = gql(/* GraphQL */ `
  mutation LexSendMessage(
    $sessionId: String!
    $requestId: String!
    $text: String!
    $conversationId: Int
    $requestAttributes: JSONObject
    $sessionAttributes: JSONObject
    $llmOnly: Boolean
  ) {
    lexSendMessage(
      input: {
        sessionId: $sessionId
        requestId: $requestId
        text: $text
        requestAttributes: $requestAttributes
        sessionAttributes: $sessionAttributes
        conversationId: $conversationId
        llmOnly: $llmOnly
      }
    ) {
      conversationId
      recognizeTextCommandOutput
    }
  }
`);

export const CHAT_MESSAGE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription ChatMessageSubscription($sessionId: String!) {
    chatMessages(sessionId: $sessionId) {
      sessionId
      requestId
      error
      messageStream {
        message
        messageIndex
        contentType
        blockIndex
        event
        id
      }
    }
  }
`);

export const ASYNC_LAMBDA = gql(/* GraphQL */ `
  query AsyncLambda(
    $label: String!
    $input: JSONObject!
    $sessionId: String!
    $requestId: String!
  ) {
    siriusChat {
      asyncLambda(
        label: $label
        input: $input
        sessionId: $sessionId
        requestId: $requestId
      )
    }
  }
`);
