import { gql } from "_graphql-types/gql";

export const FETCH_FAMILY_PERFORMANCE = gql(/* GraphQL */ `
  query FamilyPerformance($id: Int!) {
    investment(id: $id) {
      id
      name
      familyId
      family {
        id
        name
        investmentsFamily(sort: [{ field: vintageYear, order: DESC }]) {
          investment {
            id
            name
            isEntitled
            inceptionDate
            vintageYear
            performancePrivate(
              page: { limit: 1 }
              sort: [{ field: asOfDate, order: DESC }]
            ) {
              returnYear
              returnQuarter
              netIRR
              dpi
              tvpi
            }
          }
        }
      }
      firm {
        id
        name
        investments(sort: [{ field: vintageYear, order: DESC }]) {
          items {
            id
            name
            isEntitled
            inceptionDate
            vintageYear
            performancePrivate(
              page: { limit: 1 }
              sort: [{ field: asOfDate, order: DESC }]
            ) {
              returnYear
              returnQuarter
              netIRR
              dpi
              tvpi
            }
          }
        }
      }
    }
  }
`);
