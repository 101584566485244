import React, { useEffect, useMemo } from "react";

import * as types from "_graphql-types/graphql";
import Static from "frontend/src/components/Template/Static";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { FieldStamp } from "frontend/src/components/Template/Field/FieldAuditStamp";
import { CopyField } from "frontend/src/components/Template/Field/CopyField";
import { FieldStateBadge } from "frontend/src/components/Template/Field/FieldStateBadge";
import { startCase } from "lodash";

export function getFieldTitle(
  field: types.FetchFieldsListQuery["fieldList"]["items"][0]
) {
  return field.fieldMeta?.section || field.fieldMeta?.displayName
    ? `${field.fieldMeta?.section ? `${field.fieldMeta?.section} - ` : ""}${
        field.fieldMeta?.displayName || ""
      }`
    : startCase(field.fieldKey);
}

const cardStyle = { marginTop: 16 };

const ownerTypeRouteMapping = {
  company: "company",
  investment: "investments",
  firm: "firms",
};

function FieldDetails({
  field,
}: {
  field: types.FetchFieldsListQuery["fieldList"]["items"][0];
}) {
  const { fieldMeta, ...fieldWithoutMeta } = field;

  return (
    <>
      <div className="d-flex flex-wrap float-right">
        <FieldStamp field={fieldWithoutMeta} />
        <CopyField field={fieldWithoutMeta} />
      </div>
    </>
  );
}

function FieldShow({
  field,
}: {
  field: types.FetchFieldsListQuery["fieldList"]["items"][0];
}) {
  const isBooleanField = [
    "boolean_dropdown_na",
    "boolean_dropdown",
    "boolean",
  ].includes(field.fieldMeta?.displayType);

  const fieldEntityLink = `/${ownerTypeRouteMapping[field.ownerType]}/${
    field.ownerId
  }`;

  const FieldExtra = () => (
    <Link to={fieldEntityLink}>{field.owner?.name || fieldEntityLink}</Link>
  );

  return (
    <>
      {field.fieldMeta ? (
        <Card
          data-cy="fields-card"
          style={cardStyle}
          title={getFieldTitle(field)}
          extra={<FieldExtra />}
        >
          <FieldStateBadge field={field} />
          {field.fieldMeta.questionText && !isBooleanField && (
            <p>
              <strong>{field.fieldMeta.questionText}</strong>
            </p>
          )}
          <Static
            displayType={field.fieldMeta.displayType as string}
            value={field && field.value}
            notApplicable={field?.notApplicable}
            metadata={field.fieldMeta}
          />
          <FieldDetails field={field} />
        </Card>
      ) : (
        <Card
          style={cardStyle}
          title={`(Legacy) ${startCase(field.fieldKey)}`}
          extra={<FieldExtra />}
        >
          <FieldStateBadge field={field} />
          <div style={{ whiteSpace: "pre-wrap" }}>
            {JSON.stringify(field.value, null, 2)}
          </div>
          <FieldDetails field={field} />
        </Card>
      )}
    </>
  );
}

export default function FieldList({
  fields,
}: {
  fields: types.FetchFieldsListQuery["fieldList"]["items"];
}) {
  return (
    <>
      <div data-cy="fields-list">
        {fields.map(field => (
          <FieldShow field={field} />
        ))}
      </div>
    </>
  );
}
