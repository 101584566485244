import _ from "lodash";
import Immutable from "immutable";
import * as Helpers from "Helpers/index";

const HEDGE_FUNDS = ["HedgeFund", "SeparateAccount", "OpenEndFund", "Sma"];

const PRIVATE_INVESTMENTS = [
  "PrivateInvestment",
  "PrivateMarkets",
  "PrivateEquity",
  "PrivateCredit",
  "RealAsset",
  "RealEstate",
  "VentureCapital",
];

// this should be replaced with via the fields reducer and
// memoized selectors.
export default class Fund {
  static get hedgeFunds() {
    return HEDGE_FUNDS;
  }

  static get privateInvestments() {
    return PRIVATE_INVESTMENTS;
  }

  static typeAbbreviation(type) {
    switch (type) {
      case "Private Credit":
      case "Private Markets":
        return "pc";
      case "Private Equity":
        return "pe";
      case "Real Asset":
        return "ra";
      case "Real Estate":
        return "re";
      case "Mutual Fund":
      case "SMA":
      case "Open-End Fund":
      case "Closed-End Fund":
        return "trad";
      case "Venture Capital":
        return "vc";
      default:
        return "hf";
    }
  }

  constructor(record = {}) {
    this.id = record.id;
    this._attributes = record.attributes || {};
    this._event_id = record.event_id;
  }

  get accessible() {
    return this.attributes.visibility === "full";
  }

  get attributes() {
    return this._attributes;
  }

  set attributes(attributes) {
    this._attributes = _.merge(this._attributes, attributes);
  }

  get aum() {
    return this.attributes.aum && this.attributes.aum.value;
  }

  get aumStrategy() {
    return this.attributes.strategy_aum && this.attributes.strategy_aum.value;
  }

  get benchmark_hedge_fund_id() {
    return this.attributes.hedge_benchmark_fund_id;
  }

  get benchmark_hedge_fund_name() {
    return this.attributes.hedge_benchmark_fund_name;
  }

  get benchmark_market_fund_id() {
    return this.attributes.market_benchmark_fund_id;
  }

  get benchmark_market_fund_name() {
    return this.attributes.market_benchmark_fund_name;
  }

  get event_id() {
    return this._event_id;
  }

  set event_id(val) {
    this._event_id = val;
  }

  get firm() {
    return (
      this.attributes.firm &&
      new Models.Firm(this.attributes.firm.data.attributes)
    );
  }

  get geographic_focus() {
    let geoFocus = _.get(this.attributes, "geographic_focus", "");

    if (Immutable.isCollection(geoFocus)) {
      geoFocus = geoFocus.join(", ");
    }

    return geoFocus;
  }

  get growthOf1000() {
    return this.attributes.growthOf1000 || {};
  }

  get headquarters() {
    const { headquarters } = this.attributes;

    if (_.isEmpty(headquarters)) return "";

    if (headquarters.name) return headquarters.name;

    if (_.isString(headquarters)) return headquarters;

    const { city, state, country_name } = headquarters;
    const locationValues = [];

    if (city) locationValues.push(city);
    if (state) locationValues.push(state);
    if (country_name) locationValues.push(Helpers.toTitleCase(country_name));

    return locationValues.join(", ");
  }

  get inception_date() {
    switch (this.profileType) {
      case "PrivateInvestment":
        return this.attributes.vintage;
      default:
        return Helpers.asFullDate(this.attributes.inception_date);
    }
  }

  get manager() {
    return this.attributes.manager;
  }

  static profileType(type) {
    const profileType = _.upperFirst(_.camelCase(type));

    if (_.includes(Fund.hedgeFunds, profileType)) {
      return "HedgeFund";
    }

    if (_.includes(Fund.privateInvestments, profileType)) {
      return "PrivateInvestment";
    }

    return "GenericInvestment";
  }

  // grabs information from reducer
  // this logic should be managed on the react component via selectors.
  get profileType() {
    return Fund.profileType(this.type);
  }

  get isPrivate() {
    return this.profileType === "PrivateInvestment";
  }

  get name() {
    return this.attributes.name;
  }

  get performance() {
    return (
      (this.attributes.performance && this.attributes.performance[0]) || {
        value: null,
      }
    );
  }

  get ratings() {
    return (
      this.attributes.ratings &&
      this.attributes.ratings.reduce((ratings, rating) => {
        ratings[rating.attributes.section_category.toString()] =
          rating.attributes.ratings;
        return ratings;
      }, {})
    );
  }

  get serialize() {
    return {
      id: this.id,
      event_id: this._event_id,
      attributes: this.attributes,
    };
  }

  get shareClass() {
    return this.attributes.share_class_id;
  }

  get statistics() {
    return (
      this.attributes.statistics &&
      this.attributes.statistics.data &&
      this.attributes.statistics.data.attributes
    );
  }

  get type() {
    return this.attributes.type || "";
  }

  get assetClass() {
    return this.attributes.asset_class || null;
  }

  get strategy() {
    return (
      (this.attributes.strategy && this.attributes.strategy[0]) || undefined
    );
  }

  get characteristics() {
    return (
      (this.attributes.strategy && this.attributes.strategy[1]) || undefined
    );
  }

  get terms() {
    return this.attributes.terms;
  }

  get term_default() {
    const defaultShareClass =
      this.attributes.terms &&
      this.attributes.terms.find(
        shareClass =>
          shareClass.attributes && shareClass.attributes.is_default === true
      );

    return defaultShareClass && defaultShareClass.attributes;
  }

  get alternatives() {
    return this.attributes.alternatives || [];
  }

  get badgeColor() {
    return this.attributes.type ? _.kebabCase(this.attributes.type) : "default";
  }

  get badgeColorClass() {
    return `fund-type--${this.badgeColor}`;
  }
}
