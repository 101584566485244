import { useLocation, useNavigate, useParams } from "react-router-dom";

import Modal from "Components/modal";
import i18n from "i18next";
/* eslint-disable react/jsx-no-undef */
import { useApolloClient } from "@apollo/client";
import { TEAM_MEMBER } from "Components/graphql/fragments/Person";
import Profile from "./profile";

function PersonProfile() {
  const client = useApolloClient();
  const { personId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const person = client.readFragment({
    id: `Person:${personId}`,
    fragment: TEAM_MEMBER,
    fragmentName: "TeamMember",
  });
  if (!person) return;

  const routeToTeamsPage = () => {
    const currentPath = location.pathname.split("/");
    currentPath.pop();
    navigate(`${currentPath.join("/")}`);
  };

  return (
    <Modal
      visible
      title={i18n.t("team_summary.profile")}
      onCancel={routeToTeamsPage}
      width="840px"
    >
      <Profile person={person} />
    </Modal>
  );
}

export default PersonProfile;
