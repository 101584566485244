import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";
import { AutoComplete, Input } from "antd";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { useMemo, useState } from "react";
import {
  addToEntityFilter,
  clearAllInvestmentFilters,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { InvestmentFilter } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";
import { useDebouncedCallback } from "use-debounce";
import { FilterInterface } from "../FilterInterface";

export const ENTITY_SEARCH_LIST = gql(/* GraphQL */ `
  query savedEntitySearchList(
    $organizationId: Int!
    $userId: Int!
    $nameSearch: String
  ) {
    savedEntitySearchList(
      filter: {
        organizationId: $organizationId
        userId: $userId
        entityType: investment
        nameSearch: $nameSearch
      }
      sort: [{ field: nameSearchRank, order: ASC }]
      page: { offset: 0, limit: 20 }
    ) {
      items {
        id
        entityType
        name
        data
      }
    }
  }
`);
/* eslint-disable */
export function SavedEntitySearchFilter() {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [nameSearch, setNameSearch] = useState("");
  const [value, setValue] = useState("");
  const [optionPick, setOptionPick] = useState<types.SavedEntitySearch>();

  const { data, refetch: refetchRaw } = useQuery<{
    savedEntitySearchList: types.SavedEntitySearchList;
  }>(ENTITY_SEARCH_LIST, {
    variables: {
      organizationId: Number(user.organizationId),
      userId: user.id,
      entityType: "investment",
      nameSearch,
    },
  });

  const refetchDebounced = useDebouncedCallback(variables => {
    refetchRaw?.(variables);
  }, 300);
  const options = useMemo(
    () =>
      data?.savedEntitySearchList.items.map(({ id, name }) => ({
        value: String(id),
        label: <div>{name}</div>,
      })) ?? [],
    [data]
  );

  const set = () => {
    if (optionPick) {
      dispatch(clearAllInvestmentFilters());
      const investmentFilters: InvestmentFilter[] = JSON.parse(optionPick.data);
      // TODO: validate filters
      for (const investmentFilter of investmentFilters) {
        switch (investmentFilter.type) {
          case "investment":
            switch (investmentFilter.data.type) {
              case "FIRM_ENTITY":
              case "INVESTMENT_ENTITY":
              case "TAG_ENTITY":
                for (const entityKey of investmentFilter.data.data.values) {
                  addToEntityFilter({
                    type: "investment",
                    data: {
                      type: investmentFilter.data.type,
                      data: entityKey,
                    },
                  });
                }
                break;
              default:
                dispatch(
                  updateFilter({
                    type: "investment",
                    data: investmentFilter,
                  })
                );
            }
            break;
          default:
            dispatch(
              updateFilter({
                type: "investment",
                data: investmentFilter,
              })
            );
        }
      }
    }
  };

  return (
    <FilterInterface
      label="Saved Filters"
      count={0}
      set={set}
      menuClass="filters__interseption-datepicker-wrap"
      width={400}
    >
      <div
        className="main-dropdown__menu-body"
        data-cy="saved-entity-search-filter-dropdown"
      >
        <div>
          <AutoComplete
            className="auto-complete-with-clearable-input-search-child"
            options={options}
            value={value}
            onChange={(value: string) => {
              setValue(value);
            }}
            dropdownMatchSelectWidth={252}
            style={{
              width: 300,
            }}
            onSelect={(value: number | string) => {
              const optionPick = value
                ? data?.savedEntitySearchList.items.find(
                    item => item.id === Number(value)
                  )
                : undefined;
              setValue(optionPick?.name ?? "");
              setOptionPick(optionPick);
            }}
            onSearch={(nameSearch: string) => {
              refetchDebounced?.({
                organizationId: Number(user.organizationId),
                userId: user.id,
                nameSearch,
              });
            }}
          >
            <Input.Search
              data-cy="name-search"
              placeholder="select existing; type to filter"
              enterButton
              allowClear
            />
          </AutoComplete>
        </div>
      </div>
    </FilterInterface>
  );
}
