import { gql } from "_graphql-types/gql";

export const COMPANY_ASSIGNMENTS = gql(/* GraphQL */ `
  query CompanyAssignment($id: Int!) {
    company(id: $id) {
      id
      name
      primaryAssignment
      primaryCompanyAdvisor {
        email
        userName
        imageType
        imageTypeSm
        commonName
      }
      faceOfCompany {
        employeeRoleEnumId
        employeeRole {
          id
          name
        }
        person {
          id
          pronouns
          fullName
          lastName
          firstName
          middleName
          photoLink
        }
      }
      primaryCompanyManager {
        employeeRoleEnumId
        employeeRole {
          id
          name
        }
        person {
          id
          pronouns
          fullName
          lastName
          firstName
          middleName
          photoLink
        }
      }
    }
  }
`);
