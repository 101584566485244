import { gql } from "_graphql-types/gql";

export const DOCUMENT_SHOW = gql(/* GraphQL */ `
  fragment DocumentShow on Document {
    id
    name
    signedUrl
    signedThumbnailUrl
    createUser
    createDate
    date
    isVectorized
    investment {
      id
      name
    }
    firm {
      id
      name
    }
    documentTypeEnum {
      id
      name
      documentCategoryEnumId
    }
  }
`);
