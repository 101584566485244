import { gql } from "_graphql-types/gql";

export const INVESTMENT_ASSIGNMENTS = gql(/* GraphQL */ `
  query InvestmentAssignments($id: Int!) {
    investment(id: $id) {
      id
      name
      primaryAssignment
      primaryFundAdvisor {
        email
        userName
        imageType
        imageTypeSm
        commonName
      }
      primaryPortfolioManager {
        firmEmployment {
          id
          person {
            id
            pronouns
            fullName
            lastName
            firstName
            middleName
            photoLink
          }
        }
      }
      faceOfInvestment {
        firmEmployment {
          person {
            id
            pronouns
            fullName
            lastName
            firstName
            middleName
            photoLink
          }
        }
      }
    }
  }
`);
