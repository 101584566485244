/* eslint-disable max-len */
import { gql } from "_graphql-types/gql";

export const FETCH_SUBSCRIBED_INVESTMENT_SETS = gql(/* GraphQL */ `
  query subscribedLists {
    investmentSetList(filter: { subscribed: true }) {
      items {
        id
        name
        description
        accessLevel {
          name
          id
        }
      }
      total
    }
  }
`);

export const FETCH_INVESTMENT_SET_INVESTMENTS = gql(/* GraphQL */ `
  query fetchInvestmentSetInvestments(
    $page: PageInput
    $investmentSetId: Int!
  ) {
    investmentList(
      page: $page
      searchFilters: [{ INVESTMENT_SET: { investmentSetId: $investmentSetId } }]
    ) {
      total
      items {
        ...InvestmentTile
      }
    }
  }
`);

export const QUERY_CURATED_SETS = gql(`
  query curatedSets {
    setList(
      filter: { setType: Investment, myCuratedSets: true }
    ) {
      items {
        id
        name
        description
      }
    }
  }
`);

export const FETCH_SET_INVESTMENTS = gql(`
  query fetchSetInvestments($page: PageInput, $setId: Int!) {
    investmentList(page: $page, searchFilters: [{ SET: { setId: $setId } }]) {
      total
      items {
        ...InvestmentTile
      }
    }
  }
`);
