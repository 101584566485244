import Portal from "frontend/src/components/Portal";
import React from "react";

const Tabs = ({ children }: { children: any }) => (
  <Portal target="tabs-yield">
    <div className="main-nav-tabs-wrap" role="tablist">
      {children}
    </div>
  </Portal>
);

export default Tabs;
