import React, { useContext, useMemo } from "react";
import * as types from "_graphql-types/graphql";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import LoadingPanel from "Components/loading_panel";
import { round } from "lodash";
import { RelativePerformanceContext } from "./context";
if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

function RiskVsReturnChart(): JSX.Element {
  const { investment, loading } = useContext(RelativePerformanceContext);

  const chartData: HighCharts.SeriesScatterOptions[] = useMemo(() => {
    if (!investment?.groupStats || !investment.primaryBenchmark) return [];

    const { items, stats } = investment.groupStats;

    return items.map((inv, iinv) => {
      let stdDev = stats.stdDev?.[iinv];
      let cmpRet = stats.compoundReturn[iinv];
      if (typeof stdDev !== "number" || typeof cmpRet !== "number") {
        return {
          type: "scatter",
          data: [] as [number, number][],
        };
      }

      stdDev *= 100;
      cmpRet *= 100;

      return {
        type: "scatter",
        name: `${inv.name}`,
        marker: { symbol: "diamond" },
        data: [[round(stdDev, 2), round(cmpRet, 2)]] as [number, number][],
      };
    });
  }, [investment]);

  const chartOptions: HighCharts.Options = {
    chart: {
      type: "scatter",
    },
    title: {
      text: "Risk vs. Return (ITD) (%)",
    },
    xAxis: {
      title: {
        text: "Annualized Std Dev",
      },
      minPadding: 0.2,
      maxPadding: 0.2,
      min: 0,
    },
    yAxis: {
      title: {
        text: "Annualized Return",
      },
      minPadding: 0.2,
      maxPadding: 0.2,
    },
    series: chartData,
    plotOptions: {
      scatter: {
        marker: {
          radius: 8,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{series.name}</span></br>',
          pointFormat:
            '<span style="padding:0">Std Dev: {point.x:.2f}% <br/>Return: {point.y:.2f}%</span>',
        },
      },
    },
    lang: {
      noData: !investment?.primaryBenchmark
        ? "No benchmark defined"
        : "No data to display",
    },
    credits: {
      enabled: false,
    },
  };

  if (!investment?.primaryBenchmark) return <></>;

  return (
    <>
      <LoadingPanel loading={loading}>
        <HighChartsReact
          constructorType="chart"
          options={chartOptions}
          highcharts={HighCharts}
        />
      </LoadingPanel>
    </>
  );
}

export default RiskVsReturnChart;
