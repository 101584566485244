/* eslint-disable indent */
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import PieChartView from "./PieChart";
import BubbleChartView from "./BubbleChart";
import ColumnChartView from "./ColumnChart";
import { IOptions } from "./Visualizations.component";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChartViewFor(chartType: IOptions["chartType"]) {
  switch (chartType) {
    case "pie":
      return PieChartView;
    case "column":
      return ColumnChartView;
    case "packedbubble":
      return BubbleChartView;
    case "radial":
      return ColumnChartView;
    default:
      break;
  }

  return false;
}

interface ChartViewBaseProps {
  deiCategory1List: types.DeiCategory1Enum[];
  deiCategory2List: types.DeiCategory2Enum[];
  aggregatedData: Partial<types.FirmDeiRecord>[];
  isPercent: boolean;
  title?: string;
  chartType: IOptions["chartType"];
  teamSize: number | boolean;
  callback?: Highcharts.ChartCallbackFunction;
  fontSize?: number;
  height?: number;
  width?: number;
  chartDiameter?: number;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  allowChartUpdate?: boolean;
}

export default function ChartViewBase({
  deiCategory1List,
  deiCategory2List,
  aggregatedData,
  isPercent,
  title,
  chartType,
  teamSize,
  callback,
  fontSize,
  height,
  width,
  chartDiameter,
  legendSymbolHeight,
  legendItemMarginTop,
  allowChartUpdate = false,
}: ChartViewBaseProps): JSX.Element | null {
  const Chart = useMemo(() => ChartViewFor(chartType), [chartType]);
  if (!Chart) return null;

  return (
    <Chart
      key={`${chartType}::${title}`}
      deiCategory1List={
        Chart === PieChartView
          ? deiCategory1List
          : deiCategory1List.filter(
              category => !category.isAdditionalMinorityGroup
            )
      }
      deiCategory2List={deiCategory2List.filter(category => category.id !== 5)}
      aggregatedData={aggregatedData}
      isPercent={isPercent}
      title={title}
      polar={chartType === "radial"}
      subTitle={teamSize ? `Team Size: ${teamSize}` : ""}
      callback={callback}
      fontSize={fontSize}
      height={height}
      width={width}
      chartDiameter={chartDiameter}
      legendSymbolHeight={legendSymbolHeight}
      legendItemMarginTop={legendItemMarginTop}
      allowChartUpdate={allowChartUpdate}
    />
  );
}
