/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import * as types from "_graphql-types/graphql";
import { Col, Typography, Modal, Row, Space, Checkbox, Menu } from "antd";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { useMutation, useQuery } from "@apollo/client";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  SAVED_ENTITY_SEARCH_QUERY,
  INVESTMENT_SET_QUERY,
  CLEAR_USER_SETTING,
} from "./graphql";

interface ModalState {
  open: boolean;
  saveError: string;
}

type Props = {
  currentUser: types.CurrentUserQuery["currentUser"];
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
};

const initModal = (): ModalState => ({
  open: false,
  saveError: "",
});

const UserSettingsModal = ({
  currentUser,
  modalState,
  setModalState,
}: Props) => {
  const { userSettings } = currentUser;

  const [clearUserSetting] = useMutation(CLEAR_USER_SETTING);

  const { data: savedEntitySearchData } = useQuery<{
    savedEntitySearch: types.SavedEntitySearchQuery["savedEntitySearch"];
  }>(SAVED_ENTITY_SEARCH_QUERY, {
    variables: {
      id: userSettings?.data?.defaultInvestmentView?.id,
    },
    skip:
      userSettings?.data?.defaultInvestmentView?.type !==
      types.SettingEntityType.SavedEntitySearch,
  });

  const { data: investmentSetData } = useQuery<{
    investmentSet: types.InvestmentSetSearchQuery["investmentSet"];
  }>(INVESTMENT_SET_QUERY, {
    variables: {
      id: userSettings?.data?.defaultInvestmentView?.id,
    },
    skip:
      userSettings?.data?.defaultInvestmentView?.type !==
      types.SettingEntityType.InvestmentSet,
  });

  function handleChange({
    event,
    type,
  }: {
    type: types.ClearSettingKey;
    event: CheckboxChangeEvent;
  }) {
    event.preventDefault();
    const { checked } = event.target;
    if (checked) {
      clearUserSetting({
        variables: {
          input: {
            clearSettingKey: type,
            userId: currentUser.id,
          },
        },
      });
    }
  }

  function renderSetting(searchSetting?: types.SearchSetting | null) {
    let name;

    if (!searchSetting) {
      return (
        <Row>
          <Typography.Text>
            Default view settings can be added by editing a list, or
            adding/editing a saved filter
          </Typography.Text>
        </Row>
      );
    }

    if (searchSetting?.type === types.SettingEntityType.InvestmentSet)
      name = investmentSetData?.investmentSet?.name;
    if (searchSetting?.type === types.SettingEntityType.SavedEntitySearch)
      name = savedEntitySearchData?.savedEntitySearch?.name;

    return (
      <>
        <Row justify="space-between">
          <Col xs={4}>
            <Typography.Text italic>Type:</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text data-cy="userSettingsModal__type">
              {userSettings?.data?.defaultInvestmentView?.type &&
                I18n.t(
                  `nav_bar.user_settings.${userSettings?.data?.defaultInvestmentView?.type}`
                )}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Typography.Text italic>
              {userSettings?.data?.defaultInvestmentView?.type &&
                `${I18n.t(
                  `nav_bar.user_settings.${userSettings?.data?.defaultInvestmentView?.type}`
                )} Name:`}
            </Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text data-cy="userSettingsModal__name">
              {name}
            </Typography.Text>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Modal
        data-cy="userSettings__modal"
        title="User Settings"
        okText="Save"
        open={modalState.open}
        onOk={async () => {
          setModalState({
            ...modalState,
            open: false,
          });
        }}
        onCancel={() => setModalState(initModal())}
      >
        <Space
          data-cy="userSettingsModal__body"
          direction="vertical"
          style={{ width: "100%" }}
        >
          <Row align="middle">
            <Typography.Title level={4}>
              {I18n.t("nav_bar.user_settings.defaultInvestmentView")}{" "}
            </Typography.Title>
          </Row>
          {renderSetting(userSettings?.data?.defaultInvestmentView)}
          <Row>
            <Checkbox
              disabled={!userSettings?.data?.defaultInvestmentView}
              defaultChecked={false}
              data-cy="userSettingsModal__clearSettings"
              onChange={event =>
                userSettings?.data?.defaultInvestmentView &&
                handleChange({
                  event,
                  type: types.ClearSettingKey.DefaultInvestmentView,
                })
              }
            >
              Clear Settings
            </Checkbox>
          </Row>
        </Space>
      </Modal>
    </>
  );
};

export const UserSettingsMenuItem = ({ key }: { key: string }): JSX.Element => {
  const [modalState, setModalState] = useState<ModalState>(initModal());
  const currentUser = useCurrentUser();

  return (
    <>
      <Menu.Item
        key={key}
        data-cy="main-navbar__userSettings"
        onClick={() => setModalState({ ...modalState, open: true })}
      >
        <p className="main-popup-dropdown__item">User Settings</p>
      </Menu.Item>
      <UserSettingsModal
        currentUser={currentUser}
        {...{ modalState, setModalState }}
      />
    </>
  );
};
