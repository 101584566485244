import React from "react";
import RelativePerformance from "./components/RelativePerformance";
import { Grid, Box } from "@mui/material";

const RelativePerformanceSubTab = ({
  investmentId,
}: {
  investmentId: number;
}) => {
  return (
    <Box id="relative-performance-subtab-page">
      <RelativePerformance investmentId={investmentId}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Box>
              <RelativePerformance.MonthlyReturnsChart />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <RelativePerformance.StatsChart />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box>
              <RelativePerformance.ComparitiveStatsChart />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box>
              <RelativePerformance.RiskVsReturnChart />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <RelativePerformance.BenchmarkTable />
            </Box>
          </Grid>
        </Grid>
      </RelativePerformance>
    </Box>
  );
};

export default RelativePerformanceSubTab;
