import { Typography } from "antd";
import { INVESTMENT_PERFORMANCE_ATTRIBUTE_FRAGMENT } from "Components/graphql/fragments/Investment";
import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import Monthly from "./MonthlyPerformance";
import Irr from "./PrivatePerformance";

const { Text } = Typography;

function PerformanceContainer({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [monthlyRendered, changeMonthlyRendered] = useState<
    boolean | undefined
  >(undefined);
  const [irrRendered, changeIrrRendered] = useState<boolean | undefined>(
    undefined
  );

  const client = useApolloClient();

  // loads in PerformanceTable
  const investmentDetails = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_PERFORMANCE_ATTRIBUTE_FRAGMENT,
  });

  return (
    <>
      {investmentDetails?.isManagerUnwillingToProvidePerformance && (
        <Text strong italic>
          Manager declined to provide performance data.
        </Text>
      )}

      <div className="heading" data-cy="performance-heading">
        {I18n.t("overview.performance")}
      </div>

      <Monthly
        investmentId={investmentId}
        changeHasData={changeMonthlyRendered}
        hasData={monthlyRendered}
      />
      <Irr
        investmentId={investmentId}
        changeHasData={changeIrrRendered}
        hasData={!!irrRendered}
      />
    </>
  );
}

export default PerformanceContainer;
