import React from "react";
import { getPieChartOptions } from "frontend/src/components/HighCharts";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { PieChartSeries } from "frontend/src/components/HighCharts/dataSeriesFormatters";

const PieChart = ({ chartData }: { chartData: PieChartSeries[] }) => {
  const options: HighCharts.Options = getPieChartOptions({
    dataSeries: chartData,
    height: 350,
    width: 400,
    fontSize: 10,
    allowExport: false,
    formatSuffix: "",
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: 180,
    legendSymbolHeight: 14,
    legendItemMarginTop: 14,
    legendAlign: "right",
  });
  return (
    <HighChartsReact
      options={options}
      highcharts={HighCharts}
      immutable
      allowChartUpdate={false}
    />
  );
};

export default PieChart;
