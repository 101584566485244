import { FundTagList } from "./fund_tag_list.js.jsx";
import { renderIconGrid } from "frontend/src/components/SDGIcons";
import i18n from "i18next";
import { camelCase, compact, startCase, uniq } from "lodash";

import React, { useContext, useMemo } from "react";

import { Link } from "react-router-dom";
import { InvestmentTileHeading } from "./TileHeading";
import { InvestmentTileContext } from "../context.js";

export function PortfolioTileBody() {
  const { investment, details } = useContext(InvestmentTileContext);

  const {
    investmentTags,
    assetClass,
    primaryPortfolioManager,
    isImpact,
    firm,
  } = investment;

  const { name: firmName, id: firmId } = firm ?? {};

  const pmName =
    primaryPortfolioManager?.firmEmployment &&
    compact([
      primaryPortfolioManager.firmEmployment.person.firstName,
      primaryPortfolioManager.firmEmployment.person.middleName,
      primaryPortfolioManager.firmEmployment.person.lastName,
    ]).join(" ");

  const geoFocus = investment.primaryGeographicFocus?.name;

  const tags = useMemo(
    () =>
      compact([
        assetClass?.name,
        details?.strategy,
        ...uniq(
          (investmentTags || []).map(investmentTag => investmentTag?.tag?.name)
        ),
      ]),
    [assetClass, investmentTags, details?.strategy]
  );

  return (
    <div className="fund-tile-background">
      <div className="tile-body">
        <InvestmentTileHeading />
        <div className="mb-8 p-relative">
          {isImpact && (
            <div className="fund-tile__impact-flag">
              {i18n.t("fund_tile.impact")}
            </div>
          )}
          <div className="tile-detail">
            <span className="icon icon-calendar" />
            <span className="inception-date tile-detail__txt">
              {details?.inceptionDate}
            </span>
          </div>

          <div className="tile-detail">
            <span className="icon icon-earth" />
            <span className="tile-detail__txt">{geoFocus}</span>
          </div>
        </div>
      </div>
      <div style={{ height: "72px" }}></div>
      <FundTagList tags={tags} truncateWithInitials={[0]} />
      <div className="tile-detail">
        <div className="tile-sdgs">
          {renderIconGrid({
            impactTags:
              investment?.impactTags?.filter(
                tag => tag.impactCategory?.name === "SDGs"
              ) ?? [],
            chunkSize: 12,
          })}
        </div>
      </div>
    </div>
  );
}
