import { ActionType } from "_sirius/src/actions/action_types";

const defaults = {
  editMode: false,
};

const editMode = (
  state = false,
  action: {
    type: ActionType.INVESTMENT_EDIT_MODE_SET;
    payload?: {
      editMode?: boolean;
    };
  }
) => {
  switch (action.type) {
    case ActionType.INVESTMENT_EDIT_MODE_SET:
      return action.payload?.editMode ?? state;

    default:
      return state;
  }
};

export default editMode;
