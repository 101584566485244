import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamMemberFragment } from "_graphql-types/graphql";
import { ReactNode } from "react";

interface SocialType {
  key: keyof TeamMemberFragment;
  path: string;
  className?: string;
  icon?: ReactNode;
}

const socialTypes: SocialType[] = [
  {
    key: "linkedin",
    path: "linkedin.com/in/",
    className: "icon icon-linkedin",
  },
  {
    key: "twitter",
    path: "twitter.com/",
    className: "icon icon-x-twitter",
    icon: <FontAwesomeIcon icon={faXTwitter} />,
  },
];

function formatSocialUrl(path: string, url: string) {
  const tail = url.split(path, 2).at(-1);
  return `//${path}${tail}`;
}

const SocialLinks = ({
  person,
}: {
  person: TeamMemberFragment;
}): JSX.Element => {
  return (
    <>
      {socialTypes.map(({ key, path, className, icon }) => {
        const url = person[key] as string | null | undefined;
        if (url) {
          return (
            <a
              key={`${person.id}::social::${url}`}
              aria-label={`${key} account link`}
              target="_blank"
              rel="noopener noreferrer"
              href={formatSocialUrl(path, url)}
              className={`social-icon social-icon--active ${className ?? ""}`}
            >
              {icon}
            </a>
          );
        }

        return (
          <span
            key={`${person.id}::social::${key}`}
            className={`social-icon ${className ?? ""}`}
          >
            {icon}
          </span>
        );
      })}
    </>
  );
};

export default SocialLinks;
