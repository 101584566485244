import React, { useState, useEffect } from "react";
import { useCurrentUser } from "frontend/src/utils/hooks";
import Version1 from "./Version1.js.jsx";
import Version2 from "./Version2.js.jsx";

const versions = {
  1: Version1,
  2: Version2,
};

const currentVersion = 2;

const CurrentVersionComponent = versions[currentVersion];

const getTermKey = (userId, version) =>
  `AgreedToTermsOfServicePolicy::${userId}::${version}`;

const TermsOfServicePolicy = ({ children }) => {
  const currentUser = useCurrentUser();
  const [agreed, setAgreed] = useState(false);

  const userId = currentUser.id;

  const setAgreedStorage = () => {
    localStorage.setItem(getTermKey(userId, currentVersion), true);
    setAgreed(true);
  };

  if (!userId) {
    return <></>;
  }

  if (localStorage.getItem(getTermKey(userId, currentVersion))) {
    return children;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>Terms of Service</h1>

      <p>
        Please read and agree to our terms of service before using this site.
      </p>

      <CurrentVersionComponent />

      <div className="actions">
        <button
          data-cy="tos-authorize"
          type="button"
          name="Authorize"
          className="rounded-btn rounded-btn--blue mt-15"
          onClick={() => {
            setAgreedStorage();
          }}
        >
          I Agree
        </button>
      </div>
    </div>
  );
};

export default TermsOfServicePolicy;
