import React, { useState } from "react";

import LoadingPanel from "../loading_panel";
import { PrivateInvestmentTermItem } from "./private_investment_term_item.js";
import { FieldItem, maxTerm } from "./FieldItem";
import { PrivateGetShareClassesQuery } from "src/graphql-types/graphql";

export function PrivateInvestmentTermsList({
  peLegalLifeYrs,
  shareClasses,
  loading,
  extensionPeriodYrs,
  investmentPeriodYrs,
  harvestPeriodYrs,
}: {
  peLegalLifeYrs: number | null | undefined;
  shareClasses: PrivateGetShareClassesQuery["shareClassList"]["items"];
  loading: boolean;
  extensionPeriodYrs: number | null | undefined;
  investmentPeriodYrs: number | null | undefined;
  harvestPeriodYrs: number | null | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const shareClassesToDisplay = isOpen
    ? shareClasses
    : shareClasses.slice(0, 1);

  function ShowMore() {
    if (shareClasses.length <= 1) return null;
    return (
      <div className="text-center">
        <button
          className="pt-15 pb-15 switch-btn"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "Show Less" : "Show More"}

          <span
            className={`switch-btn__icon icon icon--20 ${
              isOpen ? "icon-arrow-up" : "icon-arrow"
            }`}
          />
        </button>
      </div>
    );
  }

  return (
    <div id="investment_terms">
      <LoadingPanel loading={loading}>
        <div>
          <h3 data-cy="investment-terms-heading" className="invt-tab__title">
            Investment Terms
          </h3>
          <div className="overview-page__card pb-0">
            <div className="flex-row">
              <div className="flex-1 mr-10">
                <FieldItem
                  title="Legal Life of Fund (Years)"
                  value={peLegalLifeYrs || "-"}
                  className="fund-term-total-term"
                />
                <FieldItem
                  title="Maximum Term (Years)"
                  value={maxTerm(peLegalLifeYrs, extensionPeriodYrs)}
                  className="fund-term-maximum-term"
                />
              </div>
              <div className="flex-1 ml-10">
                <FieldItem
                  title="Investment Period (Years)"
                  value={investmentPeriodYrs || "-"}
                  className="fund-term-investment-term"
                />
                <FieldItem
                  title="Harvest Period (Years)"
                  value={harvestPeriodYrs || "-"}
                  className="fund-term-harvest-term"
                />
              </div>
            </div>
          </div>

          <div className="overview-page__card pb-0">
            {shareClassesToDisplay.map(shareClass => (
              <PrivateInvestmentTermItem
                shareClass={shareClass}
                key={shareClass.id}
              />
            ))}
          </div>

          <ShowMore />
        </div>
      </LoadingPanel>
    </div>
  );
}
