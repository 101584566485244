/* eslint-disable react/jsx-props-no-spreading */
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { InvestmentTile } from "Components/InvestmentTile";
import { TOOLTIP_ATTRIBUTES } from "Components/InvestmentTooltip/graphql";
import NotFound from "Components/show/not_found.js";
import PortfolioSummaryContainer from "Components/show/portfolio/PortfolioSummaryContainer";
import PublicInvestmentSummaryContainer from "Components/show/public_investment/public_investment_summary_container.js";
import { getTypeCode } from "Helpers/investment_tag_helpers";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import HeaderHandlerInEditMode from "../../containers/header_handler_in_edit_mode.js.jsx";
import PrivateInvestmentSummaryContainer from "../show/private_investment/private_investment_summary_container";
import { INVESTMENT_TYPE } from "./graphql";
import { PropsFromRedux } from "./InvestmentPage.container";

interface Containers {
  [key: string]: any;
}

const SummaryContainers: Containers = {
  PrivateInvestment: PrivateInvestmentSummaryContainer,
  PublicInvestment: PublicInvestmentSummaryContainer,
  Portfolio: PortfolioSummaryContainer,
};

function InvestmentTileQueryDataCentered({ id }: { id: number }) {
  const { loading } = useQuery<types.TooltipAttributesQuery>(
    TOOLTIP_ATTRIBUTES,
    {
      variables: { id },
    }
  );

  if (loading) return null;

  return (
    <div
      style={{
        width: "300px",
      }}
    >
      <InvestmentTile id={id} />
    </div>
  );
}

function InvestmentPageComponent(
  props: PropsFromRedux
): React.ReactElement | null {
  const { setInvestmentViewMode, fundRatings } = props;

  const { selectedTabName, investmentId } = useParams();

  const { data, loading, error } = useQuery<types.InvestmentTypeQuery>(
    INVESTMENT_TYPE,
    {
      variables: { id: Number(investmentId) },
    }
  );

  const market = useMemo(() => data?.investment.market, [data]);
  const isPortfolio = useMemo(() => !!data?.investment.isPortfolio, [data]);
  const isPreviewOnly = useMemo(
    () => !!data?.investment.access?.preview && !data?.investment.access?.read,
    [data]
  );
  const code = useMemo(
    () =>
      getTypeCode(
        data?.investment.assetClass?.name,
        data?.investment.strategy?.name
      ),
    [data]
  );
  const name = useMemo(() => data?.investment.name, [data]);
  const shortName = useMemo(() => data?.investment.shortName, [data]);

  function _updateSelectedTabName(nextTabName?: string) {
    const { updateSelectedTabName } = props;

    if (nextTabName !== selectedTabName) {
      updateSelectedTabName(nextTabName);
    }
  }

  function getProfileType() {
    if (isPortfolio) return "Portfolio";

    if (market && market.id === 2) return "PrivateInvestment";

    return "PublicInvestment";
  }

  useEffect(() => {
    _updateSelectedTabName(selectedTabName);

    return function cleanup() {
      setInvestmentViewMode();
      _updateSelectedTabName(undefined);
    };
  }, [selectedTabName]);

  if (loading) return null;
  if (error) {
    if (error.message.includes("Unauthorized")) return <NotFound />;

    return <span>Something went wrong loading this investment.</span>;
  }
  if (!market) return <span>No market associated with investment.</span>;
  if (isPreviewOnly) {
    return <InvestmentTileQueryDataCentered id={Number(investmentId)} />;
  }

  const SummaryContainer =
    SummaryContainers[getProfileType()] || PublicInvestmentSummaryContainer;

  return (
    <HeaderHandlerInEditMode>
      <SummaryContainer
        {...props}
        investmentId={Number(investmentId)}
        fundRatings={fundRatings}
        investmentName={name}
        investmentShortName={shortName}
        profileType={getProfileType()}
        code={code}
        isImpact={!!data?.investment.isImpact}
        odcSourced={!!data?.investment.portalSubmitted?.migratedAt}
        isInActive={!!data?.investment.isInActive}
      />
    </HeaderHandlerInEditMode>
  );
}

export default InvestmentPageComponent;
