/* eslint-disable max-lines-per-function */
import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, DatePicker, notification, Select } from "antd";
import {
  ADD_NOTES,
  NOTES_META,
  NOTES_SEARCH,
} from "Components/NotesIndex/graphql";
import { notesIndexContext } from "Components/NotesIndex/NotesIndex.context";
import { formatDate } from "frontend/src/utils/date";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { abbreviation } from "Helpers/text_formatting";
import i18n from "i18next";
import React, { useContext, useMemo, useState } from "react";

import { RCGReactQuillInput } from "frontend/src/components/RCGReactQuillInput";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import dayjs from "dayjs";

const { Option } = Select;
const RCG_ORG_ID = 1;
const accessLevels = [
  { id: 1, name: "Public" },
  { id: 2, name: "Organization" },
];

export default function NoteAdd({
  investmentId,
  firmId,
  companyId,
}: {
  investmentId?: number;
  firmId?: number;
  companyId?: number;
}) {
  const { commonName, organizationId } = useCurrentUser();
  const { notesFilters, notesSort } = useContext(notesIndexContext);
  const [notifyTeam, changeNotifyTeam] = useState(false);
  const [text, changeText] = useState("");
  const [noteMetaId, changeNoteMetaId] = useState<number | null>(null);
  const [accessLevelId, changeAccessLevelId] = useState<number>(2);
  const [noteDate, changeNoteDate] = useState(new Date());
  const [expanded, changeExpanded] = useState(false);
  const { data } = useQuery(NOTES_META);
  const noteMetaEnums = useMemo(
    () =>
      data?.noteCategoryEnum?.noteTypes.flatMap(
        noteType => noteType.noteMetaEnums
      ),
    [data?.noteCategoryEnum]
  );
  const [addNote] = useMutation(ADD_NOTES, {
    refetchQueries: [
      {
        query: NOTES_SEARCH,
        variables: {
          filters: notesFilters,
          page: { offset: 0, limit: 15 },
          sort: [notesSort],
        },
      },
    ],
  });

  function handleSubmit() {
    if (isDefinedAndNotNull(noteMetaId)) {
      addNote({
        variables: {
          input: {
            text,
            investmentId,
            firmId,
            noteMetaId,
            companyId,
            accessLevelId,
            notifyTeam,
            noteDate: formatDate(noteDate, "yyyy-MM-dd"),
          },
        },
      });
      changeText("");
      changeNoteMetaId(null);
      changeExpanded(false);
    } else {
      notification.error({
        // eslint-disable-next-line max-len
        message: `Failed to add note, missing required field category. Please select a category and try again.`,
      });
    }
  }

  const label = noteMetaEnums?.find(meta => meta.id === noteMetaId)?.label;

  const [focus, changeFocus] = useState(expanded);

  return (
    <div className="fund-notes__add-wrap fund-notes__add-wrap--expand">
      <p className="fund-notes__user-img main-userpic main-userpic--36">
        {abbreviation(commonName)}
      </p>
      <div className="fund-notes__add-form">
        <div
          className="main-input"
          style={{
            border: "1px solid",
            borderColor: focus ? "#0078D4" : "grey",
            display: "flex",
            flexDirection: "column",
            justifyItems: "stretch",
            backgroundColor: "white",
          }}
          data-cy="note-body-0"
          onFocus={() => {
            changeExpanded(true);
            changeFocus(true);
          }}
          onBlur={() => {
            changeFocus(false);
          }}
        >
          <RCGReactQuillInput
            toolbarId={0}
            toolbarStyle={{ border: "none", borderBottom: "1px solid grey" }}
            textValue={text}
            onChange={content => changeText(content)}
            style={{
              overflow: "auto",
              lineHeight: "1.6em",
              minHeight: "3.2em",
              maxHeight: "32em",
            }}
          />
        </div>
        {expanded && data && noteMetaEnums && (
          <>
            <div className="d-flex  flex-wrap">
              <div className="fund-notes__category-field main-input">
                <p className="main-input__label">
                  {i18n.t("fund_notes.category")}
                </p>
                <Select
                  value={label || ""}
                  onChange={v => changeNoteMetaId(Number(v))}
                  data-cy="note-meta"
                >
                  {noteMetaEnums.map(meta => (
                    <Option
                      key={meta.id}
                      value={meta.id}
                      data-cy={`note-meta-${meta.id}`}
                    >
                      {meta.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="fund-notes__type-field main-input">
                <p className="main-input__label">Access</p>
                <Select
                  value={
                    accessLevels.find(({ id }) => id === accessLevelId)?.name
                  }
                  onChange={v => changeAccessLevelId(Number(v))}
                  data-cy="note-access-level"
                >
                  {accessLevels.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="fund-notes__date-field main-input">
                <p className="main-input__label">
                  {i18n.t("fund_notes.effective_date")}
                </p>
                <DatePicker
                  onChange={date => {
                    date && changeNoteDate(date.toDate());
                  }}
                  allowClear={false}
                  value={dayjs(noteDate)}
                />
              </div>
            </div>
            <div className="text-right">
              {organizationId === RCG_ORG_ID && (
                <div className="main-input">
                  <Checkbox
                    checked={notifyTeam}
                    onChange={() => changeNotifyTeam(!notifyTeam)}
                  >
                    Notify Team
                  </Checkbox>
                </div>
              )}
              <button
                onClick={() => {
                  changeText("");
                  changeNoteMetaId(null);
                  changeExpanded(false);
                }}
                type="submit"
                className="rounded-btn rounded-btn--grey mr-10"
              >
                {i18n.t("fund_notes.cancel")}
              </button>

              <button
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                disabled={!text || !isDefinedAndNotNull(noteMetaId)}
                className="rounded-btn rounded-btn--blue mr-16"
                cy-get="add-note"
              >
                Add Note
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
