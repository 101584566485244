import { gql } from "_graphql-types/gql";

export const FETCH_FIRM_CONTACT = gql(/* GraphQL */ `
  query FirmContacts($id: Int!) {
    firm(id: $id) {
      id
      email
      firmIrEmail
      primaryAddress {
        email
        id
        website
        phone
        line1
        line2
        line3
        city
        state {
          id
          name
        }
        stateEnumId
        country {
          name
          id
        }
        countryEnumId
        zipCode
      }
    }
  }
`);
