import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { startOfQuarter, lastDayOfQuarter, parseISO } from "date-fns";
import * as types from "_graphql-types/graphql";
import { DateSelector } from "Components/DateRangeSelector";
import classNames from "classnames";
import i18n from "i18next";
import {
  downloadSpreadsheet,
  SheetFormat,
  DownloadButtons,
} from "Helpers/downloadSpreadsheet";
import { formatLargeCurrency } from "Helpers/index";
import { PRIVATE_PEERS_STATS, PRIVATE_PEERS_ROOT } from "./graphql";
import Table from "./Table";

// eslint-disable-next-line max-lines-per-function
function PrivatePeers({ investmentId }: { investmentId: number }): JSX.Element {
  const { data } = useQuery<types.InvestmentPrivatePeersRootQuery>(
    PRIVATE_PEERS_ROOT,
    {
      variables: {
        id: investmentId,
      },
    }
  );

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  const [startPeriodDate, endPeriodDate] = useMemo(() => {
    if (
      !data ||
      !data.investment.performancePrivate ||
      !data.investment.performancePrivate.length
    )
      return [];
    const latestRecord = data.investment.performancePrivate[0];
    const firstRecord = data.investment.performancePrivate.slice(-1)[0];
    const start = new Date(
      firstRecord.returnYear,
      (firstRecord.returnQuarter - 1) * 3,
      1
    );
    const end = new Date(
      latestRecord.returnYear,
      (latestRecord.returnQuarter - 1) * 3,
      1
    );
    const startPeriod = start
      ? parseISO(start.toISOString().slice(0, 10))
      : undefined;
    const endPeriod = end
      ? parseISO(end.toISOString().slice(0, 10))
      : undefined;
    setSelectedDate(endPeriod);
    return [startPeriod, endPeriod];
  }, [data]);

  const { data: statsGroupData } =
    useQuery<types.InvestmentPrivatePeersStatsQuery>(PRIVATE_PEERS_STATS, {
      variables: {
        id: investmentId,
        performancePrivateFilter: {
          asOfDateRange: {
            start: selectedDate && startOfQuarter(selectedDate),
            end: selectedDate && lastDayOfQuarter(selectedDate),
          },
        },
        investmentPeersFilter: {
          investmentFilter: {
            active: true,
          },
        },
        investmentSort: [
          {
            field: "name",
            order: types.SortInput.Asc,
          },
        ],
      },
      skip: !selectedDate,
    });

  const statsGroup = statsGroupData?.investment?.peersList?.items;
  const download = (format: SheetFormat) =>
    statsGroup &&
    downloadSpreadsheet(
      [
        {
          name: "Sheet 1",
          data: [
            [
              i18n.t("analytics.private_peers.name"),
              i18n.t("analytics.private_peers.vintage_year"),
              i18n.t("analytics.private_peers.size"),
              i18n.t("analytics.private_peers.net_irr"),
              i18n.t("analytics.private_peers.tvpi"),
              i18n.t("analytics.private_peers.dpi"),
              i18n.t("analytics.private_peers.as_of_date"),
              i18n.t("analytics.private_peers.max_performance_date"),
            ],
            ...(statsGroup ?? []).map(
              ({
                name,
                vintageYear,
                committedCapital,
                committedCapitalCurrency,
                performancePrivate,
                performanceDataRange,
              }) => [
                name,
                vintageYear,
                formatLargeCurrency(
                  committedCapital,
                  committedCapitalCurrency?.name
                ),
                performancePrivate?.[0].netIRR
                  ? `${performancePrivate?.[0].netIRR.toFixed(1)}%`
                  : undefined,
                performancePrivate?.[0].tvpi,
                performancePrivate?.[0].dpi,
                performancePrivate
                  ? `Q${performancePrivate[0].returnQuarter} ${performancePrivate[0].returnYear}`
                  : "",
                performanceDataRange
                  ? `Q${performanceDataRange[0].returnQuarter} ${performanceDataRange[0].returnYear}`
                  : "",
              ]
            ),
          ],
        },
      ],
      `${statsGroupData?.investment.name} Peer Statistics`,
      format
    );

  return (
    <>
      <div className="summary-heading" data-cy="peer-statistics-private">
        <h3 className="invt-tab__title" id="peer_statistics_private">
          {i18n.t("overview.peer_statistics")}
        </h3>
        {statsGroup && (
          <div
            id="peer-statistics__date-selector"
            className={classNames("flex-row", "align-center")}
          >
            <p className="summary-heading__desc">As of</p>
            <DateSelector
              interval="quarter"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              range={
                startPeriodDate && endPeriodDate
                  ? { start: startPeriodDate, end: endPeriodDate }
                  : undefined
              }
            />
          </div>
        )}
      </div>
      <Table investments={statsGroup} />
      <DownloadButtons {...{ download }} />
    </>
  );
}

export default PrivatePeers;
