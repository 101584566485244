import React from "react";
import NoteAdd from "Components/InvestmentNotes/noteAdd";
import { NotesIndex } from "Components/NotesIndex";

export default function CompanyNotesComponent({
  companyId,
}: {
  companyId: number;
}) {
  return (
    <div className="main-content main-content--lg">
      <NotesIndex notesFilters={{ companyId }}>
        <NoteAdd companyId={companyId} />
      </NotesIndex>
    </div>
  );
}
