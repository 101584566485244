import { gql } from "_graphql-types/gql";

export const FETCH_INVESTMENT_POSITIONS = gql(/* GraphQL */ `
  query InvestmentPositions(
    $id: Int!
    $positionFilter: PositionFilterInput
    $page: PageInput
    $sort: [PositionSort!]
  ) {
    investment(id: $id) {
      id
      name
      positions(filter: $positionFilter, page: $page, sort: $sort) {
        items {
          id
          investmentId
          name
          weight
          ticker
          reportDate
          sourceDate
        }
        total
      }
    }
  }
`);
