export const TOGGLE_FIELD_OPTION = "TOGGLE_FIELD_OPTION";
export const DISABLE_FIELD_OPTION = "DISABLE_FIELD_OPTION";
export const ENABLE_FIELD_OPTION = "ENABLE_FIELD_OPTION";

export const toggleFieldOption = (key: string) => ({
  type: TOGGLE_FIELD_OPTION,
  payload: key,
});

export const disableFieldOption = (key: string) => ({
  type: DISABLE_FIELD_OPTION,
  payload: key,
});

export const enableFieldOption = (key: string) => ({
  type: ENABLE_FIELD_OPTION,
  payload: key,
});

export const togglePriorTrackRecord = () =>
  toggleFieldOption("priorTrackRecord");

export const enablePriorTrackRecord = () =>
  enableFieldOption("priorTrackRecord");

export const disablePriorTrackRecord = () =>
  disableFieldOption("priorTrackRecord");
