import { DatePicker as AntdDatePicker } from "antd";
import momentConfig from "rc-picker/lib/generate/moment";
import { isAfter, isBefore } from "date-fns";
import moment, { Moment } from "moment";
import React from "react";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

export function DateSelector({
  selectedDate,
  setSelectedDate,
  range,
  interval = "month",
}: {
  selectedDate: Date | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  range?: { start: Date; end: Date };
  interval?: "month" | "quarter" | "year";
}) {
  function handleChange(momentObj: Moment | null) {
    if (!momentObj) return;
    const date = momentObj.toDate();
    setSelectedDate(date);
  }
  function disabledDate(momentObj: Moment) {
    const date = momentObj.toDate();
    if (!range) return true;

    // return true if date is before the range start or after the range begining
    return isBefore(date, range.start) || isAfter(date, range.end);
  }

  let format = "MMM-yyyy";

  switch (interval) {
    case "quarter":
      format = "[Q]Q-YYYY";
      break;
    case "year":
      format = "YYYY";
      break;
    default:
      break;
  }

  return (
    <DatePicker
      picker={interval}
      onChange={handleChange}
      format={format}
      allowClear={false}
      disabledDate={disabledDate}
      value={selectedDate && moment(selectedDate)}
    />
  );
}
