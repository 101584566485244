import React from "react";
import classNames from "classnames";

export enum Layout {
  vertical = "vertical",
  horizontal = "horizontal",
  tile = "tile",
  list = "list",
  category = "category",
}

interface LayoutSwitcherProps {
  layout: Layout;
  changeLayout: React.Dispatch<React.SetStateAction<Layout>>;
  layoutOptions: Layout[];
}

export const LayoutSwitcher = ({
  layout,
  layoutOptions,
  changeLayout,
}: LayoutSwitcherProps) => (
  <div className="mr-16">
    {layoutOptions.includes(Layout.horizontal) && (
      <button
        type="button"
        className={classNames("toggle-view__btn icon icon-burger", {
          "toggle-view__btn--active": layout === "horizontal",
        })}
        onClick={() => changeLayout(Layout.horizontal)}
      />
    )}
    {layoutOptions.includes(Layout.vertical) && (
      <button
        type="button"
        className={classNames("toggle-view__btn icon icon-vertical-equal", {
          "toggle-view__btn--active": layout === "vertical",
        })}
        onClick={() => changeLayout(Layout.vertical)}
      />
    )}
    {layoutOptions.includes(Layout.tile) && (
      <button
        type="button"
        data-cy="tile-view-button"
        className={classNames("toggle-view__btn icon icon-bricks", {
          "toggle-view__btn--active": layout === "tile",
        })}
        onClick={() => changeLayout(Layout.tile)}
      />
    )}
    {layoutOptions.includes(Layout.list) && (
      <button
        type="button"
        data-cy="list-view-button"
        className={classNames("toggle-view__btn icon icon-burger", {
          "toggle-view__btn--active": layout === "list",
        })}
        onClick={() => changeLayout(Layout.list)}
      />
    )}
  </div>
);
