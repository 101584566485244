import { useMutation } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import { OktaAuth } from "@okta/okta-auth-js";
import { useEffect, useMemo } from "react";
import { UserActivityInput } from "_graphql-types/graphql";
import { useLocation } from "react-router-dom";
import config from "../config/config";
import { logException } from "../helpers";

interface Props {
  auth: OktaAuth;
}

export const HistoryLogger = ({ auth }: Props) => {
  const [addUserActivity] = useMutation(
    gql(/* GraphQL */ `
      mutation AddUserActivity($input: UserActivityInput!) {
        addUserActivity(input: $input) {
          id
        }
      }
    `)
  );

  const location = useLocation();

  const accessToken = auth.getAccessToken();
  const email = useMemo(() => {
    if (accessToken) {
      return auth.token.decode(accessToken).payload.sub;
    }
    return undefined;
  }, [accessToken]);

  const logLocation = async ({ pathname, search, hash }: typeof location) => {
    const APP_VERSION = config.SENTRY_RELEASE();
    if (pathname.startsWith("/implicit")) {
      // do not log auth navigation
      return;
    }
    if (!email) {
      // do not log for unauthenticated user
      return;
    }
    if (!APP_VERSION) {
      console.warn("Unknown Application Verison");
      return;
    }
    const requestUri = [pathname, search, hash].join("");
    const input: UserActivityInput = {
      email,
      applicationId: config.SIRIUS_APP_ID(),
      rcgApplicationVersion: APP_VERSION,
      requestUri,
      requestUtc: new Date().toISOString(),
    };
    const { errors } = await addUserActivity({ variables: { input } });
    if (errors?.length) {
      errors.forEach(logException);
    }
  };

  useEffect(() => {
    logLocation(location);
  }, [location]);

  return null;
};
