import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { extname } from "path";
import React from "react";

export const ChatSelecter = ({
  doc,
  setSpecificChatDocumentId,
}: {
  doc: { name: string; id: number };
  setSpecificChatDocumentId: (id: number) => void;
}) => {
  const contentType = extname(doc.name);

  if (contentType !== ".pdf") {
    return null;
  }

  return (
    <Tooltip title={`Chat about this document`}>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusCircleOutlined />}
        size="small"
        onClick={() => {
          setSpecificChatDocumentId(doc.id);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        style={{
          fontSize: 14,
          margin: "3px 3px",
        }}
      />
    </Tooltip>
  );
};
