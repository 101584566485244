import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";

import ColumnChart from "./ColumnChart.component";
import { GET_RETURN_DISTRIBUTION } from "./graphql";

const COLUMNS = [
  "-10+",
  "-10-8",
  "-8-6",
  "-6-4",
  "-4-2",
  "-2-0",
  "0-2",
  "2-4",
  "4-6",
  "6-8",
  "8-10",
  "10+",
];

function DistributionOfReturns({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element | null {
  const { data } = useQuery(GET_RETURN_DISTRIBUTION, {
    variables: { investmentId },
  });

  const returnDistribution =
    data?.investment?.performanceAnalysis?.returnDistribution;
  const name = data?.investment?.name;
  const chartData = useMemo(() => {
    if (!returnDistribution) return null;

    return [
      {
        name: name ?? "",
        data: [
          returnDistribution.underNegative10,
          returnDistribution.negative10to8,
          returnDistribution.negative8to6,
          returnDistribution.negative6to4,
          returnDistribution.negative4to2,
          returnDistribution.negative2to0,
          returnDistribution.positive0to2,
          returnDistribution.positive2to4,
          returnDistribution.positive4to6,
          returnDistribution.positive6to8,
          returnDistribution.positive8to10,
          returnDistribution.overPositive10,
        ],
      },
    ];
  }, [returnDistribution, name]);

  if (!chartData) return null;

  if (chartData[0].data.every(value => value === 0)) {
    return null; // no data to show
  }

  return (
    <div className="invt-tab__section overview-page__card">
      <ColumnChart
        categories={COLUMNS}
        title="Distribution of Returns"
        chartData={chartData}
      />
    </div>
  );
}

export default DistributionOfReturns;
