import {
  CompanySearchWorkspace,
  InvestmentSearchWorkspace,
  PortfolioWorkspace,
} from "Components/GlobalSearchWorkspace";
import TermsOfServicePolicy from "Components/TermsOfServicePolicy";
import React, { useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes, Navigate } from "react-router-dom";
import CompanyPage from "./CompanyPage";
import Forbidden from "./errors/forbidden";
import FirmPage from "./FirmPage";
import { GlobalNotesComponent } from "./GlobalNotes";
import { GlobalDocuments } from "./global_documents";
import InvestmentPage from "./InvestmentPage";
import Main from "./main";
import SettingsLoader from "./SettingsLoader";
import NotFound from "./show/not_found.js.jsx";
import { VersionChecker } from "./ApplicationVersionCheck";
import { Authorized } from "./Authorized";
import { InternalDashboards } from "./InternalDashboards";
import { ChatPage } from "./ChatPage";
import { GlobalFieldsComponent } from "./GlobalFields";
import { useCurrentUser } from "frontend/src/utils/hooks";
import PdfShowPage from "./show/documents/documentViewer";
import { UserFlagCheck } from "./Authorized/Authorized";
import { usePortfolioSearch } from "./GlobalSearchWorkspace/InvestmentSearch.hooks";
import { ClientViewCheck } from "./ClientView";

// static props to avoid re-rendering
const isRockCreekCheck: UserFlagCheck[] = ["isRockCreek"];
const companyListChecks: UserFlagCheck[] = isRockCreekCheck;
const companyShowChecks: UserFlagCheck[] = isRockCreekCheck;
const chatChecks: UserFlagCheck[] = ["canSiriusChat"];
const investmentPageChecks: UserFlagCheck[] = ["investmentPageAccess"];

export const HomeRoute = () => {
  const user = useCurrentUser();

  const { result } = usePortfolioSearch();
  const homePage = useMemo(() => {
    if (user.clientView) {
      if (result?.total) {
        return "/portfolios";
      }
    }
    if (user.flags?.investmentPageAccess) {
      return "/investments";
    } else if (user.flags?.isRockCreek) {
      return "/company";
    } else {
      return "/notes";
    }
  }, [user, result]);

  if (!result) {
    return null;
  }

  return <Navigate to={homePage} replace={true} />;
};

const MercuryApp = () => (
  <DndProvider backend={HTML5Backend}>
    <SettingsLoader>
      <TermsOfServicePolicy>
        <VersionChecker />
        <ClientViewCheck />

        <Routes>
          <Route path="/" element={<HomeRoute />} />

          <Route
            path="/notes"
            element={
              <Main type="tile">
                <GlobalNotesComponent />
              </Main>
            }
          />

          <Route
            path="/notes/*"
            element={
              <Main type="tile">
                <GlobalNotesComponent />
              </Main>
            }
          />

          <Route
            path="/documents"
            element={
              <Main type="tile">
                <GlobalDocuments />
              </Main>
            }
          />

          <Route
            path="/documents/:documentId"
            element={
              <Main type="centered">
                <PdfShowPage />
              </Main>
            }
          />

          <Route
            path="/403"
            element={
              <Main type="centered">
                <Forbidden />
              </Main>
            }
          />

          <Route
            path="firms/:firmId/*"
            element={
              <Main type="centered">
                <FirmPage />
              </Main>
            }
          />

          <Route
            path="/investments"
            element={
              <Authorized checks={investmentPageChecks} redirect>
                <Main type="tile">
                  <InvestmentSearchWorkspace />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="/investments/:investmentId/*"
            element={
              <Main type="centered">
                <InvestmentPage />
              </Main>
            }
          />

          <Route
            path="/diligenceSearch"
            element={
              <Authorized checks={isRockCreekCheck} redirect>
                <Main type="tile">
                  <GlobalFieldsComponent />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="/portfolios"
            element={
              <Main type="tile">
                <PortfolioWorkspace />
              </Main>
            }
          />

          <Route
            path="/company"
            element={
              <Authorized checks={companyListChecks} redirect>
                <Main type="tile">
                  <CompanySearchWorkspace />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="/company/:companyId/*"
            element={
              <Authorized checks={companyShowChecks} redirect>
                <Main type="centered">
                  <CompanyPage />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="/internalDashboards"
            element={
              <Authorized checks={["isRockCreek"]} redirect>
                <Main type="centered">
                  <InternalDashboards />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="/chat"
            element={
              <Authorized checks={chatChecks} redirect>
                <Main type="centered" style={{ backgroundColor: "#F0F0F0" }}>
                  <ChatPage />
                </Main>
              </Authorized>
            }
          />

          <Route
            path="*"
            element={
              <Main type="tile">
                <NotFound />
              </Main>
            }
          />
        </Routes>
      </TermsOfServicePolicy>
    </SettingsLoader>
  </DndProvider>
);

export default MercuryApp;
