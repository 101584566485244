import React from 'react';

const Version1 = () => (
  <>
    <h3 className="clear-margin-top">Related to Product in General</h3>

    <p>
      Welcome to RockCreek's innovative research and analysis platform (the "Platform").  The Platform provides users like you (the "User" and collectively "Users") with certain tools and information used by RockCreek to support its asset management business.  The Platform provides transparency within each aspect of the investment process, including customized analytics tailored to address specific risk and investment challenges of institutional investors.
    </p>

    <p>
      You acknowledge that the Platform will be utilized by you solely for the purposes contemplated herein, with respect to your review, from time to time, of the information about funds considered for your portfolio. You acknowledge this access is non-transferable and that you hold no property, copyright, intellectual property or other rights in the information and tools provided other than the right to use the Platform for the purposes permitted in connection with your relationship with RockCreek. You shall use the information and tools provided for your sole and exclusive use and benefit. You shall not disclose, copy, sell such tools and the information provided with regard to the Platform.
    </p>

    <p>
      You shall provide the RockCreek with a listing of employees you would like RockCreek to provide Platform access to. Such listing shall be updated by you from time to time, if so required. This provision remains in effect upon the departure of any of such persons from your staff. If an employee is no longer employed by you, you shall ensure that the information and tools provided hereunder have been safeguarded.  Upon RockCreek's request, you shall furnish to it evidence of sufficiently robust policies and procedures to safeguard the information referred to herein. You shall take commercially reasonable precautions and use best efforts to protect the secrecy of and to avoid unauthorized disclosure, use, reproduction, summarization, reverse engineering and/or distribution of the information and tools provided by the Platform. Your standard of care with regard to the safekeeping of such information shall be no less that the standard in place with respect to safekeeping of your own confidential information. You shall cooperate as reasonable with the RockCreek's security testing of your computer systems to ensure that your systems permit and are compatible with the use of the tools provided.
    </p>

    <p>
      RockCreek reserves the right to change any and all content, software and other items used or contained in the platform any time without notice. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by RockCreek.
    </p>

    <p>
      ROCK CREEK SHALL NOT BE LIABLE FOR ANY DAMAGES OR COSTS OF ANY TYPE ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE PLATFORM.  THE SERVICE AND THE CONTENT ARE PROVIDED "AS-IS" AND ROCK CREEK DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. ROCK CREEK CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE PLATFORM. ROCK CREEK DOES NOT REPRESENT OR WARRANT THAT SOFTWARE, CONTENT OR MATERIALS ON THE SERVICE OR ELSEWHERE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE SERVICE OR ANY OF THE SERVERS USED TO OPERATE THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY CONTENT OR MATERIALS AND USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES. WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT, DATA OR OTHER MATERIAL FROM OR THROUGH THE SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM OF ANY KIND THAT MAY RESULT.
    </p>

    <p>
      IN NO EVENT WILL ROCK CREEK, OR ITS AFFILIATES, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SERVICE, OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SERVICE, EVEN IF ROCK CREEK IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE COMPANY FOR THE SERVICE IN THE IMMEDIATELY PRECEDING TWELVE MONTHS, BUT IN NO CASE WILL ROCK CREEK'S LIABILITY TO YOU EXCEED $1000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE ROCK CREEK FOR THE SERVICE, YOU SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM ROCK CREEK, REGARDLESS OF THE CAUSE OF ACTION.
    </p>

    <h3 className="clear-margin-top">Data and Reports</h3>

    <h4>Generally</h4>
    <p>
      This information is for general informational purposes only. It is presented without any representation or warranty as to its accuracy, completeness or timeliness.  No part of this material may be reproduced in any form, or referred to in any other publication.
    </p>

    <p>
      RockCreek is not responsible or liable in any manner for any content, whether posted by RockCreek or by third parties. We do not control and are not responsible for what information fund managers provide or share on the platform, and are not responsible for any inaccurate, offensive, incorrect, outdated, unlawful or otherwise objectionable content you may encounter on the platform.  The platform may be temporarily unavailable from time to time for maintenance or other reasons. RockCreek assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, content.
    </p>

    <p>
      RockCreek is not responsible for any technical malfunction or other problems of any telephone network or service, computer systems, servers or providers, computer or mobile phone equipment, or software on account of technical problems or traffic congestion on the Internet or at any site or with respect to the Service or combination thereof, including injury or damage to user's or to any other person's computer, mobile phone, or other hardware or software, related to or resulting from using or downloading content in connection with the Web and/or in connection with the platform. Under no circumstances will RockCreek be responsible for any loss or damage, including any loss or damage to any content, resulting from anyone's use of the platform or any posted on or through the platform or transmitted to users.
    </p>

    <h4>Accuracy of Reports</h4>
    <p>
      Our reports are based upon information gathered from various sources believed to be reliable but are not guaranteed as to accuracy or completeness. The information is not intended to be, and shall not constitute, an offer to sell or a solicitation of an offer to buy any security or investment product or service. The information in this report is subject to change without notice, and RockCreek assumes no responsibility to update the information contained in the site.
    </p>

    <p>
      Information that RockCreek uses in its evaluations of investment funds is obtained from the fund manager, a fund's service providers, or other external sources. In most cases, RockCreek is unable to verify much of the information that it receives. The information obtained may be inaccurate or incomplete. In addition, RockCreek may not receive, or receive in a timely manner, communications or documents typically available to investors that could be materially relevant to our evaluation. RockCreek does not evaluate funds on a daily or real-time basis. Consequently, there is a risk that the current view on the alternative investment may change. RockCreek does not control the underlying investments in a manager's portfolio. RockCreek does not control any manager's daily business or compliance program, and therefore is unable to enforce or implement any manager controls that could be necessary to prevent fraud or other business, regulatory or reputational risks.
    </p>

    <h4>Generally Related to Investments</h4>
    <p>
      Investing in alternative investment securities involves a risk of loss that Users should be prepared to bear. To properly understand the risks, fees and expenses associated with a particular alternative investment security, Users should carefully review the specific fund's private placement memorandum or other offering documents.
    </p>

    <p>
      Users should be aware that fund managers with a successful track record may not be able to maintain the same or similar levels of fund performance in the future. Past performance is not guarantee of future results. Investing in alternative investments can be speculative and involve a high degree of risk. Users could lose all or a substantial amount of any investments made in such securities. Furthermore, alternative investment funds can involve complex tax structures and charge fees that offset any trading profits. Some alternative investment funds may have limited operating histories and can be highly volatile.
    </p>

    <p>
      In many cases, there may not be a secondary market for interests in alternative investment funds. Users can be subject to restrictions on redemptions and transfers of interests in such funds and such interests may otherwise be illiquid. Some alternative investment funds may have increased risk exposure from the use of leverage or focus on a concentrated strategy. Investments in alternative investment securities are not guaranteed and Users could lose money on their investments.
    </p>
  </>
);

export default Version1;
