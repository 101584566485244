import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";
import { isNumber } from "../..";

export function formatPercent(value: number) {
  return `${(value * 100).toFixed(2)}%`;
}

function PerformanceFilterTag({
  filter,
}: {
  filter: types.PerformanceSearchFilter;
}) {
  const dispatch = useDispatch();
  const { statType, max, min } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="performance-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "PERFORMANCE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {statType}{" "}
      {isNumber(min) && isNumber(max) ? (
        <>
          between {formatPercent(min)} and {formatPercent(max)}
        </>
      ) : isNumber(min) ? (
        <>&ge; {formatPercent(min)}</>
      ) : isNumber(max) ? (
        <>&le; {formatPercent(max)}</>
      ) : null}
    </Tag>
  );
}

export default PerformanceFilterTag;
