import React from "react";
import { CompanyListRow } from "../CompanyListRow";

interface Props {
  companyIds: number[];
}

export function CompanyList({ companyIds }: Props) {
  return (
    <div className="main-fund-row-list">
      <div className="main-fund-row-list-wrap">
        <div
          className="main-fund-row main-fund-row--header"
          data-cy="list-header-row"
        >
          <table>
            <tbody>
              <tr>
                <th className="main-fund-row__base-info-col">Name</th>
                <th className="main-fund-row__strategy-col">Industry</th>
                <th className="main-fund-row__strategy-col">SDGs</th>
                <th className="main-fund-row__strategy-col">Year Founded</th>
                <th className="main-fund-row__aum-col">Valuation</th>
                <th className="main-fund-row__actions-col" />
              </tr>
            </tbody>
          </table>
        </div>
        {companyIds.map(id => (
          <CompanyListRow key={id} id={id} />
        ))}
      </div>
    </div>
  );
}
