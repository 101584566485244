import { getInitials } from "Helpers/index";
import classNames from "classnames";

interface Props {
  personName?: string | null;
  personId?: string | number | null;
  photoLink?: string | null;
  mainClassName?: string;
  fallbackClassName?: string;
}

const ProfileImage = ({
  personName,
  personId,
  photoLink,
  mainClassName,
  fallbackClassName,
}: Props) => {
  const renderUserIcon = () => {
    const divClassNames = classNames(
      "cms-overview-advisor__img",
      "main-userpic",
      "main-userpic--100"
    );

    return (
      <div className={divClassNames}>
        <i className="icon icon-user" />
      </div>
    );
  };

  if (!personName || !personId) {
    return renderUserIcon();
  }

  return photoLink ? (
    <img className={mainClassName} src={photoLink} alt="profile" />
  ) : (
    <span
      aria-hidden="true"
      data-testid="profile-initials-icon"
      className={fallbackClassName}
    >
      {getInitials(personName)}
    </span>
  );
};

ProfileImage.defaultProps = {
  fallbackClassName: "ant-tag__userpic-icon main-userpic",
  photoLink: undefined,
  personName: undefined,
  personId: null,
};

export default ProfileImage;
