import { useQuery } from "@apollo/client";
import { unhandledCase } from "Reducers/globalSearchV2/globalSearchV2.types";
import {
  GetGlobalSearchCompanyQuery,
  GetGlobalSearchDocumentQuery,
  GetGlobalSearchFirmQuery,
  GetGlobalSearchInvestmentQuery,
  GetGlobalSearchNoteQuery,
} from "_graphql-types/graphql";
import { useMemo } from "react";
import {
  AutoCompleteResults,
  GlobalSearchOptions,
} from "./GlobalSearchEntityOptions.component";
import {
  GET_GLOBAL_SEARCH_COMPANY,
  GET_GLOBAL_SEARCH_DOCUMENT,
  GET_GLOBAL_SEARCH_FIRM,
  GET_GLOBAL_SEARCH_INVESTMENT,
  GET_GLOBAL_SEARCH_NOTE,
} from "./graphql";

type InvestmentDropdownProps = {
  searchTerm: string;
  blur: () => void;
};

export function InvestmentDropdown({
  searchTerm,
  blur,
}: InvestmentDropdownProps) {
  const queries = {
    investment: useQuery(GET_GLOBAL_SEARCH_INVESTMENT, {
      skip: !searchTerm,
      variables: {
        searchFilters: [
          {
            SEARCH_TERM: {
              value: searchTerm,
            },
          },
          {
            PORTFOLIO: {
              isPortfolio: false,
            },
          },
        ],
      },
    }),
    portfolio: useQuery(GET_GLOBAL_SEARCH_INVESTMENT, {
      skip: !searchTerm,
      variables: {
        searchFilters: [
          {
            SEARCH_TERM: {
              value: searchTerm,
            },
          },
          {
            PORTFOLIO: {
              isPortfolio: true,
            },
          },
        ],
      },
    }),
    company: useQuery(GET_GLOBAL_SEARCH_COMPANY, {
      skip: !searchTerm,
      variables: {
        companyFilters: [
          {
            NAME: searchTerm,
          },
        ],
      },
    }),
    firm: useQuery(GET_GLOBAL_SEARCH_FIRM, {
      skip: !searchTerm,
      variables: {
        firmFilter: {
          q: searchTerm,
        },
      },
    }),
    note: useQuery(GET_GLOBAL_SEARCH_NOTE, {
      skip: !searchTerm,
      variables: {
        noteFilter: {
          text: searchTerm,
        },
      },
    }),
    document: useQuery(GET_GLOBAL_SEARCH_DOCUMENT, {
      skip: !searchTerm,
      variables: {
        documentFilter: {
          text: searchTerm,
        },
      },
    }),
  };

  const getResult = (key: keyof typeof queries) => {
    const { loading, data } = queries[key];
    switch (key) {
      case "investment":
      case "portfolio":
        return {
          loading,
          data: (data as GetGlobalSearchInvestmentQuery)?.investment,
        };
      case "company":
        return {
          loading,
          data: (data as GetGlobalSearchCompanyQuery)?.company,
        };
      case "firm":
        return {
          loading,
          data: (data as GetGlobalSearchFirmQuery)?.firm,
        };
      case "note":
        return {
          loading,
          data: (data as GetGlobalSearchNoteQuery)?.note,
        };
      case "document":
        return {
          loading,
          data: (data as GetGlobalSearchDocumentQuery)?.document,
        };
      default:
        throw unhandledCase(key);
    }
  };

  const results = useMemo(
    () => ({
      investments: getResult(
        "investment"
      ) as AutoCompleteResults["investments"],
      company: getResult("company") as AutoCompleteResults["company"],
      firms: getResult("firm") as AutoCompleteResults["firms"],
      notes: getResult("note") as AutoCompleteResults["notes"],
      documents: getResult("document") as AutoCompleteResults["documents"],
      portfolios: getResult("portfolio") as AutoCompleteResults["investments"],
    }),
    Object.values(queries).map(({ data }) => data)
  );

  return (
    <GlobalSearchOptions
      searchTerm={searchTerm}
      results={results}
      blur={blur}
    />
  );
}
