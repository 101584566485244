import classnames from "classnames";
import numeral from "numeral";
import { useContext } from "react";
import { InvestmentTileContext } from "../context";
import { PieChartOutlined } from "@ant-design/icons";
import placeHolderImageUrl from "./image.png";

export function PortfolioTileFooter() {
  const {
    details,
    investment: { portfolioConfig },
  } = useContext(InvestmentTileContext);

  return (
    <div className="tile-footer -padded">
      <div className="tile-footer__col">
        <img
          height={70}
          width={70}
          src={
            portfolioConfig?.tileImage?.signedThumbnailUrl ||
            placeHolderImageUrl
          }
        ></img>
      </div>

      <div className="tile-footer__col text-right">
        <div className="asset-value tile-footer__col-string fund-aum fund-tile-aum">
          {details?.assetValue
            ? numeral(details.assetValue).format("0.0a").slice(0, -1)
            : "-"}
          <span className="font-weight-400 font-14 in-secondary-blue pl-4">
            {details?.assetValue &&
              `${numeral(details.assetValue).format("0.0a").slice(-1)} ${
                details.assetValueCurrency ?? ""
              }`}
          </span>
        </div>

        <div className="asset-value-type tile-footer__col-string tile-footer-medium">
          {details?.assetValueType}
        </div>
        <div className="asset-value-date tile-footer__col-string tile-footer-small">
          {details?.assetValueDate}
        </div>
      </div>
    </div>
  );
}
