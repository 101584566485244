import { gql } from "_graphql-types/gql";

export const GET_PORTFOLIO_CONFIG = gql(/* GraphQL */ `
  query GetPortfolioConfig($id: Int!) {
    porfolioConfigByPortfolioId(id: $id) {
      id
      showInvestments
      performanceLag {
        lag
        lagUnit {
          id
          name
        }
        lagUnitId
      }
      portfolioId
      portfolio {
        id
        name
      }
      lookthroughEnabled
      lookthroughInvestments {
        id
        name
      }
      tileImageId
      tileImage {
        id
        name
        signedUrl
      }
      clientVisibleTabs
    }
  }
`);
