/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-lines-per-function */
import { useApolloClient } from "@apollo/client";
import classnames from "classnames";
import { INVESTMENT_TILE } from "Components/graphql/fragments/Investment";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { getSearchResultDetails } from "Helpers/investment_helpers";
import { camelCase, compact, startCase, uniq } from "lodash";
import numeral from "numeral";
import { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

interface Props {
  id: number;
}

function InvestmentListRowComponent({ id }: Props) {
  const client = useApolloClient();

  const investment = client.readFragment({
    id: `Investment:${id}`,
    fragment: INVESTMENT_TILE,
  });
  if (!investment) return null;

  const details = useMemo(
    () => getSearchResultDetails(investment),
    [investment]
  );

  const assetClass = investment.assetClass?.name;
  const strategy = details?.strategy;
  const investmentTags = uniq(
    (investment.investmentTags || []).map(
      investmentTag => investmentTag?.tag?.name
    )
  );
  const tags = useMemo(
    () => compact([assetClass, strategy, ...investmentTags]),
    [assetClass, strategy, investmentTags]
  );

  const pmName =
    investment.primaryPortfolioManager?.firmEmployment &&
    compact([
      investment.primaryPortfolioManager.firmEmployment.person.firstName,
      investment.primaryPortfolioManager.firmEmployment.person.middleName,
      investment.primaryPortfolioManager.firmEmployment.person.lastName,
    ]).join(" ");

  const location = investment.firm
    ? compact([
        investment?.firm?.primaryAddress?.city,
        investment?.firm?.primaryAddress?.state?.code ??
          investment?.firm?.primaryAddress?.otherState,
        investment?.firm?.primaryAddress?.country?.name &&
          startCase(camelCase(investment?.firm?.primaryAddress?.country.name)),
      ]).join(", ")
    : "";

  const geoFocus = investment.primaryGeographicFocus?.name;

  const link = useMemo(() => `/investments/${id}`, [id]);
  const { isSelected, toggleInvestmentId, isActive } = useContext(
    InvestmentControlsContext
  );
  const toggleInvestment: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.preventDefault();
      return toggleInvestmentId(Number(id));
    },
    [toggleInvestmentId, id]
  );

  return (
    <div className="main-fund-row fund -full-access">
      <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>
        <table>
          <tbody>
            <tr>
              {isActive && (
                <td className="main-fund-row__checkbox-col">
                  <div className="main-checkbox" onClick={toggleInvestment}>
                    <input
                      id={`investment-list-${id}`}
                      type="checkbox"
                      checked={isSelected(Number(id))}
                      className="main-checkbox__input main-checkbox__input--only-checkbox"
                      readOnly
                    />
                    <label
                      className="main-checkbox__label"
                      htmlFor={`investment-list-${id}`}
                    />
                  </div>
                </td>
              )}
              <td className="main-fund-row__base-info-col">
                <div className="d-flex align-items-center">
                  <div className="main-fund-row__base-info-col-content clearfix">
                    <p className="main-fund-row__name fund-name">
                      {investment.name}
                    </p>
                    <div className="main-fund-row__info hidden-md-down">
                      {details?.inceptionDate && (
                        <span className="d-inline-block va-middle mr-10 inception-date">
                          <i className="icon icon--20 icon-calendar font-17 mr-8" />
                          <span className="d-inline-block va-middle">
                            {details?.inceptionDate}
                          </span>
                        </span>
                      )}
                      {pmName && (
                        <span className="d-inline-block va-middle mr-10 portfolio-manager">
                          <i className="icon icon--20 icon-user font-14 mr-8" />
                          <span className="d-inline-block va-middle">
                            {pmName}
                          </span>
                        </span>
                      )}
                      {location && (
                        <span className="d-inline-block va-middle mr-10 location">
                          <i className="icon icon--20 icon-pin font-17 mr-8" />
                          <span className="d-inline-block va-middle">
                            {location}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="d-flex justify-between hidden-md-up">
                      <div className="d-flex">
                        <span className="main-fund-row__numbers in-plue-900">
                          -
                        </span>
                        <span> 1Y </span>
                      </div>
                      <div className="d-flex">
                        <span className="main-fund-row__numbers in-blue-900">
                          -
                        </span>
                        &nbsp;
                        <span>Size</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="main-fund-row__strategy-col tags">
                <div className="main-fund-row__strategy-col-content">
                  {tags.map(tag => (
                    <span
                      key={tag}
                      className="main-fund-row__tag ant-tag ant-tag--fund"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </td>
              <td className="main-fund-row__geography-col">
                <div>{geoFocus}</div>
              </td>
              <td className="main-fund-row__aum-col">
                <span className="main-fund-row__numbers in-blue-900 asset-value">
                  {details?.assetValue
                    ? numeral(details.assetValue).format("0.0a").slice(0, -1)
                    : "-"}
                  <span className="in-secondary-blue">
                    {details?.assetValue &&
                      `${numeral(details.assetValue)
                        .format("0.0a")
                        .slice(-1)} ${details.assetValueCurrency ?? ""}`}
                  </span>
                </span>
                <p className="main-fund-row__range asset-value-date">
                  {details?.assetValueDate && `As of ${details.assetValueDate}`}
                </p>
              </td>
              <td className="main-fund-row__perform-col">
                <span
                  className={classnames(
                    "main-fund-row__numbers",
                    "fund-performance",
                    {
                      negative: Number(details?.performance) < 0,
                      positive: Number(details?.performance) > 0,
                      neutral: !details?.performance,
                    }
                  )}
                >
                  {details?.performance
                    ? numeral(details.performance).format("0.00%")
                    : "-"}
                </span>
                <p className="main-fund-row__range fund-performance-date">
                  {details?.performanceDate}
                </p>
              </td>
              <td className="main-fund-row__actions-col" draggable="true">
                <span className="main-fund-row__dnd-icon icon icon--20 icon-drag-and-drop " />
              </td>
            </tr>
          </tbody>
        </table>
      </Link>
      <div className="main-fund-row__bottom-shadow" />
    </div>
  );
}

export default InvestmentListRowComponent;
