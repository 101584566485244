import i18n from "i18next";
import { Spin, AutoComplete, Select } from "antd";
import React from "react";
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { useDebounce } from "Helpers/hooks";
import { FETCH_INVESTMENT_SETS } from "./graphql";

const { Option } = Select;

export function CompanySetSelect({
  name,
  updateForm,
  setSelectedList,
  selectedList,
}: {
  name: string;
  selectedList: types.InvestmentSetQuery["investmentSet"] | null;
  setSelectedList: (
    arg0: types.InvestmentSetQuery["investmentSet"] | null
  ) => void;
  updateForm: ({ name }: { name: string }) => void;
}) {
  const debouncedName = useDebounce(name, 250);
  const { data, loading } = useQuery(FETCH_INVESTMENT_SETS, {
    variables: {
      query: debouncedName,
    },
    fetchPolicy: "cache-and-network", // incase list is added
  });
  return (
    <div className="main-input">
      <div className="main-input__label">
        {selectedList
          ? `${i18n.t("funds.form.update_list_message")} ${selectedList.name}`
          : i18n.t("funds.form.new_list_message")}
      </div>

      <AutoComplete
        showSearch
        value={name}
        showArrow={false}
        searchValue={undefined}
        allowClear
        filterOption={false}
        defaultValue={name || undefined}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        onSearch={value => {
          updateForm({ name: value });
        }}
        onSelect={(value: string | number) => {
          const investmentSet = data?.investmentSetList.items.find(
            ({ id }: { id: number }) => id === Number(value)
          );
          setSelectedList(investmentSet);
        }}
        onClear={() => {
          setSelectedList(null);
        }}
        placeholder="Select a List"
      >
        {data &&
          data.investmentSetList.items.map(investmentSet => {
            if (!investmentSet.id || !investmentSet.name) return null;
            return (
              <Option value={investmentSet.id} key={investmentSet.id}>
                <div>
                  <h4>{investmentSet.name}</h4>
                </div>
                <div>
                  {investmentSet.createUser}
                  <span className="text-right">{investmentSet.updateDate}</span>
                </div>
              </Option>
            );
          })}
      </AutoComplete>
    </div>
  );
}
