import React, { useContext, useCallback, useState, useMemo } from "react";
import i18n from "i18next";
import InvestmentSetForm from "Components/InvestmentSetForm";
import { SearchDownloader } from "Components/InvestmentSearchDownloader";
import { InvestmentControlsContext } from "./context";

export function InvestmentControlsActions({
  ExtraControls,
}: {
  ExtraControls?: () => JSX.Element;
}): JSX.Element {
  const { selectedInvestmentIds, clearSelected, selectAll } = useContext(
    InvestmentControlsContext
  );

  const [isAddToListVisible, setIsAddToListVisible] = useState(false);
  const [isDownloadListVisible, setDownloadListVisible] = useState(false);

  const showAddToList = () => {
    setIsAddToListVisible(true);
  };

  const handleCancel = () => {
    setIsAddToListVisible(false);
  };

  const clearSelection = useCallback(() => {
    clearSelected();
  }, [clearSelected]);

  return (
    <div>
      <div className="main-gf__specific-actions main-select-actions">
        {ExtraControls && <ExtraControls />}
        {selectedInvestmentIds.size === 0 && (
          <div className="main-gf__specific-actions main-select-actions">
            <div
              className="main-select-actions__item main-select-actions__item"
              data-cy="select-all-investments"
              onClick={selectAll}
            >
              <span className="main-select-actions__icon icon icon-plus" />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.select_all")}
              </span>
            </div>
          </div>
        )}
        {selectedInvestmentIds.size > 0 && (
          <>
            <div className="main-select-actions__item main-select-actions__item--passive">
              <span
                className="main-select-actions__icon icon icon-checkbox-minus"
                data-cy="clear-selected-investments"
                onClick={clearSelection}
              />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.selected_amount", {
                  amount: `${selectedInvestmentIds.size}`,
                })}
              </span>
            </div>

            <div
              className="main-select-actions__item"
              onClick={() => {
                setDownloadListVisible(true);
              }}
              data-cy="download-selected-investments"
            >
              <span className="main-select-actions__icon icon icon-download" />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.download")}
              </span>
            </div>

            <div
              id="add-to-list-action"
              className="main-select-actions__item"
              data-cy="add-selected-investments-to-list"
              onClick={showAddToList}
            >
              <span className="main-select-actions__icon icon icon-plus" />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.add_to_list")}
              </span>
            </div>

            {isDownloadListVisible && (
              <SearchDownloader
                handleCancel={() => setDownloadListVisible(false)}
                visible={isDownloadListVisible}
              />
            )}

            {isAddToListVisible && (
              <InvestmentSetForm
                handleCancel={handleCancel}
                visible={isAddToListVisible}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
