import { TChartData } from "./types";

export const sortStringColumns = (currEl?: string, nextEl?: string): number => {
  const currE = currEl?.toLowerCase() ?? "";
  const nextE = nextEl?.toLowerCase() ?? "";
  if (currE < nextE) return -1;
  if (currE > nextE) return 1;
  return 0;
};

export const renderCell = (value: any, fallback = "-") => {
  if (value === undefined || value === null) return fallback;
  return `${value}%`;
};

export const isDefined = (num?: number | null): num is number =>
  num !== undefined && num !== null;

export function sortNumColumns(
  currEl?: number | null,
  nextEl?: number | null
): number {
  if (isDefined(currEl) && isDefined(nextEl)) return currEl - nextEl;
  if (!isDefined(currEl) && isDefined(nextEl)) return 1;
  if (isDefined(currEl) && !isDefined(nextEl)) return -1;
  return 0;
}

export const filterAdditonalMinorityGroup = (data: TChartData): boolean =>
  data.deiCategory1Enum.isAdditionalMinorityGroup;
