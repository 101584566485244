/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines-per-function */
import { ApolloError, useQuery } from "@apollo/client";
import classNames from "classnames";
import { DateSelector } from "Components/DateRangeSelector";
import { differenceInMonths, eachMonthOfInterval, parseISO } from "date-fns";
import {
  DownloadButtons,
  downloadSpreadsheet,
  SheetFormat,
} from "Helpers/downloadSpreadsheet";
import { asDisplayDate } from "Helpers/index";
import i18n from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import * as types from "_graphql-types/graphql";
import { INVESTMENT_SET_RETURNS_STREAM, PEERS_RETURNS_STREAM } from "./graphql";
import {
  FETCH_INVESTMENTS_FROM_SET,
  PEERS_ROOT,
} from "../PeerStatistics/graphql";
import Table from "./Table";
import { getStatResult } from "./SummaryCalcs";

interface Props {
  investmentId: number;
  investmentSetId?: number;
}
type InvestmentInfo =
  | types.PeersReturnsStreamQuery["investment"]["peersStats"]["items"]
  | types.InvestmentSetReturnsStreamQuery["investment"]["groupStats"]["items"]
  | undefined;

type ReturnsMatrix = (number | null | undefined)[][];

function useGetReturnsData({
  investmentId,
  investmentSetId,
  selectedEndDate,
  selectedStartDate,
}: {
  investmentId: number;
  investmentSetId?: number;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
}): [ReturnsMatrix, InvestmentInfo, ApolloError | undefined] {
  const { data: investmentSet } =
    useQuery<types.FetchInvestmentSetInvestmentsQuery>(
      FETCH_INVESTMENTS_FROM_SET,
      {
        variables: {
          searchFilters: investmentSetId
            ? [{ INVESTMENT_SET: { investmentSetId } }]
            : [],
        },
      }
    );

  const { data: investmentSetData, error: investmentSetError } =
    useQuery<types.InvestmentSetReturnsStreamQuery>(
      INVESTMENT_SET_RETURNS_STREAM,
      {
        variables: {
          id: investmentId,
          dependentIds:
            investmentSet?.investmentList?.items
              ?.map(i => i.id)
              .filter(id => id !== investmentId) ?? [],
          asOfDate: selectedEndDate?.toISOString().slice(0, 10),
          rangeOffset:
            selectedStartDate && selectedEndDate
              ? differenceInMonths(selectedEndDate, selectedStartDate)
              : 0,
        },
        skip: !selectedStartDate || !selectedEndDate || !investmentSetId, // render if using investment set for comparison
      }
    );

  const { data: peersData, error: peersError } =
    useQuery<types.PeersReturnsStreamQuery>(PEERS_RETURNS_STREAM, {
      variables: {
        id: investmentId,
        asOfDate: selectedEndDate?.toISOString().slice(0, 10),
        rangeOffset:
          selectedStartDate && selectedEndDate
            ? differenceInMonths(selectedEndDate, selectedStartDate)
            : 0,
        filter: {
          active: true,
        },
      },
      skip: !selectedStartDate || !selectedEndDate || !!investmentSetId, // render if using peers list for comparison
    });

  if (investmentSetId) {
    const investmentInfo = investmentSetData?.investment?.groupStats?.items;
    const returnsStream = investmentSetData?.investment?.groupStats?.stats
      ?.returnsStream as ReturnsMatrix;

    return [returnsStream, investmentInfo, investmentSetError];
  }

  const investmentInfo = peersData?.investment?.peersStats?.items;
  const returnsStream = peersData?.investment?.peersStats?.stats
    ?.returnsStream as ReturnsMatrix;

  return [returnsStream, investmentInfo, peersError];
}

function PeerReturnStream({
  investmentId,
  investmentSetId,
}: Props): JSX.Element {
  const { data: rootData } = useQuery<types.InvestmentPeersRootQuery>(
    PEERS_ROOT,
    {
      variables: { id: investmentId },
    }
  );

  const [selectedStartDate, setSelectedStartDate] = useState<
    Date | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();

  const [startPeriodDate, endPeriodDate] = useMemo(() => {
    if (!rootData) return [];
    const { start, end } = rootData.investment.performancePeriodRange ?? {};

    return [start, end].map(d =>
      d ? parseISO(parseISO(d).toISOString().slice(0, 10)) : undefined
    );
  }, [rootData]);

  useEffect(() => {
    setSelectedStartDate(startPeriodDate);
    setSelectedEndDate(endPeriodDate);
  }, [startPeriodDate, endPeriodDate]);

  const [returnsStream, investmentInfo, error] = useGetReturnsData({
    investmentId,
    investmentSetId,
    selectedStartDate,
    selectedEndDate,
  });

  const returnsByDate = returnsStream
    ? returnsStream[0]?.map((date, dIndex) =>
        returnsStream.map(
          (investment, iIndex) => returnsStream?.[iIndex]?.[dIndex] || undefined
        )
      )
    : [];

  const download = (format: SheetFormat) =>
    returnsStream &&
    downloadSpreadsheet(
      [
        {
          name: `Sheet 1`,
          data: [
            [
              i18n.t("analytics.peer_statistics.name"),
              ...eachMonthOfInterval({
                start: selectedStartDate ?? 0,
                end: selectedEndDate ?? 0,
              }).map(date => asDisplayDate(date) ?? "-"),
            ],
            ...(investmentInfo || []).map(({ name }, iinvestment) => [
              name,
              ...returnsStream[iinvestment].map(ret => ret ?? "-"),
            ]),
            [],
            ...["High", "Mean", "Median", "Low"].map(stat => [
              stat,
              ...returnsByDate.map(
                dataByDate => getStatResult(stat, dataByDate) ?? "-"
              ),
            ]),
          ],
        },
      ],
      `${rootData?.investment.name} Peer Monthly Returns`,
      format
    );

  if (error) {
    return <>{error.message}</>;
  }

  return (
    <>
      <div className="summary-heading" data-cy="peer-returns">
        <h3 className="invt-tab__title" id="peer_returns">
          {i18n.t("overview.peer_returns")}
        </h3>
        {returnsStream && (
          <div
            id="peer-returns__date-Selector"
            className={classNames("flex-row", "align-center")}
          >
            <p className="summary-heading__desc">From: </p>
            <DateSelector
              selectedDate={selectedStartDate}
              setSelectedDate={setSelectedStartDate}
              range={
                startPeriodDate && endPeriodDate
                  ? {
                      start: startPeriodDate,
                      end: selectedEndDate ?? endPeriodDate,
                    }
                  : undefined
              }
            />
            <p className="summary-heading__desc">To: </p>
            <DateSelector
              selectedDate={selectedEndDate}
              setSelectedDate={setSelectedEndDate}
              range={
                startPeriodDate && endPeriodDate
                  ? {
                      start: selectedStartDate ?? startPeriodDate,
                      end: endPeriodDate,
                    }
                  : undefined
              }
            />
          </div>
        )}
      </div>
      <Table
        investments={investmentInfo}
        returnsStream={returnsStream}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
      />
      <DownloadButtons {...{ download }} />
    </>
  );
}

export default PeerReturnStream;
