// Array implementation of handling a selection of items
export default class UniversalFund {
  // children_funds should be built as fund models perhaps.
  constructor(data = {}) {
    this.children_funds = _.get(data, ['attributes', 'children_funds'], []);
    this.id = data.id;
    this.selectedPositionIndex = 0;
  }

  hasChildFunds() {
    return this.children_funds.length !== 0;
  }

  childFundIndex(childId) {
    return _.findIndex(this.children_funds, (f) => f.id === childId);
  }

  childFund(childId) {
    if (!this.childExists(childId)) return null;
    return this.children_funds[this.childFundIndex(childId)];
  }

  childExists(childId) {
    return this.childFundIndex(childId) !== -1;
  }

  /** **POSITION RELATED INTERACTION**** */
  selectedPositionsChild() {
    return this.children_funds[this.selectedPositionIndex];
  }

  get selectedPositionsChildID() {
    return _.get(this.selectedPositionsChild(), 'id');
  }

  selectPositionsForChild(childIndex) {
    this.selectedPositionIndex = childIndex;
    return this;
  }
  //* ****
}
