import React from "react";
import i18n from "i18next";
import { Link } from "react-router-dom";
import NotFoundURL from "./404_img.svg";

const NotFound = () => (
  <div className="main-content main-content--lg">
    <div className="not-found">
      <img src={NotFoundURL} alt="Not found" className="mb-20" />
      <p className="not-found__title">{i18n.t("not_found.title")}</p>
      <p className="not-found__descr">{i18n.t("not_found.description")}</p>
      <Link to="/" className="rounded-btn rounded-btn--blue rounded-btn--lg">
        {i18n.t("not_found.btn_name")}
      </Link>
    </div>
  </div>
);

export default NotFound;
