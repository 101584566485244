import { gql } from "_graphql-types/gql";

export const FETCH_FIRM_CONTACTS = gql(/* GraphQL */ `
  query FirmContactsQuery(
    $page: PageInput
    $sort: [TeamSort!]
    $filter: TeamFilterInput
  ) {
    teamMemberList(page: $page, sort: $sort, filter: $filter) {
      items {
        ...TeamMember
      }
      total
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);
