/* eslint-disable max-lines-per-function */
import React from "react";
import i18n from "i18next";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { formatDate } from "frontend/src/utils/date";
import { booleanToAnswer } from "Helpers/text_formatting";
import { formatLargeCurrency } from "Helpers/index";
import FieldRange from "Components/show/overview/FieldRange";
import { INVESTMENT_ATTRIBUTES } from "./graphql";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { isAuthorized } from "Components/Authorized/authHelpers";

function SummaryFundTile({
  investmentId,
  rcgOnlyFields = [],
  header = i18n.t("fund_summary.title"),
}: {
  investmentId: number;
  rcgOnlyFields?: string[];
  header?: string;
}) {
  const { data } = useQuery(INVESTMENT_ATTRIBUTES, {
    variables: { id: investmentId },
  });

  const attributes = get(data, "investment");

  const user = useCurrentUser();

  const filterRestrictedFields = (field: { displayName: string }) => {
    if (rcgOnlyFields.length && rcgOnlyFields.includes(field.displayName)) {
      return isAuthorized({ user, checks: ["isRockCreek"] });
    }
    return true;
  };

  const cardFields = [
    {
      key: 1,
      className: "cms-broke-long-value",
      displayName: "Inception Date",
      content:
        formatDate(get(attributes, "inceptionDate"), "dd-MMM-yyyy") ?? "-",
    },
    {
      key: 2,
      className: "cms-broke-long-value",
      displayName: "Investment AUM",
      content: formatLargeCurrency(
        get(attributes, ["latestInvestmentAUM", "value"]),
        get(attributes, ["latestInvestmentAUM", "currency", "name"])
      ),
    },
    {
      key: 3,
      className: "cms-broke-long-value",
      displayName: "Strategy AUM",
      content: formatLargeCurrency(
        get(attributes, ["latestStrategyAUM", "value"]),
        get(attributes, ["latestStrategyAUM", "currency", "name"])
      ),
    },
    {
      key: 4,
      className: "cms-broke-long-value",
      displayName: "Is Sub-Advised",
      content: booleanToAnswer(get(attributes, "isSubAdvised")),
    },
    {
      key: 5,
      className: "cms-broke-long-value",
      displayName: "Primary Benchmark",
      content: get(attributes, "primaryBenchmark.name", "-"),
    },
    {
      key: 6,
      className: "cms-broke-long-value",
      displayName: "Secondary Benchmark",
      content: get(attributes, "secondaryBenchmark.name", "-"),
    },
  ];

  const rangeFields = [
    {
      key: 1,
      className: "",
      displayName: "Gross Exposure Range",
      rangeStart: get(attributes, "minGrossExposure"),
      rangeEnd: get(attributes, "maxGrossExposure"),
      numberFormat: "whole_percent",
    },
    {
      key: 2,
      className: "",
      displayName: "Net Exposure Range",
      rangeStart: get(attributes, "minNetExposure"),
      rangeEnd: get(attributes, "maxNetExposure"),
      numberFormat: "whole_percent",
    },
    {
      key: 3,
      className: "",
      displayName: "Expected Volatility Range",
      rangeStart: get(attributes, "minExpectedVolatility"),
      rangeEnd: get(attributes, "maxExpectedVolatility"),
      numberFormat: "whole_percent",
    },
  ];

  const authorizedCardFields = cardFields.filter(field =>
    filterRestrictedFields(field)
  );
  const authorizedRangeFields = rangeFields.filter(field =>
    filterRestrictedFields(field)
  );

  return (
    <div
      className="overview-page__two-equal-col invt-tab__section"
      id="investment"
    >
      <h3 className="invt-tab__title">{header}</h3>
      <div className="overview-page__card">
        {authorizedCardFields.map(field => (
          <FieldInlinePreview
            key={field.key}
            className={field.className}
            displayName={field.displayName}
            content={field.content}
          />
        ))}
        {authorizedRangeFields.map(field => (
          <FieldRange
            key={field.key}
            className={field.className}
            displayName={field.displayName}
            startRange={field.rangeStart}
            endRange={field.rangeEnd}
            numberFormat={field.numberFormat}
          />
        ))}
      </div>
    </div>
  );
}

export default SummaryFundTile;
