import { ActionType } from "_sirius/src/actions/action_types";

const selectedTabName = (
  state = "overview",
  action: {
    type: ActionType.SELECTED_TAB_NAME_SET;
    payload?: {
      selectedTabName?: string;
    };
  }
) => {
  switch (action.type) {
    case ActionType.SELECTED_TAB_NAME_SET:
      return action?.payload?.selectedTabName || state;

    default:
      return state;
  }
};

export default selectedTabName;
