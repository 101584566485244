import { combineReducers } from "redux";

import app from "./app_reducer";
import user from "./user_reducer";
import organization from "./organization_reducer";
import navigationSidebar from "./navigation_sidebar_reducer";
import header from "./header_reducer";
import globalSearchV2 from "./globalSearchV2/globalSearchV2.reducer";
import investmentEditMode from "./investment/edit_mode_reducer";
import investmentProfileReducer from "./investment_profile_reducer/selected_tab_name_reducer";

export const reducer = combineReducers({
  app,
  user,
  organization,
  globalSearchV2,
  navigationSidebar,
  header,
  investmentEditMode,
  investmentProfileReducer,
});

export type RootState = Parameters<typeof reducer>[0];
export type RootAction = Parameters<typeof reducer>[1];
