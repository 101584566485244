/*
Organization, Group, User lists.
*/
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Spin } from "antd";
import classNames from "classnames";
import { INVESTMENT_PATHS } from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import InvestmentSetForm from "Components/InvestmentSetForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";
import { FavoriteInvestment } from "./FavoriteInvestment";
import { FETCH_INVESTMENT_SET_INVESTMENTS } from "./graphql";

interface InvestmentSetTitleProps {
  investmentSetName: string;
  numberInvestments: number | undefined;
  investmentSetId: number;
  editInvestmentSet: (arg0: number) => void;
  setExpanded: (arg0: boolean) => void;
  expanded: boolean;
}

function InvestmentSetTitle({
  investmentSetName,
  numberInvestments,
  setExpanded,
  expanded,
  investmentSetId,
  editInvestmentSet: editInvestmentSetProp,
}: InvestmentSetTitleProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="main-tree__item-header">
      <span
        className={classNames("main-tree__item-header-toggle", "icon", {
          "icon-arrow": expanded,
          "icon-arrow-right": !expanded,
        })}
        data-cy="investment-set-toggle"
        onClick={() => setExpanded(!expanded)}
      />
      <button
        type="button"
        className="main-tree__item-header-switcher"
        data-cy="investment-set-header"
        onClick={() => {
          navigate(INVESTMENT_PATHS[0]);
          dispatch(
            updateFilter({
              type: "investment",
              data: {
                type: "investment",
                data: {
                  type: "INVESTMENT_SET",
                  data: {
                    investmentSetId,
                  },
                },
              },
            })
          );
        }}
      >
        <p className="main-tree__item-header-switcher-text">
          {`${investmentSetName} `}
          {numberInvestments && `(${numberInvestments})`}
        </p>
        <div className="list-item-drag-icon main-dnd-icon">
          <span
            className="icon icon-pencil"
            style={{ marginRight: "5px", marginBottom: "5px" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              editInvestmentSetProp(investmentSetId);
            }}
          />
        </div>
      </button>
    </div>
  );
}

function InvestmentSet({
  investmentSet,
  setEditingInvestmentSet,
}: {
  investmentSet: types.InvestmentSet;
  setEditingInvestmentSet: (arg0: number) => void;
}) {
  const [expanded, setExpanded] = useState(false);
  const [totalItems, setTotalItems] = useState<number | undefined>();

  const { data, loading, error } = useQuery(FETCH_INVESTMENT_SET_INVESTMENTS, {
    variables: {
      page: {
        limit: 24,
        offset: 0,
      },
      investmentSetId: investmentSet.id,
    },
    skip: !expanded,
  });

  useEffect(() => {
    // When we load it and then collapse I wanted to continue showing the
    // total investments for the list.
    // the skip condiition sets data to undefined when collapsed.
    if (data) {
      setTotalItems(data.investmentList.total);
    }
  }, [data]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (
    !investmentSet.name ||
    investmentSet.id === undefined ||
    investmentSet.id === null
  )
    return <div>Missing Keys investment set name or Id</div>;

  return (
    <div className="main-tree__item">
      <InvestmentSetTitle
        editInvestmentSet={setEditingInvestmentSet}
        investmentSetName={investmentSet.name}
        numberInvestments={totalItems}
        investmentSetId={investmentSet.id}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      {error && (
        <div className="main-tree__item">
          Something went wrong loading investments
        </div>
      )}
      {loading && (
        <div className="text-center">
          <Spin />
        </div>
      )}
      {expanded && data && (
        <>
          <div className="main-tree__item-content bg-grey-10">
            {data.investmentList.items.map(investment => (
              <FavoriteInvestment investment={investment} key={investment.id} />
            ))}
          </div>
          <div className="text-right pr-15 ">
            <button
              type="button"
              className="in-blue-500"
              onClick={() => {
                navigate(INVESTMENT_PATHS[0]);
                dispatch(
                  updateFilter({
                    type: "investment",
                    data: {
                      type: "investment",
                      data: {
                        type: "INVESTMENT_SET",
                        data: {
                          investmentSetId: investmentSet.id,
                        },
                      },
                    },
                  })
                );
              }}
            >
              Show All
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function InvestmentSets({
  investmentSets,
}: {
  investmentSets: types.InvestmentSet[];
}) {
  const [editingInvestmentSetId, setEditingInvestmentSet] = useState<
    undefined | number
  >();
  return (
    <div className="main-tree__item-content">
      {investmentSets.map((investmentSet: types.InvestmentSet) => (
        <InvestmentSet
          investmentSet={investmentSet}
          key={investmentSet.id}
          setEditingInvestmentSet={setEditingInvestmentSet}
        />
      ))}
      {editingInvestmentSetId !== undefined && (
        <InvestmentSetForm
          selectedListId={editingInvestmentSetId}
          handleCancel={() => {
            setEditingInvestmentSet(undefined);
          }}
          visible
        />
      )}
    </div>
  );
}

export function InvestmentSetGroup({
  total,
  investmentSets,
  title,
}: {
  total: number;
  investmentSets: types.InvestmentSet[];
  title: string;
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <li
      className={classNames("main-tree__item", {
        "main-tree__item--open": expanded,
      })}
      data-cy="investment-set-group"
    >
      <div className="main-tree__item-header">
        <span
          className={classNames("main-tree__item-header-toggle", "icon", {
            "icon-arrow": expanded,
            "icon-arrow-right": !expanded,
          })}
          data-cy="investment-set-group-toggle"
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
        <button
          type="button"
          className="main-tree__item-header-switcher"
          data-cy="investment-set-group-header"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {title} {`(${total})`}
        </button>
      </div>

      {expanded && <InvestmentSets investmentSets={investmentSets} />}
    </li>
  );
}
