import React from "react";
import NavHeader from "Components/layout/NavHeader";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export default function DocumentNavbar({ name }: { name: string }) {
  const navigate = useNavigate();

  return (
    <NavHeader>
      <div className="invst-header">
        <div className="invst-header__col--left">
          <div className={classNames("invst-header__title-wrap")}>
            <i
              className="invst-header__back-icon icon icon-back icon--24"
              onClick={() => navigate(-1)}
            />

            <div className="invst-header__title">
              <div id="investment-name" className="invst-header__title-txt">
                {name}
              </div>
            </div>
          </div>
        </div>

        <div className="invst-header__col--right">
          <div className="text-right hidden-lg-down">
            <span className="no-reports__edit-toggle-spacer" />
          </div>
        </div>
      </div>
    </NavHeader>
  );
}
