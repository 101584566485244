import { gql } from "_graphql-types/gql";

export const PEERS_CORRELATION = gql(/* GraphQL */ `
  query PeersCorrelation(
    $id: Int!
    $asOfDate: Date!
    $wrtInvestmentId: Int!
    $rangeOffset: Int
    $rangeUnit: StatsRangeUnit
    $filter: InvestmentFilterInput
  ) {
    investment(id: $id) {
      id
      name
      inceptionDate
      peersStats(
        asOfDate: $asOfDate
        wrtInvestmentId: $wrtInvestmentId
        filter: $filter
      ) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          correlationMatrix(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
        }
      }
    }
  }
`);

export const INVESTMENT_SET_CORRELATION = gql(/* GraphQL */ `
  query InvestmentSetCorrelation(
    $id: Int!
    $dependentIds: [Int!]!
    $asOfDate: Date!
    $wrtInvestmentId: Int!
    $rangeOffset: Int
    $rangeUnit: StatsRangeUnit
  ) {
    investment(id: $id) {
      id
      name
      inceptionDate
      groupStats(
        dependentIds: $dependentIds
        asOfDate: $asOfDate
        wrtInvestmentId: $wrtInvestmentId
      ) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          correlationMatrix(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
        }
      }
    }
  }
`);
