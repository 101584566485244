import { round } from "lodash";
import { Bounds, inBounds, percentChange } from "../helpers";

// Some inaccuracy due to floating point precision.
// Perhaps all calculations should use original returns rather than derived
export function growthOf1000InBounds(
  data: [number, number][],
  bounds: Bounds,
  boundedGrowthRootData?: [number, number | null][]
): [number, number | null][] {
  let value = 1000;
  let rootMatch: [number, number] | null;
  return data.map(([x, y], i) => {
    if (inBounds(x, bounds)) {
      const previousValue = data[i - 1];

      if (!rootMatch && boundedGrowthRootData) {
        rootMatch = boundedGrowthRootData.find(([rootX]) => rootX === x) as
          | [number, number]
          | null;

        if (rootMatch) {
          // eslint-disable-next-line prefer-destructuring
          value = rootMatch[1];
          return [x, value];
        }

        return [x, null];
      }

      let individualReturn: number | null;

      if (previousValue) {
        if (!inBounds(previousValue[0], bounds)) {
          return [x, value];
        }
        individualReturn = percentChange(previousValue[1], y);
      } else {
        return [x, y];
      }

      value += value * individualReturn;
      return [x, round(value, 0)];
    }

    return [x, null];
  });
}
