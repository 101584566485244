import { gql, useQuery } from "@apollo/client";
import { PerformanceMtd, PerformanceQuarterly } from "_graphql-types/graphql";
import { togglePriorTrackRecord } from "_sirius/src/actions/field_option_actions";
import { parseISO } from "date-fns";
import { get } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priorTrackRecordSelector } from "Selectors/field_option_selectors";

const HAS_PRIOR_TRACKRECORD = gql(/* GraphQL */ `
  query HasPriorTrackrecord($id: Int!) {
    investment(id: $id) {
      id
      inceptionDate
      performanceMTD(
        sort: [{ field: asOfDate, order: ASC }]
        page: { limit: 1 }
      ) {
        asOfDate
      }
      performanceQuarterly(
        sort: [{ field: asOfDate, order: ASC }]
        page: { limit: 1 }
      ) {
        returnYear
      }
    }
  }
`);

function PriorTrackRecord({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element | null {
  const dispatch = useDispatch();

  const { data } = useQuery(HAS_PRIOR_TRACKRECORD, {
    variables: {
      id: investmentId,
    },
    fetchPolicy: "cache-first",
  });

  const hasPriorTrackrecord = useMemo(() => {
    const inceptionDate: Date | null = get(
      data,
      "investment.inceptionDate",
      null
    );
    const performanceMTD: PerformanceMtd | null = get(
      data,
      "investment.performanceMTD[0]",
      null
    );
    const performanceQ: PerformanceQuarterly | null = get(
      data,
      "investment.performanceQuarterly[0]",
      null
    );
    if (!inceptionDate) return false;
    if (!performanceQ && !performanceMTD) return false;
    if (performanceQ)
      return performanceQ.returnYear < new Date(inceptionDate).getFullYear();
    if (performanceMTD)
      return (
        parseISO(performanceMTD.asOfDate).getTime() <
        new Date(inceptionDate).getTime()
      );
    return false;
  }, [data]);

  const onChange = () => dispatch(togglePriorTrackRecord());
  const isChecked = useSelector(state => priorTrackRecordSelector(state));

  if (!hasPriorTrackrecord) return null;

  return (
    <div className="flex-shrink mb-4 pt-4 pr-30">
      <input
        type="checkbox"
        id="prior-track-record"
        className="main-checkbox__input"
        onChange={onChange}
        checked={isChecked}
      />
      <label htmlFor="prior-track-record" className="main-checkbox__label">
        Prior Track Record
      </label>
    </div>
  );
}

export default PriorTrackRecord;
