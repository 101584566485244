import { useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import * as types from "_graphql-types/graphql";
import { updateOrganization } from "_sirius/src/actions/organization_actions";
import { updateUser } from "_sirius/src/actions/user_actions";
import { ApolloProviderFallback } from "Components/ApolloProviderFallback";
import { CURRENT_USER_QUERY } from "frontend/src/utils/graphql";
import { useEffect, useMemo } from "react";
import { useDispatch } from "src/react-redux";
import { INVESTMENT_SET_QUERY, SAVED_ENTITY_SEARCH_QUERY } from "./graphql";
import { loadInvestmentViewFiltersFromUserSettings } from "./useUserSettings";

function SettingsLoader({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null {
  const { data, loading, error } = useQuery<{
    currentUser: types.CurrentUserQuery["currentUser"];
  }>(CURRENT_USER_QUERY);
  const dispatch = useDispatch();

  const userSettingsData = data?.currentUser.userSettings?.data;

  const { data: savedEntitySearchData, error: savedEntitySearchError } =
    useQuery<{
      savedEntitySearch: types.SavedEntitySearchQuery["savedEntitySearch"];
    }>(SAVED_ENTITY_SEARCH_QUERY, {
      variables: {
        id: userSettingsData?.defaultInvestmentView?.id,
      },
      skip:
        userSettingsData?.defaultInvestmentView?.type !==
        types.SettingEntityType.SavedEntitySearch,
    });

  const { data: investmentSetData, error: investmentSetError } = useQuery<{
    investmentSet: types.InvestmentSetSearchQuery["investmentSet"];
  }>(INVESTMENT_SET_QUERY, {
    variables: {
      id: userSettingsData?.defaultInvestmentView?.id,
    },
    skip:
      userSettingsData?.defaultInvestmentView?.type !==
      types.SettingEntityType.InvestmentSet,
  });

  useEffect(() => {
    if (!data) return;

    dispatch(
      updateUser({
        email: data.currentUser.email,
        fullName: data.currentUser.commonName,
        organizationId: data.currentUser.organizationId,
        id: data.currentUser.id,
      })
    );

    dispatch(
      updateOrganization({
        organizationName: data.currentUser.organization.name,
        isRockCreek:
          data.currentUser.organization.name === "The Rock Creek Group",
        isAdvisorOrganization:
          data.currentUser.organization.name === "The Rock Creek Group",
      })
    );

    loadInvestmentViewFiltersFromUserSettings(
      dispatch,
      userSettingsData?.defaultInvestmentView,
      savedEntitySearchData?.savedEntitySearch ??
        investmentSetData?.investmentSet
    );
  }, [data, dispatch, savedEntitySearchData, investmentSetData]);

  if (loading) return null;

  const currentUserError = useMemo(() => {
    const currentUserError =
      error ?? savedEntitySearchError ?? investmentSetError;
    if (currentUserError) {
      captureException(currentUserError, {
        tags: { context: "Error fetching current user" },
      });
    }
    return currentUserError;
  }, [error, savedEntitySearchError, investmentSetError]);

  if (currentUserError) return <ApolloProviderFallback />;

  return children;
}

export default SettingsLoader;
