import React from "react";
import { getChartOptions } from "frontend/src/components/HighCharts";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import * as types from "_graphql-types/graphql";
import { getTwoCategorySeries } from "./helpers";

interface ColumnChartProps {
  aggregatedData: Partial<types.FirmDeiRecord>[];
  title?: string;
  subTitle?: string;
  deiCategory1List: types.DeiCategory1Enum[];
  deiCategory2List: types.DeiCategory2Enum[];
  isPercent: boolean;
  polar?: boolean;
  callback?: Highcharts.ChartCallbackFunction;
  fontSize?: number;
  height?: number;
  width?: number;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  allowChartUpdate?: boolean;
}

function ColumnChartView({
  aggregatedData,
  title,
  deiCategory1List,
  deiCategory2List,
  isPercent,
  polar = false,
  subTitle,
  callback,
  fontSize = 10,
  height = 500,
  width = 800,
  legendSymbolHeight,
  legendItemMarginTop,
  allowChartUpdate,
}: ColumnChartProps): JSX.Element {
  const rValueKey: "percentValue" | "value" = isPercent
    ? "value"
    : "percentValue";
  const dataSeries: any = getTwoCategorySeries(
    deiCategory1List,
    deiCategory2List,
    aggregatedData,
    rValueKey,
    "column"
  );
  const options: HighCharts.Options = getChartOptions({
    highChartType: "column",
    dataSeries,
    height,
    width,
    fontSize,
    xAxisCategories: deiCategory2List.map(c => c.description),
    yAxisCategories: [],
    yAxisType: "linear",
    xAxisType: "category",
    allowExport: false,
    polar,
    maxPointWidth: 50,
    title,
    subTitle,
    allowDecimals: false,
    tickInterval: 5,
    maxValue: 100,
    dataLabelFormat: "{y}%",
    tooltipValueSuffix: "%",
    legendSymbolHeight,
    legendItemMarginTop,
    stackLabelOptions: {
      formatter(): string {
        // eslint-disable-next-line react/no-this-in-sfc
        return `${(this as any).total}%`;
      },
    },
  });

  if (callback) {
    return (
      <HighChartsReact
        data-cy="firm-vis-column-chart"
        options={options}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={false}
        callback={callback}
      />
    );
  }

  return (
    <HighChartsReact
      data-cy="firm-vis-column-chart"
      options={options}
      highcharts={HighCharts}
      immutable
      allowChartUpdate={allowChartUpdate}
    />
  );
}

export default ColumnChartView;
