import React from "react";
import { getPieChartOptions } from "frontend/src/components/HighCharts";
import { rotatePieChartLabels } from "frontend/src/components/HighCharts/helpers";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import * as types from "_graphql-types/graphql";
import { CHART_COLORS_SERIES_2 } from "frontend/src/components/HighCharts/universalOptions";
import {
  aggregateDataByCategory,
  getAdditionalDiversityDataSeries,
  getOneCategorySeries,
} from "./helpers";

interface PieChartProps {
  aggregatedData: Partial<types.FirmDeiRecord>[];
  title?: string;
  deiCategory1List: types.DeiCategory1Enum[];
  deiCategory2List: types.DeiCategory2Enum[];
  isPercent: boolean;
  subTitle?: string;
  fontSize?: number;
  height?: number;
  width?: number;
  chartDiameter?: number;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  allowChartUpdate?: boolean;
}

const CHART_COLORS_PROPORTION = ["#5597ff", "#ededed"];

function setAdditionalPieChartOptions(
  options: HighCharts.Options
): HighCharts.Options {
  return {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      pie: {
        ...options.plotOptions?.pie,
        showInLegend: false,
        dataLabels: {
          ...options.plotOptions?.pie?.dataLabels,
          distance: -90,
          style: {
            fontSize: "18px",
          },
          formatter() {
            if (
              this.point.name &&
              new RegExp(/^[^(Not)]/).test(this.point.name)
            ) {
              return `${this.y}%`;
            }
            return "";
          },
        },
      },
    },
  };
}

/* eslint-disable max-lines-per-function */

function PieChartView({
  aggregatedData,
  title,
  deiCategory1List,
  deiCategory2List,
  isPercent,
  subTitle,
  fontSize = 10,
  height = 350,
  width = 400,
  chartDiameter = 180,
  legendSymbolHeight,
  legendItemMarginTop,
  allowChartUpdate,
}: PieChartProps): JSX.Element {
  const rValueKey: "percentValue" | "value" = isPercent
    ? "value"
    : "percentValue";

  const combinedByGenderCategory = aggregateDataByCategory(
    aggregatedData,
    rValueKey,
    "deiCategory2EnumId"
  );
  const combinedByDiversityCategory = aggregateDataByCategory(
    aggregatedData,
    rValueKey,
    "deiCategory1EnumId"
  );

  const ethnicityDataSeries = getOneCategorySeries(
    combinedByDiversityCategory,
    rValueKey,
    deiCategory1List.filter(category => !category.isAdditionalMinorityGroup)
  );

  const veteransDataSeries = getAdditionalDiversityDataSeries(
    combinedByDiversityCategory,
    rValueKey,
    deiCategory1List,
    9
  );

  const disabilityDataSeries = getAdditionalDiversityDataSeries(
    combinedByDiversityCategory,
    rValueKey,
    deiCategory1List,
    10
  );

  const lgbtqDataSeries = getAdditionalDiversityDataSeries(
    combinedByDiversityCategory,
    rValueKey,
    deiCategory1List,
    11
  );

  const genderDataSeries = getOneCategorySeries(
    combinedByGenderCategory,
    rValueKey,
    deiCategory2List
  );

  const eOptions: HighCharts.Options = getPieChartOptions({
    dataSeries: ethnicityDataSeries,
    height,
    width,
    fontSize: fontSize * 0.9,
    allowExport: false,
    formatSuffix: "%",
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: chartDiameter,
    legendSymbolHeight,
    legendItemMarginTop,
    legendVerticalAlign: "bottom",
  });

  const gOptions: HighCharts.Options = getPieChartOptions({
    dataSeries: genderDataSeries,
    title,
    subTitle,
    height,
    width,
    fontSize,
    allowExport: false,
    formatSuffix: "%",
    chartColors: CHART_COLORS_SERIES_2,
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: chartDiameter,
    legendSymbolHeight,
    legendItemMarginTop: 82,
    legendAlign: "center",
    legendVerticalAlign: "bottom",
  });

  const vOptions: HighCharts.Options = getPieChartOptions({
    dataSeries: veteransDataSeries,
    title: "Veterans",
    height,
    width,
    fontSize,
    allowExport: false,
    formatSuffix: "%",
    chartColors: CHART_COLORS_PROPORTION,
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: chartDiameter,
    legendSymbolHeight,
    legendItemMarginTop,
    legendVerticalAlign: "bottom",
  });

  const dOptions: HighCharts.Options = getPieChartOptions({
    dataSeries: disabilityDataSeries,
    title: "Persons with Disabilities",
    height,
    width,
    fontSize,
    allowExport: false,
    formatSuffix: "%",
    chartColors: CHART_COLORS_PROPORTION,
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: chartDiameter,
    legendSymbolHeight,
    legendItemMarginTop,
    legendVerticalAlign: "bottom",
  });

  const lOptions: HighCharts.Options = getPieChartOptions({
    dataSeries: lgbtqDataSeries,
    title: "LGBTQ+",
    height,
    width,
    fontSize,
    allowExport: false,
    formatSuffix: "%",
    chartColors: CHART_COLORS_PROPORTION,
    showAnimation: true,
    allowDecimals: false,
    innerSize: "60%",
    diameter: chartDiameter,
    legendSymbolHeight,
    legendItemMarginTop,
    legendVerticalAlign: "bottom",
  });

  const ethnicityOptions = rotatePieChartLabels(eOptions);
  const genderOptions = rotatePieChartLabels(gOptions);

  const veteransOptions = setAdditionalPieChartOptions(
    rotatePieChartLabels(vOptions)
  );
  const disabilityOptions = setAdditionalPieChartOptions(
    rotatePieChartLabels(dOptions)
  );
  const lgbtqOptions = setAdditionalPieChartOptions(
    rotatePieChartLabels(lOptions)
  );

  return (
    <div
      data-cy="firm-vis-pie-chart"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      <HighChartsReact
        options={genderOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={allowChartUpdate}
      />
      <HighChartsReact
        options={ethnicityOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={allowChartUpdate}
      />
      <div className="break" style={{ flexBasis: "100%", height: 0 }} />
      <HighChartsReact
        options={veteransOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={allowChartUpdate}
      />
      <HighChartsReact
        options={disabilityOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={allowChartUpdate}
      />
      <HighChartsReact
        options={lgbtqOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={allowChartUpdate}
      />
    </div>
  );
}

export default PieChartView;
