import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export interface Props {
  filter: types.AssetClassStrategyFilter;
}

function attributeLabel(
  entities: types.InputMaybe<types.EntityKey[]> | undefined
) {
  if (!entities?.length) {
    return "";
  }
  if (entities.length > 1) {
    return `${entities[0].label} (+${entities.length - 1})`;
  }
  return entities[0].label;
}

function filterValueLabel(filter: types.AssetClassStrategy) {
  return [
    attributeLabel(filter.assetClass),
    attributeLabel([...(filter.strategy ?? []), ...(filter.subStrategy ?? [])]),
  ]
    .filter(Boolean)
    .join(" - ");
}

function AssetClassStrategyFilterTag({ filter }: Props): JSX.Element {
  const dispatch = useDispatch();
  let label = filter.values.slice(0, 2).map(filterValueLabel).join(" or ");
  if (filter.values.length > 2) {
    label += ` or (${filter.values.length - 2} other class${
      filter.values.length - 2 > 1 ? "es" : ""
    })`;
  }

  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="asset-class-strategy-filter-tag"
      data-type="ASSET_CLASS_STRATEGY"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "ASSET_CLASS_STRATEGY",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {label}
    </Tag>
  );
}

export default AssetClassStrategyFilterTag;
