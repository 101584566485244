import { useContext, useState, useEffect, useMemo } from "react";
import { Select, Radio, Row, Tooltip, Switch } from "antd";
import { fieldsIndexContext } from "./FieldsIndex.context";
import { FieldSortEnum, SortInput } from "src/graphql-types/graphql";
import {
  CalendarOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

export function FieldsSorts() {
  const { fieldsSort, fieldsFilters, updateFieldsSort } =
    useContext(fieldsIndexContext);

  function updateSort(sortKey: `${FieldSortEnum}_${SortInput}`) {
    console.log("update sort: ", sortKey);
    const [field, order] = sortKey.split("_") as [FieldSortEnum, SortInput];
    updateFieldsSort([{ field, order }]);
  }

  function addTextRelevancySort(checked: boolean) {
    if (checked) {
      updateFieldsSort([
        {
          field: FieldSortEnum.TextSearchRelevance,
          order: SortInput.Desc,
        },
        ...(fieldsSort || []).filter(
          f => f.field !== FieldSortEnum.TextSearchRelevance
        ),
      ]);
    } else {
      updateFieldsSort(
        (fieldsSort || []).filter(
          f => f.field !== FieldSortEnum.TextSearchRelevance
        )
      );
    }
  }

  return (
    <>
      <Row wrap={false} align="middle">
        <Tooltip title="Sort By Text Relevancy">
          <Switch
            disabled={!fieldsFilters?.text}
            checkedChildren={<SearchOutlined />}
            unCheckedChildren={<SearchOutlined />}
            onClick={checked => addTextRelevancySort(checked)}
            defaultChecked={fieldsSort?.some(
              f => f.field === FieldSortEnum.TextSearchRelevance
            )}
            style={{ marginRight: "8px" }}
          />
        </Tooltip>
        <Radio.Group
          optionType="button"
          value={
            fieldsSort ? `${fieldsSort[0].field}_${fieldsSort[0].order}` : ""
          }
          onChange={e => updateSort(e.target.value)}
        >
          <Radio.Button value="owner_ASC">
            <Tooltip title="Name ASC">
              <SortAscendingOutlined />
            </Tooltip>
          </Radio.Button>
          <Radio.Button value="owner_DESC">
            <Tooltip title="Name DESC">
              <SortDescendingOutlined />
            </Tooltip>
          </Radio.Button>
          <Radio.Button value="modifyDate_DESC">
            <Tooltip title="Most Recent">
              <CalendarOutlined />
            </Tooltip>
          </Radio.Button>
        </Radio.Group>
      </Row>
    </>
  );
}
