import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { FieldState } from "_graphql-types/graphql";
import {
  DatePicker as AntdDatePicker,
  Col,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { DownloadButtons, SheetFormat } from "Helpers/downloadSpreadsheet";
import { omit, startCase } from "lodash";
import { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import { useContext } from "react";
import { useDebouncedCallback } from "use-debounce";
import FieldsOwnerAutocomplete from "./FieldOwnerFilter";
import { fieldsIndexContext } from "./FieldsIndex.context";
import { FieldsSorts } from "./FieldsSorts";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

function FilterMarks() {
  const { fieldsFilters, removeFieldsFilter } = useContext(fieldsIndexContext);

  const MAX_QUESTIONS_DISPLAYED = 10;

  return (
    <>
      {fieldsFilters?.text && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFieldsFilter("text")}
          closable
        >
          {fieldsFilters?.text}
        </Tag>
      )}

      {fieldsFilters?.state && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFieldsFilter("state")}
          closable
        >
          {startCase(fieldsFilters?.state || "published")}
        </Tag>
      )}

      {(fieldsFilters?.startDate || fieldsFilters?.endDate) && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => {
            removeFieldsFilter("endDate");
            removeFieldsFilter("startDate");
          }}
          closable
        >
          {fieldsFilters?.startDate?.toLocaleDateString()} -{" "}
          {fieldsFilters?.endDate?.toLocaleDateString()}
        </Tag>
      )}
      {!!fieldsFilters?.owners?.length &&
        fieldsFilters.owners.map(owner => (
          <Tag
            key={owner.id}
            id={`${owner.id}`}
            className="ant-tag--blue ant-tag--close"
            onClose={() => {
              removeFieldsFilter("owners", owner);
            }}
            closable
          >
            {owner.label}
          </Tag>
        ))}
      {fieldsFilters?.modifyUser && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFieldsFilter("modifyUser")}
          closable
        >
          Author: {fieldsFilters?.modifyUser}
        </Tag>
      )}
      {!!fieldsFilters?.fieldKeys?.length && (
        <Tooltip
          title={
            <>
              {fieldsFilters?.fieldKeys
                .slice(0, MAX_QUESTIONS_DISPLAYED)
                .map(s =>
                  s
                    .replace("investment", "")
                    .replace("firm", "")
                    // un cammel case
                    .replace(
                      /([A-Z])(?=[A-Z][a-z]|\d|\W|$)(?!$)|([a-z])(?=[A-Z])/g,
                      "$1$2 "
                    )
                    .trim()
                )
                .map(fieldKey => <div key={fieldKey}>{fieldKey}</div>)}
              {fieldsFilters?.fieldKeys.length > MAX_QUESTIONS_DISPLAYED && (
                <div>
                  {fieldsFilters?.fieldKeys.length - MAX_QUESTIONS_DISPLAYED}{" "}
                  more...
                </div>
              )}
            </>
          }
          placement="bottomLeft"
          overlayInnerStyle={{ width: "500px" }}
          overlayStyle={{ width: "500px" }}
          overlayClassName="overlayClassName"
        >
          <Tag
            className="ant-tag--blue ant-tag--close"
            onClose={() => removeFieldsFilter("fieldKeys")}
            closable
          >
            {`Matched Questions: ${fieldsFilters?.fieldKeys?.length}`}
          </Tag>
        </Tooltip>
      )}
    </>
  );
}

function FieldsStateFilter() {
  const { fieldsFilters, updateFieldsFilter } = useContext(fieldsIndexContext);

  const updateStateFilter = (state: string) => {
    updateFieldsFilter({
      ...omit(fieldsFilters, "state"),
      state,
    });
  };
  // null is published, mgrDraft, mgr, draft use a select input

  return (
    <Select
      value={fieldsFilters?.state as FieldState}
      style={{ width: 120 }}
      onChange={(value: FieldState | null) => {
        if (value === null) {
          updateFieldsFilter({
            ...omit(fieldsFilters, "state"),
          });
        } else {
          updateStateFilter(value as string);
        }
      }}
      placeholder="State"
      allowClear
      options={[
        { label: "Manager", value: "mgr" },
        { label: "Draft", value: "draft" },
        { label: "Published", value: "published" },
      ]}
    />
  );
}

function FieldsDateRangeFilter() {
  const { fieldsFilters, updateFieldsFilter, removeFieldsFilter } =
    useContext(fieldsIndexContext);

  const updateDates = (dateRange: [Moment | null, Moment | null] | null) => {
    const dateFilters = (dateRange ?? undefined) && {
      startDate: dateRange?.[0]?.toDate(),
      endDate: dateRange?.[1]?.toDate(),
    };
    if (!dateFilters) {
      removeFieldsFilter("startDate");
      removeFieldsFilter("endDate");
    } else {
      updateFieldsFilter({
        ...omit(fieldsFilters, ["startDate", "endDate"]),
        ...(dateFilters && dateFilters),
      });
    }
  };

  return (
    <DatePicker.RangePicker onChange={updateDates} allowEmpty={[true, true]} />
  );
}

function FieldsTextFilter() {
  const { fieldsFilters, updateFieldsFilter } = useContext(fieldsIndexContext);

  const updateFilterDebounced = useDebouncedCallback(variables => {
    updateFieldsFilter?.(variables);
  }, 600);

  const updateTextFilter = (text: string) => {
    updateFilterDebounced({
      ...fieldsFilters,
      text,
    });
  };

  return (
    <Input
      placeholder="Search by text"
      addonBefore={<SearchOutlined />}
      onChange={e => {
        if (!e.target.value) {
          updateFilterDebounced(omit(fieldsFilters, "text"));
        } else {
          updateTextFilter(e.target.value);
        }
      }}
    />
  );
}

function FieldsOwnerFilter() {
  const { updateFieldsFilter } = useContext(fieldsIndexContext);
  const updateFilterDebounced = useDebouncedCallback(value => {
    updateFieldsFilter?.(value, "owners");
  }, 600);

  function addOwner({
    id,
    name,
    ownerType,
  }: {
    id: number;
    name: string;
    ownerType: string;
  }) {
    updateFilterDebounced({
      id,
      type: ownerType,
      label: name,
    });
  }

  return (
    <FieldsOwnerAutocomplete
      placeholder="Search by owner (investment, firm)"
      addOwner={addOwner}
    />
  );
}

function FieldsAuthorFilter() {
  const { fieldsFilters, updateFieldsFilter } = useContext(fieldsIndexContext);

  const updateFilterDebounced = useDebouncedCallback(variables => {
    updateFieldsFilter?.(variables);
  }, 600);

  const updateAuthorFilter = (modifyUser: string) => {
    updateFilterDebounced({
      ...omit(fieldsFilters, "modifyUser"),
      modifyUser,
    });
  };

  return (
    <Input
      placeholder="Search by author (email)"
      style={{ width: 220 }}
      onChange={e => {
        if (!e.target.value) {
          updateFilterDebounced(omit(fieldsFilters, "modifyUser"));
        } else {
          updateAuthorFilter(e.target.value);
        }
      }}
    />
  );
}

function FieldQuestionFilter({}: {}) {
  const {
    fieldsFilters,
    updateFieldsFilter,
    updateFilterDebounced,
    setSearchTerm,
  } = useContext(fieldsIndexContext);

  return (
    <Input
      placeholder="Search by question"
      addonBefore={<QuestionCircleOutlined />}
      onChange={e => {
        if (!e.target.value) {
          setSearchTerm("");
          updateFieldsFilter(omit(fieldsFilters, "fieldKeys"));
        } else {
          setSearchTerm(e.target.value);
        }
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          updateFilterDebounced?.flush();
        }
      }}
    />
  );
}

export default function FieldActions({
  download,
}: {
  download: (format: SheetFormat) => void;
}) {
  return (
    <>
      <Row>
        <Col xs={24}>
          <FieldsTextFilter />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={24}>
          <FieldQuestionFilter />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <div style={{ marginRight: "10px" }}>
          <FieldsStateFilter />
        </div>
        <div style={{ marginRight: "10px" }}>
          <FieldsDateRangeFilter />
        </div>
        <div style={{ marginRight: "10px" }}>
          <FieldsOwnerFilter />
        </div>
        <div style={{ marginRight: "10px" }}>
          <FieldsAuthorFilter />
        </div>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={19}>
          <FilterMarks />
        </Col>
        <Col xs={4}>
          <FieldsSorts />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <DownloadButtons download={download} buttonSize="middle" />
      </Row>
    </>
  );
}
