/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import i18n from "i18next";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import Authorized from "Components/Authorized/Authorized";
import { isAuthorized } from "Components/Authorized/authHelpers";
import { useCurrentUser } from "frontend/src/utils/hooks";
import PerformanceSubtabPage from "./performance_subtab_page";
import ExposureSubtabPage from "./exposure_subtab_page.js.jsx";
import PeersSubtabPage from "./peers_subtab_page";
import PositionsSubtabPage from "./positions_subtab_page.js.jsx";
import PriorTrackRecordCheckbox from "./PriorTrackRecord.component";
import AUMSubtabPage from "./aum_subtab_page";
import CustomAnalyticsSubtabPage from "./custom_analytics_subtab_page";
import RelativPerformanceSubtabPage from "./relativePerformanceSubtabpage";

enum TabsTypes {
  performance = "performance",
  exposure = "exposure",
  peers = "peers",
  positions = "positions",
  aum = "aum",
  custom = "custom",
  relativePerformance = "relativePerformance",
}

const tabs = [
  TabsTypes.performance,
  TabsTypes.relativePerformance,
  TabsTypes.exposure,
  TabsTypes.peers,
  TabsTypes.positions,
  TabsTypes.aum,
];

const PublicAnalytics = ({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [_, pathSubtab] = hash.split("#") as [string, TabsTypes];
  const [selectedSubtab, changeSelectedSubtab] = useState<TabsTypes>(
    pathSubtab || TabsTypes.performance
  );

  const currentUser = useCurrentUser();
  const subtabRoute = (subtab: TabsTypes) => {
    navigate(`${pathname}#${subtab}`);
  };

  const SubtabPage = (): JSX.Element => {
    switch (selectedSubtab) {
      case TabsTypes.exposure:
        return <ExposureSubtabPage investmentId={investmentId} />;
      case TabsTypes.peers:
        return <PeersSubtabPage investmentId={investmentId} />;
      case TabsTypes.positions:
        return <PositionsSubtabPage investmentId={investmentId} />;
      case TabsTypes.aum:
        return <AUMSubtabPage investmentId={investmentId} />;
      case TabsTypes.custom:
        return (
          <Authorized checks={["isRockCreek"]}>
            <CustomAnalyticsSubtabPage investmentId={investmentId} />
          </Authorized>
        );
      case TabsTypes.relativePerformance:
        return <RelativPerformanceSubtabPage investmentId={investmentId} />;
      case TabsTypes.performance:
      default:
        return <PerformanceSubtabPage investmentId={investmentId} />;
    }
  };

  if (
    isAuthorized({ user: currentUser, checks: ["isRockCreek"] }) &&
    !tabs.includes(TabsTypes.custom)
  ) {
    tabs.push(TabsTypes.custom);
  }

  return (
    <section style={{ paddingBottom: "64px" }}>
      <div>
        <div className="d-flex justify-between flex-wrap">
          <ul
            id="analytics-nav"
            data-cy="analytics-nav"
            className="analytics-secondary-tabs secondary-tabs"
          >
            {tabs.map(tab => (
              <li
                id={`${tab}-subtab`}
                key={tab}
                data-cy={`${tab}-nav`}
                className={classNames({
                  "secondary-tabs__tab secondary-tabs__tab--active":
                    selectedSubtab === tab,
                  "secondary-tabs__tab": !(selectedSubtab === tab),
                })}
                onClick={() => {
                  subtabRoute(tab);
                  changeSelectedSubtab(tab);
                }}
              >
                {i18n.t(`analytics.${tab}.title`)}
              </li>
            ))}
          </ul>

          {selectedSubtab === TabsTypes.performance && (
            <PriorTrackRecordCheckbox investmentId={investmentId} />
          )}
        </div>
        <div className="divider" />
      </div>

      <div id="analytics-body" className="analytics-tab-content">
        <SubtabPage />
      </div>
    </section>
  );
};

export default PublicAnalytics;
