/* eslint-disable react/jsx-no-undef */
import React from "react";

import { Modal as AntdModal } from "antd";
import PropTypes from "prop-types";

function Modal({ width, footer, visible, onCancel, title, children, zIndex }) {
  return (
    <AntdModal
      data-cy="antd-modal"
      width={width}
      open={visible}
      onCancel={onCancel}
      title={title}
      zIndex={zIndex}
      centered
      footer={footer ?? null}
    >
      {children}
    </AntdModal>
  );
}

Modal.defaultProps = {
  footer: null,
  width: "850px",
  onCancel: undefined,
  zIndex: 9999,
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  footer: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
};

export default Modal;
