import React from "react";
import CustomAnalytics from "./components/customAnalytics";

export default function CustomAnalyticsSubtab({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  return (
    <div id="custom-analytics-subtab-page">
      <CustomAnalytics investmentId={investmentId} />
    </div>
  );
}
