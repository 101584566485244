/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react";
import * as types from "_graphql-types/graphql";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { SortContext } from "Components/GlobalSearch/context";
import InvestmentListRow from "../InvestmentListRow";

interface Props {
  investmentIds: number[];
}

export function InvestmentList({ investmentIds }: Props): JSX.Element {
  const { isActive } = useContext(InvestmentControlsContext);

  const { addSort, currentSort } = useContext(SortContext);

  const renderSortButtons = (field: types.InvestmentSort["field"]) =>
    addSort ? (
      <div className="main-fund-row--header__sort-icons">
        <CaretUpFilled
          data-cy="fund-row__sort-asc"
          style={{
            color:
              currentSort?.field === field &&
              currentSort.order === types.SortInput.Asc
                ? "#40a9ff"
                : "#d9d9d9",
          }}
          onClick={_e => addSort({ field, order: types.SortInput.Asc })}
        />
        <CaretDownFilled
          data-cy="fund-row__sort-desc"
          style={{
            color:
              currentSort?.field === field &&
              currentSort.order === types.SortInput.Desc
                ? "#40a9ff"
                : "#d9d9d9",
          }}
          onClick={_e => addSort({ field, order: types.SortInput.Desc })}
        />
      </div>
    ) : null;

  return (
    <div className="main-fund-row-list">
      <div className="main-fund-row-list-wrap">
        <div className="main-fund-row main-fund-row--header">
          <table>
            <tbody>
              <tr>
                {isActive && <th className="main-fund-row__checkbox-col" />}
                <th className="main-fund-row__base-info-col">
                  <div className="flex-row-nowrap align-items-center">
                    Name {renderSortButtons(types.InvestmentSortEnum.Name)}
                  </div>
                </th>
                <th className="main-fund-row__strategy-col">
                  <div className="flex-row-nowrap align-items-center">
                    Asset Class &amp; Strategy{" "}
                    {renderSortButtons(types.InvestmentSortEnum.AssetClass)}
                  </div>
                </th>
                <th className="main-fund-row__geography-col">
                  <div className="flex-row-nowrap align-items-center">
                    Geographic Focus
                    {renderSortButtons(
                      types.InvestmentSortEnum.GeographicFocus
                    )}
                  </div>
                </th>
                <th className="main-fund-row__aum-col">Size</th>
                <th colSpan={2} className="main-fund-row__perform-col">
                  <div>Return 1Y</div>
                </th>
                <th className="main-fund-row__actions-col" />
              </tr>
            </tbody>
          </table>
        </div>
        {investmentIds.map(id => (
          <InvestmentListRow key={id} id={id} />
        ))}
      </div>
    </div>
  );
}
