import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export default function VintageYearFilterTag({
  filter,
}: {
  filter: types.IntRangeFilter;
}) {
  const dispatch = useDispatch();
  const { min, max } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="vintage-year-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "VINTAGE_YEAR",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {`Vintage Year ${min ? `From: ${min}` : ""} - ${
        max ? `To: ${max}` : ""
      } `}
    </Tag>
  );
}
