import { gql } from "_graphql-types/gql";

export const COMPANY_OVERVIEW = gql(/* GraphQL */ `
  query CompanyOverview($id: Int!) {
    company(id: $id) {
      id
      name
      firmId
      summary
      operatingStatus {
        id
        name
      }
      lastFundingType {
        id
        name
      }
      companyType {
        id
        name
      }
      firmAttributes {
        id
        isCompany
        stockSymbol
        isStaffMWDBE
        primaryAddressId
        primaryAddress {
          id
          line1
          line2
          line3
          city
          stateEnumId
          country {
            name
            id
          }
          state {
            name
            id
          }
          otherState
          zipCode
          countryEnumId
          phone
          phone2
          fax
          fax2
          website
        }
        employeeTotal
        yearFounded
        email
        linkedIn
        website
      }
    }
  }
`);

export const COMPANY_DEALS = gql(/* GraphQL */ `
  query CompanyDeals(
    $page: PageInput
    $sort: [DealSort!]
    $filter: DealFilter
  ) {
    dealList(page: $page, sort: $sort, filter: $filter) {
      items {
        id
        name
        expectedCloseDate
        fundingRound {
          id
          name
        }
        firm {
          id
          name
        }
        amount
        roundSize
        preMoneyValuation
        source
        rcSource
        rcSourceUser {
          commonName
          id
        }
        dateReceived
        dealStructure {
          id
          name
        }
        stage {
          id
          name
          displayOrder
        }
        result {
          id
          name
        }
        coInvestors
      }
      total
      nextPage {
        hasMore
        offset
      }
    }
  }
`);

export const COMPANY_CLASSIFICATIONS = gql(/* GraphQL */ `
  query CompanyClassifications($id: Int!) {
    company(id: $id) {
      id
      firmId
      firmAttributes {
        id
        firmTags {
          id
          tag {
            id
            name
            tagClassId
            tagClass {
              id
              name
            }
          }
        }
        impactTags {
          id
          name
          impactEnumCategoryId
          impactCategory {
            id
            name
          }
        }
      }
    }
  }
`);
