import React from "react";
import { useQuery } from "@apollo/client";
import { CompanyOverviewQuery, Firm } from "_graphql-types/graphql";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import SummaryCompanyAdvisor from "Components/show/overview/SummaryCompanyAdvisor";
import { COMPANY_OVERVIEW } from "./graphql";
import { CompanySummaryTile } from "./CompanySummary";
import Classifications from "./Classifications";

const getClickableLink = (link: string) =>
  link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;

function CompanyLocation({
  firmAttributes,
}: {
  firmAttributes: NonNullable<
    CompanyOverviewQuery["company"]
  >["firmAttributes"];
}) {
  if (!firmAttributes?.primaryAddress) return null;
  return (
    <div className="invt-tab__section mt-20">
      <h3 className="invt-tab__title">Location</h3>
      <div className="overview-page__card">
        {firmAttributes.primaryAddress.line1 && (
          <FieldInlinePreview
            displayName="Address"
            content={
              <>
                <p>{firmAttributes.primaryAddress.line1}</p>
                {firmAttributes.primaryAddress.line2 && (
                  <p>{firmAttributes.primaryAddress.line2}</p>
                )}
                {firmAttributes.primaryAddress.line3 && (
                  <p>{firmAttributes.primaryAddress.line3}</p>
                )}
              </>
            }
          />
        )}
        {firmAttributes.primaryAddress.city && (
          <FieldInlinePreview
            displayName="City"
            content={firmAttributes.primaryAddress.city}
          />
        )}
        {firmAttributes.primaryAddress.zipCode && (
          <FieldInlinePreview
            displayName="ZIP"
            content={firmAttributes.primaryAddress.zipCode}
          />
        )}
        {firmAttributes.primaryAddress.state && (
          <FieldInlinePreview
            displayName="State"
            content={firmAttributes.primaryAddress.state.name || "-"}
          />
        )}
        {firmAttributes.primaryAddress.country && (
          <FieldInlinePreview
            displayName="Country"
            content={firmAttributes.primaryAddress.country.name || "-"}
          />
        )}
      </div>
    </div>
  );
}

export default function CompanyOverview({
  companyId,
}: {
  companyId: number;
}): JSX.Element | null {
  const { data } = useQuery(COMPANY_OVERVIEW, {
    variables: { id: companyId },
  });
  if (!data || !data?.company) return null;
  const { company } = data;

  return (
    <div className="main-content main-content--lg">
      <div className="overview-page-wrap">
        <div className="overview-page__content">
          <div className="overview-page__top-info">
            <div className="overview-page__top-info-left">
              <CompanySummaryTile summary={company.summary} />

              <Classifications companyId={companyId} />

              <div className="invt-tab__section">
                <h3 className="invt-tab__title">Company Overview</h3>
                <div data-cy="company_overview" className="overview-page__card">
                  <FieldInlinePreview
                    displayName="Year Founded"
                    content={company.firmAttributes?.yearFounded || "-"}
                  />
                  <FieldInlinePreview
                    displayName="Stock Symbol"
                    content={company.firmAttributes?.stockSymbol || "-"}
                  />
                  <FieldInlinePreview
                    displayName="Operating Status"
                    content={company.operatingStatus?.name || "-"}
                  />
                  <FieldInlinePreview
                    displayName="Last Funding Type"
                    content={company.lastFundingType?.name || "-"}
                  />
                  <FieldInlinePreview
                    displayName="Company Type"
                    content={company.companyType?.name || "-"}
                  />
                  <FieldInlinePreview
                    displayName="MWDBE"
                    content={
                      company.firmAttributes?.isStaffMWDBE ? "Yes" : "No"
                    }
                  />
                  {company.firmAttributes?.website && (
                    <FieldInlinePreview
                      displayName="Website"
                      content={
                        <a
                          href={`${getClickableLink(
                            company.firmAttributes.website
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {company.firmAttributes.website}
                        </a>
                      }
                    />
                  )}
                  <FieldInlinePreview
                    displayName="Email"
                    content={
                      <a href={`mailto: ${company.firmAttributes?.email}`}>
                        {company.firmAttributes?.email}
                      </a>
                    }
                  />
                  <FieldInlinePreview
                    displayName="LinkedIn"
                    content={company.firmAttributes?.linkedIn || "-"}
                  />
                  <FieldInlinePreview
                    displayName="Employees"
                    content={company.firmAttributes?.employeeTotal || "-"}
                  />
                </div>
                <CompanyLocation firmAttributes={company.firmAttributes} />
              </div>
            </div>
            <div className="overview-page__top-info-right">
              <SummaryCompanyAdvisor companyId={company.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
