import HighChartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useMemo } from "react";
import HighchartsData from "highcharts/modules/data";
import HighchartsExport from "highcharts/modules/exporting";
import ErrorMessages from "highcharts/es-modules/Extensions/Debugger/ErrorMessages";
import { get, cloneDeep } from "lodash";

HighchartsData(Highcharts);
HighchartsExport(Highcharts);

const errorMessages: {
  [key: string]: { title: string; text: string; enduser?: string };
} = ErrorMessages;

function CustomHighCharts({
  chartOptions,
}: {
  chartOptions: Highcharts.Options;
}): JSX.Element {
  Highcharts.addEvent(Highcharts.Chart, "displayError", err => {
    if (!Highcharts.charts || !Highcharts.charts[0] || !err) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fullError = get(errorMessages, (err as any).code);
    console.error(fullError?.title, "error");
  });

  const options = useMemo(() => {
    const opts = cloneDeep(chartOptions);
    return {
      ...opts,
      colors: [
        "#92d4e3",
        "#5597ff",
        "#774be5",
        "#0777ad",
        "#f76e4c",
        "#ffb14e",
        "#23cda0",
        "#00bb60",
        "#a5e3b0",
      ],
      exporting: {
        enabled: true,
      },
    };
  }, [chartOptions]);
  return (
    <HighChartsReact options={options} highcharts={Highcharts} immutable />
  );
}

export default CustomHighCharts;
