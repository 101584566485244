import { useGlobalCompanySearch } from "Components/GlobalSearchWorkspace/CompanySearch.hooks";
import { useGlobalInvestmentSearch } from "Components/GlobalSearchWorkspace/InvestmentSearch.hooks";
import { NotesIndex } from "Components/NotesIndex";
import React from "react";

export function GlobalNotesComponent() {
  const {
    variables: {
      searchFilters: investmentFilters,
      firmSearchFilters: firmFilters,
    },
  } = useGlobalInvestmentSearch();

  const {
    variables: { searchFilters: companyFilters },
  } = useGlobalCompanySearch();

  return (
    <div>
      <div className="main-content main-content--lg">
        <NotesIndex
          notesFilters={{
            investmentFilters: Array.isArray(investmentFilters)
              ? investmentFilters
              : undefined,
            firmFilters: Array.isArray(firmFilters) ? firmFilters : undefined,
            companyFilters,
          }}
        />
      </div>
    </div>
  );
}
