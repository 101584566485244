import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import RcTooltip from "rc-tooltip";
import { useNavigate } from "react-router-dom";

const InvestmentNameView = ({ investmentName, isImpact }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames("invst-header__title-wrap", {
        "invst-header__title--impact-tag": isImpact,
      })}
    >
      <i
        onClick={() => navigate(-1)}
        className="invst-header__back-icon icon icon-back icon--24"
      />

      <div className="invst-header__title">
        <RcTooltip
          overlay={investmentName}
          placement="top"
          mouseEnterDelay={0.25}
          destroyTooltipOnHide
        >
          <div id="investment-name" className="invst-header__title-txt">
            {investmentName}
          </div>
        </RcTooltip>
      </div>
    </div>
  );
};

InvestmentNameView.propTypes = {
  investmentName: PropTypes.string.isRequired,
  isImpact: PropTypes.bool.isRequired,
};

export default InvestmentNameView;
