import i18n from "i18next";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
/* eslint-disable react/jsx-no-undef */
import { TeamMemberListQuery } from "_graphql-types/graphql";
import About from "./about";
import Biography from "./biography.jsx";
import EducationInfo from "./education_info.jsx";
import { hasWords, truncateRecords, truncateWithEllipsis } from "./helpers.js";
import WorkExperience from "./work_experience.jsx";

function PersonCard({
  person,
}: {
  person: TeamMemberListQuery["teamMemberList"]["items"][number];
}): JSX.Element {
  const navigate = useNavigate();

  const handlePersonModal = (id: number) => () => navigate(`${id}`);

  if (!person) return <div className="team-member" />;

  const {
    biography,
    firmEmployment,
    education,
    id,
    workExperience,
    fullName,
    workExperienceSummary,
    educationSummary,
  } = person;

  const jobDescription = firmEmployment[0].jobDesc ?? "";

  const aggregateSummary = [
    jobDescription,
    biography,
    workExperienceSummary,
    educationSummary,
  ]
    .filter(summary => typeof summary === "string" && hasWords(summary))
    .join(" ");

  return (
    <div
      data-cy="person-card"
      className="team-member"
      id={`${fullName?.split(" ").join("_")}`}
    >
      <div className="team-member__col team-member__col--left">
        <About
          person={{
            ...person,
            firmEmployment: firmEmployment.map(fe => ({
              ...fe,
              investmentFirmEmployments: truncateRecords(
                firmEmployment[0].investmentFirmEmployments
              ),
            })),
          }}
        />
      </div>
      <div className="team-member__col">
        <div className="hidden-md-down">
          <Biography biography={truncateWithEllipsis(aggregateSummary, 3000)} />
          <WorkExperience workExperience={workExperience} />

          <EducationInfo education={truncateRecords(education)} />
        </div>
        <span className="team-member__show-more hidden-xl-down" />
        <Link
          to={`${id}`}
          className="team-member__show-more"
          onClick={handlePersonModal(id)}
          data-cy="show-more-link"
        >
          {i18n.t("team_summary.more")}
        </Link>
      </div>
    </div>
  );
}

PersonCard.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.number.isRequired,
    biography: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    pronouns: PropTypes.string,
    email: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    workExperienceSummary: PropTypes.string,
    educationSummary: PropTypes.string,
    firmEmployment: PropTypes.arrayOf(
      PropTypes.shape({
        investmentFirmEmployments: PropTypes.arrayOf(
          PropTypes.shape({
            startDate: PropTypes.string,
            employeeRole: PropTypes.shape({
              name: PropTypes.string,
              id: PropTypes.number,
            }),
          })
        ).isRequired,
      }).isRequired
    ).isRequired,
    education: PropTypes.arrayOf(
      PropTypes.shape({
        school: PropTypes.shape({
          name: PropTypes.string,
        }),
        degree: PropTypes.shape({
          name: PropTypes.string,
        }),
        graduationYear: PropTypes.number,
      })
    ),
    workExperience: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default PersonCard;
