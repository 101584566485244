import { gql } from "_graphql-types/gql";

export const GET_RETURN_DISTRIBUTION = gql(/* GraphQL */ `
  query GET_RETURN_DISTRIBUTION($investmentId: Int!) {
    investment(id: $investmentId) {
      id
      name
      performanceAnalysis {
        returnDistribution {
          underNegative10
          negative10to8
          negative8to6
          negative6to4
          negative4to2
          negative2to0
          positive0to2
          positive2to4
          positive4to6
          positive6to8
          positive8to10
          overPositive10
        }
      }
    }
  }
`);
