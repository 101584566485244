import classNames from "classnames";
import i18n from "i18next";
import $ from "jquery";
import { debounce } from "lodash";
import RcTooltip from "rc-tooltip";
import { useCallback, useState } from "react";

import { getInitials } from "Helpers/index";
import { useNavigate } from "react-router-dom";
import NavSidebarActions from "./NavSidebarActions";
import NavSidebarSettings from "./NavSidebarSettings.jsx";

const NavigationSidebarComponent = ({
  isExpanded,
  setIsExpanded,
  userName,
}: {
  isExpanded: boolean;
  setIsExpanded: (b: boolean) => void;
  userName: string;
}) => {
  const [isSettingsShown, changeIsSettingsShown] = useState(false);
  const navigate = useNavigate();

  function renderHelpDropdown() {
    return (
      <div id="help-modal" className="main-navbar__help-modal">
        <p>
          {i18n.t("nav_bar.contact_support")}

          <br />

          {i18n.t("nav_bar.contact_support_number")}
        </p>
      </div>
    );
  }

  const _openSidebar = useCallback(
    debounce(() => setIsExpanded(true), 250),
    []
  );

  function handleSearchDiscoveryClick() {
    if (sidebarCollapsedOnDevice()) {
      handleMouseEnter();
    } else {
      navigate("/");
      handleMouseLeave();
    }
  }

  function handleHideSettings() {
    changeIsSettingsShown(false);
  }

  function handleShowSettings() {
    if (sidebarCollapsedOnDevice()) {
      handleMouseEnter();
    } else {
      changeIsSettingsShown(true);
    }
  }

  function handleMouseEnter() {
    _openSidebar();
    $("body").addClass("body-faded");
  }

  function handleMouseLeave() {
    _openSidebar.cancel();
    setIsExpanded(false);
    $("body").removeClass("body-faded");
  }

  function sidebarCollapsedOnDevice() {
    return !isExpanded;
  }

  function renderFooter() {
    return (
      <div className="hidden-lg-up">
        <div>
          <div className="nav-sidebar__list-item nav-sidebar__list-item--help">
            <RcTooltip
              placement="top"
              trigger="click"
              overlayClassName="rc-tooltip--no-arrow"
              overlayStyle={{ width: "320px" }}
              overlay={renderHelpDropdown()}
            >
              <span className="cursor-pointer" id="help-dropdown-button">
                <i
                  className="nav-sidebar__list-item-icon icon icon-info"
                  aria-hidden="true"
                />
                <span className="nav-sidebar__list-item-text">
                  {i18n.t("navigation_sidebar.help")}
                </span>
              </span>
            </RcTooltip>
          </div>
        </div>

        <div className="divider" />

        <div
          className="nav-sidebar__list-item nav-sidebar__list-item--settings clearfix"
          onClick={handleShowSettings}
        >
          <div className="main-userpic main-userpic--24">
            {getInitials(userName)}
          </div>

          <span className="nav-sidebar__list-item-text">{userName}</span>

          <i
            className={classNames(
              "nav-sidebar__list-item-icon-settings icon icon--24",
              "icon-angle-right float-right mr-16"
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames("nav-sidebar__wrap", {
        "nav-sidebar__wrap--expanded": isExpanded,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="main-logo-sidebar" onClick={handleSearchDiscoveryClick}>
        <i className="main-logo-sidebar__icon" />

        <span className="main-logo-sidebar__txt">
          {i18n.t("navigation_sidebar.logo")}
        </span>
      </div>

      <div className="nav-sidebar__content">
        {isSettingsShown && (
          <NavSidebarSettings
            handleClose={handleHideSettings}
            userName={userName}
          />
        )}

        {!isSettingsShown && (
          <NavSidebarActions
            onSearchDiscoveryClick={handleSearchDiscoveryClick}
            handleClose={handleMouseLeave}
          />
        )}

        {!isSettingsShown && renderFooter()}
      </div>
    </div>
  );
};

export default NavigationSidebarComponent;
