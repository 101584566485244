import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { ensureDefined } from "frontend/src/utils/helpers";
import { useMemo } from "react";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

const SET_TAG_QUERY = gql(/* GraphQL */ `
  query SetFilterTag($id: Int!) {
    set(id: $id) {
      name
      id
    }
  }
`);

function setFilterTag({ filter }: { filter: types.SetSearchFilter }) {
  const dispatch = useDispatch();
  const { setId } = filter;

  // if an investment set is a user's default view it may not be in the cache on initial page load
  const { data, loading, called } = useQuery(SET_TAG_QUERY, {
    variables: {
      id: ensureDefined(setId),
    },
    skip: !setId,
  });

  const tagText = useMemo(() => {
    if (called && loading) {
      return (
        <i
          id="loading-more-spinner"
          className="main-spinner__icon"
          style={{ color: "#ffffff", fontSize: "10px" }}
        />
      );
    }
    return data?.set?.name;
  }, [data, called, loading]);

  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="set-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "SET",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {tagText}
    </Tag>
  );
}

export default setFilterTag;
