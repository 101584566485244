/* eslint-disable max-lines-per-function */
import {
  endOfQuarter,
  getQuarter,
  quartersToMonths,
  startOfDay,
} from "date-fns";
import moment from "moment";
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import LoadingPanel from "../../loading_panel";
import StatisticsTable from "../../show/overview/statistics_table";
import { BenchmarkLayoutTypesEnum } from "./types";

type DataMappingQuarterlyHorizontal = {
  Q1?: number;
  Q2?: number;
  Q3?: number;
  Q4?: number;
  year: number;
};
type DataMappingQuarterlyVertical = { Quarter?: Date; Return?: number };
type DataMappingMtdHorizontal = {
  year?: number;
  Jan?: number;
  Feb?: number;
  Mar?: number;
  Apr?: number;
  May?: number;
  Jun?: number;
  Jul?: number;
  Aug?: number;
  Sep?: number;
  Oct?: number;
  Nov?: number;
  Dec?: number;
};
type DataMappingMtdVertical = { Date?: Date; Return?: number };

type Months = keyof Omit<DataMappingMtdHorizontal, "year">;

const months: Months[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function isHorizontal(
  dataMapping:
    | DataMappingMtdHorizontal
    | DataMappingMtdVertical
    | DataMappingQuarterlyHorizontal
    | DataMappingQuarterlyVertical
): dataMapping is DataMappingMtdHorizontal | DataMappingQuarterlyHorizontal {
  return !("Return" in dataMapping);
}

function isQuarterlyHorizonal(
  dataMapping: DataMappingMtdHorizontal | DataMappingQuarterlyHorizontal
): dataMapping is DataMappingQuarterlyHorizontal {
  return (
    "Q1" in dataMapping ||
    "Q2" in dataMapping ||
    "Q3" in dataMapping ||
    "Q4" in dataMapping
  );
}

interface TableProps {
  layout: string;
  frequency: string;
  benchmarkLayoutType?: BenchmarkLayoutTypesEnum;
  benchmarkId?: number;
  data?: types.FetchInvestmentPerformanceQuery;
  convertedData?: types.FetchPerformanceCurrencyConvertedQuery;
  benchmarkData?: types.FetchInvestmentPerformanceQuery;
  benchmarkConvertedData?: types.FetchPerformanceCurrencyConvertedQuery;
  loading: boolean;
  loadingBenchmark: boolean;
}

const monthFormat = (month: number) =>
  moment()
    .month(month - 1)
    .format("MMM");

function ytd(
  returns:
    | DataMappingMtdHorizontal
    | DataMappingMtdVertical
    | DataMappingQuarterlyHorizontal
    | DataMappingQuarterlyVertical
) {
  if (!isHorizontal(returns)) return undefined;
  if (isQuarterlyHorizonal(returns)) return undefined;

  const filteredReturns = Object.keys(returns).filter(
    (month): month is Months => months.includes(month as Months)
  );
  const _ytd = filteredReturns.reduce((acc, month) => {
    const monthReturn = returns[month];
    if (monthReturn) {
      return acc * (1 + Number(monthReturn) / 100);
    }
    return acc;
  }, 1);

  return (_ytd - 1) * 100;
}

function QuarterlyDataMapping(
  data: types.FetchInvestmentPerformanceQuery,
  layout: string,
  startYear?: number,
  startQuarter?: number
) {
  let dataMapping: (
    | DataMappingQuarterlyHorizontal
    | DataMappingQuarterlyVertical
  )[] = [];
  const performance =
    data.investment.performanceQuarterly?.filter(
      ({ returnQuarter, returnYear }) =>
        (startQuarter &&
          startYear &&
          (returnYear > startYear ||
            (returnYear === startYear && returnQuarter >= startQuarter))) ||
        (!startQuarter && !startQuarter)
    ) || [];

  if (layout === "horizontal") {
    dataMapping = performance.reduce(
      (acc: DataMappingQuarterlyHorizontal[], quarter) => {
        const { returnYear, returnQuarter } = quarter;
        const returnPercent = quarter.return;
        if (acc.map(rec => rec.year).includes(returnYear)) {
          acc[acc.findIndex(x => x.year === returnYear)] = {
            ...acc[acc.findIndex(x => x.year === returnYear)],
            [`Q${returnQuarter}`]: returnPercent != null && returnPercent,
          };
        } else {
          acc.push({ year: returnYear });
          acc[acc.findIndex(x => x.year === returnYear)] = {
            ...acc[acc.findIndex(x => x.year === returnYear)],
            [`Q${returnQuarter}`]: returnPercent != null && returnPercent,
          };
        }
        return acc;
      },
      []
    );
    dataMapping = dataMapping.map(year => ({ ...year, ytd: ytd(year) }));
  } else {
    dataMapping = performance.map(perfData => ({
      Quarter: startOfDay(
        endOfQuarter(
          new Date(
            perfData.returnYear,
            quartersToMonths(perfData.returnQuarter) - 1
          )
        )
      ),
      Return: perfData.return ?? undefined,
    }));
  }
  return dataMapping;
}

function MtdConvertedDataMapping(
  data: types.FetchPerformanceCurrencyConvertedQuery,
  layout: string,
  startYear?: number,
  startMonth?: number
) {
  let dataMapping: (DataMappingMtdHorizontal | DataMappingMtdVertical)[] = [];
  const performance =
    data.investment.performanceMTDCurrencyConverted?.filter(
      ({ returnYear, returnMonth }) =>
        (!!startYear &&
          !!startMonth &&
          (startYear < returnYear ||
            (returnYear === startYear && startMonth <= returnMonth))) ||
        (!startYear && !startMonth)
    ) || [];

  if (layout === "horizontal") {
    dataMapping = performance.reduce((acc, month) => {
      const { returnYear, returnMonth } = month;
      const returnPercent = month.return;

      if (acc.map(rec => rec.year).includes(returnYear)) {
        acc[acc.findIndex(x => x.year === returnYear)] = {
          ...acc[acc.findIndex(x => x.year === returnYear)],
          [monthFormat(returnMonth)]: returnPercent != null && returnPercent,
        };
      } else {
        acc.push({ year: returnYear });
        acc[acc.findIndex(x => x.year === returnYear)] = {
          ...acc[acc.findIndex(x => x.year === returnYear)],
          [monthFormat(returnMonth)]: returnPercent != null && returnPercent,
        };
      }
      return acc;
    }, [] as DataMappingMtdHorizontal[]);

    dataMapping = dataMapping.map(year => ({ ...year, ytd: ytd(year) }));
  } else {
    dataMapping = performance.map(perfData => ({
      // JS month is zero-based; setting day of month to 0 yields the last day of the previous month
      Date: new Date(perfData.returnYear, perfData.returnMonth, 0),
      Return: perfData.return ?? undefined,
    }));
  }
  return dataMapping;
}

function MtdDataMapping(
  data: types.FetchInvestmentPerformanceQuery,
  layout: string,
  startYear?: number,
  startMonth?: number
) {
  let dataMapping: (DataMappingMtdHorizontal | DataMappingMtdVertical)[] = [];
  const performance =
    data.investment.performanceMTD?.filter(
      ({ returnYear, returnMonth }) =>
        (!!startYear &&
          !!startMonth &&
          (startYear < returnYear ||
            (returnYear === startYear && startMonth <= returnMonth))) ||
        (!startYear && !startMonth)
    ) || [];

  if (layout === "horizontal") {
    dataMapping = performance.reduce((acc, month) => {
      const { returnYear, returnMonth } = month;
      const returnPercent = month.return;

      if (acc.map(rec => rec.year).includes(returnYear)) {
        acc[acc.findIndex(x => x.year === returnYear)] = {
          ...acc[acc.findIndex(x => x.year === returnYear)],
          [monthFormat(returnMonth)]: returnPercent != null && returnPercent,
        };
      } else {
        acc.push({ year: returnYear });
        acc[acc.findIndex(x => x.year === returnYear)] = {
          ...acc[acc.findIndex(x => x.year === returnYear)],
          [monthFormat(returnMonth)]: returnPercent != null && returnPercent,
        };
      }
      return acc;
    }, [] as DataMappingMtdHorizontal[]);

    dataMapping = dataMapping.map(year => ({ ...year, ytd: ytd(year) }));
  } else {
    dataMapping = performance.map(perfData => ({
      // JS month is zero-based; setting day of month to 0 yields the last day of the previous month
      Date: new Date(perfData.returnYear, perfData.returnMonth, 0),
      Return: perfData.return ?? undefined,
    }));
  }
  return dataMapping;
}

const formatReturnValue = (value: unknown) =>
  !!value || value === 0
    ? `${(value as number).toFixed(2).toString()}%`
    : ((value as number) ?? "-").toString();

const Table = ({
  layout = "horizontal",
  frequency,
  benchmarkId,
  benchmarkLayoutType,
  data,
  convertedData,
  benchmarkData,
  benchmarkConvertedData,
  loading,
  loadingBenchmark,
}: TableProps): JSX.Element => {
  const benchmarkDataMapping = useMemo(() => {
    if (
      benchmarkConvertedData &&
      frequency === "MTD" &&
      data?.investment.performanceMTD
    ) {
      const { returnYear, returnMonth } = convertedData?.investment
        .performanceMTDCurrencyConverted
        ? convertedData?.investment.performanceMTDCurrencyConverted[
            convertedData?.investment.performanceMTDCurrencyConverted.length - 1
          ] || {}
        : data?.investment.performanceMTD[
            data?.investment.performanceMTD.length - 1
          ] || {};

      return MtdConvertedDataMapping(
        benchmarkConvertedData,
        layout,
        returnYear,
        returnMonth
      );
    }

    if (
      benchmarkData &&
      frequency === "MTD" &&
      data?.investment.performanceMTD
    ) {
      const { returnYear, returnMonth } =
        data?.investment.performanceMTD[
          data?.investment.performanceMTD.length - 1
        ] || {};
      return MtdDataMapping(benchmarkData, layout, returnYear, returnMonth);
    }

    if (
      benchmarkData &&
      frequency === "Quarterly" &&
      data?.investment.performanceQuarterly
    ) {
      const { returnYear, returnQuarter } =
        data?.investment.performanceQuarterly[
          data?.investment.performanceQuarterly.length - 1
        ] || {};
      return QuarterlyDataMapping(
        benchmarkData,
        layout,
        returnYear,
        returnQuarter
      );
    }
    return [];
  }, [
    benchmarkData,
    benchmarkConvertedData,
    layout,
    frequency,
    benchmarkLayoutType,
    data,
  ]);

  const dataMapping = useMemo(() => {
    if (
      convertedData &&
      frequency === "MTD" &&
      !!convertedData.investment.performanceMTDCurrencyConverted
    ) {
      return MtdConvertedDataMapping(convertedData, layout);
    }

    if (data && frequency === "MTD" && !!data.investment.performanceMTD) {
      return MtdDataMapping(data, layout);
    }
    if (
      data &&
      frequency === "Quarterly" &&
      !!data.investment.performanceQuarterly
    ) {
      return QuarterlyDataMapping(data, layout);
    }
    return [];
  }, [data, convertedData, layout, frequency, benchmarkLayoutType]);

  const statisticsLayout = useMemo(() => {
    let keyOrder: string[] = [];
    let headers: string[] = [];
    let benchmarkLookupKey = "";
    let ignoredBenchmarkKeys: string[] = [];
    let formatters: {
      [key: string]: (value: unknown) => string;
    } = {};

    if (frequency === "MTD") {
      if (layout === "horizontal") {
        keyOrder = ["year", ...months, "ytd"];
        headers = ["Year", ...months, "YTD"];
        benchmarkLookupKey = "year";
        ignoredBenchmarkKeys = ["year"];
        formatters = {
          ...[...months, "ytd"].reduce(
            (acc, month) => ({
              ...acc,
              [month]: formatReturnValue,
            }),
            {}
          ),
        };
      } else {
        keyOrder = ["Date", "Return"];
        headers = ["Date", "Return"];
        benchmarkLookupKey = "Date";
        ignoredBenchmarkKeys = ["Date"];
        formatters = {
          Date: value =>
            // JS month is zero-based
            `${monthFormat((value as Date).getMonth() + 1)}-${(
              value as Date
            ).getFullYear()}`,
          Return: formatReturnValue,
        };
      }
    }
    if (frequency === "Quarterly") {
      if (layout === "horizontal") {
        ignoredBenchmarkKeys = ["year"];
        benchmarkLookupKey = "year";
        keyOrder = ["year", "Q1", "Q2", "Q3", "Q4", "YTD"];
        headers = ["Year", "Q1", "Q2", "Q3", "Q4", "YTD"];
        formatters = {
          ...["Q1", "Q2", "Q3", "Q4", "YTD"].reduce(
            (acc, quarter) => ({
              ...acc,
              [quarter]: formatReturnValue,
            }),
            {}
          ),
        };
      } else {
        ignoredBenchmarkKeys = ["Quarter"];
        benchmarkLookupKey = "Quarter";
        headers = ["Quarter", "Return"];
        keyOrder = ["Quarter", "Return"];
        formatters = {
          Quarter: value =>
            `Q${getQuarter(value as Date)}-${(value as Date).getFullYear()}`,
          Return: formatReturnValue,
        };
      }
    }

    return {
      ignoredBenchmarkKeys,
      benchmarkLookupKey,
      keyOrder,
      headers,
      formatters,
    };
  }, [layout, frequency]);

  return (
    <LoadingPanel loading={loading || loadingBenchmark}>
      <div data-cy={`performance-table-${frequency.toLowerCase()}`}>
        <StatisticsTable
          dataMapping={dataMapping}
          keyOrder={statisticsLayout.keyOrder}
          headers={statisticsLayout.headers}
          benchmarkDataMapping={benchmarkId && benchmarkDataMapping}
          benchmarkLayoutType={benchmarkId && benchmarkLayoutType}
          benchmarkLookupKey={statisticsLayout.benchmarkLookupKey}
          ignoredBenchmarkKeys={statisticsLayout.ignoredBenchmarkKeys}
          formatters={statisticsLayout.formatters}
          fileName={`${data?.investment?.name}_performance`}
          sortableKeys={["Date", "Quarter", "Return"]}
        />
      </div>
    </LoadingPanel>
  );
};

export default Table;
