import { gql } from "_graphql-types/gql";

export const FETCH_PERFORMANCE_PRIVATE = gql(/* GraphQL */ `
  query getOneInvestmentPerformancePrivate($id: Int!) {
    investment(id: $id) {
      ...PerformanceInvestmentAttributeFragment
      performancePrivate(sort: [{ field: asOfDate, order: DESC }]) {
        returnYear
        returnQuarter
        netIRR
        tvpi
        dpi
      }
    }
  }
`);
