import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFromEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { CompanyEntityFilterType } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";

export function EntityFilterTag({
  type,
  filter,
}: {
  type: CompanyEntityFilterType;
  filter: types.EntitySearchFilter;
}) {
  const dispatch = useDispatch();
  return (
    <>
      {filter.values.map(({ id, label }) => {
        if (!id || !label) return null;
        return (
          <Tag
            className={`ant-tag--close ${
              type === "COMPANY_ENTITY" ? "ant-tag--green" : "ant-tag--blue"
            }`}
            data-cy="entity-filter-tag"
            data-type={type}
            key={JSON.stringify([type, id])}
            onClose={() => {
              dispatch(
                removeFromEntityFilter({
                  type: "company",
                  data: {
                    type,
                    data: {
                      id,
                      label,
                    },
                  },
                })
              );
            }}
            closable
          >
            {label}
          </Tag>
        );
      })}
    </>
  );
}
