import React from "react";
import NoteAdd from "Components/InvestmentNotes/noteAdd";
import { NotesIndex } from "Components/NotesIndex";

export default function CompanyNotesComponent({ firmId }: { firmId: number }) {
  return (
    <div className="main-content main-content--lg">
      <NotesIndex notesFilters={{ firmId }}>
        <NoteAdd firmId={firmId} />
      </NotesIndex>
    </div>
  );
}
