import React from "react";
import PropTypes from "prop-types";
import InvestmentPositionsTable from "Components/InvestmentPositionsTable";

const PositionsSubtabPage = ({ investmentId }) => (
  <div id="positions-subtab-page">
    <InvestmentPositionsTable investmentId={investmentId} />
  </div>
);

PositionsSubtabPage.propTypes = {
  investmentId: PropTypes.number.isRequired,
};

export default PositionsSubtabPage;
