import React, { useMemo } from "react";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

interface SeriesData {
  name: string;
  data: [number, number][];
}

function DrawdownsHighChart({
  chartData,
  title,
}: {
  title: string;
  chartData: SeriesData[];
}): JSX.Element {
  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "area",
        zoomType: "x",
      },
      title: {
        text: title,
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },
      yAxis: {
        labels: {
          format: "{value}%",
        },
        title: {
          enabled: false,
        },
        max: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        xDateFormat: "%Y-%m-%d",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: chartData,
    }),
    [chartData]
  );

  return (
    <HighChartsReact
      constructorType="chart"
      options={options}
      highcharts={HighCharts}
    />
  );
}

export default DrawdownsHighChart;
