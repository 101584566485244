import { gql } from "_graphql-types/gql";

export const GEOGRAPHY_ENUMS = gql(/* GraphQL */ `
  query GeographyEnumList {
    geographyEnumList {
      items {
        id
        description
      }
    }
  }
`);

export const FETCH_VISUALIZATION_DATA = gql(/* GraphQL */ `
  query DeiVisualizationData(
    $entityId: Int!
    $isCompany: Boolean!
    $geographyEnumId: Int
    $sort: [DeiCategory1EnumSort!]
  ) {
    deiCategory1EnumList(sort: $sort) {
      items {
        id
        description
        isAdditionalMinorityGroup
      }
    }
    deiCategory2EnumList {
      items {
        id
        description
      }
    }
    latestFirmDEI(
      entityId: $entityId
      isCompany: $isCompany
      geographyEnumId: $geographyEnumId
    ) {
      id
      firmId
      geographyEnumId
      asOfDate
      firmDEIRecords {
        firmDEIId
        deiCategory1EnumId
        deiCategory2EnumId
        measureEnumId
        value
        percentValue
        deiCategory1Enum {
          id
          description
          isAdditionalMinorityGroup
        }
        deiCategory2Enum {
          id
          description
        }
        measureEnum {
          id
          description
        }
      }
    }
  }
`);
