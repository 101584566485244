/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines-per-function */
import * as types from "_graphql-types/graphql";
import {
  AxisChartSeries,
  BubbleChartSeries,
  getAxisChartSeries,
  getPackedBubbleSeries,
  getPieChartSeries,
  PieChartSeries,
} from "frontend/src/components/HighCharts/dataSeriesFormatters";
import { roundPercentageWithCap } from "Components/fund_report/FactSheet/utils/roundedPercentages";

export type PartialFirmDEIRecord = Partial<types.FirmDeiRecord> &
  Pick<
    types.FirmDeiRecord,
    | "deiCategory1Enum"
    | "deiCategory2Enum"
    | "percentValue"
    | "value"
    | "deiCategory1EnumId"
    | "deiCategory2EnumId"
  >;

export type TRecord = types.DeiVisualizationDataQuery["latestFirmDEI"];

export type ITwoCategorySeries = "column" | "bar" | "packedbubble";

function sumOverValueInRecords<
  R extends Partial<Record<keyof R, unknown>>,
  C extends Record<keyof C, string>
>(data: R[], recordValueKey: keyof R): number {
  return data.reduce((acc: number, curr): number => {
    if (!curr[recordValueKey]) return acc;
    acc += curr[recordValueKey] as number;
    return acc;
  }, 0);
}

function isDeiCategory1Enum(
  param: (types.DeiCategory1Enum | types.DeiCategory2Enum)[]
): param is types.DeiCategory1Enum[] {
  return param.every(p => p.__typename && p.__typename === "DeiCategory1Enum");
}

function getPercentRecords(
  data: Partial<types.FirmDeiRecord>[],
  isPercent: boolean
) {
  return data.map(r => {
    if (isPercent) {
      return r.value ? r.value : 0;
    }

    return r.percentValue ? r.percentValue * 100 : 0;
  });
}

/**
 * generates a data series across two categories (ie. gender + race)
 * @param seriesC series categories (used in legend)
 * @param axisC axis categories or bubble categories (used for bubbles or plotting along axis)
 * @param records data records
 * @param valueKey key of point value on record
 * @param chartType (eg. packedbubble, column, bar)
 */
export function getTwoCategorySeries(
  seriesC: types.DeiCategory1Enum[],
  axisC: types.DeiCategory2Enum[],
  records: Partial<types.FirmDeiRecord>[],
  valueKey: "value" | "percentValue",
  chartType: ITwoCategorySeries
): BubbleChartSeries[] | AxisChartSeries[] {
  const diversityCategories = seriesC.map(
    ({ id, description }: types.DeiCategory1Enum) => ({
      id,
      name: description,
    })
  );
  const genderCategories = axisC.map(
    ({ id, description }: types.DeiCategory2Enum) => ({
      id,
      name: description,
    })
  );

  if (chartType === "packedbubble") {
    return getPackedBubbleSeries(
      records,
      valueKey,
      diversityCategories,
      "deiCategory1EnumId",
      genderCategories,
      "deiCategory2EnumId"
    );
  }

  let categoryAxis: "x" | "y" = "x";
  if (chartType === "bar") {
    categoryAxis = "y";
  }
  return getAxisChartSeries(
    records,
    valueKey,
    diversityCategories,
    "deiCategory1EnumId",
    genderCategories,
    "deiCategory2EnumId",
    categoryAxis
  );
}

/**
 * generates a data series across a single category (ie. gender)
 * @param records data records
 * @param valueKey key of point value on record
 * @param seriesC series categories (used in legend)
 */
export function getOneCategorySeries(
  records: Partial<types.FirmDeiRecord>[],
  valueKey: "value" | "percentValue",
  seriesC: types.DeiCategory1Enum[] | types.DeiCategory2Enum[]
): PieChartSeries[] {
  if (isDeiCategory1Enum(seriesC)) {
    const seriesCategories = seriesC.map(
      ({ id, description }: types.DeiCategory1Enum) => ({
        id,
        name: description,
      })
    );

    return getPieChartSeries(
      records,
      valueKey,
      seriesCategories,
      "deiCategory1EnumId"
    );
  }

  const seriesCategories = seriesC.map(
    ({ id, description }: types.DeiCategory2Enum) => ({
      id,
      name: description,
    })
  );

  return getPieChartSeries(
    records,
    valueKey,
    seriesCategories,
    "deiCategory2EnumId"
  );
}

/**
 * generates a data series for the proportion of people in a firm DEI measure belonging to an additional diversity category
 * (ie. veterans, people with disabilities or LGBTQ+)
 * @param records data records
 * @param valueKey key of point value on record
 * @param seriesC series categories (used in legend)
 * @param categoryId ID of category of interest
 */

export function getAdditionalDiversityDataSeries(
  records: Partial<types.FirmDeiRecord>[],
  valueKey: "value" | "percentValue",
  seriesC: types.DeiCategory1Enum[],
  categoryId: number
): PieChartSeries[] {
  const seriesCategories = seriesC
    .map(({ id, description }: types.DeiCategory1Enum) => ({
      id,
      name: description,
    }))
    .filter(category => category.id === categoryId);

  seriesCategories.push({ id: 0, name: `Not ${seriesCategories[0].name}` });

  if (!records.length) {
    return getPieChartSeries(
      records,
      valueKey,
      seriesCategories,
      "deiCategory1EnumId"
    );
  }

  const recordsForSeries = [];

  const valueTotal = sumOverValueInRecords(
    records.filter(rec => !rec.deiCategory1Enum?.isAdditionalMinorityGroup),
    valueKey
  );

  const categoryRecordBase = records.filter(
    rec => rec.deiCategory1EnumId === categoryId
  )[0];

  const categoryRecord = categoryRecordBase
    ? {
        ...categoryRecordBase,
        [valueKey]: Math.round(
          ((categoryRecordBase[valueKey] ?? 0) / valueTotal) * 100
        ), // percentage of total
      }
    : {
        deiCategory1Enum: {
          ...seriesCategories[0],
          description: seriesCategories[0].name,
          isAdditionalMinorityGroup: true,
        },
        deiCategory1EnumId: seriesCategories[0].id,
        [valueKey]: 0,
      };

  recordsForSeries.push(categoryRecord);

  recordsForSeries.push({
    deiCategory1Enum: {
      id: 0,
      description: `Not ${seriesCategories[0].name}`,
    },
    deiCategory1EnumId: 0,
    [valueKey]: categoryRecord ? 100 - (categoryRecord[valueKey] ?? 0) : 100,
  });

  return getPieChartSeries(
    recordsForSeries,
    valueKey,
    seriesCategories,
    "deiCategory1EnumId"
  );
}

export function aggregateDataByCategory<
  R extends Partial<Record<keyof R, unknown>>,
  C extends Record<keyof C, string>
>(
  data: R[],
  recordValueKey: keyof R,
  recordCategoryKey: keyof R
): Partial<R>[] {
  const records = data.reduce((acc: Partial<R>[], curr) => {
    if (acc.find(r => r[recordCategoryKey] === curr[recordCategoryKey]))
      return acc;

    const categoryRecords = data.filter(
      r => r[recordCategoryKey] === curr[recordCategoryKey]
    );

    const valueSum = sumOverValueInRecords(categoryRecords, recordValueKey);

    acc.push({
      ...curr,
      [recordCategoryKey]: curr[recordCategoryKey],
      [recordValueKey]: valueSum,
    });

    return acc;
  }, []);

  return records;
}

interface AggregatedData {
  aggregatedData: PartialFirmDEIRecord[];
  totalTeamSize: number | boolean;
}

/**
 * combines records by a list of measures
 * @param record
 * @param measureEnumIds list of measures to aggregate
 * @param isPercent is the measure recorded in percent or number of people
 */
export function calculateAggregatedData({
  measureEnumIds,
  isPercent,
  record,
}: {
  measureEnumIds: number[];
  isPercent: boolean;
  record?: TRecord;
}): AggregatedData {
  const data = record?.firmDEIRecords ?? [];

  const valueKey: keyof types.FirmDeiRecord = isPercent
    ? "value"
    : "percentValue";

  const recordsFromMeasure = data.filter(
    r => r.measureEnumId && measureEnumIds.includes(r.measureEnumId)
  );
  if (!recordsFromMeasure || !recordsFromMeasure.length)
    return { aggregatedData: [], totalTeamSize: 0 };

  const totalTeamSize = recordsFromMeasure.reduce((accu, curr) => {
    if (!curr.value) return accu;
    accu += curr.value;
    return accu;
  }, 0);

  const records = recordsFromMeasure.reduce(
    (acc: PartialFirmDEIRecord[], curr) => {
      if (
        acc.find(
          r =>
            r.deiCategory1EnumId === curr.deiCategory1EnumId &&
            r.deiCategory2EnumId === curr.deiCategory2EnumId
        )
      )
        return acc;

      const categoryRecords = recordsFromMeasure.filter(
        rec =>
          rec.deiCategory1EnumId === curr.deiCategory1EnumId &&
          rec.deiCategory2EnumId === curr.deiCategory2EnumId
      );

      const categoryTeamSize = categoryRecords.reduce((accu, c) => {
        if (!c.value && c.value !== 0) return accu;
        accu += c.value;

        return accu;
      }, 0);

      let percentSum = categoryTeamSize / totalTeamSize;
      if (isPercent) {
        percentSum = categoryTeamSize;
      }

      acc.push({
        deiCategory1Enum: curr.deiCategory1Enum,
        deiCategory2Enum: curr.deiCategory2Enum,
        deiCategory1EnumId: curr.deiCategory1EnumId,
        deiCategory2EnumId: curr.deiCategory2EnumId,
        [valueKey]: percentSum,
      });
      return acc;
    },
    []
  );

  if (isPercent) {
    return {
      aggregatedData: records,
      totalTeamSize: false,
    };
  }

  const aggRecords = records.map((rec, i) => {
    const percentRecords = roundPercentageWithCap(
      getPercentRecords(records, isPercent)
    );
    return {
      ...rec,
      [valueKey]: percentRecords[i],
    };
  });

  return {
    aggregatedData: aggRecords,
    totalTeamSize: isPercent ? false : totalTeamSize,
  };
}
