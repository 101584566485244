/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-lines-per-function */
import { useApolloClient } from "@apollo/client";
import { CompanyControlsContext } from "Components/CompanyControls/context";
import { COMPANY_TILE } from "Components/graphql/fragments/Company";
import { renderIconGrid } from "frontend/src/components/SDGIcons";
import { formatDate } from "frontend/src/utils/date";
import { camelCase, startCase } from "lodash";
import numeral from "numeral";
import { useContext } from "react";
import { Link } from "react-router-dom";

interface Props {
  id: number;
}

function CompanyListRowComponent({ id }: Props) {
  const client = useApolloClient();

  const company = client.readFragment({
    id: `Company:${id}`,
    fragment: COMPANY_TILE,
  });

  if (!company) return null;

  const industry = company.firmAttributes.firmTags?.[0]?.tag.name;
  const { valuation, asOfDate: valuationDate } =
    (company.valuations && company.valuations[0]) ?? {};

  const topFounders =
    company.firmAttributes?.firmEmployments?.map(({ person }) => person) || [];

  const location = [
    company?.firmAttributes?.primaryAddress?.city,
    company?.firmAttributes?.primaryAddress?.state?.code ??
      company?.firmAttributes?.primaryAddress?.otherState,
    company?.firmAttributes?.primaryAddress?.country?.name &&
      startCase(
        camelCase(company?.firmAttributes?.primaryAddress?.country?.name)
      ),
  ]
    .filter(Boolean)
    .join(", ");

  const link = ["/company", id].join("/");
  const { isSelected, toggleCompanyId, isActive } = useContext(
    CompanyControlsContext
  );

  return (
    <div className="main-fund-row fund -full-access" data-cy="list-item-row">
      <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>
        <table>
          <tbody>
            <tr>
              <td className="main-fund-row__base-info-col">
                <div className="d-flex align-items-center">
                  <div className="main-fund-row__base-info-col-content clearfix">
                    {isActive && (
                      <div
                        className="main-checkbox"
                        onClick={event => {
                          event.preventDefault();
                          toggleCompanyId(id);
                        }}
                      >
                        <input
                          id={String(id)}
                          type="checkbox"
                          checked={isSelected(id)}
                          className="main-checkbox__input main-checkbox__input--only-checkbox"
                          readOnly
                        />
                        <label
                          className="main-checkbox__label"
                          htmlFor={String(id)}
                        />
                      </div>
                    )}
                    <p className="main-fund-row__name fund-name">
                      {company.name}
                    </p>
                    <div className="main-fund-row__info hidden-md-down">
                      {topFounders.map(founder => (
                        <span className="d-inline-block va-middle mr-10 portfolio-manager">
                          <i className="icon icon--20 icon-user font-14 mr-8" />
                          <span className="d-inline-block va-middle">
                            {founder.firstName} {founder.middleName}{" "}
                            {founder.lastName}
                          </span>
                        </span>
                      ))}
                      {location && (
                        <span className="d-inline-block va-middle mr-10 location">
                          <i className="icon icon--20 icon-pin font-17 mr-8" />
                          <span className="d-inline-block va-middle">
                            {location}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="d-flex justify-between hidden-md-up">
                      <div className="d-flex">
                        <span className="main-fund-row__numbers in-plue-900">
                          -
                        </span>
                        <span> 1Y </span>
                      </div>
                      <div className="d-flex">
                        <span className="main-fund-row__numbers in-blue-900">
                          -
                        </span>
                        &nbsp;
                        <span>Size</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="main-fund-row__strategy-col tags">
                <div className="main-fund-row__strategy-col-content">
                  {industry}
                </div>
              </td>
              <td className="main-fund-row__strategy-col tags">
                <div className="main-fund-row__strategy-col-content">
                  {renderIconGrid({
                    impactTags: company.firmAttributes?.impactTags ?? [],
                  })}
                </div>
              </td>
              <td className="main-fund-row__strategy-col tags">
                <div className="main-fund-row__strategy-col-content">
                  {company.firmAttributes.yearFounded}
                </div>
              </td>
              <td className="main-fund-row__aum-col">
                <span className="main-fund-row__numbers in-blue-900 asset-value">
                  {valuation
                    ? numeral(valuation).format("0.0a").slice(0, -1)
                    : "-"}
                  <span className="in-secondary-blue">
                    {valuation && numeral(valuation).format("0.0a").slice(-1)}
                  </span>
                </span>
                <p className="main-fund-row__range asset-value-date">
                  {valuationDate &&
                    `As of ${formatDate(valuationDate, "yyyy-MM-dd")}`}
                </p>
              </td>
              <td className="main-fund-row__actions-col" draggable="true">
                <span className="main-fund-row__dnd-icon icon icon--20 icon-drag-and-drop " />
              </td>
            </tr>
          </tbody>
        </table>
      </Link>
      <div className="main-fund-row__bottom-shadow" />
    </div>
  );
}

export const CompanyListRow = CompanyListRowComponent;
