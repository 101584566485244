import React, { useState } from "react";
import { AutoComplete } from "antd";
import { useQuery } from "@apollo/client";
import { DefaultOptionType } from "rc-select/lib/Select";
import { ADD_BENCHMARK, GET_INVESTMENT_BENCHMARKS } from "./graphql";

const AddBenchmark = ({
  addBenchmark,
  investmentId,
}: {
  investmentId: number;
  addBenchmark: (data: [{ id: number; name: string }]) => any;
}): JSX.Element => {
  const [term, changeTerm] = useState("");

  const { data: investmentData, loading: loadingInvestment } = useQuery(
    GET_INVESTMENT_BENCHMARKS,
    {
      variables: { investmentId },
    }
  );

  const { data, loading } = useQuery(ADD_BENCHMARK, {
    variables: { term },
    skip: term.length < 2,
  });

  const formatOptions = () => {
    if (!investmentData) return [];
    const { primaryBenchmark, secondaryBenchmark } =
      investmentData.investment || {};
    if (!data)
      return [
        ...((primaryBenchmark && [
          {
            label: <>{primaryBenchmark.name}</>,
            data: { id: primaryBenchmark.id, name: primaryBenchmark.name },
            value: primaryBenchmark.name,
          },
        ]) ||
          []),
        ...((secondaryBenchmark && [
          {
            label: <>{secondaryBenchmark.name}</>,
            data: { id: secondaryBenchmark.id, name: secondaryBenchmark.name },
            value: secondaryBenchmark.name,
          },
        ]) ||
          []),
      ];
    const { items } = data.investmentList;
    return items.map(item => ({
      value: item.name,
      label: <>{item.name}</>,
      data: { id: item.id, name: item.name },
    }));
  };

  return (
    <>
      <AutoComplete
        notFoundContent={
          (loading || loadingInvestment) && (
            <i className="fa fa-spinner fa-spin" />
          )
        }
        placeholder="Add Benchmark"
        id="addBenchmarkSearch"
        data-testid="addBenchmarkSearch"
        dropdownMatchSelectWidth={252}
        style={{ width: 300 }}
        onSearch={value => changeTerm(value)}
        onSelect={(_value: string | number, option: DefaultOptionType) => {
          addBenchmark([option.data]);
          changeTerm("");
        }}
        options={formatOptions()}
        value={term}
        data-cy="Benchmark-Add-Search"
      />
    </>
  );
};

export default AddBenchmark;
