import React from "react";
import { Select, Button } from "antd";
import * as types from "_graphql-types/graphql";
import AddBenchmark from "Components/AddBenchmark";
import { FunnelPlotOutlined, FunnelPlotFilled } from "@ant-design/icons";
import { BenchmarkLayoutTypesEnum } from "./types";

interface ControlProps {
  investmentId: number;
  frequency: "MTD" | "Quarterly";
  changeFrequency: React.Dispatch<React.SetStateAction<"MTD" | "Quarterly">>;
  returnType: string;
  setBenchmark: React.Dispatch<
    React.SetStateAction<{ id: number; name: string } | undefined>
  >;
  startingCurrency: types.CurrencyDenominationEnum | undefined;
  benchmarkStartingCurrency: types.CurrencyDenominationEnum | undefined;
  currency: types.CurrencyDenominationEnum | undefined;
  changeCurrency: React.Dispatch<
    React.SetStateAction<types.CurrencyDenominationEnum | undefined>
  >;
  loading: boolean;
  benchmarkLoading: boolean;
  currencyOptions: types.CurrencyDenominationEnum[];
  benchmark: { id: number; name: string } | undefined;
  setBenchmarkLayoutType: React.Dispatch<
    React.SetStateAction<BenchmarkLayoutTypesEnum>
  >;
  benchmarkLayoutType: BenchmarkLayoutTypesEnum;
  changeReturnType: React.Dispatch<React.SetStateAction<string>>;
}

const Controls = ({
  investmentId,
  frequency,
  changeFrequency,
  returnType,
  changeReturnType,
  startingCurrency,
  benchmarkStartingCurrency,
  currency,
  changeCurrency,
  loading,
  benchmarkLoading,
  currencyOptions,
  benchmark,
  setBenchmark,
  setBenchmarkLayoutType,
  benchmarkLayoutType,
}: ControlProps): JSX.Element => (
  <div className="performance-statistics-table__action-panel">
    <div className="d-flex align-items-center full-width">
      <Select
        data-cy="period-switch"
        className="main-select width-120 mr-16 mb-10"
        popupClassName="main-select__menu"
        value={frequency}
        onChange={e => changeFrequency(e)}
      >
        <Select.Option data-cy="period-switch-mtd" value="MTD">
          MTD
        </Select.Option>
        <Select.Option data-cy="period-switch-quarterly" value="Quarterly">
          Quarterly
        </Select.Option>
      </Select>
      <Select
        className="main-select width-120 mr-16 mb-10"
        popupClassName="main-select__menu"
        value={returnType}
        onChange={(e: string) => changeReturnType(e)}
      >
        <Select.Option value="NET">NET</Select.Option>
        <Select.Option value="GROSS">GROSS</Select.Option>
      </Select>
      {frequency === "MTD" && (
        <>
          <Select
            data-cy="currency-switch"
            className="main-select width-120 mr-16 mb-10"
            popupClassName="main-select__menu"
            value={currency?.id}
            onChange={(e: number) =>
              changeCurrency(currencyOptions.find(c => c.id === e))
            }
          >
            {currencyOptions.map(option => (
              <Select.Option
                key={option.id}
                value={option.id}
                data-cy={`currency-switch-${option.name}`}
              >
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <div>
            {loading && <i className="fa fa-spinner fa-spin" />}
            {!loading && currency?.id !== startingCurrency?.id && (
              <div>
                Fund performance values are based on a currency conversion
              </div>
            )}
            {benchmark && (
              <div>
                {benchmarkLoading && <i className="fa fa-spinner fa-spin" />}
                {!benchmarkLoading &&
                  currency?.id !== benchmarkStartingCurrency?.id && (
                    <div>
                      Benchmark performance values are based on a currency
                      conversion
                    </div>
                  )}
              </div>
            )}
          </div>
        </>
      )}

      <div className="ml-auto text-right d-flex">
        {!benchmark && (
          <div>
            <AddBenchmark
              investmentId={investmentId}
              addBenchmark={([bench]: [{ id: number; name: string }]) =>
                setBenchmark(bench)
              }
            />
          </div>
        )}
        {benchmark && (
          <Button
            onClick={() => {
              setBenchmark(undefined);
            }}
          >
            {benchmark.name}
            {" ( X )"}
          </Button>
        )}
        <div>
          <Button
            data-testid="switch-benchmark-layout-type"
            icon={
              (benchmarkLayoutType === BenchmarkLayoutTypesEnum.combination && (
                <FunnelPlotOutlined />
              )) ||
              (benchmarkLayoutType === BenchmarkLayoutTypesEnum.delta && (
                <FunnelPlotFilled />
              ))
            }
            onClick={() => {
              if (benchmarkLayoutType === BenchmarkLayoutTypesEnum.delta) {
                setBenchmarkLayoutType(BenchmarkLayoutTypesEnum.combination);
              } else if (
                benchmarkLayoutType === BenchmarkLayoutTypesEnum.combination
              ) {
                setBenchmarkLayoutType(BenchmarkLayoutTypesEnum.delta);
              }
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Controls;
