import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import i18n from "i18next";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export default function MwdbeFilterTag({
  filter,
}: {
  filter: types.MwdbeFilter;
}) {
  const dispatch = useDispatch();
  const { ownershipPercentage, categories } = filter;

  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="mwdbe-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "firm",
              data: {
                type: "MWDBE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {`${ownershipPercentage} ${(categories || [])
        .map(category => i18n.t(`team_member.diversity.${category}`))
        .join(" or ")} ownership`}
    </Tag>
  );
}
