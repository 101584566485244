import React, { Suspense, lazy } from "react";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import Spinner from "frontend/src/components/Spinner";

const get_tablau_token = gql`
  query tableauToken {
    tableauToken
  }
`;

export const InternalDashboards = () => {
  const { data, loading, error } = useQuery(get_tablau_token);
  const TableauDashboard = lazy(() => import("./tableauDashboard"));
  const renderLoader = () => <Spinner />;
  if (error) {
    return (
      <Alert
        message="Error"
        description="Error getting Tablau token. Please reload or contact support or try again."
        type="error"
        showIcon
      />
    );
  }
  if (!data) return null;

  return (
    <Suspense fallback={renderLoader()}>
      <TableauDashboard token={data.tableauToken} />
    </Suspense>
  );
};
