import { gql } from "_graphql-types/gql";

export const INVESTMENT_SET_FILTER_TAG = gql(/* GraphQL */ `
  fragment InvestmentSetFragment on InvestmentSet {
    id
    name
  }
`);

export const InvestmentSetAttributes = gql(/* GraphQL */ `
  fragment InvestmentSetAttributes on InvestmentSet {
    id
    name
    description
    isDynamic
    createUser
    updateDate
    setId
    accessLevel {
      name
      id
    }
    investmentSetItems {
      investmentId
    }
    userSubscriptions {
      user {
        id
        userName
      }
    }
    groupSubscriptions {
      group {
        id
        userName
      }
    }
    organizationSubscriptions {
      organization {
        id
        name
      }
    }
  }
`);
