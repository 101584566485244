import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Spin, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import i18n from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { FilterInterface } from "../FilterInterface";
import { FETCH_TYPE_ASSET_CLASSES_STRATEGIES } from "../graphql";

type OnCheckParams = Parameters<
  NonNullable<Parameters<typeof Tree>[0]["onCheck"]>
>;

/**
 * Type-safe custom Map that stringifies keys internally,
 * for structure-like equality.
 */
class StructuralMap<K, V> {
  // no alloc by default
  private __map?: Map<string, V>;

  private get map() {
    this.__map ??= new Map();
    return this.__map;
  }

  private stringifyKey = (key: K) => JSON.stringify(key);

  set = (key: K, value: V) => {
    this.map.set(this.stringifyKey(key), value);
    return value;
  };

  get = (key: K) => this.map.get(this.stringifyKey(key));

  getOrSet(key: K, set: () => V) {
    const innerKey = this.stringifyKey(key);
    let val: V;
    if (this.map.has(innerKey)) {
      val = this.map.get(innerKey)!;
    } else {
      val = set();
      this.map.set(innerKey, val);
    }
    return val;
  }

  *values() {
    yield* this.map.values();
  }

  get size() {
    return this.map.size;
  }
}

interface OptionKey {
  assetClassId: number;
  strategyId?: number;
  subStrategyId?: number;
}

interface OptionNode {
  name: string;
  path: string;
  children: OptionTree;
}

interface IdName {
  id: number;
  name: string;
}

class OptionTree extends StructuralMap<number, OptionNode> {
  toDataNodes = (): DataNode[] =>
    [...this.values()].map(({ path: key, name: title, children }) => ({
      key,
      title,
      children: !children.size ? undefined : children.toDataNodes(),
    }));
}

/**
 * Yields the most general tree path by ignoring child nodes whose prefix-parents are selected
 * @param strs
 */
function* minPrefixes(strs: string[]) {
  strs.sort();
  let prefix = "";
  for (const str of strs) {
    // we append a "^" to avoid partial matches on the same node level (eg. 1 should match 1^2 but 1^2 should not match 1^20)
    if (!prefix || !str.startsWith(prefix + "^")) {
      yield (prefix = str);
    }
  }
}

// eslint-disable-next-line max-lines-per-function
function AssetClassStrategyFilterInput({
  filter,
  setFilter,
  availableTypeAssetClassStrategies,
}: {
  filter: types.AssetClassStrategyFilter;
  setFilter: React.Dispatch<
    React.SetStateAction<types.AssetClassStrategyFilter>
  >;
  availableTypeAssetClassStrategies: {
    assetClass: IdName;
    strategy?: (IdName & { subStrategies: IdName[] }) | null;
    vehicleType: IdName;
  }[];
}): JSX.Element {
  const { dataNodes, optionNode } = useMemo(() => {
    const optionTree = new OptionTree();
    const optionNode = new StructuralMap<OptionKey, OptionNode>();
    availableTypeAssetClassStrategies.forEach(
      ({ assetClass: { id: assetClassId, name }, strategy }) => {
        const assetNode = optionTree.getOrSet(assetClassId, () =>
          optionNode.set(
            { assetClassId },
            {
              name,
              path: [assetClassId].join("^"),
              children: new OptionTree(),
            }
          )
        );
        if (strategy) {
          const { id: strategyId, name } = strategy;
          const strategyNode = assetNode.children.getOrSet(strategyId, () =>
            optionNode.set(
              { assetClassId, strategyId },
              {
                name,
                path: [assetClassId, strategyId].join("^"),
                children: new OptionTree(),
              }
            )
          );
          strategy.subStrategies.forEach(({ id: subStrategyId, name }) => {
            strategyNode.children.getOrSet(subStrategyId, () =>
              optionNode.set(
                { assetClassId, subStrategyId },
                {
                  name,
                  path: [assetClassId, strategyId, subStrategyId].join("^"),
                  children: new OptionTree(),
                }
              )
            );
          });
        }
      }
    );

    const dataNodes = optionTree.toDataNodes();

    return { dataNodes, optionNode };
  }, [availableTypeAssetClassStrategies]);

  const setFilterFromKeys = (...[checkedKeys]: OnCheckParams) => {
    if (!Array.isArray(checkedKeys)) throw new Error("Unexpected type.. Antd!");
    const assetClasses = new StructuralMap<number, types.AssetClassStrategy>();

    for (const minPrefix of minPrefixes(checkedKeys.map(String))) {
      const [assetClassId, strategyId, subStrategyId] = String(minPrefix)
        .split("^")
        .map(Number) as [number, ...(number | undefined)[]];

      const assetClass = assetClasses.getOrSet(assetClassId, () => ({
        assetClass: [
          {
            id: assetClassId,
            label: optionNode.get({ assetClassId })!.name,
          },
        ],
      }));
      // use the most specific one
      if (typeof subStrategyId === "number") {
        (assetClass.subStrategy ??= []).push({
          id: subStrategyId,
          label: optionNode.get({ assetClassId, subStrategyId })!.name,
        });
      } else if (typeof strategyId === "number") {
        (assetClass.strategy ??= []).push({
          id: strategyId,
          label: optionNode.get({ assetClassId, strategyId })!.name,
        });
      }
    }

    const values = [...assetClasses.values()];

    setFilter({ values });
  };

  const checkedKeysFromFilter = useMemo(
    () =>
      filter.values.flatMap(({ assetClass, strategy, subStrategy }) =>
        (assetClass ?? []).flatMap(({ id: assetClassId }) =>
          !strategy?.length && !subStrategy?.length
            ? [optionNode.get({ assetClassId })!.path]
            : [
                ...(strategy ?? []).map(
                  ({ id }) =>
                    optionNode.get({ assetClassId, strategyId: id })!.path
                ),
                ...(subStrategy ?? []).map(
                  ({ id }) =>
                    optionNode.get({ assetClassId, subStrategyId: id })!.path
                ),
              ]
        )
      ),
    [filter]
  );

  return (
    <Tree
      checkable
      treeData={dataNodes}
      onCheck={setFilterFromKeys}
      checkedKeys={checkedKeysFromFilter}
    />
  );
}

const defaultFilter: types.AssetClassStrategyFilter = { values: [] };

function AssetClassStrategyFilter(): JSX.Element {
  const investmentFilters = useSelector(getInvestmentFilters);

  const assetClassStrategyFilter: types.AssetClassStrategyFilter | null =
    useMemo(() => {
      const foundFilter = investmentFilters.find(
        filter =>
          filter.type === "investment" &&
          filter.data.type === "ASSET_CLASS_STRATEGY"
      );
      if (!foundFilter) return null;
      return foundFilter.data.data as types.AssetClassStrategyFilter;
    }, [investmentFilters]);

  const { loading, data, error } =
    useQuery<types.GetAssetClassStrategiesForFilterQuery>(
      FETCH_TYPE_ASSET_CLASSES_STRATEGIES
    );

  const [localFilter, setLocalFilter] =
    useState<types.AssetClassStrategyFilter>(
      assetClassStrategyFilter ?? defaultFilter
    );

  const dispatch = useDispatch();

  const setFilter = () => {
    if (localFilter) {
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: "ASSET_CLASS_STRATEGY",
              data: localFilter,
            },
          },
        })
      );
    }
  };

  const reset = () => {
    setLocalFilter(defaultFilter);
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "ASSET_CLASS_STRATEGY",
            data: defaultFilter,
          },
        },
      })
    );
  };

  useEffect(() => {
    setLocalFilter(assetClassStrategyFilter ?? defaultFilter);
  }, [assetClassStrategyFilter]);

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.assetClassStrategy")}
      count={localFilter.values.length}
      reset={reset}
      set={setFilter}
      menuClass="filters__menu-dropdown"
      width={500}
      maxWidth={500}
    >
      <div className="main-dropdown__menu-body">
        {loading && <Spin size="small" />}
        {error && <span>Types failed to load</span>}
        {data && (
          <AssetClassStrategyFilterInput
            availableTypeAssetClassStrategies={
              data.typeAssetClassStrategyOptionsList.items
            }
            filter={localFilter}
            setFilter={setLocalFilter}
          />
        )}
      </div>
    </FilterInterface>
  );
}

export default AssetClassStrategyFilter;
