/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import i18n from "i18next";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import Authorized from "Components/Authorized/Authorized";
import { isAuthorized } from "Components/Authorized/authHelpers";
import { useCurrentUser } from "frontend/src/utils/hooks";
import CustomAnalyticsSubtabPage from "./custom_analytics_subtab_page";
import ExposureSubtabPage from "./exposure_subtab_page.js.jsx";

enum TabsTypes {
  exposure = "exposure",
  custom = "custom",
}

const tabs = [TabsTypes.exposure];

const PrivateAnalytics = ({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [_, pathSubtab] = hash.split("#") as [string, TabsTypes];
  const [selectedSubtab, changeSelectedSubtab] = useState<TabsTypes>(
    pathSubtab || TabsTypes.exposure
  );

  const subtabRoute = (subtab: TabsTypes) => {
    navigate(`${pathname}#${subtab}`);
  };

  const currentUser = useCurrentUser();

  const SubtabPage = (): JSX.Element => {
    switch (selectedSubtab) {
      case TabsTypes.exposure:
        return <ExposureSubtabPage investmentId={investmentId} />;
      case TabsTypes.custom:
        return (
          <Authorized checks={["isRockCreek"]}>
            <CustomAnalyticsSubtabPage investmentId={investmentId} />
          </Authorized>
        );
      default:
        return <ExposureSubtabPage investmentId={investmentId} />;
    }
  };

  if (
    isAuthorized({ user: currentUser, checks: ["isRockCreek"] }) &&
    !tabs.includes(TabsTypes.custom)
  ) {
    tabs.push(TabsTypes.custom);
  }

  return (
    <section style={{ paddingBottom: "64px" }}>
      <div>
        <div className="d-flex justify-between flex-wrap">
          <ul
            id="analytics-nav"
            data-cy="analytics-nav"
            className="analytics-secondary-tabs secondary-tabs"
          >
            {tabs.map(tab => (
              <li
                id={`${tab}-subtab`}
                key={tab}
                data-cy={`${tab}-nav`}
                className={classNames({
                  "secondary-tabs__tab secondary-tabs__tab--active":
                    selectedSubtab === tab,
                  "secondary-tabs__tab": !(selectedSubtab === tab),
                })}
                onClick={() => {
                  subtabRoute(tab);
                  changeSelectedSubtab(tab);
                }}
              >
                {i18n.t(`analytics.${tab}.title`)}
              </li>
            ))}
          </ul>
        </div>
        <div className="divider" />
      </div>

      <div id="analytics-body" className="analytics-tab-content">
        <SubtabPage />
      </div>
    </section>
  );
};

export default PrivateAnalytics;
