import React from "react";
import { get } from "lodash";
import { PrivateGetShareClassesQuery } from "src/graphql-types/graphql";
import { booleanToAnswer } from "Helpers/text_formatting";
import { FieldItem, percent } from "./FieldItem";

export function PrivateInvestmentTermItem({
  shareClass,
}: {
  shareClass: PrivateGetShareClassesQuery["shareClassList"]["items"][number];
}) {
  return (
    <div className="fund-term-row">
      <div className="font-weight-700 pb-16 fund-term-name">
        {shareClass.name}
      </div>

      <div className="flex-row">
        <div className="flex-1 mr-10">
          <FieldItem
            title="Is RockCreek Negotiated?"
            value={booleanToAnswer(shareClass.isRcgNegotiated)}
            className="fund-term-is-rcg-negotiated"
          />
          <FieldItem
            title="Management Fee"
            value={percent(get(shareClass, "investmentFees.mgmtFee"))}
            className="fund-term-mgmt-fee"
          />
          <FieldItem
            title="Management Fee Basis"
            value={get(shareClass, "investmentFees.peMgmtFeeCalc.name") || "-"}
            className="fund-term-mgmt-fee-basis"
          />
          <FieldItem
            title="Carry"
            value={percent(get(shareClass, "investmentFees.incentiveFee"))}
            className="fund-term-carry"
          />
          <FieldItem
            title="Carry Basis"
            value={
              get(shareClass, "investmentFees.peIncentiveFeeCalc.name") || "-"
            }
            className="fund-term-carry-basis"
          />
          <FieldItem
            title="Catchup"
            value={percent(get(shareClass, "peLiquidity.catchupPercent"))}
            className="fund-term-catchup"
          />
          <FieldItem
            title="Preferred Return"
            value={percent(get(shareClass, "peLiquidity.preferredReturn"))}
            className="fund-term-preferred-return"
          />
        </div>
        <div className="flex-1 ml-10">
          <FieldItem
            title="Management Fee During Commitment"
            value={percent(
              shareClass.investmentFees?.peMgmtFeeDuringCommitment
            )}
            className="fund-term-mgmt-fee-during-commitment"
          />
          <FieldItem
            title="Management Fee During Commitment Basis"
            value={
              shareClass.investmentFees?.peMgmtFeeDuringCommitmentCalc?.name ??
              "-"
            }
            className="fund-term-mgmt-fee-during-commitment-basis"
          />

          <FieldItem
            title="Management Fee During Harvest"
            value={percent(shareClass.investmentFees?.peMgmtFeeDuringHarvest)}
            className="fund-term-mgmt-fee-during-harvest"
          />
          <FieldItem
            title="Management Fee During Harvest Basis"
            value={
              shareClass.investmentFees?.peMgmtFeeDuringHarvestCalc?.name ?? "-"
            }
            className="fund-term-mgmt-fee-during-harvest-basis"
          />
          <FieldItem
            title="Management Fee Step Down Percent"
            value={percent(shareClass.investmentFees?.peMgmtFeeStepDownPercent)}
            className="fund-term-mgmt-fee-step-down-percent"
          />
          <FieldItem
            title="Management Fee Step Down Basis"
            value={
              shareClass.investmentFees?.peMgmtFeeStepDownCalc?.name ?? "-"
            }
            className="fund-term-mgmt-fee-step-down-basis"
          />
        </div>
      </div>
    </div>
  );
}
