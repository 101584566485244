import { PeersTable } from "Components/show/analytics/PeersTable.component";
import React from "react";

const PeersSubtabPage = ({ investmentId }: { investmentId: number }) => (
  <div id="peers-subtab-page">
    <PeersTable investmentId={investmentId} />
  </div>
);

export default PeersSubtabPage;
