import * as types from "_graphql-types/graphql";

export type Tagged<T> = NonNullable<
  {
    [K in keyof T]: {
      type: K;
      data: NonNullable<T[K]>;
    };
  }[keyof T]
>;

export type Filter = Tagged<{
  investment?: InvestmentFilter;
  company?: CompanyFilter;
}>;

export type InvestmentFilter = Tagged<{
  investment?: InvestmentSearchFilter;
  firm?: FirmSearchFilter;
}>;

export type InvestmentSearchFilter = Tagged<types.InvestmentSearchFilters>;
export type FirmSearchFilter = Tagged<types.FirmSearchFilters>;
export type CompanyFilter = Tagged<types.CompanySearchFilters>;

export type Sort = Tagged<{
  investment?: InvestmentSort;
  company?: CompanySort;
}>;

export type InvestmentSort = types.InvestmentSort;
export type CompanySort = types.CompanySort;

export type FilterState = Record<string, Filter>;
export type SortState = {
  investment?: InvestmentSort;
  company?: CompanySort;
};

export interface EntityKey {
  id: number;
  label: string;
}

export type InvestmentEntityFilterType = keyof Pick<
  types.InvestmentSearchFilters,
  | "FIRM_ENTITY"
  | "INVESTMENT_ENTITY"
  | "TAG_ENTITY"
  | "TYPE"
  | "PRIMARY_GEOGRAPHY"
>;

export type CompanyEntityFilterType = keyof Pick<
  types.CompanySearchFilters,
  "COMPANY_ENTITY" | "TAG_ENTITY"
>;

export type EntityFilter =
  | {
      type: "investment";
      data: {
        type: InvestmentEntityFilterType;
        data: EntityKey;
      };
    }
  | {
      type: "company";
      data: {
        type: CompanyEntityFilterType;
        data: EntityKey;
      };
    };

export interface GlobalSearchState {
  filters: FilterState;
  sorts: SortState;
}

export interface GlobalSearchAction {
  type: "GLOBAL_SEARCH";
  data:
    | { type: "ADD_FILTER"; data: Filter }
    | { type: "REMOVE_FILTER"; data: Filter }
    | { type: "ADD_TO_ENTITY_FILTER"; data: EntityFilter }
    | { type: "REMOVE_FROM_ENTITY_FILTER"; data: EntityFilter }
    | { type: "CLEAR_INVESTMENT_FILTERS" }
    | { type: "CLEAR_COMPANY_FILTERS" }
    | { type: "ADD_SORT"; data: Sort }
    | { type: "REMOVE_SORT"; data: Sort };
}

export const unhandledCase = (unreachable: never) =>
  new Error(`unhandled case ${JSON.stringify(unreachable)}`);

export const unimplemented = () => new Error("unimplemented");
