export type Bounds = null | {
  min: number | null;
  max: number | null;
};

export interface SeriesData {
  name: string;
  data: [number, number][];
}

export function inBounds(value: number, bounds: Bounds): boolean {
  if (!bounds) return true;
  return (
    (!bounds.min || value >= bounds.min) && (!bounds.max || value <= bounds.max)
  );
}

export function percentChange(originalValue: number, newValue: number): number {
  return (newValue - originalValue) / originalValue;
}
