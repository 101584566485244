/* eslint-disable max-lines-per-function */
import * as types from "_graphql-types/graphql";
import { Typography } from "antd";
import { EntityFilterTag as CompanyEntityFilterTag } from "Components/GlobalSearchWorkspace/FilterTags/Company/EntityFilterTag";
import { EntityFilterTag as InvestmentEntityFilterTag } from "Components/GlobalSearchWorkspace/FilterTags/Investment/EntityFilterTag";
import { INVESTMENT_PATHS } from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import i18n from "i18next";
import { orderBy, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getTagEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import {
  Filter,
  unhandledCase,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { OptGroup, Option } from "../GlobalSearchDropdownOptions.component";
import { useDispatch } from "src/react-redux";

function CategoryLabel({ group }: { group: string }) {
  return (
    <span>
      <span className="search-form__dropdown-menu-header">{group}</span>
    </span>
  );
}

interface SearchedTagsProps {
  filterType: Filter["type"];
  searchTerm: string;
}

function getEntityFilterTag(filterType: Filter["type"]) {
  switch (filterType) {
    case "company":
      return CompanyEntityFilterTag;
    case "investment":
      return InvestmentEntityFilterTag;
    default:
      throw unhandledCase(filterType);
  }
}

function SearchedTags({ filterType, searchTerm }: SearchedTagsProps) {
  const tagsFilter = useSelector(getTagEntityFilter(filterType));

  const EntityFilterTag = getEntityFilterTag(filterType);

  return (
    <>
      <Option>
        <i className="search-form__dropdown-menu-icon icon icon-loupe icon--20" />
        <span className="flex-grow text-truncate min-width-0 in-secondary-blue">
          {`Searching tags by "${searchTerm}".`}
        </span>
      </Option>
      {tagsFilter && (
        <Option>
          <EntityFilterTag type="TAG_ENTITY" filter={tagsFilter} />
        </Option>
      )}
    </>
  );
}

interface TagGroupsProps {
  filterType: Filter["type"];
  results: types.GetGlobalInvestmentTagsQuery;
}

function TagGroups({ filterType, results }: TagGroupsProps) {
  const navigate = useNavigate();
  const filterTags = useSelector(getTagEntityFilter(filterType));
  const dispatch = useDispatch();

  const tagClassList = useMemo(() => {
    const tags = results.tagList.items.map(item => ({
      name: item.tagClass.name,
      id: item.tagClass.id,
      tags: [item],
    }));
    const groupedTags = new Map<number, (typeof tags)[number]>();
    for (const tag of tags) {
      const groupedTag = groupedTags.get(tag.id);
      if (groupedTag) groupedTag.tags.push(...tag.tags);
      else groupedTags.set(tag.id, tag);
    }

    const values = [...groupedTags.values()];
    return {
      items: orderBy(values, "name", "asc"),
      total: values.length,
    };
  }, [results]);

  if (!tagClassList.items.length) {
    return (
      <Option>
        <Typography.Title level={5}>
          No results found, for given search term / tags.
        </Typography.Title>
      </Option>
    );
  }

  return (
    <>
      {tagClassList.items.map(tagClass => (
        <OptGroup
          key={tagClass.id}
          label={<CategoryLabel group={tagClass.name} />}
        >
          {tagClass.tags.map(tag => (
            <Option
              key={tag.id}
              active={filterTags?.values.some(({ id }) => id === tag.id)}
              onClick={() => {
                dispatch(
                  addToEntityFilter({
                    type: filterType,
                    data: {
                      type: "TAG_ENTITY",
                      data: {
                        id: tag.id,
                        label: tag.name,
                      },
                    },
                  })
                );
                navigate(INVESTMENT_PATHS[0]);
              }}
            >
              <span
                className="flex-grow text-truncate min-width-0"
                data-cy="tag-result"
              >
                {tag.name}
                {` (${i18n.t("global_search.results_count", {
                  count: tag.investmentCount,
                })})`}
              </span>

              <span className="add-as-tag">
                <span className="add-as-tag__txt">
                  {i18n.t("global_search.add_as_tag")}
                </span>
                <i className="search-form__dropdown-menu-icon icon icon-tag icon--20" />
              </span>
            </Option>
          ))}
        </OptGroup>
      ))}
    </>
  );
}

function FetchMoreTags({
  results,
  loading,
  fetchMore,
}: {
  results?: types.GetGlobalInvestmentTagsQuery;
  loading: boolean;
  fetchMore: any;
}) {
  const [isLoadingMore, setLoadingMore] = useState(false);
  if (loading || isLoadingMore) {
    return (
      <Option>
        <i className="fa fa-spinner fa-spin" />
      </Option>
    );
  }
  if (!results?.tagList.nextPage.hasMore) return null;

  return (
    <Option
      onClick={() => {
        setLoadingMore(true);
        const offset = results.tagList.nextPage.offset ?? 0;
        const limit = results.tagList.nextPage.limit ?? 25;
        fetchMore({
          variables: {
            page: {
              limit,
              offset: offset + limit,
            },
          },
          updateQuery: (
            existing: { tagList: types.TagList },
            incoming: { fetchMoreResult: { tagList: types.TagList } }
          ): { tagList: types.TagList } => {
            setLoadingMore(false);
            if (!incoming.fetchMoreResult) return existing;
            return {
              tagList: {
                ...existing.tagList,
                ...incoming.fetchMoreResult.tagList,
                items: uniqBy(
                  [
                    ...existing.tagList.items,
                    ...incoming.fetchMoreResult.tagList.items,
                  ],
                  "id"
                ),
              },
            };
          },
        });
      }}
    >
      <Typography.Link style={{ fontSize: "12px" }}>More</Typography.Link>
    </Option>
  );
}

export function GlobalSearchOptions({
  filterType,
  results,
  searchTerm,
  loading,
  fetchMore,
}: {
  filterType: Filter["type"];
  results?: types.GetGlobalInvestmentTagsQuery;
  searchTerm: string;
  loading: boolean;
  fetchMore: any;
}) {
  return (
    <>
      <SearchedTags filterType={filterType} searchTerm={searchTerm} />
      {!!results && <TagGroups filterType={filterType} results={results} />}
      <FetchMoreTags
        fetchMore={fetchMore}
        results={results}
        loading={loading}
      />
    </>
  );
}
