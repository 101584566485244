import _ from "lodash";

// Array implementation of handling a selection of items
class Selection {
  constructor(selection = []) {
    this.selection = selection;
  }

  // Mutates selection
  toggle(item) {
    const nextSelection = this.selection.slice(0);
    const isNeedToAdd = _.isEmpty(
      _.remove(nextSelection, selectedItem => selectedItem.id === item.id)
    );

    if (isNeedToAdd) {
      nextSelection.push(item);
    }

    return new Selection(nextSelection);
  }

  clear() {
    return new Selection([]);
  }

  isSelected(itemId) {
    return !!_.find(this.selection, selectedItem => selectedItem.id === itemId);
  }

  get serialized() {
    return this.selection.slice(0).map(item => item.id);
  }

  hasSelection() {
    return !!this.selection.length;
  }

  map(callback) {
    return new Selection(this.selection.map(callback));
  }

  get length() {
    return this.selection.length;
  }

  [Symbol.iterator]() {
    let index = -1;
    const { selection } = this;

    return {
      next: () => ({
        value: selection[++index],
        done: !(index in selection),
      }),
    };
  }
}

export default Selection;
