class TableLayoutTypes {
  static get default() {
    return this._validTypes[0];
  }

  static isValid(mode) {
    return this._validTypes.includes(mode);
  }

  static get validTypes() {
    return [
      this.horizontal,
      this.vertical
    ];
  }

  static get horizontal() {
    return 'horizontal';
  }

  static get vertical() {
    return 'vertical';
  }

  static isHorizontal(layoutType) {
    return layoutType === this.horizontal;
  }

  static isVertical(layoutType) {
    return layoutType === this.vertical;
  }

  static get _validTypes() {
    return [
      this.horizontal,
      this.vertical
    ];
  }
}

export default TableLayoutTypes;
