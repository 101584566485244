import { gql } from "_graphql-types/gql";

export const SERVICE_PROVIDERS = gql(/* GraphQL */ `
  query GetServiceProviders($id: Int!) {
    investment(id: $id) {
      id
      firm {
        id
        name
      }
      investmentInstitutionsList(sort: [{ field: name, order: ASC }]) {
        items {
          id
          institutionId
          institution {
            id
            name
            institutionsInstitutionTypeEnumList {
              items {
                id
                institutionTypeEnum {
                  id
                  name
                  displayOrder
                }
              }
            }
            globalProviderInstitutionsList {
              items {
                id
                provider {
                  id
                  name
                  tierLevel
                }
              }
            }
          }
        }
      }
    }
  }
`);
