/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from "react";
import i18n from "i18next";
import { Layout, LayoutSwitcher } from "Components/layoutswitcher";
import * as types from "_graphql-types/graphql";
import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { format } from "date-fns";
import ReactQuill from "react-quill";
import Controls from "./Controls";
import PerformanceTable from "./PerformanceTable";
import {
  FETCH_PERFORMANCE,
  FETCH_CURRENCY_ENUM_LIST,
  FETCH_PERFORMANCE_CURRENCY_CONVERTED,
} from "./graphql";
import { BenchmarkLayoutTypesEnum } from "./types";

interface PerformanceProps {
  investmentId: number;
  changeHasData: (_?: boolean) => void;

  hasData?: boolean;
}

function Performance({
  investmentId,
  changeHasData,
  hasData,
}: PerformanceProps): JSX.Element {
  const [frequency, changeFrequency] = useState<"MTD" | "Quarterly">("MTD");
  const [returnType, changeReturnType] = useState<string>("NET");
  const [currency, changeCurrency] = useState<
    types.CurrencyDenominationEnum | undefined
  >(undefined);
  const [layout, changeLayoutType] = useState<Layout>(Layout.horizontal);
  const [benchmark, setBenchmark] = useState<
    { name: string; id: number } | undefined
  >(undefined);
  const [benchmarkLayoutType, setBenchmarkLayoutType] =
    useState<BenchmarkLayoutTypesEnum>(BenchmarkLayoutTypesEnum.combination);
  const [priorTrackRecord, changePriorTrackRecord] = useState<boolean>(false);

  const { loading, data } = useQuery(FETCH_PERFORMANCE, {
    variables: {
      id: investmentId,
      priorTrackRecord,
      returnType: returnType as types.ReturnTypes,
    },
  });

  const startingCurrency = data?.investment?.performanceMTD?.[0]?.currency;

  useEffect(() => {
    changeCurrency(startingCurrency || undefined);
  }, [startingCurrency]);

  const { loading: loadingConversion, data: convertedData } = useQuery(
    FETCH_PERFORMANCE_CURRENCY_CONVERTED,
    {
      variables: {
        id: investmentId,
        currencyId: currency?.id as number,
        priorTrackRecord,
        returnType: returnType as types.ReturnTypes,
      },
      skip: !currency?.id,
    }
  );

  const { loading: loadingBenchmark, data: benchmarkData } = useQuery(
    FETCH_PERFORMANCE,
    {
      variables: {
        id: benchmark?.id as number,
        priorTrackRecord,
        returnType: returnType as types.ReturnTypes,
      },
      skip: !benchmark?.id,
    }
  );

  const benchmarkStartingCurrency =
    benchmarkData?.investment?.performanceMTD?.[0]?.currency;

  const { loading: loadingBenchmarkConversion, data: benchmarkConvertedData } =
    useQuery(FETCH_PERFORMANCE_CURRENCY_CONVERTED, {
      variables: {
        id: benchmark?.id as number,
        currencyId: currency?.id as number,
        priorTrackRecord,
        returnType: returnType as types.ReturnTypes,
      },
      skip: !benchmark?.id || !currency?.id,
    });

  const { data: currencyData } = useQuery(FETCH_CURRENCY_ENUM_LIST, {
    variables: {
      convertibleFromCurrency: currency?.name || startingCurrency?.name,
    },
  });

  useEffect(() => {
    if (data && changeHasData) {
      changeHasData(
        !!convertedData?.investment?.performanceMTDCurrencyConverted?.length ||
          !!data.investment.performanceMTD?.length ||
          !!data.investment.performanceQuarterly?.length
      );

      if (
        data.investment.performanceQuarterly?.length &&
        !data.investment.performanceMTD?.length
      ) {
        changeFrequency("Quarterly");
      }
    }
  }, [data]);

  return (
    <>
      <div
        className={classNames("main-checkbox", classNames("mb-10", "mr-16"))}
      >
        <input
          id="control-checkbox"
          type="checkbox"
          className={classNames("main-checkbox__input", {
            "main-checkbox__input--only-checkbox": false,
          })}
          onChange={() => changePriorTrackRecord(!priorTrackRecord)}
          checked={priorTrackRecord}
        />
        <label className="main-checkbox__label" htmlFor="control-checkbox">
          {i18n.t("analytics.performance.prior_track_record")}
        </label>
      </div>
      <div
        className="summary-heading"
        id="performance"
        data-testid="performance"
      >
        <div className="heading" />
        <div className="summary-heading__desc-wrap">
          <LayoutSwitcher
            layout={layout}
            layoutOptions={[Layout.vertical, Layout.horizontal]}
            changeLayout={changeLayoutType}
          />
        </div>
      </div>
      <Controls
        investmentId={investmentId}
        frequency={frequency}
        changeFrequency={changeFrequency}
        returnType={returnType}
        changeReturnType={changeReturnType}
        currency={currency}
        changeCurrency={changeCurrency}
        loading={loadingConversion}
        benchmarkLoading={loadingBenchmarkConversion}
        startingCurrency={startingCurrency}
        benchmarkStartingCurrency={benchmarkStartingCurrency}
        currencyOptions={currencyData?.currencyDenominationEnumList.items ?? []}
        benchmark={benchmark}
        setBenchmark={setBenchmark}
        benchmarkLayoutType={benchmarkLayoutType}
        setBenchmarkLayoutType={setBenchmarkLayoutType}
      />
      {hasData && (
        <>
          <div className="invt-tab__section">
            <div
              id="performance-statistics-table"
              className="performance-statistics-table"
            >
              <PerformanceTable
                benchmarkId={benchmark && benchmark.id}
                benchmarkLayoutType={benchmarkLayoutType}
                layout={layout}
                frequency={frequency}
                data={data}
                convertedData={convertedData}
                benchmarkData={benchmarkData}
                benchmarkConvertedData={benchmarkConvertedData}
                loading={loading}
                loadingBenchmark={loadingBenchmark}
              />
            </div>
          </div>
          {!!data?.investment?.latestNote && (
            <div className="invt-tab__section">
              <div id="performance__comments">
                <Typography variant="h6" style={{ marginBottom: "0.5em" }}>
                  Comments
                </Typography>
                <ReactQuill
                  theme="bubble"
                  value={data?.investment.latestNote?.text || undefined}
                  readOnly
                />
                <Typography variant="caption" color="textSecondary">
                  <span>
                    Modified by {data?.investment.latestNote?.createUser} on{" "}
                    {data?.investment.latestNote?.createDate &&
                      format(
                        new Date(data?.investment.latestNote?.createDate),
                        "MM/dd/yyyy"
                      )}
                  </span>
                </Typography>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Performance;
