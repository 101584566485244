/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-lines-per-function */
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import * as types from "_graphql-types/graphql";
import { Input, Switch } from "antd";
import classnames from "classnames";
import i18n from "i18next";
import { round } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "src/react-redux";
import { ifNumber, isNumber } from "../..";
import { FilterInterface } from "../FilterInterface";

const StatisticsOptions = [
  {
    label: "1M",
    statType: types.PerformanceStatType.CompoundReturn1m,
  },
  {
    label: "1Y",
    statType: types.PerformanceStatType.CompoundReturn1yAnnualized,
  },
  {
    label: "YTD",
    statType: types.PerformanceStatType.CompoundReturnYtd,
  },
  {
    label: "3Y",
    statType: types.PerformanceStatType.CompoundReturn3yAnnualized,
  },
  {
    label: "5Y",
    statType: types.PerformanceStatType.CompoundReturn5yAnnualized,
  },
];

const StatisticsOptionsPrivate = [
  {
    label: "Net IRR",
    statType: types.PerformancePrivateStatType.NetIrr,
  },
  {
    label: "TVPI",
    statType: types.PerformancePrivateStatType.Tvpi,
  },
  {
    label: "DPI",
    statType: types.PerformancePrivateStatType.Dpi,
  },
];

function PerformanceFilter() {
  const investmentFilters = useSelector(getInvestmentFilters);

  const [isPrivate, setIsPrivate] = useState(false);

  const [publicFilter, privateFilter] = useMemo(() => {
    for (const filter of investmentFilters) {
      if (filter.type === "investment") {
        return [
          filter.data.type === "PERFORMANCE" ? filter.data.data : undefined,
          filter.data.type === "PERFORMANCE_PRIVATE"
            ? filter.data.data
            : undefined,
        ];
      }
    }
    return [];
  }, [investmentFilters]);

  const defaultPublicFilter: types.PerformanceSearchFilter = {
    statType: types.PerformanceStatType.CompoundReturn1yAnnualized,
  };

  const defaultPrivateFilter: types.PerformancePrivateSearchFilter = {
    statType: types.PerformancePrivateStatType.NetIrr,
  };

  const [localPublicFilter, setLocalPublicFilter] = useState(
    publicFilter ?? defaultPublicFilter
  );

  const [localPrivateFilter, setLocalPrivateFilter] = useState(
    privateFilter ?? defaultPrivateFilter
  );

  const dispatch = useDispatch();

  const set = () => {
    const localFilter = isPrivate
      ? ({ type: "PERFORMANCE_PRIVATE", data: localPrivateFilter } as const)
      : ({ type: "PERFORMANCE", data: localPublicFilter } as const);

    if (isNumber(localFilter.data.min) || isNumber(localFilter.data.max)) {
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "investment",
            data: localFilter,
          },
        })
      );
    }
  };

  const reset = () => {
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "PERFORMANCE",
            data: defaultPublicFilter,
          },
        },
      })
    );
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "PERFORMANCE_PRIVATE",
            data: defaultPrivateFilter,
          },
        },
      })
    );
  };

  const toggleMode = () => {
    setIsPrivate(!isPrivate);
    reset();
    setLocalPublicFilter({ ...defaultPublicFilter });
    setLocalPrivateFilter({ ...defaultPrivateFilter });
  };

  return (
    <FilterInterface
      label="Performance"
      count={publicFilter || privateFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        {isPrivate ? (
          <>
            <label className="main-input__label font-12">Range:</label>
            <div className="main-range-inputs">
              <Input
                value={ifNumber(localPrivateFilter.min, min => min)}
                suffix="%"
                size="small"
                style={{ width: 110 }}
                onChange={event => {
                  const min = ifNumber(event.target.value, min => min);
                  setLocalPrivateFilter({ ...localPrivateFilter, min });
                }}
              />
              <Input
                value={ifNumber(localPrivateFilter.max, max => max)}
                suffix="%"
                size="small"
                style={{ width: 110 }}
                onChange={event => {
                  const max = ifNumber(event.target.value, max => max);
                  setLocalPrivateFilter({ ...localPrivateFilter, max });
                }}
              />
            </div>
            <ul className="filters__performance-list">
              {StatisticsOptionsPrivate.map(({ label, statType }) => (
                <li
                  key={label}
                  onClick={() => {
                    setLocalPrivateFilter({ ...localPrivateFilter, statType });
                  }}
                  className={classnames("filters__performance-list-item", {
                    active: statType === localPrivateFilter.statType,
                  })}
                >
                  {label}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <label className="main-input__label font-12">
              {i18n.t("search_discovery.filters.performance.returns")}
            </label>
            <div className="main-range-inputs">
              <Input
                value={ifNumber(localPublicFilter.min, min =>
                  round(min * 100, 2)
                )}
                suffix="%"
                size="small"
                style={{ width: 110 }}
                onChange={event => {
                  const min = ifNumber(event.target.value, min => min / 100);
                  setLocalPublicFilter({ ...localPublicFilter, min });
                }}
              />
              <Input
                value={ifNumber(localPublicFilter.max, max =>
                  round(max * 100, 2)
                )}
                suffix="%"
                size="small"
                style={{ width: 110 }}
                onChange={event => {
                  const max = ifNumber(event.target.value, max => max / 100);
                  setLocalPublicFilter({ ...localPublicFilter, max });
                }}
              />
            </div>
            <ul className="filters__performance-list">
              {StatisticsOptions.map(({ label, statType }) => (
                <li
                  key={label}
                  onClick={() => {
                    setLocalPublicFilter({ ...localPublicFilter, statType });
                  }}
                  className={classnames("filters__performance-list-item", {
                    active: statType === localPublicFilter.statType,
                  })}
                >
                  {label}
                </li>
              ))}
            </ul>
          </>
        )}
        <Switch
          checked={isPrivate}
          onClick={() => {
            toggleMode();
          }}
          data-cy="performance-type-toggle"
        />{" "}
        <label>
          {!isPrivate ? `General Performance` : `Private Performance`}
        </label>
      </div>
    </FilterInterface>
  );
}

export default PerformanceFilter;
