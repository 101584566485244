import { useApolloClient, useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { INVESTMENT_SET_FILTER_TAG } from "Components/graphql/fragments/InvestmentSet";
import { ensureDefined } from "frontend/src/utils/helpers";
import { useMemo } from "react";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

const INVESTMENT_SET_TAG_QUERY = gql(/* GraphQL */ `
  query InvestmentSetFilterTag($id: Int!) {
    investmentSet(id: $id) {
      ...InvestmentSetFragment
    }
  }
`);

function InvestmentSetFilterTag({
  filter,
}: {
  filter: types.InvestmentSetSearchFilter;
}) {
  const dispatch = useDispatch();
  const { investmentSetId } = filter;

  const client = useApolloClient();

  const investmentSet = client.readFragment({
    id: `InvestmentSet:${investmentSetId}`,
    fragment: INVESTMENT_SET_FILTER_TAG,
  });

  // if an investment set is a user's default view it may not be in the cache on initial page load
  const { data, loading, called } = useQuery(INVESTMENT_SET_TAG_QUERY, {
    variables: {
      id: ensureDefined(investmentSetId),
    },
    skip: !!investmentSet || !investmentSetId,
  });

  const tagText = useMemo(() => {
    if (called && loading) {
      return (
        <i
          id="loading-more-spinner"
          className="main-spinner__icon"
          style={{ color: "#ffffff", fontSize: "10px" }}
        />
      );
    }
    return investmentSet?.name || data?.investmentSet?.name;
  }, [investmentSet, called, loading]);

  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="investment-set-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "INVESTMENT_SET",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {tagText}
    </Tag>
  );
}

export default InvestmentSetFilterTag;
