import React from "react";
import classNames from "classnames";
import { formatStatValue } from "Helpers/investment_helpers";

interface Params {
  value: any;
  format?: string;
}

const Cell = ({ value, format }: Params) => (
  <div className={classNames({ negative: value < 0 })}>
    {formatStatValue(value, format)}
  </div>
);

export default Cell;
