import { format, parseISO } from "date-fns";
import i18n from "i18next";
import * as types from "_graphql-types/graphql";
import numeral from "numeral";

export function utcformat(dateString: string, formatString: string) {
  if (!dateString) return null;
  // remove the "Z" to make the date as local timezone
  const s = parseISO(dateString);
  return format(s, formatString);
}

function isPerformanceStatistics(
  data:
    | NonNullable<types.InvestmentTileFragment["performancePrivate"]>[0]
    | types.InvestmentTileFragment["performanceStats"]
): data is types.InvestmentTileFragment["performanceStats"] {
  return !!(data && data?.__typename === "PerformanceStatistics");
}

function isPerformancePrivate(
  data:
    | NonNullable<types.InvestmentTileFragment["performancePrivate"]>[0]
    | types.InvestmentTileFragment["performanceStats"]
): data is NonNullable<types.InvestmentTileFragment["performancePrivate"]>[0] {
  return !!(data && data.__typename === "PerformancePrivate");
}

function displayPerformanceDate(
  performanceData?:
    | NonNullable<types.InvestmentTileFragment["performancePrivate"]>[0]
    | types.InvestmentTileFragment["performanceStats"]
    | null
) {
  if (!performanceData) return "-";

  if (isPerformancePrivate(performanceData)) {
    return [
      "Q",
      performanceData.returnQuarter,
      "-",
      performanceData.returnYear,
    ].join("");
  }
  if (isPerformanceStatistics(performanceData)) {
    return [
      utcformat(performanceData.startDate, "MMM-yyyy"),
      "-",
      utcformat(performanceData.endDate, "MMM-yyyy"),
    ].join(" ");
  }

  return "-";
}

export interface SearchResultDetails {
  id: number;
  assetClass: "private" | "public";
  inceptionDate?: string | number | null;
  strategy?: string | null;
  performance?: number | null;
  performanceDate: string;
  performanceType: string;
  assetValue?: number | null;
  assetValueCurrency?: string | null;
  assetValueDate?: string | null;
  assetValueType: string;
  readAccess?: boolean | null;
  accessRequest?: boolean | null;
}
export function getSearchResultDetails(
  investment: NonNullable<types.InvestmentTileFragment>
): SearchResultDetails {
  const { performanceStats, latestInvestmentAUM } = investment;
  const performancePrivate = investment?.performancePrivate?.[0];

  if (investment?.market?.id === 2) {
    return {
      id: investment.id,
      assetClass: "private",
      inceptionDate: investment?.vintageYear,
      strategy: investment?.peStrategy?.name,
      performance:
        performancePrivate?.netIRR && performancePrivate.netIRR / 100.0,
      performanceDate: displayPerformanceDate(performancePrivate),
      performanceType: i18n.t("fund_tile.irr", { type: "IRR" }),
      assetValue: investment?.committedCapital,
      assetValueCurrency: investment?.committedCapitalCurrency?.name,
      assetValueDate: null,
      assetValueType: i18n.t("fund_tile.committed_capital"),
      readAccess: investment.access?.read,
      accessRequest: investment.accessRequest,
    };
  }
  return {
    id: investment.id,
    assetClass: "public",
    inceptionDate: utcformat(investment?.inceptionDate, "yyyy-MM-dd"),
    strategy: investment?.strategy?.name,
    performance: investment?.performanceStats?.compoundReturn1yAnnualized,
    performanceDate: displayPerformanceDate(performanceStats),
    performanceType: i18n.t("fund_tile.return", { type: "1Y" }),
    assetValue: latestInvestmentAUM?.value,
    assetValueDate: utcformat(latestInvestmentAUM?.date, "yyyy-MM-dd"),
    assetValueType: i18n.t("fund_tile.aum"),
    readAccess: investment.access?.read,
    accessRequest: investment.accessRequest,
  };
}

export function getListItemDetails(
  investment: NonNullable<types.InvestmentTileFragment>
) {
  const details = getSearchResultDetails(investment);
  const {
    performanceDate,
    performance,
    performanceType,
    assetValue,
    assetValueCurrency,
    assetClass,
  } = details;
  return {
    performanceValue: performance,
    size: assetValue,
    sizeCurrency: assetValueCurrency,
    performanceDate,
    performanceType:
      assetClass === "public"
        ? i18n.t("performance.types.1y")
        : performanceType,
  };
}

export function renderSize(size?: number | null, sizeCurrency?: string | null) {
  if (!size) return "-";
  const result = numeral(size).format("0.0a");
  return sizeCurrency ? `${result} ${sizeCurrency}` : result;
}

export const formatStatValue = (
  value?: string | number | null,
  formatStr?: string
): string =>
  value == null ? "-" : numeral(value).format(formatStr ?? "0.00%");
