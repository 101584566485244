export type ChartKey =
  | "seniorInvestmentTeam"
  | "investmentTeam"
  | "opProfessionals"
  | "seniorLeadership"
  | "totalOrganization"
  | "ownership"
  | "profitParticipation"
  | "boardOfDir"
  | "founders"
  | "investmentCommitee"
  | "companyHead"
  | "companyLeadershipCSuite";

export interface AggregateCategory {
  title: string;
  key: ChartKey;
  measureEnumIds: number[];
  isPercent: boolean;
}

export const aggregateFirmCharts: AggregateCategory[] = [
  {
    title: "Senior Investment Team",
    measureEnumIds: [6, 7],
    key: "seniorInvestmentTeam",
    isPercent: false,
  },
  {
    title: "Investment Team",
    measureEnumIds: [6, 7, 8, 9, 10],
    key: "investmentTeam",
    isPercent: false,
  },
  {
    title: "Operating Professionals",
    measureEnumIds: [9],
    key: "opProfessionals",
    isPercent: false,
  },
  {
    title: "Senior Leadership",
    measureEnumIds: [6, 11],
    key: "seniorLeadership",
    isPercent: false,
  },
  {
    title: "Total Organization",
    measureEnumIds: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    key: "totalOrganization",
    isPercent: false,
  },
  {
    title: "Ownership",
    measureEnumIds: [18],
    isPercent: true,
    key: "ownership",
  },
  {
    title: "Profit Participation",
    measureEnumIds: [3],
    isPercent: true,
    key: "profitParticipation",
  },
  {
    title: "Board of Directors/Advisory Board",
    measureEnumIds: [4],
    key: "boardOfDir",
    isPercent: false,
  },
  {
    title: "Founders",
    measureEnumIds: [5],
    key: "founders",
    isPercent: false,
  },
  {
    title: "Investment Commitee",
    measureEnumIds: [5],
    key: "investmentCommitee",
    isPercent: false,
  },
];

export const aggregateCompanyCharts: AggregateCategory[] = [
  {
    title: "Company Head (CEO)",
    measureEnumIds: [20],
    key: "companyHead",
    isPercent: false,
  },
  {
    title: "Board of Directors/Advisory Board",
    measureEnumIds: [4],
    key: "boardOfDir",
    isPercent: false,
  },
  {
    title: "Founders",
    measureEnumIds: [5],
    key: "founders",
    isPercent: false,
  },
  {
    title: "Company Leadership (C-Suite)",
    measureEnumIds: [21],
    key: "companyLeadershipCSuite",
    isPercent: false,
  },
];
