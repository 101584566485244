import { handleLogout } from "_sirius/src/actions/action_helpers";
import classNames from "classnames";
import { getInitials } from "Helpers/index";
import i18n from "i18next";
import PropTypes from "prop-types";

interface Props {
  handleClose: React.MouseEventHandler<HTMLDivElement>;
  userName: string;
}

const NavSidebarSettings = ({ handleClose, userName }: Props) => (
  <div className="hidden-lg-up">
    <div
      className="nav-sidebar__list-item nav-sidebar__list-item--settings"
      onClick={handleClose}
    >
      <i
        className={classNames(
          "nav-sidebar__list-item-icon-settings",
          "icon icon--24 icon-angle-left ml-8 mr-4"
        )}
      />

      <div className="main-userpic main-userpic--24 mr-8">
        {getInitials(userName)}
      </div>

      <span className="nav-sidebar__list-item-text">{userName}</span>
    </div>

    <div className="nav-sidebar__list-item nav-sidebar__list-item--profile-sett">
      <a href="users/change_password">
        <i className="nav-sidebar__list-item-icon icon icon-user" />

        <span className="nav-sidebar__list-item-text">
          {i18n.t("nav_bar.profile_settings")}
        </span>
      </a>
    </div>

    <div className="divider" />

    <div
      className="nav-sidebar__list-item nav-sidebar__list-item--help"
      onClick={handleLogout}
    >
      <i className="nav-sidebar__list-item-icon" />

      <span className="nav-sidebar__list-item-text">
        {i18n.t("nav_bar.log_out")}
      </span>
    </div>
  </div>
);

NavSidebarSettings.propTypes = {
  handleClose: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default NavSidebarSettings;
