export default (data, colors) => {
  // Dimensions of sunburst.
  const width = 450;
  const height = 400;
  const radius = Math.min(width, height) / 2;

  // Breadcrumb dimensions: width, height, spacing, width of tip/tail.
  const b = {
    w: 150, h: 30, s: 3, t: 10
  };

  // Total size of all segments; we set this later, after loading the data.
  let totalSize = 0;

  d3.select('#chart > svg').remove();
  d3.select('#legend > svg').remove();

  const vis = d3.select('#chart').append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('id', 'container')
      .attr('transform', `translate(${width / 2},${height / 2})`);

  const partition = d3.layout.partition()
      .size([2 * Math.PI, radius * radius])
      .value((d) => Math.abs(d.size));

  const arc = d3.svg.arc()
      .startAngle((d) => d.x)
      .endAngle((d) => d.x + d.dx)
      .innerRadius((d) => Math.sqrt(d.y))
      .outerRadius((d) => Math.sqrt(d.y + d.dy));

  function getTotalValue(data) {
    return data.children.reduce((sum, children) => sum + getNodeValue(children), 0);
  }

  function getNodeValue(d) {
    if (d.depth === 1 && !d.size) {
      return d.children.reduce((sum, children) => sum + children.size, 0);
    }
    return d.size;
  }

  // Main function to draw and set up the visualization, once we have the data.
  function createVisualization(data) {
    // Basic setup of page elements.
    initializeBreadcrumbTrail();
    drawLegend();

    // Bounding circle underneath the sunburst, to make it easier to detect
    // when the mouse leaves the parent g.
    vis.append('circle')
       .attr('r', radius)
       .style('opacity', 0);

    // For efficiency, filter nodes to keep only those large enough to see.
    const nodes = partition.nodes(data)
        .filter((d) => {
          if (d.parent) {
            return (d.dx > 0.005 && d.parent.dx > 0.005); // 0.005 radians = 0.29 degrees
          }
          return (d.dx > 0.005);
        });

    const path = vis.data([data]).selectAll('path')
        .data(nodes)
        .enter()
.append('path')
        .attr('display', (d) => (d.depth ? null : 'none'))
        .attr('d', arc)
        .attr('fill-rule', 'evenodd')
        .style('fill', (d) => colors[d.name])
        .style('opacity', 1)
        .on('mouseover', mouseover);

    totalSize = getTotalValue(data);

    const total = (100 * totalSize).toPrecision(3);
    const totalString = `${total}%`;
    const totalPercentage = 100;
    const totalPercentageString = `${totalPercentage}%  of total`;

    d3.select('#total')
  	  .text(totalString);

    d3.select('#totalPercentage')
        .text(totalPercentageString);

    // Add the mouseleave handler to the bounding circle.
    d3.select('#container').on('mouseleave', mouseleave);
  }

  // Fade all but the current sequence, and show it in the breadcrumb trail.
  function mouseover(d) {
    const nodeValue = getNodeValue(d);
    const value = (100 * nodeValue).toPrecision(3);
    const valueString = `${value}%`;
    const percentage = (100 * nodeValue / totalSize).toPrecision(3);
    let percentageString = `${percentage}% of total`;
    if (Math.abs(percentage) < 0.1) {
      percentageString = '< 0.1% of total';
    }

    d3.select('#value')
  	  .text(valueString);

    d3.select('#percentage')
      .text(percentageString);

    d3.select('#total-explanation')
      .style('visibility', 'hidden');

    d3.select('#explanation')
      .style('visibility', '');

    const sequenceArray = getAncestors(d);
    updateBreadcrumbs(sequenceArray, valueString);

    // Fade all the segments.
    d3.selectAll('path')
        .style('opacity', 0.3);

    // Then highlight only those that are an ancestor of the current segment.
    vis.selectAll('path')
        .filter((node) => (sequenceArray.indexOf(node) >= 0))
        .style('opacity', 1);
  }

  // Restore everything to full opacity when moving off the visualization.
  function mouseleave(d) {
    // Hide the breadcrumb trail
    d3.select('#trail')
      .style('visibility', 'hidden');

    // Transition each segment to full opacity and then reactivate it.
    d3.selectAll('path')
      .style('opacity', 1);

    d3.select('#explanation')
      .style('visibility', 'hidden');

    d3.select('#total-explanation')
      .style('visibility', '');
  }

  // Given a node in a partition layout, return an array of all of its ancestor
  // nodes, highest first, but excluding the root.
  function getAncestors(node) {
    const path = [];
    let current = node;
    while (current.parent) {
      path.unshift(current);
      current = current.parent;
    }
    return path;
  }

  function initializeBreadcrumbTrail() {
    // Add the svg area.
    const trail = d3.select('#sequence').append('svg')
      .attr('width', width)
      .attr('height', 50)
      .attr('id', 'trail');

    // Add the label at the end, for the value.
    trail.append('text')
      .attr('id', 'endlabel')
  	  .style('font-size', '1.5em')
      .style('fill', '#163f5e');
  }

  // Generate a string that describes the points of a breadcrumb polygon.
  function breadcrumbPoints(d, i) {
    const points = [];
    points.push('0,0');
    points.push(`${b.w},0`);
    points.push(`${b.w + b.t},${b.h / 2}`);
    points.push(`${b.w},${b.h}`);
    points.push(`0,${b.h}`);
    if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
      points.push(`${b.t},${b.h / 2}`);
    }
    return points.join(' ');
  }

  // Update the breadcrumb trail to show the current sequence and percentage.
  function updateBreadcrumbs(nodeArray, valueString) {
    // Data join; key function combines name and depth (= position in sequence).
    const g = d3.select('#trail')
      .selectAll('g')
      .data(nodeArray, (d) => d.name + d.depth);

    // Add breadcrumb and label for entering nodes.
    const entering = g.enter().append('g');

    entering.append('polygon')
      .attr('points', breadcrumbPoints)
      .style('fill', (d) => colors[d.name]);

    entering.append('text')
      .attr('x', (b.w + b.t) / 2)
      .attr('y', b.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text((d) => d.name);

    // Set position for entering and updating nodes.
    g.attr('transform', (d, i) => `translate(${i * (b.w + b.s)}, 0)`);

    // Remove exiting nodes.
    g.exit().remove();

    // Now move and update the percentage at the end.
    d3.select('#trail').select('#endlabel')
        .attr('x', (nodeArray.length + 0.3) * (b.w + b.s) + 12)
        .attr('y', b.h / 2)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .text(valueString);

    // Make the breadcrumb trail visible, if it's hidden.
    d3.select('#trail')
        .style('visibility', '');
  }

  function drawLegend() {
    // Dimensions of legend item: width, height, spacing, radius of rounded rect.
    const li = {
      w: 20, h: 20, s: 5, r: 3
    };

    const legend = d3.select('#legend').append('svg')
        .attr('width', 200)
        .attr('height', d3.keys(colors).length * (li.h + li.s));

    const g = legend.selectAll('g')
        .data(d3.entries(colors))
        .enter().append('g')
        .attr('transform', (d, i) => `translate(0,${i * (li.h + li.s)})`);

    g.append('rect')
        .attr('rx', li.r)
        .attr('ry', li.r)
        .attr('width', li.w)
        .attr('height', li.h)
        .style('fill', (d) => d.value);

    g.append('text')
        .attr('x', li.r + 30)
        .attr('y', li.h / 2)
        .attr('dy', '0.35em')
        .style('fill', '#000')
        .attr('text-anchor', 'start')
        .text((d) => d.key);
  }

  return createVisualization(data);
};
