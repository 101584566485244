import React from "react";
import i18n from "i18next";
import { get } from "lodash";
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import * as types from "_graphql-types/graphql";
import config from "../../../config/config";
import { INVESTMENT_ASSIGNMENTS } from "./graphql";
import ProfileImage from "./ProfileImage";
import { startCase } from "lodash";
const SummaryFundAdvisor = ({
  className,
  investmentId,
}: {
  className?: string;
  investmentId: number;
}): JSX.Element => {
  const { data } = useQuery<{ investment: types.Investment }>(
    INVESTMENT_ASSIGNMENTS,
    {
      variables: {
        id: investmentId,
      },
    }
  );

  const primaryAdvisor = data?.investment?.primaryFundAdvisor;
  const portfolioManager =
    data?.investment?.faceOfInvestment?.firmEmployment?.person ??
    data?.investment?.primaryPortfolioManager?.firmEmployment?.person;

  const mainClassNames = classNames(
    "cms-overview-advisor__img",
    "main-userpic",
    "main-userpic--100"
  );

  const portfolioManagerPhotoLink = portfolioManager?.photoLink
    ? `${config.RCG_SIRIUS_URI()}${portfolioManager.photoLink}?size=small`
    : "";
  const advisorPhotoLink = primaryAdvisor?.imageType
    ? `${config.RCG_SIRIUS_URI()}/api/users/${primaryAdvisor.userName}/image`
    : "";

  return (
    <div data-cy="fund-assignments">
      <div className="fund-advisor" data-test-id="primary-portfolio-manager">
        {!!portfolioManager && (
          <>
            <ProfileImage
              personName={portfolioManager.fullName}
              personId={portfolioManager.id}
              photoLink={portfolioManagerPhotoLink}
              mainClassName={mainClassNames}
              fallbackClassName={mainClassNames}
            />
            <div className="mb-10">
              <p className="fund-advisor__name">{portfolioManager.fullName} </p>

              <p className="fund-advisor__position">
                {i18n.t("primary_portfolio_manager.job_title")}
              </p>
            </div>
          </>
        )}
      </div>
      <div className={`fund-advisor ${className}`} data-test-id="fund-advisor">
        {!!primaryAdvisor && (
          <>
            <ProfileImage
              personName={primaryAdvisor.commonName}
              personId={primaryAdvisor.userName}
              photoLink={advisorPhotoLink}
              mainClassName={mainClassNames}
              fallbackClassName={mainClassNames}
            />

            <div className="mb-10">
              <p className="fund-advisor__name">
                {get(primaryAdvisor, "commonName", undefined)}
              </p>

              <p className="fund-advisor__position">
                {i18n.t("summary_fund_advisor.job_title")}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SummaryFundAdvisor.defaultProps = {
  className: "",
};

export default SummaryFundAdvisor;
