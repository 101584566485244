/**
 * Get the start and end of the pagination range
 * based on the current document page, total pages in the document,
 * and the pagination limit/page size
 * @param pageNumber document page number (not the offset)
 * @param pageSize limit - number of items/document pages per pagination "page"
 * @param numPages total number of document pages
 * @returns tuple of start and end of pagination range
 */
export function getPageRange(
  pageNumber: number,
  pageSize: number,
  numPages: number | null
): [number, number] {
  if (!numPages) {
    return [0, 0];
  }
  if (pageSize === 1) {
    return [pageNumber, pageNumber];
  }
  const end = pageNumber + (pageSize - 1);
  return [pageNumber, end > numPages ? numPages : end];
}

/**
 * Get the document page number based on the offset and limit
 * @param pageOffset offset (1-based) - comes from pagination controller
 * @param pageSize limit - number of items/document pages per pagination "page"
 * @returns document page number
 */
export function getNextPage(pageOffset: number, pageSize?: number) {
  if (pageOffset === 1) {
    return pageOffset;
  }
  if (pageSize && pageSize > 1) {
    return pageOffset * pageSize - (pageSize - 1);
  }
  return pageOffset;
}
