import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import LoadingPanel from "Components/loading_panel";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import DrawdownsHighChart from "./DrawdownsHighChart.component";
import { GET_DRAWDOWN } from "./graphql";
import { round } from "lodash";

function DrawdownGraph({ investmentId }: { investmentId: number }) {
  const { data, loading } = useQuery(GET_DRAWDOWN, {
    variables: { investmentId },
  });

  const drawdowns = useMemo(() => {
    if (!data?.investment.performanceAnalysis?.drawdown) return [];
    return data.investment.performanceAnalysis?.drawdown?.filter(
      (d): d is { year: number; month: number; value: number } =>
        isDefinedAndNotNull(d.value) &&
        isDefinedAndNotNull(d.month) &&
        isDefinedAndNotNull(d.year)
    );
  }, [data]);

  const chartData = useMemo(
    () => [
      {
        name: data?.investment.name || "",
        data: drawdowns.map(
          (
            { value, year, month } // round value to 2 decimal places
          ) =>
            [new Date(year, month - 1).getTime(), round(value, 2)] as [
              number,
              number
            ]
        ),
      },
    ],
    [data, drawdowns]
  );

  if (loading) return <LoadingPanel loading />;

  if (!data || !drawdowns) return null;

  if (
    !chartData ||
    chartData.map(({ data }) => data.length).every(len => len === 0)
  ) {
    return null;
  }

  return (
    <div className="invt-tab__section overview-page__card">
      <DrawdownsHighChart title="Drawdowns" chartData={chartData} />
    </div>
  );
}

export default DrawdownGraph;
