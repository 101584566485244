/* eslint-disable max-lines-per-function */
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Button, DatePicker, Modal, Radio, Tag } from "antd";
import InvestmentFilterTag from "Components/GlobalSearchWorkspace/FilterTags/Investment/InvestmentFilterTag";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { format } from "date-fns";
import { downloadSpreadsheet, SheetFormat } from "Helpers/downloadSpreadsheet";
import i18n from "i18next";
import { camelCase, compact, startCase } from "lodash";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { clearAllInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { InvestmentFilter } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";
import {
  LOAD_INVESTMENTS_FOR_DOWNLOAD,
  PERFORMANCE_STATS_GROUP,
} from "./graphql";

const { Group: RadioGroup, Button: RadioButton } = Radio;

const PERFORMANCE_STATS_HEADERS = [
  i18n.t("analytics.peer_statistics.previous_month"),
  i18n.t("analytics.peer_statistics.3_m"),
  i18n.t("analytics.peer_statistics.ytd"),
  i18n.t("analytics.peer_statistics.1_y"),
  i18n.t("analytics.peer_statistics.3_y"),
  i18n.t("analytics.peer_statistics.5_y"),
  i18n.t("analytics.peer_statistics.itd_anl"),
  "As Of Date",
];

const PRIVATE_PERFORMANCE_HEADERS = [
  "IRR Quarter",
  "IRR Year",
  "IRR",
  "TVPI",
  "DPI",
];

const AUM_HEADER = [
  "Latest Investment AUM Date",
  "Latest Investment AUM",
  "Latest Strategy AUM Date",
  "Latest Strategy AUM",
  "Latest Firm AUM Date",
  "Latest Firm AUM",
];

function utcformat(dateString: string, formatString: string) {
  if (!dateString) return null;
  const s = new Date(new Date(dateString).toISOString().slice(0, -1));
  return format(s, formatString);
}

function InvestmentFilterTags() {
  const filters: InvestmentFilter[] = useSelector(getInvestmentFilters);
  const dispatch = useDispatch();
  if (filters.length === 0) return null;

  return (
    <div className="main-gf__marks-list">
      <label>Filters:</label>
      <br />
      {filters.map((filter: InvestmentFilter, index) => (
        <InvestmentFilterTag key={index} filter={filter} />
      ))}
      {filters.length > 0 && (
        <Tag
          className="ant-tag--reset"
          data-cy="clear-all-filters"
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(clearAllInvestmentFilters())}
        >
          {i18n.t("filters.reset_all")}
        </Tag>
      )}
    </div>
  );
}

export function SearchDownloader({
  handleCancel,
  visible,
}: {
  handleCancel: () => void;
  visible: boolean;
}): JSX.Element {
  const { selectedInvestmentIds } = useContext(InvestmentControlsContext);

  const [sheetFormat, changeSheetFormat] = useState<SheetFormat>(
    SheetFormat.Xlsx
  );
  const [includeAum, changeIncludeAum] = useState(false);
  const [includePerformance, changeIncludePerformance] = useState(false);
  const [includePrivatePerformance, changeIncludePrivatePerformance] =
    useState(false);
  const [performanceAsOfDate, changePerformanceAsOfDate] = useState(new Date());

  const { data: investmentData, loading } = useQuery<{
    investmentMany: types.InvestmentTileFragment[];
  }>(LOAD_INVESTMENTS_FOR_DOWNLOAD, {
    variables: {
      investmentIds: Array.from(selectedInvestmentIds),
    },
    skip: !visible,
  });

  const investments = investmentData?.investmentMany;

  const noReadAccessOnInvestments = !investments?.some(
    investment => !!investment?.access?.read
  );

  const unentitledInvestmentPresent = investments?.some(
    investment => !investment?.access?.read
  );

  const includeAccessNote =
    unentitledInvestmentPresent &&
    (includeAum || includePerformance || includePrivatePerformance);

  const { data: performanceData, loading: performanceLoading } = useQuery(
    PERFORMANCE_STATS_GROUP,
    {
      variables: {
        investmentIds: Array.from(selectedInvestmentIds),
        asOfDate: performanceAsOfDate.toISOString(),
      },
      skip: !visible || !includePerformance,
    }
  );

  const downloadData = () => {
    if (!investments) return;
    const headers = [
      "id",
      "Investment Name",
      "Firm Name",
      "Strategy",
      "Asset Class",
      "Vintage Year",
      "Inception Date",
      "Location",
      "Impact",
      "Primary Geographic Focus",
      ...(includeAccessNote ? ["*Note"] : []),
      ...(includeAum ? AUM_HEADER : []),
      ...(includePerformance ? PERFORMANCE_STATS_HEADERS : []),
      ...(includePrivatePerformance ? PRIVATE_PERFORMANCE_HEADERS : []),
    ];
    const data = investments.map((investment, i) => [
      investment.id,
      investment.name,
      investment.firm?.name,
      investment?.strategy?.name,
      investment.assetClass?.name,
      investment?.vintageYear,
      utcformat(investment?.inceptionDate, "yyyy-MM-dd"),
      investment.firm
        ? compact([
            investment?.firm.primaryAddress?.city,
            investment?.firm?.primaryAddress?.state?.code ??
              investment?.firm?.primaryAddress?.otherState,
            investment?.firm?.primaryAddress?.country?.name &&
              startCase(
                camelCase(investment?.firm?.primaryAddress?.country.name)
              ),
          ]).join(", ")
        : "",
      investment?.isImpact ? "True" : "",
      investment?.primaryGeographicFocus?.name,
      ...(includeAccessNote
        ? [
            !investment?.access?.read
              ? "Please request access to fund to view performance/AUM data"
              : "",
          ]
        : []),
      ...(includeAum && !!investment?.access?.read
        ? [
            investment?.latestInvestmentAUM?.date
              ? (investment?.latestInvestmentAUM?.date).split("T")[0]
              : "-",
            investment?.latestInvestmentAUM?.value ?? "-",
            investment?.latestStrategyAUM?.date
              ? (investment?.latestStrategyAUM?.date).split("T")[0]
              : "-",
            investment?.latestStrategyAUM?.value ?? "-",
            investment?.latestFirmAUM?.date
              ? (investment?.latestFirmAUM?.date).split("T")[0]
              : "-",
            investment?.latestFirmAUM?.value ?? "-",
          ]
        : []),
      ...(includePerformance && !!investment?.access?.read
        ? [
            performanceData?.statsGroup.compoundReturn1m[i] ?? "-",
            performanceData?.statsGroup.compoundReturn3m[i] ?? "-",
            performanceData?.statsGroup.compoundReturnYtd[i] ?? "-",
            performanceData?.statsGroup.compoundReturn1yAnnualized[i] ?? "-",
            performanceData?.statsGroup.compoundReturn3yAnnualized[i] ?? "-",
            performanceData?.statsGroup.compoundReturn5yAnnualized[i] ?? "-",
            performanceData?.statsGroup.compoundReturnItdAnnualized[i] ?? "-",
            `${
              performanceAsOfDate.getUTCMonth() + 1
            }/${performanceAsOfDate.getFullYear()}`,
          ]
        : []),
      ...(includePrivatePerformance &&
      !!investment?.access?.read &&
      investment?.performancePrivate &&
      investment?.performancePrivate[0]
        ? [
            investment?.performancePrivate[0]?.returnQuarter,
            investment?.performancePrivate[0]?.returnYear,
            investment?.performancePrivate[0]?.netIRR,
            investment?.performancePrivate[0]?.tvpi,
            investment?.performancePrivate[0]?.dpi,
          ]
        : []),
    ]);

    downloadSpreadsheet(
      [{ data: [headers, ...data], name: "InvestmentSearch" }],
      "InvestmentSearch",
      sheetFormat
    );
  };

  return (
    <Modal
      title="Download Investment Results"
      open={visible}
      onCancel={handleCancel}
      footer={[
        <div data-testid="search-download-footer">
          <Button
            onClick={downloadData}
            disabled={loading || performanceLoading}
            data-testid="download-button"
            type="primary"
          >
            {loading || performanceLoading ? (
              <i
                className="main-spinner__icon"
                style={{ fontSize: "22px" }}
                data-testid="search-download-modal-button-loading"
              />
            ) : (
              "Download"
            )}
          </Button>
        </div>,
      ]}
    >
      <div data-testid="search-download-modal">
        <div style={{ marginBottom: "10px" }}>
          <InvestmentFilterTags />
          <br />
          {selectedInvestmentIds.size} Selected investments
        </div>
        <h4>Optional Sections</h4>
        <div className="main-checkbox" style={{ marginBottom: "10px" }}>
          <input
            id="include__performance"
            type="checkbox"
            className="main-checkbox__input"
            onChange={() => {
              changeIncludePerformance(!includePerformance);
            }}
            value={includePerformance ? "checked" : "unchecked"}
            disabled={noReadAccessOnInvestments}
          />
          <label
            className="main-checkbox__label"
            htmlFor="include__performance"
          >
            Performance Stats (TWRR)
          </label>
        </div>
        {includePerformance && (
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="performance__stat_as_of_date">As of Date: </label>
            <DatePicker
              id="performance__stat_as_of_date"
              picker="month"
              onChange={(_, dateString) =>
                changePerformanceAsOfDate(
                  dateString ? new Date(String(dateString)) : new Date()
                )
              }
            />
          </div>
        )}

        <div className="main-checkbox" style={{ marginBottom: "10px" }}>
          <input
            id="include__private_performance"
            type="checkbox"
            className="main-checkbox__input"
            onChange={() => {
              changeIncludePrivatePerformance(!includePrivatePerformance);
            }}
            value={includePrivatePerformance ? "checked" : "unchecked"}
            disabled={noReadAccessOnInvestments}
          />
          <label
            className="main-checkbox__label"
            htmlFor="include__private_performance"
          >
            Private Performance (Quarterly IRR)
          </label>
        </div>
        <div className="main-checkbox" style={{ marginBottom: "10px" }}>
          <input
            id="include__AUM"
            type="checkbox"
            className="main-checkbox__input"
            onChange={() => {
              changeIncludeAum(!includeAum);
            }}
            value={includeAum ? "checked" : "unchecked"}
            disabled={noReadAccessOnInvestments}
          />
          <label className="main-checkbox__label" htmlFor="include__AUM">
            Latest AUM
          </label>
        </div>
        {noReadAccessOnInvestments && (
          <div style={{ marginBottom: "10px" }}>
            <label>
              <i>
                You must request access to the selected investments to view
                their optional section data.
              </i>
            </label>
          </div>
        )}
        <div style={{ marginBottom: "10px" }}>
          <label>Format:</label>
          <br />
          <RadioGroup
            value={sheetFormat}
            onChange={e => changeSheetFormat(e.target.value)}
          >
            <RadioButton value={SheetFormat.Xlsx}>Excel</RadioButton>
            <RadioButton value={SheetFormat.Csv}>CSV</RadioButton>
          </RadioGroup>
        </div>
      </div>
    </Modal>
  );
}
