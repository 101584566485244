import { gql } from "_graphql-types/gql";

export const TEAM_MEMBER = gql(/* GraphQL */ `
  fragment TeamMember on Person {
    id
    firstName
    lastName
    fullName
    pronouns
    biography
    phone
    phone2
    email
    twitter
    linkedin
    educationSummary
    workExperienceSummary
    photoLink
    diversity {
      id
      africanAmerican
      asian
      hispanic
      lgbtq
      nativeAmerican
      other
      veteran
      disabled
      nonBinary
      hawaiianOrPacificIslander
      woman
    }
    firmEmployment(filter: { isCurrent: true }) {
      id
      firmId
      isFounder
      firm {
        id
        name
      }
      contactType {
        id
        name
      }
      startDate
      leftDate
      jobDesc
      employeeRole {
        name
        id
      }
      investmentFirmEmployments {
        id
        investment {
          id
        }
        startDate
        leftDate
        employeeRole {
          name
          id
        }
      }
    }
    education {
      id
      school {
        id
        name
      }
      degree {
        id
        name
      }
      graduationYear
    }
    workExperience {
      id
      title
      toYear
      fromYear
      summary
      company {
        id
        name
      }
    }
  }
`);
