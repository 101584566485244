import * as types from "_graphql-types/graphql";
import { UserFlagCheck } from "./Authorized";

export function isAuthorized({
  user,
  checks,
}: {
  user: types.CurrentUserQuery["currentUser"];
  checks: UserFlagCheck[];
}): boolean {
  return checks.some(check => {
    return !!user.flags[check];
  });
}
