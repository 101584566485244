/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { TypedDocumentNode, gql, useApolloClient } from "@apollo/client";
import {
  FormatOptions,
  ReportStructure,
  ReportTypeOptions,
  SubSections,
} from "_graphql-types/graphql";
import { Alert, Modal, Space } from "antd";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import {
  ASYNC_TASK_STATUS_QUERY,
  GET_REPORT,
  SUBMIT_REPORT_JOB,
} from "./graphql";

type GqlVars<T> = T extends TypedDocumentNode<any, infer V> ? V : never;

const CommonOverviewSubSections = [
  SubSections.OverviewInvestmentSummaryData,
  SubSections.OverviewFirmTileData,
  SubSections.OverviewClassificationsTileData,
  SubSections.OverviewStatusTileData,
  SubSections.OverviewFundTileData,
  SubSections.OverviewFundTermsData,
  SubSections.OverviewContactsData,
];

const PublicOverviewSubSections = [
  ...CommonOverviewSubSections,
  SubSections.OverviewServiceProvidersData,
  SubSections.OverviewPerformanceData,
  SubSections.OverviewPeerStatisticsData,
  SubSections.OverviewPositionsData,
  SubSections.OverviewBenchmarkStatisticsData,
  SubSections.OverviewRiskStatisticsData,
  SubSections.OverviewRiskBenchmarkStatisticsData,
  SubSections.OverviewMaxDrawDownStatisticsData,
];

const PrivateOverviewSubSections = [
  ...CommonOverviewSubSections,
  SubSections.OverviewPrivateInvestmentFamilyData,
  SubSections.OverviewPrivatePerformanceData,
  SubSections.OverviewPrivatePositionsData,
];

const DiligenceSubSections = [
  SubSections.DiligenceInvestment,
  SubSections.DiligenceOperations,
  SubSections.DiligenceImpact,
];

const TeamSubSections = [
  SubSections.TeamTeamSummary,
  SubSections.TeamKeyPersonnel,
];

const PublicAnalyticsSubSections = [
  SubSections.AnalyticsAssetClassExposure,
  SubSections.AnalyticsBenchmarkReturns,
  SubSections.AnalyticsCumulativeReturns,
  SubSections.AnalyticsDistributionOfReturns,
  SubSections.AnalyticsDrawdowns,
  SubSections.AnalyticsFirmAum,
  SubSections.AnalyticsGrowthOf_1000,
  SubSections.AnalyticsInvestmentAum,
  SubSections.AnalyticsPortfolioExposure,
  SubSections.AnalyticsRegionExposure,
  SubSections.AnalyticsRiskVsReturns,
  SubSections.AnalyticsSectorExposure,
  SubSections.AnalyticsStrategyAum,
  SubSections.AnalyticsUpDownCapture,
];

const PrivateAnalyticsSubSections = [
  SubSections.AnalyticsPortfolioExposure,
  SubSections.AnalyticsRegionExposure,
  SubSections.AnalyticsSectorExposure,
];

export default function DiligenceReport({
  investmentId,
}: {
  investmentId: number;
}) {
  const client = useApolloClient();

  const { name, market } = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: gql(/* GraphQL */ `
      fragment InvestmentMarket on Investment {
        id
        name
        market {
          id
          name
        }
      }
    `),
  });
  const isPublic = market.id === 1;

  const sectionSubSectionsMap: { [k in ReportStructure]: SubSections[] } = {
    [ReportStructure.Overview]: isPublic
      ? PublicOverviewSubSections
      : PrivateOverviewSubSections,
    [ReportStructure.Diligence]: DiligenceSubSections,
    [ReportStructure.Team]: TeamSubSections,
    [ReportStructure.Analytics]: isPublic
      ? PublicAnalyticsSubSections
      : PrivateAnalyticsSubSections,
  };

  const orderedSubsections = Object.values(sectionSubSectionsMap).flat();

  const [isOpen, setIsOpen] = useState(false);
  const [formatOption, setFormatOption] = useState<FormatOptions>(
    FormatOptions.Pdf
  );
  const [reportType, setReportType] = useState<ReportTypeOptions>(
    ReportTypeOptions.Summary
  );
  const [sections, setSections] = useState<ReportStructure[]>([]);
  const [subSections, setSubSections] = useState<SubSections[]>([]);

  const toggleSections = (section: ReportStructure) => {
    if (sections.includes(section)) {
      setSections(sections.filter(s => s !== section));
      setSubSections(
        subSections.filter(s => !sectionSubSectionsMap[section].includes(s))
      );
    } else {
      setSections([...sections, section]);
      setSubSections(
        Array.from(new Set([...subSections, ...sectionSubSectionsMap[section]]))
      );
    }
  };

  const [submitReportJob, { loading, data, error }] = useLazyWorkerQuery();

  function useLazyWorkerQuery() {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);
    const [data, setData] = React.useState<any>(null);

    const client = useApolloClient();

    const query = useCallback(
      (createJobVariables: GqlVars<typeof SUBMIT_REPORT_JOB>) => {
        setLoading(true);
        setError(null);
        setData(null);

        return client
          .mutate({
            mutation: SUBMIT_REPORT_JOB,
            variables: createJobVariables,
            fetchPolicy: "network-only",
          })
          .then(async ({ data }) => {
            if (!data) throw Error("data is undefined");
            const jobId = data["investmentReport"].id;
            if (!jobId) throw Error("jobId is undefined");

            for (let i = 0; i < 360; i++) {
              const {
                data: { asyncTaskStatus },
              } = await client.query({
                query: ASYNC_TASK_STATUS_QUERY,
                variables: {
                  id: jobId,
                },
                fetchPolicy: "network-only",
              });

              if (asyncTaskStatus) {
                const { error, id, endDate } = asyncTaskStatus;
                if (!id) throw Error("asyncTaskStatus.id is undefined");

                if (error) {
                  throw new Error(error);
                }
                if (endDate) {
                  return client
                    .query({
                      query: GET_REPORT,
                      variables: {
                        id,
                        format: formatOption,
                      },
                      fetchPolicy: "network-only",
                    })
                    .then(({ data }) => {
                      setData(data);
                      setLoading(false);
                      return data;
                    })
                    .catch(e => {
                      setLoading(false);
                      setError(e);
                      throw e;
                    });
                }
              }

              await new Promise(resolve => setTimeout(resolve, 1000));
            }

            throw new Error("Timed out polling for job");
          })
          .catch(e => {
            setLoading(false);
            setError(e);
            throw e;
          });
      },
      [client, formatOption]
    );

    return [query, { loading, error, data }] as const;
  }

  useEffect(() => {
    if (data?.report) {
      fetch(data.report)
        .then(response => response.blob())
        .then(blob => saveAs(blob, `${name}.${formatOption}`));
    }
  }, [data?.report]);

  const subSectionCheckBox = (subSection: SubSections) => (
    <div key={subSection} className="main-checkbox">
      <input
        id={`custom-report-element-option-${subSection}`}
        type="checkbox"
        name={`elements[${subSection}]`}
        checked={subSections.includes(subSection)}
        onChange={() =>
          setSubSections(
            subSections.includes(subSection)
              ? subSections.filter(s => s !== subSection)
              : orderedSubsections.filter(item =>
                  [...subSections, subSection].includes(item)
                ) // we want to keep the order of the subSections
          )
        }
        className="main-checkbox__input"
      />

      <label
        className="main-checkbox__label"
        htmlFor={`custom-report-element-option-${subSection}`}
      >
        {I18n.t(
          `reports.create_modal.sub_sections.${subSection.substring(
            subSection.indexOf("_") + 1
          )}`
        )}
      </label>
    </div>
  );

  return (
    <>
      <button
        data-cy="report-modal-button"
        type="button"
        className="round-icon-btn round-icon-btn--36 round-icon-btn--transparent-white ml-15 mb-10 hidden-lg-down"
        onClick={() => setIsOpen(true)}
      >
        <i className="icon icon-print font-15" />
      </button>
      <Modal
        title={I18n.t("reports.create_modal.title")}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={
          <div className="main-modal__footer">
            <button
              type="button"
              className="rounded-btn rounded-btn--grey mr-10 mb-15"
              onClick={() => setIsOpen(false)}
            >
              {I18n.t("funds.form.cancel")}
            </button>

            <button
              type="submit"
              id="download-report-button"
              className="rounded-btn rounded-btn--blue mb-15 download-report"
              onClick={() => {
                submitReportJob({
                  investmentId,
                  format: formatOption,
                  reportType,
                  ...(reportType === ReportTypeOptions.Custom
                    ? { elements: sections, subSections }
                    : {}),
                });
              }}
            >
              {loading ? (
                <i
                  id="loading-more-spinner"
                  className="main-spinner__icon"
                  style={{ color: "#ffffff", fontSize: "12px" }}
                />
              ) : (
                I18n.t("create_report")
              )}
            </button>
          </div>
        }
      >
        <span className="create-report-modal-content">
          <div className="d-flex">
            {Object.values(FormatOptions)
              .reverse()
              .map(format => (
                <div key={format} className="main-radio mr-30">
                  <input
                    id={`report-format-option-${format}`}
                    type="radio"
                    name="format"
                    value={format}
                    checked={formatOption === format}
                    onChange={() => setFormatOption(format)}
                    className="main-radio__input"
                  />
                  <label
                    htmlFor={`report-format-option-${format}`}
                    className="main-radio__label"
                  >
                    {format.toUpperCase()}
                  </label>
                </div>
              ))}
          </div>
          <div className="divider mb-15" />
          <div className="form-group">
            {[
              ReportTypeOptions.Summary,
              ReportTypeOptions.Complete,
              ReportTypeOptions.Custom,
              ReportTypeOptions.TwoPage,
              ReportTypeOptions.Ranger,
            ].map(type => (
              <div key={type} className="main-radio">
                <input
                  id={`report-type-option-${type}`}
                  type="radio"
                  name="type"
                  value={I18n.t(
                    `reports.create_modal.types.${type.toLowerCase()}`
                  )}
                  checked={reportType === type}
                  onChange={() => setReportType(type)}
                  className="main-radio__input"
                />

                <label
                  className="main-radio__label"
                  htmlFor={`report-type-option-${type}`}
                >
                  {type === "TwoPage" ? "Two-Page Report" : type}
                </label>
              </div>
            ))}
            {reportType === ReportTypeOptions.Custom && (
              <div style={{ display: "flex" }}>
                <div className="pl-30">
                  {[
                    ReportStructure.Overview,
                    ReportStructure.Diligence,
                    ReportStructure.Team,
                    ReportStructure.Analytics,
                  ].map(element => (
                    <div key={element} className="main-checkbox">
                      <input
                        id={`custom-report-element-option-${element}`}
                        type="checkbox"
                        name={`elements[${element}]`}
                        checked={sections.includes(element)}
                        onChange={() => toggleSections(element)}
                        className="main-checkbox__input"
                      />

                      <label
                        className="main-checkbox__label"
                        htmlFor={`custom-report-element-option-${element}`}
                      >
                        {element}
                      </label>
                    </div>
                  ))}
                </div>
                <div
                  className="pl-30"
                  style={{ maxHeight: "200px", overflow: "scroll" }}
                >
                  {sections.includes(ReportStructure.Overview) && (
                    <>
                      <div>Overview</div>
                      {sectionSubSectionsMap[ReportStructure.Overview].map(
                        subSectionCheckBox
                      )}
                    </>
                  )}
                  {sections.includes(ReportStructure.Diligence) && (
                    <>
                      <div>Diligence</div>
                      {DiligenceSubSections.map(subSectionCheckBox)}
                    </>
                  )}
                  {sections.includes(ReportStructure.Team) && (
                    <>
                      <div>Team</div>
                      {TeamSubSections.map(subSectionCheckBox)}
                    </>
                  )}
                  {sections.includes(ReportStructure.Analytics) && (
                    <>
                      <div>Analytics</div>
                      {sectionSubSectionsMap[ReportStructure.Analytics].map(
                        subSectionCheckBox
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {error && (
            <Space>
              <Alert
                message="Error Generating Report"
                description={error.message}
                type="error"
              />
            </Space>
          )}
        </span>
      </Modal>
    </>
  );
}
