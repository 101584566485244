import { gql, useQuery } from "@apollo/client";
import { FirmFundAssets } from "_graphql-types/graphql";
import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import AumTableStatic from "frontend/src/components/Template/Static/AumTableStatic";
import { Metadata } from "frontend/src/components/Template/types";
import { logException } from "Helpers/index";
import { get } from "lodash";
import { useMemo } from "react";

const AUM_DATA = gql(/* GraphQL */ `
  query GetAUMRecords($id: Int!) {
    investment(id: $id) {
      id
      name
      assets {
        investmentId
        asOfDate
        investmentAUM
        investmentAumCurrency
        strategyAUM
        strategyAumCurrency
        firmAUM
        firmAumCurrency
      }
    }
  }
`);

interface AumRecord {
  aum: number;
  currency: string;
  asOfDate: string | Date;
}

function getDataByType(
  assets: FirmFundAssets[],
  type: "investment" | "strategy" | "firm"
): AumRecord[] {
  return assets.flatMap(asset =>
    asset[`${type}AUM`]
      ? {
          aum: asset[`${type}AUM`]!,
          currency: asset[`${type}AumCurrency`]!,
          asOfDate: asset.asOfDate,
        }
      : []
  );
}

function AUMSubtabPage({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const { data, error } = useQuery(AUM_DATA, {
    variables: { id: investmentId, sort: { field: "asOfDate", order: "ASC" } },
    fetchPolicy: "cache-first",
  });

  const assets = useMemo(() => get(data, "investment.assets", []), [data]);
  const getMetadata = (type: string): Metadata => ({
    displayName: `${type} AUM`,
    displayType: "aum_table",
    value: {
      arrayDepth: 1,
      propertyKey: "value",
      returnTypeName: `${type}AUM`,
      isScalar: false,
      nullable: true,
      props: [],
    },
  });
  const [firmAums, firmMeta, firmCurrency] = useMemo(() => {
    const firmAums = getDataByType(assets, "firm");
    const { currency } = firmAums.at(-1) ?? {};
    return [firmAums, getMetadata("Firm"), currency ?? ""];
  }, [assets]);
  const [investmentAums, investmentMeta, investmentCurrency] = useMemo(() => {
    const investmentAums = getDataByType(assets, "investment");
    const { currency } = investmentAums.at(-1) ?? {};
    return [investmentAums, getMetadata("Investment"), currency ?? ""];
  }, [assets]);
  const [strategyAums, strategyMeta, strategyCurrency] = useMemo(() => {
    const strategyAums = getDataByType(assets, "strategy");
    const { currency } = strategyAums.at(-1) ?? {};
    return [strategyAums, getMetadata("Strategy"), currency ?? ""];
  }, [assets]);

  if (error) {
    logException(error, { investmentId, query: AUM_DATA });
    displayErrorFlash();
  }
  return (
    <div id="aum-subtab-page">
      <p className="cms-subtitle">
        Firm AUM {firmCurrency && `(${firmCurrency})`}
      </p>
      <AumTableStatic
        value={firmAums}
        metadata={firmMeta}
        currency={firmCurrency}
      />
      <p className="cms-subtitle">
        Investment AUM {investmentCurrency && `(${investmentCurrency})`}
      </p>
      <AumTableStatic
        value={investmentAums}
        metadata={investmentMeta}
        currency={investmentCurrency}
      />
      <p className="cms-subtitle">
        Investment Strategy AUM {strategyCurrency && `(${strategyCurrency})`}
      </p>
      <AumTableStatic
        value={strategyAums}
        metadata={strategyMeta}
        currency={strategyCurrency}
      />
    </div>
  );
}

export default AUMSubtabPage;
