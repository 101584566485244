import classNames from "classnames";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";

export function percent(amount: number | null | undefined) {
  return isDefinedAndNotNull(amount)
    ? new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(amount) + "%"
    : "-";
}

export function maxTerm(
  firstTerm: number | null | undefined,
  secondTerm: number | null | undefined,
  fallback = "-"
) {
  if (!isDefinedAndNotNull(firstTerm)) return fallback;
  return firstTerm + (isDefinedAndNotNull(secondTerm) ? secondTerm : 0);
}

export function units(value?: number | null, unit?: string) {
  return isDefinedAndNotNull(value) ? `${value} ${unit}` : "-";
}

export function FieldItem({
  title,
  value,
  className,
}: {
  title: string;
  value: string | number;
  className?: string;
}) {
  const fieldContentClasses = classNames(
    "cms-invst-terms__edit-cell",
    className
  );
  return (
    <div className="flex-row">
      <div className="flex-1">
        <div className="pb-10 in-secondary-blue">{title}</div>
      </div>

      <div className="flex-1 pl-20">
        <div className={fieldContentClasses}>{value}</div>
      </div>
    </div>
  );
}
