import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useResizeDetector } from "react-resize-detector";
import { ApolloError } from "@apollo/client";
import { Result } from "antd";

function LoadingPanel({
  loading,
  error,
  children,
}: {
  loading: boolean;
  error?: ApolloError;
  children: React.ReactNode;
}): JSX.Element {
  if (error !== undefined && error) {
    return (
      <Result title={error.name} subTitle={error.message} status="error" />
    );
  }
  const { ref, width, height } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  });

  const calcLoaderSize = useCallback(() => {
    let defaultSize;
    if (ref.current.offsetWidth > ref.current.offsetHeight) {
      defaultSize = ref.current.offsetHeight / 4.0;
    } else {
      defaultSize = ref.current.offsetWidth / 4.0;
    }

    const maxSize = 36;
    if (defaultSize < maxSize) {
      return defaultSize;
    }
    return maxSize;
  }, [ref]);

  // this seems to be off center for modals / popovers
  const calcTop = useCallback(
    () =>
      ref.current.offsetTop +
      ref.current.offsetHeight / 2.0 -
      calcLoaderSize() / 2.0,
    [ref, calcLoaderSize]
  );

  // this seems to be off center for modals / popovers
  const calcLeft = useCallback(
    () =>
      ref.current.offsetLeft +
      ref.current.offsetWidth / 2.0 -
      calcLoaderSize() / 2.0,
    [ref, calcLoaderSize]
  );

  const locationStyle = useMemo(
    () =>
      ref.current && {
        position: "absolute",
        zIndex: 20,
        top: `${calcTop()}px`,
        left: `${calcLeft()}px`,
      },
    [width, height, ref, calcTop, calcLeft]
  );

  const spinnerStyle = useMemo(
    () =>
      ref.current && {
        fontSize: `${calcLoaderSize()}px`,
        color: "#17688e", // lightNavy
      },
    [width, height, ref, calcLoaderSize]
  );

  return (
    <div ref={ref}>
      {loading && locationStyle && (
        <div data-testid="loading-spinner" style={locationStyle}>
          <i className="fa fa-spinner fa-spin" style={spinnerStyle} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
}

LoadingPanel.defaultProps = {
  children: undefined,
};

LoadingPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default LoadingPanel;
