import { getSearchResultDetails } from "Helpers/investment_helpers";
import { createContext } from "react";
import * as types from "_graphql-types/graphql";

type InvestmentTileContextType = {
  investment: NonNullable<types.InvestmentTileFragment>;
  details: ReturnType<typeof getSearchResultDetails>;
  isHovered: boolean;
  InvestmentControlsAreAvailable: boolean;
  toggleInvestment: (event: React.MouseEvent) => void;
  isSelected: (id: number) => boolean;
};

export const InvestmentTileContext = createContext<InvestmentTileContextType>({
  investment: {} as NonNullable<types.InvestmentTileFragment>,
  details: {} as ReturnType<typeof getSearchResultDetails>,
  isHovered: false,
  InvestmentControlsAreAvailable: false,
  toggleInvestment: () => {},
  isSelected: () => false,
});
