import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { formatDate } from "frontend/src/utils/date";
import i18n from "i18next";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

function InceptionDateFilterTag({ filter }: { filter: types.DateRangeFilter }) {
  const dispatch = useDispatch();
  const { min, max } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="inception-date-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "INCEPTION_DATE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {i18n.t("search_discovery.filters.labels.inception_date")}{" "}
      {min && max ? (
        <>
          between {formatDate(min, "yyyy-MM-dd")} and{" "}
          {formatDate(max, "yyyy-MM-dd")}
        </>
      ) : min ? (
        <>&ge; {formatDate(min, "yyyy-MM-dd")}</>
      ) : max ? (
        <>&le; {formatDate(max, "yyyy-MM-dd")}</>
      ) : null}
    </Tag>
  );
}

export default InceptionDateFilterTag;
