import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";

const Biography = ({ biography }) => (
  <div data-cy="person-card-biography" className="team-member__info">
    <p className="team-member__info-title">
      {i18n.t("team_summary.biography")}
    </p>
    <div className="team-member__info-value">
      <p>{biography || "-"}</p>
    </div>
  </div>
);

Biography.propTypes = {
  biography: PropTypes.string.isRequired,
};

export default Biography;
