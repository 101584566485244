import * as types from "_graphql-types/graphql";
import i18n from "i18next";
/* eslint-disable max-lines-per-function */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { FilterInterface } from "../FilterInterface";

const toWord = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

function MwdbeFilter() {
  const investmentFilters = useSelector(getInvestmentFilters);

  const dispatch = useDispatch();

  const mwdbeFilter = useMemo(() => {
    for (const filter of investmentFilters) {
      if (filter.type === "firm" && filter.data.type === "MWDBE") {
        return filter.data.data;
      }
    }
  }, [investmentFilters]);

  const defaultFilter = {
    categories: [],
    ownershipPercentage: types.OwnershipPercentageType.Any,
  };

  const [localFilter, setLocalFilter] = useState(mwdbeFilter);

  const set = () => {
    if (localFilter?.categories || localFilter?.ownershipPercentage)
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "firm",
            data: {
              type: "MWDBE",
              data: localFilter,
            },
          },
        })
      );
  };

  const reset = () => {
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "firm",
          data: {
            type: "MWDBE",
            data: defaultFilter,
          },
        },
      })
    );
  };

  const changeCategory = (
    category: types.OwnershipDiversityType,
    add: boolean
  ) => {
    if (add) {
      setLocalFilter({
        ownershipPercentage:
          localFilter?.ownershipPercentage || types.OwnershipPercentageType.Any,
        categories: [
          ...(localFilter?.categories || []),
          category,
        ] as types.OwnershipDiversityType[],
      });
    } else {
      setLocalFilter({
        ownershipPercentage:
          localFilter?.ownershipPercentage || types.OwnershipPercentageType.Any,
        categories:
          localFilter?.categories?.filter(v => v !== category) ||
          ([] as types.OwnershipDiversityType[]),
      });
    }
  };

  const changeOwnershipType = (ownershipType: types.OwnershipPercentageType) =>
    setLocalFilter({ ...localFilter, ownershipPercentage: ownershipType });

  const CategoryCheckbox = ({
    value,
    checked,
  }: {
    value: types.OwnershipDiversityType;
    checked: boolean | undefined;
  }) => (
    <>
      <input
        type="checkbox"
        className="main-checkbox__input"
        checked={checked}
        onChange={() => changeCategory(value, !checked)}
      />
      <label
        htmlFor={value}
        className="main-checkbox__label"
        onClick={() => changeCategory(value, !checked)}
        data-cy={`category-${value}`}
      >
        {i18n.t(`team_member.diversity.${value}`)}
      </label>
    </>
  );

  const OwnershipTypeRadio = ({
    value,
    selected,
    disabled,
  }: {
    value: types.OwnershipPercentageType;
    selected: boolean;
    disabled: boolean;
  }) => (
    <div
      className={`main-dropdown__menu-option main-dropdown__menu-option--input main-radio ${
        disabled ? "main-radio--disabled" : ""
      }`}
    >
      <input
        type="radio"
        checked={selected && !disabled}
        className="main-radio__input"
        onChange={() => changeOwnershipType(value)}
        disabled={disabled}
      />
      <label
        htmlFor={value}
        className="main-radio__label"
        onClick={() => changeOwnershipType(value)}
        data-cy={`ownership-type-${value}`}
      >
        {toWord(value)}
      </label>
    </div>
  );

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.mwdbe")}
      count={mwdbeFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown"
      disabled={
        !(localFilter?.categories?.length && localFilter?.ownershipPercentage)
      }
    >
      <div className="main-dropdown__menu-body">
        <p className="more-filters__subtitle">
          {i18n.t("search_discovery.filters.mwdbe.categories")}
        </p>
        <div className="filters__mwdbe-two-columns mb-20">
          {Object.values(types.OwnershipDiversityType).map((category, i) => (
            <CategoryCheckbox
              key={i}
              value={category}
              checked={localFilter?.categories?.includes(category)}
            />
          ))}
        </div>
        <p className="more-filters__subtitle">
          {i18n.t("search_discovery.filters.mwdbe.ownership")}
        </p>
        <div>
          {Object.values(types.OwnershipPercentageType).map(
            (ownershipType, i) => (
              <OwnershipTypeRadio
                key={i}
                value={ownershipType}
                selected={localFilter?.ownershipPercentage === ownershipType}
                disabled={!localFilter?.categories?.length}
              />
            )
          )}
        </div>
      </div>
    </FilterInterface>
  );
}

export default MwdbeFilter;
