import { gql } from "_graphql-types/gql";

export const FIRM_ATTRIBUTES = gql(/* GraphQL */ `
  query FetchInvestmentFirmAttributes($id: Int!) {
    investment(id: $id) {
      id
      name
      latestFirmAUM {
        value
        date
        currency {
          id
          name
        }
      }
      firm {
        id
        name
        employeeTotal
        yearFounded
        isStaffMWDBE
        investments(page: { limit: 5, offset: 0 }) {
          items {
            id
            name
            isImpact
            investmentRegulatorsList(sort: [{ field: name, order: ASC }]) {
              items {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const FETCH_FIRM_PARTNERS = gql(/* GraphQL */ `
  query FirmPartners(
    $page: PageInput
    $sort: [TeamSort!]
    $filter: TeamFilterInput
  ) {
    teamMemberList(page: $page, sort: $sort, filter: $filter) {
      items {
        id
        firstName
        lastName
        pronouns
        fullName
        biography
        phone
        phone2
        email
        twitter
        linkedin
        educationSummary
        workExperienceSummary
        photoLink
        diversity {
          id
          africanAmerican
          asian
          hispanic
          lgbtq
          nativeAmerican
          other
          veteran
          disabled
          nonBinary
          hawaiianOrPacificIslander
          woman
        }
        firmEmployment(filter: { isCurrent: true }) {
          id
          firmId
          firm {
            id
            name
          }
          startDate
          leftDate
          jobDesc
          employeeRole {
            name
            id
          }
          investmentFirmEmployments {
            id
            investment {
              id
            }
            startDate
            leftDate
            employeeRole {
              name
              id
            }
          }
        }
        education {
          id
          school {
            id
            name
          }
          degree {
            id
            name
          }
          graduationYear
        }
        workExperience {
          id
          title
          toYear
          fromYear
          company {
            id
            name
          }
        }
      }
      total
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);
