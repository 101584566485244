import React from "react";
import i18n from "i18next";

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const AddNoteLinkMobile = ({ onClick }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className="invst-header__actions-dropdown-menu-item hidden-lg-up"
  >
    <i className="in-blue-500 mr-16 icon icon--24 icon-note-preview" />
    <span className="va-middle">{i18n.t("overview.add_a_note")}</span>
  </button>
);

export default AddNoteLinkMobile;
