import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";
import { isNumber } from "../..";

export function SizeFilterTag({ filter }: { filter: types.FloatRangeFilter }) {
  const dispatch = useDispatch();
  const { max, min } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="size-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "SIZE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      Size{" "}
      {isNumber(min) && isNumber(max) ? (
        <>
          between ${(min / 1e6).toFixed(2)}m and ${(max / 1e6).toFixed(2)}m
        </>
      ) : isNumber(min) ? (
        <>&ge; ${(min / 1e6).toFixed(2)}m</>
      ) : isNumber(max) ? (
        <>&le; ${(max / 1e6).toFixed(2)}m</>
      ) : null}
    </Tag>
  );
}
