import PropTypes from "prop-types";
import React, { useState } from "react";
import ToggleEditModeButton from "../../presenters/heading/ToggleEditModeButton";
import AddNoteButtonPresenter from "../../presenters/heading/add_note_button_presenter.js.jsx";
import DiligenceReport from "Components/fund_report/Diligence/Diligence.component";

const InvestmentHeadingActions = ({
  investmentId,

  canGenerateReport,
  canAddNote,
}: {
  investmentId: number;

  canGenerateReport: boolean;
  canAddNote: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const menuClass = isOpen ? "invst-header__actions-dropdown-menu--open" : "";

  return (
    <div className="invst-header__col--right">
      <div className="text-right hidden-lg-down">
        <ToggleEditModeButton />

        {canGenerateReport && <DiligenceReport investmentId={investmentId} />}

        {canAddNote && <AddNoteButtonPresenter investmentId={investmentId} />}
      </div>

      <div className="p-relative mb-10 hidden-lg-up">
        {isOpen && (
          <div
            className="invst-header__actions-dropdown-underlay"
            onClick={handleMenuToggle}
          />
        )}
        <span
          className="invst-header__actions-dropdown-btn icon icon-ellipsis"
          onClick={handleMenuToggle}
        />

        <div
          className={`invst-header__actions-dropdown-menu ${menuClass}`}
          onClick={handleMenuToggle}
        >
          <AddNoteButtonPresenter investmentId={investmentId} />
        </div>
      </div>
    </div>
  );
};

export default InvestmentHeadingActions;
