import { ApolloError } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { DebouncedState } from "use-debounce";

const filterKeys = [
  "endDate",
  "fieldKey",
  "modifyUser",
  "ownerId",
  "ownerType",
  "owners",
  "showOnlyPublished",
  "startDate",
  "state",
  "text",
  "fieldKeys",
] satisfies (keyof types.FieldFilter)[];

// filters that are logically OR'd together (ie. fields for investment X or investment Y)
export type LogicalOrFilters = Pick<types.FieldFilter, "owners">;

export function isLogicalOrFilterKey(
  filterKey: keyof types.FieldFilter
): filterKey is keyof LogicalOrFilters {
  return filterKey === "owners";
}

/**
 * Type check to determine if value is a filter object (eg. { startDate: "2021-01-01" })
 * or a single element to be added to a logical OR array (eg. { id: "123", type: "investment", label: "Some Name" })
 */
export function isFieldFilterObject(
  value:
    | types.FieldFilter
    | NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number]
): value is types.FieldFilter {
  return Object.keys(value).every(key =>
    filterKeys.includes(key as keyof types.FieldFilter)
  );
}

export type FieldsIndexContextInterface = {
  fields?: types.FetchFieldsListQuery["fieldList"]["items"];
  error?: ApolloError;
  loading: boolean;
  investmentFilters?: types.InvestmentSearchFilters;
  fieldsFilters?: types.FieldFilter;
  fieldsSort?: types.FieldSort[];
  nextPage?: types.NextPage;
  updateFieldsFilter: (
    value:
      | types.FieldFilter
      | NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number],
    filterKey?: keyof LogicalOrFilters
  ) => void;
  updateFilterDebounced?: DebouncedState<(arg0: types.FieldFilter) => void>;
  removeFieldsFilter: (
    filterKey: keyof types.FieldFilter,
    value?: NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number]
  ) => void;
  fetchMore: () => void;
  updateFieldsSort: (arg0: types.FieldSort[]) => void;
  setSearchTerm: (arg0: string) => void;
  getSpreadsheetData?: () => Promise<
    Omit<types.FieldEntityWithMetadata, "documents">[]
  >;
};
