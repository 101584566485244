import { useQuery } from "@apollo/client";
import { Spinner } from "Components/show/spinner.js";
import * as Types from "_graphql-types/graphql";
import i18n from "i18next";
import React from "react";
import Chart from "./chart";
import ChartTicker from "./chart_ticker.jsx";
import { FETCH_TEAM_STRUCTURE } from "./graphql";

type noTypename<T> = T & { __typename: never };

const PRIMARY_GROUP = "total";
const GROUP_BREAKDOWN: (keyof noTypename<Types.FirmTeamStructure>)[] = [
  "trader",
  "analyst",
  "operations",
  "portfolioManager",
  "general",
  "admin",
  "legal",
  "investorRelations",
  "investment",
];

const t = (groupName: string) => i18n.t(`team_summary.${groupName}`);

function TeamStructure({ firmId }: { firmId: number }) {
  const { error, data, loading } = useQuery<{
    firmTeamStructure: Types.FirmTeamStructure;
  }>(FETCH_TEAM_STRUCTURE, {
    variables: { id: firmId },
  });

  if (error) {
    return <>error</>;
  }

  if (loading && !data) {
    return <Spinner />;
  }

  const firmTeamStructure = data?.firmTeamStructure;

  return (
    <div data-cy="team-structure-chart" className="team-structure">
      <p className="invt-tab__title">Team structure</p>

      <div className="d-flex">
        <ChartTicker
          isMain
          groupName={PRIMARY_GROUP}
          statistic={firmTeamStructure}
        />

        <div className="team-structure__chart-wrap">
          <Chart
            chartData={[
              {
                type: "pie",
                name: "Firm Team Structure",
                colorByPoint: true,
                data: [
                  { name: t("general"), y: firmTeamStructure?.general || 0 },
                  { name: t("trader"), y: firmTeamStructure?.trader || 0 },
                  { name: t("analyst"), y: firmTeamStructure?.analyst || 0 },
                  {
                    name: t("operations"),
                    y: firmTeamStructure?.operations || 0,
                  },
                  {
                    name: t("portfolioManager"),
                    y: firmTeamStructure?.portfolioManager || 0,
                  },
                  { name: t("admin"), y: firmTeamStructure?.admin || 0 },
                  { name: t("legal"), y: firmTeamStructure?.legal || 0 },
                  {
                    name: t("investorRelations"),
                    y: firmTeamStructure?.investorRelations || 0,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default TeamStructure;
