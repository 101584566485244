import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  RefreshingDocumentSignedUrl,
  pluralizeOwnerType,
} from "Helpers/utils.js";
import { Button, Empty } from "antd";
import { downloadFileLink } from "frontend/src/utils/helpers";
import { useEffect, useState } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import i18n from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../spinner.js.jsx";
import PdfViewerNavbar from "./navbar";
import PdfViewer from "./pdfViewer";
import { DOCUMENT_SHOW } from "Components/graphql/fragments/Document";
import { DOCUMENT_SHOW_QUERY } from "../graphql.js";

function PdfShowPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const [signedUrl, setSignedUrl] = useState<RefreshingDocumentSignedUrl>();
  const [documentParent, setDocumentParent] = useState<{
    type: "investment" | "firm" | "company" | "deal";
    id: number;
  } | null>(null);
  if (!documentId || !+documentId) {
    throw new Error("Invalid document ID");
  }
  useQuery(DOCUMENT_SHOW_QUERY, {
    variables: { id: +documentId },
    onCompleted: data => {
      if (data.document) {
        if (data.document.investment) {
          setDocumentParent({
            type: "investment",
            id: data.document.investment.id,
          });
        } else if (data.document.firm) {
          setDocumentParent({ type: "firm", id: data.document.firm.id });
        }
      }
    },
  });

  useEffect(() => {
    if (!documentId) return;
    const signedUrl = new RefreshingDocumentSignedUrl(
      +documentId,
      void 0,
      apolloClient
    );
    signedUrl.getActive(true).then(() => {
      setSignedUrl(signedUrl);
    });
    const document = apolloClient.readFragment({
      id: `Document:${documentId}`,
      fragment: DOCUMENT_SHOW,
    });
    console.log("DOCUMENT:", document);
    if (document) {
      if (document.investment) {
        setDocumentParent({ type: "investment", id: document.investment.id });
      } else if (document.firm) {
        setDocumentParent({ type: "firm", id: document.firm.id });
      }
    }
  }, [documentId]);

  if (!signedUrl) {
    return <Spinner />;
  }
  if (!signedUrl.current) {
    return (
      <div className="main-content">
        <div className="sub-header">
          <Empty description="Document Not Found" />
        </div>
      </div>
    );
  }
  if (signedUrl.contentType !== ".pdf") {
    navigate("/documents");
  }

  return (
    <div className="app-row" data-cy="PdfShowPage">
      <PdfViewerNavbar name={signedUrl.name ?? ""} />
      <div className="main-content main-content--lg">
        <div className="doc-show__header">
          <h1 className="doc-show__title">{signedUrl.name ?? ""}</h1>
          <br />

          <Button
            type="primary"
            shape="circle"
            icon={<DownloadOutlined />}
            data-cy="download-document-button"
            size="small"
            style={{ fontSize: 14, margin: "0 3px" }}
            onClick={() =>
              signedUrl.getActive().then(url => {
                if (url) downloadFileLink(url, signedUrl.name ?? "");
              })
            }
          />
          {documentParent && (
            <Button
              type="primary"
              icon={<LeftOutlined />}
              style={{ marginLeft: "auto" }}
              onClick={() =>
                navigate(
                  `/${pluralizeOwnerType(documentParent.type)}/${
                    documentParent.id
                  }`
                )
              }
            >
              Back to{" "}
              {i18n.t(`documents.ownerType.${documentParent.type}`, {
                defaultValue: documentParent.type,
              })}
            </Button>
          )}
        </div>
        <div className="doc-show__section">
          <PdfViewer signedUrl={signedUrl.current} />
        </div>
      </div>
    </div>
  );
}

export default PdfShowPage;
