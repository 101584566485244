import { gql } from "_graphql-types/gql";

export const GET_GLOBAL_INVESTMENT_TAGS = gql(/* GraphQL */ `
  query GetGlobalInvestmentTags($filter: TagFilterInput, $page: PageInput) {
    tagList(
      filter: $filter
      page: $page
      sort: [{ field: tagClassName, order: ASC }]
    ) {
      items {
        id
        name
        tagClass {
          id
          name
        }
        investmentCount
      }
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);
