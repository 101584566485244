import React from "react";
import * as types from "_graphql-types/graphql";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { isAuthorized } from "Components/Authorized/authHelpers";
import { Navigate } from "react-router-dom";

export type UserFlagCheck =
  keyof types.CurrentUserQuery["currentUser"]["flags"];

function Authorized({
  children,
  checks,
  redirect = false,
}: {
  children: React.ReactNode;
  checks: UserFlagCheck[];
  redirect?: boolean;
}): JSX.Element | null {
  const user = useCurrentUser();

  const isAuthed = isAuthorized({ user, checks });

  if (isAuthed) return <>{children}</>;

  if (redirect) return <Navigate to="/403" />;

  return null;
}

export default Authorized;
