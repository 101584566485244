import React, { useState } from "react";

import { HfInvestmentTermItem } from "./hf_investment_term_item";
import LoadingPanel from "../loading_panel";
import { HfGetShareClassesQuery } from "src/graphql-types/graphql";

export function HfInvestmentTermsList({
  shareClasses,
  loading,
}: {
  loading: boolean;
  shareClasses: HfGetShareClassesQuery["shareClassList"]["items"];
}) {
  const [isOpen, setIsOpen] = useState(false);

  const shareClassesToDisplay = isOpen
    ? shareClasses
    : shareClasses.slice(0, 1);

  function ShowMore() {
    if (shareClasses.length <= 1) return null;
    return (
      <div className="text-center">
        <button
          className="pt-15 pb-15 switch-btn"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "Show Less" : "Show More"}

          <span
            className={`switch-btn__icon icon icon--20 ${
              isOpen ? "icon-arrow-up" : "icon-arrow"
            }`}
          />
        </button>
      </div>
    );
  }

  return (
    <div id="investment_terms">
      <LoadingPanel loading={loading}>
        <div>
          <h3
            data-cy="fund-terms-heading"
            className="invt-tab__title"
            id="investment_terms"
          >
            Investment Terms
          </h3>

          <div className="overview-page__card pb-0">
            {shareClassesToDisplay.map(shareClass => (
              <HfInvestmentTermItem
                shareClass={shareClass}
                key={shareClass.id}
              />
            ))}
          </div>

          <ShowMore />
        </div>
      </LoadingPanel>
    </div>
  );
}
