import { CompanyTile } from "Components/CompanyTile";
import React from "react";

interface Props {
  companyIds: number[];
}

export function CompanyTiles({ companyIds }: Props) {
  return (
    <div className="main-tile-list">
      {companyIds.map(id => (
        <div key={id} className="main-tile-list__item">
          <CompanyTile id={id} />
        </div>
      ))}
    </div>
  );
}
