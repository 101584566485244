import * as types from "_graphql-types/graphql";
import {
  addToEntityFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { InvestmentFilter } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";

type SearchEntity =
  | types.SavedEntitySearchQuery["savedEntitySearch"]
  | types.InvestmentSetSearchQuery["investmentSet"];

function isInvestmentSet(
  searchEntity: SearchEntity
): searchEntity is types.InvestmentSetSearchQuery["investmentSet"] {
  return searchEntity?.__typename === "InvestmentSet";
}

export function loadInvestmentViewFiltersFromUserSettings(
  dispatch: ReturnType<typeof useDispatch>,
  searchSetting?: types.SearchSetting | null,
  searchEntity?: SearchEntity
) {
  if (!searchSetting) return;
  if (!searchEntity) return;

  const { type, id } = searchSetting;

  if (
    type === types.SettingEntityType.InvestmentSet &&
    isInvestmentSet(searchEntity)
  ) {
    dispatch(
      updateFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "INVESTMENT_SET",
            data: {
              investmentSetId: id,
            },
          },
        },
      })
    );
  }
  if (
    type === types.SettingEntityType.SavedEntitySearch &&
    !isInvestmentSet(searchEntity)
  ) {
    const investmentFilters: InvestmentFilter[] = JSON.parse(searchEntity.data);
    for (const investmentFilter of investmentFilters) {
      switch (investmentFilter.type) {
        case "investment":
          switch (investmentFilter.data.type) {
            case "FIRM_ENTITY":
            case "INVESTMENT_ENTITY":
            case "TAG_ENTITY":
              for (const entityKey of investmentFilter.data.data.values) {
                addToEntityFilter({
                  type: "investment",
                  data: {
                    type: investmentFilter.data.type,
                    data: entityKey,
                  },
                });
              }
              break;
            default:
              dispatch(
                updateFilter({
                  type: "investment",
                  data: investmentFilter,
                })
              );
          }
          break;
        default:
          dispatch(
            updateFilter({
              type: "investment",
              data: investmentFilter,
            })
          );
      }
    }
  }
}
