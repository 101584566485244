/* eslint-disable camelcase */
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import * as types from "_graphql-types/graphql";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import LoadingPanel from "Components/loading_panel";
import { PORTFOLIO_EXPOSURE } from "./graphql";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

export function PorfolioExposure({ investmentId }: { investmentId: number }) {
  const { data, error } = useQuery<
    types.Portfolio_ExposureQuery,
    types.Portfolio_ExposureQueryVariables
  >(PORTFOLIO_EXPOSURE, { variables: { investmentId } });

  const chartData = useMemo(() => {
    if (!data) return undefined;
    return [
      {
        name: "Long",
        type: "area",
        data: data.investment.long?.portfolio.map(
          ({ year, month, weight }) =>
            [new Date(year, month - 1).getTime(), weight] as [number, number]
        ),
      },
      {
        name: "Short",
        type: "area",
        data: data.investment.short?.portfolio.map(
          ({ year, month, weight }) =>
            [new Date(year, month - 1).getTime(), weight] as [number, number]
        ),
      },
      {
        name: "Gross",
        type: "line",
        data: data.investment.gross?.portfolio.map(
          ({ year, month, weight }) =>
            [new Date(year, month - 1).getTime(), weight] as [number, number]
        ),
      },
      {
        name: "Net",
        type: "line",
        data: data.investment.net?.portfolio.map(
          ({ year, month, weight }) =>
            [new Date(year, month - 1).getTime(), weight] as [number, number]
        ),
      },
    ];
  }, [data]);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "area",
        zoomType: "x",
      },
      title: {
        text: "Portfolio Exposure",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
        minTickInterval: 30 * 24 * 3600 * 1000,
      },
      yAxis: {
        labels: {
          format: "{value}%",
        },
        title: {
          enabled: false,
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        // eslint-disable-next-line max-len
        pointFormat: `<tr><td style="color:{series.color};padding:0">{series.name}: </td>${'<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>'}`,
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        area: {
          stacking: "normal",
        },
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          connectNulls: true,
        },
      },
      series: chartData,
    }),
    [chartData, data]
  );

  return (
    <div className="invt-tab__section overview-page__card">
      <LoadingPanel loading={!chartData} error={error}>
        <HighChartsReact
          constructorType="chart"
          options={options}
          highcharts={HighCharts}
        />
      </LoadingPanel>
    </div>
  );
}
