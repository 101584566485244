import { formatLargeCurrency } from "Helpers/index";
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import { Table as AntdTable } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import InvestmentLink from "Components/InvestmentLink";

interface Params {
  investments?: types.InvestmentPrivatePeersStatsQuery["investment"]["peersList"]["items"];
}

type PeerItemStats = {
  investment: {
    id: number;
    name?: string | null;
    isEntitled: boolean;
    vintageYear?: number | null;
    committedCapital?: number | null;
    committedCapitalCurrency?: types.CurrencyDenominationEnum | null;
    maxPerformanceYear?: number | null;
    maxPerformanceQuarter?: number | null;
  };
  netIRR?: number | null;
  tvpi?: number | null;
  dpi?: number | null;
  returnYear?: number | null;
  returnQuarter?: number | null;
};

function getTableColumns(data: PeerItemStats[]): ColumnsType<PeerItemStats> {
  const focusedInvestment = data[0];
  const nameFilters: {
    filterMode: ColumnType<PeerItemStats>["filterMode"];
    filters: ColumnType<PeerItemStats>["filters"];
    onFilter: ColumnType<PeerItemStats>["onFilter"];
  } = {
    filterMode: "tree",
    filters: data.slice(1, -1).map(datum => ({
      text: datum.investment.name,
      value: datum.investment.id,
    })),
    onFilter: (value, record: PeerItemStats) => {
      if (record.investment.id === focusedInvestment.investment.id) return true;
      return record.investment.id === value;
    },
  };

  return [
    {
      title: I18n.t("analytics.private_peers.name"),
      dataIndex: "investment",
      key: "investment",
      width: 85,
      render: (text, record) => {
        const { id, name, isEntitled } = record.investment;
        return (
          <InvestmentLink
            className="fund-link-wrapper"
            investmentId={id}
            isEntitled={isEntitled}
          >
            {name}
          </InvestmentLink>
        );
      },
      fixed: "left",
      ...nameFilters,
    },
    {
      title: I18n.t("analytics.private_peers.vintage_year"),
      dataIndex: "investment.vintageYear",
      key: "vintageYear",
      width: 30,
      sorter: (a, b) =>
        (a.investment.vintageYear ?? -Infinity) -
        (b.investment.vintageYear ?? -Infinity),
      render: (text, record) => <div>{record.investment.vintageYear}</div>,
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.size"),
      dataIndex: "investment.committedCapital",
      key: "size",
      width: 30,
      sorter: (a, b) =>
        (a.investment.committedCapital ?? -Infinity) -
        (b.investment.committedCapital ?? -Infinity),
      render: (text, record) => (
        <div>
          {formatLargeCurrency(
            record?.investment?.committedCapital,
            record?.investment?.committedCapitalCurrency?.name
          )}
        </div>
      ),
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.net_irr"),
      dataIndex: "netIRR",
      key: "netIRR",
      width: 30,
      sorter: (a, b) => (a.netIRR ?? -Infinity) - (b.netIRR ?? -Infinity),
      render: (text, record) => (
        <div>
          {typeof record.netIRR === "number" && `${record.netIRR.toFixed(1)}%`}
        </div>
      ),
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.tvpi"),
      dataIndex: "tvpi",
      key: "tvpi",
      width: 30,
      sorter: (a, b) => (a.tvpi ?? -Infinity) - (b.tvpi ?? -Infinity),
      render: (text, record) => (
        <div>
          {typeof record.tvpi === "number" && `${record.tvpi.toFixed(1)}x`}
        </div>
      ),
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.dpi"),
      dataIndex: "dpi",
      key: "dpi",
      width: 30,
      sorter: (a, b) => (a.dpi ?? -Infinity) - (b.dpi ?? -Infinity),
      render: (text, record) => (
        <div>
          {typeof record.dpi === "number" && `${record.dpi.toFixed(1)}x`}
        </div>
      ),
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.as_of_date"),
      key: "asOfDate",
      width: 50,
      render: (text, { returnQuarter, returnYear }) => (
        <div>
          {returnYear && returnQuarter ? `Q${returnQuarter} ${returnYear}` : ""}
        </div>
      ),
      align: "right",
    },
    {
      title: I18n.t("analytics.private_peers.max_performance_date"),
      key: "maxPerformanceDate",
      width: 50,
      render: (text, record) => (
        <div>
          {record.investment.maxPerformanceYear &&
          record.investment.maxPerformanceQuarter
            ? `Q${record.investment.maxPerformanceQuarter} ${record.investment.maxPerformanceYear}`
            : ""}
        </div>
      ),
      align: "right",
    },
  ];
}

const Table = ({ investments }: Params): JSX.Element => {
  const baseInvestmentId = investments?.[0]?.id;
  const data: PeerItemStats[] | null = useMemo(() => {
    if (!investments) return null;
    return investments.map(investment => ({
      investment: {
        id: investment.id,
        name: investment.name,
        isEntitled: !!investment.isEntitled,
        vintageYear: investment.vintageYear,
        committedCapital: investment.committedCapital,
        committedCapitalCurrency: investment.committedCapitalCurrency,
        ...(investment.performanceDataRange?.length && {
          maxPerformanceYear: investment.performanceDataRange[0].returnYear,
          maxPerformanceQuarter:
            investment.performanceDataRange[0].returnQuarter,
        }),
      },
      ...(investment.performancePrivate?.length && {
        netIRR: investment.performancePrivate[0].netIRR,
        tvpi: investment.performancePrivate[0].tvpi,
        dpi: investment.performancePrivate[0].dpi,
        returnYear: investment.performancePrivate[0].returnYear,
        returnQuarter: investment.performancePrivate[0].returnQuarter,
      }),
    }));
  }, [investments]);

  const columns = useMemo(() => getTableColumns(data || []), [data]);

  return (
    <AntdTable
      rowKey={record => record.investment.id}
      scroll={{ x: 1500 }}
      size="small"
      rowClassName={record =>
        (record.investment.id === baseInvestmentId && "bg-blue-grey-50") || ""
      }
      data-cy="peer-table-private"
      loading={!data}
      columns={columns}
      dataSource={data || []}
      pagination={false}
    />
  );
};
export default Table;
