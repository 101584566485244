import { setMainHeaderHiddenState } from "_sirius/src/actions/header_actions";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { heightValue } from "Selectors/header_selectors";
import { editMode } from "Selectors/investment_edit_mode_selectors";

class HeaderHandlerInEditMode extends Component {
  constructor(props) {
    super(props);

    this.minAnimatedScrollDistance = 5;
    this.headerAnimationInterval = 50;
    this.wasScrolledDown = false;

    this._updateHeaderHiddenState = this._updateHeaderHiddenState.bind(this);
    this._handleScroll = _.throttle(
      this._trackScrollDistance.bind(this, this._updateHeaderHiddenState),
      this.headerAnimationInterval
    );
  }

  componentDidMount() {
    this._setupScrollListener(this.props.editMode);
  }

  componentDidUpdate(nextProps) {
    this._setupScrollListener(nextProps.editMode);
  }

  componentWillUnmount() {
    this._removeScrollListener();
  }

  _setupScrollListener(editMode) {
    const { editMode: oldEditMode } = this.props;

    if (editMode !== oldEditMode) {
      editMode ? this._addScrollListener() : this._removeScrollListener();
    }
  }

  _addScrollListener() {
    window.addEventListener("scroll", this._handleScroll);
  }

  _removeScrollListener() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  _trackScrollDistance(handler = () => {}) {
    const initialScrollY = window.pageYOffset;

    setTimeout(() => {
      const newScrollY = window.pageYOffset;
      const scrollDistance = newScrollY - initialScrollY;

      handler(newScrollY, scrollDistance);
    }, this.headerAnimationInterval);
  }

  _updateHeaderHiddenState(scrollPosition, scrollDistance) {
    const { setHeaderHiddenState, headerHeight } = this.props;

    const isScrolledDown = scrollDistance > 0;

    const needToUpdateHeader = _.every([
      isScrolledDown !== this.wasScrolledDown, // direction changed
      isScrolledDown ? scrollPosition > headerHeight : true, // on animation area
      Math.abs(scrollDistance) > this.minAnimatedScrollDistance, // scrolled enough
    ]);

    if (needToUpdateHeader) {
      setHeaderHiddenState(isScrolledDown);
      this.wasScrolledDown = isScrolledDown;
    }

    if (scrollPosition === 0) {
      setHeaderHiddenState(false);
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  headerHeight: heightValue(state),
  editMode: editMode(state),
});

const mapDispatchToProps = dispatch => ({
  setHeaderHiddenState(state) {
    dispatch(setMainHeaderHiddenState(state));
  },
});

HeaderHandlerInEditMode.propTypes = {
  editMode: PropTypes.bool,
  setHeaderHiddenState: PropTypes.func.isRequired,
  headerHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

HeaderHandlerInEditMode.defaultProps = {
  editMode: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderHandlerInEditMode);
