/* eslint-disable max-lines-per-function */
import i18n from "i18next";
import { Modal, Select, Input, Checkbox, Spin } from "antd";
import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import { uniq, difference } from "lodash";
import * as types from "_graphql-types/graphql";
import { useDebounce } from "Helpers/hooks";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { gql } from "_graphql-types/gql";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { useQueryWithSkip } from "frontend/src/utils/graphql";
import {
  CREATE_INVESTMENT_SET,
  UPDATE_INVESTMENT_SET,
  FETCH_INVESTMENT_SET,
} from "./graphql";
import { CompanySetSelect } from "./CompanySetSelect";

const { TextArea } = Input;

enum ACCESS_LEVEL {
  Organization = 1,
  Group = 2,
  User = 3,
  Public = 4,
}

const { Option } = Select;

const FETCH_INVESTMENTS = gql(/* GraphQL */ `
  query getInvestments($filter: [InvestmentSearchFilters!]!) {
    investmentList(page: { limit: 100 }, searchFilters: $filter) {
      items {
        id
        name
      }
      total
      nextPage {
        offset
        limit
        hasMore
      }
    }
  }
`);

function InvestmentsInput({
  investmentIds,
  selectedList,
  updateForm,
}: {
  investmentIds: number[] | null | undefined;
  selectedList: types.InvestmentSetQuery["investmentSet"] | null | undefined;
  updateForm: ({ investmentIds }: { investmentIds: number[] }) => void;
}) {
  const [query, setQuery] = useState("");

  const filter = useMemo(() => {
    if (!query && investmentIds) {
      return [
        {
          INVESTMENT_ENTITY: {
            values: investmentIds.map(id => ({ id, label: `${id}` })),
          },
        },
      ];
    }
    return [
      {
        SEARCH_TERM: {
          value: query,
        },
      },
    ];
  }, [query, investmentIds]);

  const debouncedFilter = useDebounce(filter, 1000);

  const { data, loading } = useQuery(FETCH_INVESTMENTS, {
    variables: {
      filter: debouncedFilter,
    },
  });

  useEffect(() => {
    // prevent backspace from clearing investments
    if (global && global.document) {
      const handleBackspace = (e: any) => {
        if (e.keyCode === 8 && e.target.value === "") {
          e.stopPropagation();
        }
      };
      const selector =
        "#investment-set-select input.ant-select-selection-search-input";
      const element = global.document.querySelector(selector);
      if (element) element.addEventListener("keydown", handleBackspace);
      return () => window.removeEventListener("keydown", handleBackspace);
    }
    return () => {};
  }, []);

  const investmentSet = useMemo(() => new Set(investmentIds), [investmentIds]);

  const selectedListInvestmentIds = useMemo(
    () =>
      ((selectedList && selectedList.investmentSetItems) || []).map(
        ({ investmentId }: any) => investmentId
      ),
    [selectedList]
  );

  const investmentsToRemove = useMemo(
    () => difference(selectedListInvestmentIds, investmentIds || []).length,
    [selectedListInvestmentIds, investmentIds]
  );

  const investmentsToAdd = useMemo(
    () => difference(investmentIds || [], selectedListInvestmentIds).length,
    [selectedListInvestmentIds, investmentIds]
  );

  return (
    <div className="main-input" id="investment-set-select">
      <div className="main-input__label">
        Investments In List
        {` (${(investmentIds && investmentIds.length) || 0})`}
      </div>

      <Select
        mode="multiple"
        value={investmentIds || []}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%", height: "10px" }}
        className="investment-set-list-select"
        filterOption={false}
        onSearch={setQuery}
        maxTagCount={0}
        // should be value: number but the antd type is incorrect
        // https://github.com/ant-design/ant-design/issues/34497
        onSelect={(value: any) => {
          updateForm({ investmentIds: Array.from(investmentSet.add(value)) });
        }}
        onDeselect={(value: any) => {
          // only allow deselection for investments we have access to
          if (
            data?.investmentList.items.find(
              ({ id }: { id: number }) => id === value
            )
          ) {
            investmentSet.delete(value);
            updateForm({ investmentIds: Array.from(investmentSet) });
          }
        }}
        placeholder="Select Investments"
      >
        {data &&
          data.investmentList.items.map(({ name, id }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
      </Select>
      <div>{`Investments Added (${investmentsToAdd})`}</div>
      <div>{`Investments Removed (${investmentsToRemove})`}</div>
    </div>
  );
}

function DescriptionInput({
  description,
  updateForm,
}: {
  description: string | null | undefined;
  updateForm: ({ description }: { description: string }) => void;
}) {
  return (
    <div className="main-input">
      <div className="main-input__label">Description</div>
      <TextArea
        value={description || ""}
        rows={2}
        onChange={({ target: { value } }) => updateForm({ description: value })}
      />
    </div>
  );
}

function AccessLevelInput({
  accessLevelId,
  updateForm,
}: {
  accessLevelId: number;
  updateForm: ({ accessLevelId }: { accessLevelId: number }) => void;
}) {
  return (
    <div className="main-input">
      <div className="main-input__label">Access Level</div>

      <Select
        value={accessLevelId}
        onChange={(value: number) => updateForm({ accessLevelId: value })}
      >
        <Option value={ACCESS_LEVEL.Organization}>Organization</Option>
        <Option value={ACCESS_LEVEL.User}>User</Option>
      </Select>
    </div>
  );
}

const FETCH_USER_GROUPS = gql(/* GraphQL */ `
  query getUserGroups($currentUserId: Int!) {
    userList(filter: { isGroup: true, groupMembers: [$currentUserId] }) {
      items {
        id
        userName
      }
    }
  }
`);

function GroupSubscriptionInput({
  subscriptionGroupIds,
  updateForm,
  selectedList,
}: {
  subscriptionGroupIds: number[] | null | undefined;
  selectedList: types.InvestmentSetQuery["investmentSet"] | null;
  updateForm: ({
    subscriptionGroupIds,
  }: {
    subscriptionGroupIds: number[];
  }) => void;
}) {
  const currentUser = useCurrentUser();

  const { data, loading } = useQuery(FETCH_USER_GROUPS, {
    variables: {
      currentUserId: currentUser.id,
    },
  });

  const groupSet = useMemo(
    () => new Set(subscriptionGroupIds),
    [subscriptionGroupIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        Subscribed Groups
        {` (${(subscriptionGroupIds && subscriptionGroupIds.length) || 0})`}
      </div>

      <Select
        mode="multiple"
        value={subscriptionGroupIds || []}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        onSelect={(value: any) => {
          updateForm({ subscriptionGroupIds: Array.from(groupSet.add(value)) });
        }}
        onDeselect={(value: any) => {
          // only allow deselection for groups we are a member of.
          if (
            data?.userList.items.find(({ id }: { id: number }) => id === value)
          ) {
            groupSet.delete(value);
            updateForm({ subscriptionGroupIds: Array.from(groupSet) });
          }
        }}
        placeholder="Select Groups"
      >
        {data &&
          data.userList.items.map(({ userName, id }) => (
            <Option value={id} key={id}>
              {userName}
            </Option>
          ))}
      </Select>
    </div>
  );
}

function OrganizationSubscriptionInput({
  subscriptionOrganizationIds,
  updateForm,
}: {
  subscriptionOrganizationIds: number[] | null | undefined;
  updateForm: ({
    subscriptionOrganizationIds,
  }: {
    subscriptionOrganizationIds: number[];
  }) => void;
}) {
  const currentUser = useCurrentUser();

  const organizationSet = useMemo(
    () => new Set(subscriptionOrganizationIds),
    [subscriptionOrganizationIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        {`Organization Subscriptions (${
          (subscriptionOrganizationIds && subscriptionOrganizationIds.length) ||
          0
        })`}
      </div>
      <Checkbox
        checked={organizationSet.has(currentUser.organization.id)}
        onChange={() => {
          if (organizationSet.has(currentUser.organization.id)) {
            organizationSet.delete(currentUser.organization.id);
            updateForm({
              subscriptionOrganizationIds: Array.from(organizationSet),
            });
          } else {
            organizationSet.add(currentUser.organization.id);
            updateForm({
              subscriptionOrganizationIds: Array.from(organizationSet),
            });
          }
        }}
      >
        Subscribe my Organization
      </Checkbox>
    </div>
  );
}

function UserSubscriptionInput({
  subscriptionUserIds,
  updateForm,
}: {
  subscriptionUserIds: number[];
  updateForm: ({
    subscriptionUserIds,
  }: {
    subscriptionUserIds: number[];
  }) => void;
}) {
  const currentUser = useCurrentUser();

  const userSet = useMemo(
    () => new Set(subscriptionUserIds),
    [subscriptionUserIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        {`User Subscriptions (${subscriptionUserIds.length})`}
      </div>
      <Checkbox
        checked={userSet.has(currentUser.id)}
        onChange={() => {
          if (userSet.has(currentUser.id)) {
            userSet.delete(currentUser.id);
            updateForm({
              subscriptionUserIds: Array.from(userSet),
            });
          } else {
            userSet.add(currentUser.id);
            updateForm({
              subscriptionUserIds: Array.from(userSet),
            });
          }
        }}
      >
        Subscribe myself
      </Checkbox>
    </div>
  );
}

interface InvestmentSetFormProps {
  handleCancel: () => void;
  visible: boolean;
  selectedList?: types.InvestmentSetQuery["investmentSet"];
}

/* eslint max-lines-per-function: ["error", 200] */
function InvestmentSetForm({
  handleCancel,
  visible,
  selectedList: selectedListProp,
}: InvestmentSetFormProps) {
  const { selectedInvestmentIds, clearSelected } = useContext(
    InvestmentControlsContext
  );
  const currentUser = useCurrentUser();

  const defaultFormState = {
    name: "",
    description: "",
    accessLevelId: ACCESS_LEVEL.Organization,
    investmentIds: Array.from(selectedInvestmentIds) as number[],
    subscriptionOrganizationIds: [],
    subscriptionGroupIds: [],
    subscriptionUserIds: [currentUser.id],
  };

  const [formData, setFormData] =
    useState<types.InvestmentSetInput>(defaultFormState);

  const [selectedList, setSelectedList] = useState<
    types.InvestmentSetQuery["investmentSet"] | null
  >(selectedListProp || null);

  const isDynamic = !!selectedList?.isDynamic;

  const updateFormData = useCallback(
    (newData: any): any => {
      setFormData({ ...formData, ...newData });
    },
    [formData, setFormData]
  );

  useEffect(() => {
    if (!selectedList) {
      updateFormData({
        accessLevelId: defaultFormState.accessLevelId,
        subscriptionGroupIds: defaultFormState.subscriptionGroupIds,
        subscriptionUserIds: defaultFormState.subscriptionUserIds,
        subscriptionOrganizationIds:
          defaultFormState.subscriptionOrganizationIds,
        investmentIds: defaultFormState.investmentIds,
      });
    } else {
      updateFormData({
        name: selectedList.name,
        accessLevelId:
          selectedList.accessLevel?.id || defaultFormState.accessLevelId,
        description: selectedList.description,
        investmentIds: uniq([
          ...(formData.investmentIds || []),
          ...(selectedList?.investmentSetItems || []).map(
            ({ investmentId }: any) => investmentId
          ),
        ]),
        subscriptionGroupIds: uniq([
          ...(formData.subscriptionGroupIds || []),
          ...(selectedList?.groupSubscriptions || []).map(
            ({ group: { id } }: any) => id
          ),
        ]),
        subscriptionUserIds: uniq([
          ...(selectedList?.userSubscriptions || []).map(
            ({ user: { id } }: any) => id
          ),
        ]),
        subscriptionOrganizationIds: uniq([
          ...(formData.subscriptionOrganizationIds || []),
          ...(selectedList?.organizationSubscriptions || []).map(
            ({ organization: { id } }: any) => id
          ),
        ]),
      });
    }
  }, [selectedList]);

  const [addInvestmentSet, { error: addError, loading: isAdding }] =
    useMutation(CREATE_INVESTMENT_SET, {
      refetchQueries: ["subscribedLists"],
    });

  const [updateInvestmentSet, { error: updateError, loading: isUpdating }] =
    useMutation(UPDATE_INVESTMENT_SET, {
      refetchQueries: [
        "subscribedLists", // subscription change
        "fetchInvestmentSetInvestments", // list change
      ],
    });

  const errors = useMemo(() => {
    const accuErrors = [updateError, addError].filter(Boolean);

    if (accuErrors.length === 0) {
      return false;
    }
    return accuErrors;
  }, [updateError, addError]);

  const handleSubmit = async () => {
    try {
      if (selectedList) {
        await updateInvestmentSet({
          variables: {
            id: selectedList.id,
            input: formData,
          },
        });
      } else {
        await addInvestmentSet({
          variables: {
            input: formData,
          },
        });
      }

      clearSelected();
      handleCancel();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      title={
        selectedList
          ? i18n.t("funds.form.update_list_message")
          : i18n.t("funds.form.new_list_message")
      }
      okText={selectedList ? "Update" : "Create"}
      visible={visible}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !!isUpdating || !!isAdding }}
      onCancel={handleCancel}
    >
      <div id="add-fund-to-favorite-list">
        {errors && (
          <div
            id="favorite-list-name-error"
            className="info-message info-message--red"
          >
            <i className="info-message__icon icon icon-info" />
            <p>{JSON.stringify(errors)}</p>
          </div>
        )}

        <CompanySetSelect
          updateForm={updateFormData}
          name={formData.name ?? ""}
          setSelectedList={setSelectedList}
          selectedList={selectedList}
        />
        <DescriptionInput
          updateForm={updateFormData}
          description={formData.description}
        />
        {
          // don't let non creators change access level.
          (!selectedList ||
            selectedList?.createUser === currentUser.username) && (
            <AccessLevelInput
              updateForm={updateFormData}
              accessLevelId={formData.accessLevelId ?? ACCESS_LEVEL.Public}
            />
          )
        }
        {selectedList &&
          selectedList?.createUser.toLowerCase() !==
            (currentUser.username as string).toLowerCase() && (
            <div className="main-input">
              <div className="main-input__label">
                Only the creator of list may change access level:
              </div>
              {selectedList?.createUser}
            </div>
          )}
        {formData.accessLevelId === ACCESS_LEVEL.Organization && (
          <GroupSubscriptionInput
            updateForm={updateFormData}
            subscriptionGroupIds={formData.subscriptionGroupIds}
            selectedList={selectedList}
          />
        )}
        {formData.accessLevelId === ACCESS_LEVEL.Organization && (
          <OrganizationSubscriptionInput
            updateForm={updateFormData}
            subscriptionOrganizationIds={formData.subscriptionOrganizationIds}
          />
        )}
        <UserSubscriptionInput
          updateForm={updateFormData}
          subscriptionUserIds={formData.subscriptionUserIds ?? []}
        />
        {!isDynamic && (
          <InvestmentsInput
            updateForm={updateFormData}
            investmentIds={formData.investmentIds}
            selectedList={selectedList}
          />
        )}
        {isDynamic && "List is dynamically built."}
      </div>
    </Modal>
  );
}

InvestmentSetForm.defaultProps = {
  selectedList: undefined,
};

export function CompanySetForm({
  selectedListId,
  ...otherProps
}: {
  selectedListId?: number;
} & InvestmentSetFormProps) {
  const { data } = useQueryWithSkip(
    FETCH_INVESTMENT_SET,
    selectedListId ? { investmentSetId: selectedListId } : undefined
  );

  const selectedList = useMemo(() => {
    if (!data) return undefined;
    return data.investmentSet;
  }, [data]);

  if (selectedList || !selectedListId) {
    const forwardedProps = { ...otherProps, selectedList };
    return <InvestmentSetForm {...forwardedProps} />;
  }

  return null;
}

CompanySetForm.defaultProps = {
  selectedListId: undefined,
};
