import { handleLogout } from "_sirius/src/actions/action_helpers";
import i18n from "i18next";

export function ApolloProviderFallback() {
  return (
    <div>
      <button type="button" onClick={handleLogout}>
        {i18n.t("nav_bar.log_out")}
      </button>
      <div>
        Something went wrong while loading user. A deployment may be occurring,
        try refreshing in 60 seconds.
      </div>
    </div>
  );
}
