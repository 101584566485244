import React from "react";
import NoteAdd from "./noteAdd";
import { NotesIndex } from "Components/NotesIndex";

export function InvestmentNotesComponent({
  investmentId,
}: {
  investmentId: number;
}) {
  return (
    <div className="main-content main-content--lg">
      <NotesIndex notesFilters={{ investmentId }}>
        <NoteAdd investmentId={investmentId} />
      </NotesIndex>
    </div>
  );
}
