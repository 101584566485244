import { kebabCase } from "lodash";

enum BadgeColor {
  "equities" = "equities",
  "fixed-income" = "fixed-income",
  "growth-equity" = "growth-equity",
  "hedge-fund" = "hedge-fund",
  "portfolio" = "portfolio",
  "private" = "private",
  "private-credit" = "private-credit",
  "private-equity" = "private-equity",
  "real-asset" = "real-asset",
  "real-estate" = "real-estate",
  "traditional" = "traditional",
  "venture-capital" = "venture-capital",
  "default" = "default",
}

enum InvestmentType {
  Equities = "Equities",
  FixedIncome = "FixedIncome",
  GrowthEquity = "GrowthEquity",
  HedgeFund = "HedgeFund",
  Portfolio = "Portfolio",
  Private = "Private",
  PrivateCredit = "PrivateCredit",
  PrivateEquity = "PrivateEquity",
  RealAsset = "RealAsset",
  RealEstate = "RealEstate",
  Traditional = "Traditional",
  VentureCapital = "VentureCapital",
}

export function badgeColorClass(investmentType?: InvestmentType): string {
  return (
    "fund-type--" +
    ((investmentType && (kebabCase(investmentType) as BadgeColor)) || "default")
  );
}
