import React, { useContext, useCallback, useState } from "react";
import i18n from "i18next";
import { CompanySetForm } from "Components/CompanySetForm";
import { CompanyControlsContext } from "./context";

export function CompanyControlsActions() {
  const { selectedCompanyIds, clearSelected } = useContext(
    CompanyControlsContext
  );

  const [isAddToListVisible, setIsAddToListVisible] = useState(false);
  const [isRemoveFromListVisible, setIsRemoveFromListVisible] = useState(false);

  const showAddToList = () => {
    setIsAddToListVisible(true);
  };

  const handleCancel = () => {
    setIsAddToListVisible(false);
    setIsRemoveFromListVisible(false);
  };

  const clearSelection = useCallback(() => {
    clearSelected();
  }, [clearSelected]);

  return (
    <div>
      {selectedCompanyIds.size > 0 && (
        <>
          <div className="main-gf__specific-actions main-select-actions">
            <div className="main-select-actions__item main-select-actions__item--passive">
              <span
                className="main-select-actions__icon icon icon-checkbox-minus"
                onClick={clearSelection}
              />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.selected_amount", {
                  amount: `${selectedCompanyIds.size}`,
                })}
              </span>
            </div>

            <div
              id="add-to-list-action"
              className="main-select-actions__item"
              onClick={showAddToList}
            >
              <span className="main-select-actions__icon icon icon-plus" />

              <span className="main-select-actions__text">
                {i18n.t("selected_fund_actions.add_to_list")}
              </span>
            </div>
          </div>

          {isAddToListVisible && (
            <CompanySetForm
              handleCancel={handleCancel}
              visible={isAddToListVisible}
            />
          )}
        </>
      )}
    </div>
  );
}
