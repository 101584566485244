import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export default function AccessFilterTag({
  filter,
}: {
  filter: types.EntityAccessSearchFilter;
}) {
  const dispatch = useDispatch();
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="access-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "ACCESS",
                data: { values: [] },
              },
            },
          })
        );
      }}
      closable
    >
      {`Read Access`}
    </Tag>
  );
}
