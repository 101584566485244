import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { AutoComplete } from "antd";
import i18n from "i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { FilterInterface } from "../FilterInterface";
import { ADDRESS_SEARCH } from "../graphql";

function onlyUnique(location: any, index: number, self: any[]) {
  return (
    index ===
    self.findIndex(
      t =>
        t.country === location.country &&
        t.countryEnumId === location.countryEnumId &&
        t.state === location.state &&
        t.stateEnumId === location.stateEnumId
    )
  );
}

const locationText = (location: any) =>
  [location.city, location.state, location.country]
    .filter((v: string | null) => v)
    .join(", ");

const options = (locations?: types.AddressSearchQuery["addressSearch"]) => {
  if (!locations) {
    return [];
  }
  const countryOnly = locations
    .map(location => ({
      country: location.country,
      countryEnumId: location.countryEnumId,
    }))
    .filter(onlyUnique);
  const countryState = locations
    .map(location => ({
      state: location.state,
      stateEnumId: location.stateEnumId,
    }))
    .filter(location => location.state)
    .filter(onlyUnique);

  return [...locations, ...countryOnly, ...countryState].filter(onlyUnique);
};

function HeadQuartersFilter() {
  const investmentFilters = useSelector(getInvestmentFilters);

  const dispatch = useDispatch();

  const headquartersFilter: types.LocationFilter | undefined = useMemo(() => {
    for (const filter of investmentFilters) {
      if (filter.type === "firm" && filter.data.type === "HEADQUARTERS")
        return filter.data.data;
    }
  }, [investmentFilters]);

  const defaultFilter = {
    countryEnum: null,
    stateEnum: null,
    city: null,
  };

  const [localFilter, setLocalFilter] = useState(
    headquartersFilter ?? defaultFilter
  );

  const locationSelect = (v: any, option: any) => {
    const record = option.location;
    setLocalFilter({
      countryEnum: record.countryEnumId,
      stateEnum: record.stateEnumId,
      city: record.city,
    });
  };

  const [query, setQuery] = useState("");

  const { data } = useQuery(ADDRESS_SEARCH, {
    variables: { q: query },
    skip: query.length < 4,
  });

  const set = () => {
    if (localFilter.countryEnum || localFilter.stateEnum || localFilter.city)
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "firm",
            data: {
              type: "HEADQUARTERS",
              data: localFilter,
            },
          },
        })
      );
  };

  const reset = () => {
    setQuery("");
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "firm",
          data: {
            type: "HEADQUARTERS",
            data: defaultFilter,
          },
        },
      })
    );
  };

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.location")}
      count={headquartersFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        <AutoComplete
          data-cy="Headquarters-Auto-Complete"
          onSelect={locationSelect}
          onChange={(v: any) => {
            setQuery(v);
          }}
          options={options(data?.addressSearch).map(
            (location: types.AddressSearchItem) => ({
              label: locationText(location),
              value: locationText(location),
              location,
            })
          )}
        />
      </div>
    </FilterInterface>
  );
}

export default HeadQuartersFilter;
