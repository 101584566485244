import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export interface Props {
  filter: types.PortfolioSearchFilter;
}

function PortfolioFilterTag({ filter }: Props) {
  const dispatch = useDispatch();

  const { isPortfolio } = filter;
  if (!isPortfolio) return null;

  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="search-term-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "PORTFOLIO",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {isPortfolio && "Portfolios"}
    </Tag>
  );
}

export default PortfolioFilterTag;
