import { useQuery } from "@apollo/client";
import {
  GET_GLOBAL_SEARCH_INVESTMENT,
  GET_GLOBAL_SEARCH_COMPANY,
  GET_GLOBAL_SEARCH_FIRM,
} from "Components/GlobalSearch/GlobalSearchEntityDropdown/graphql";
import { unhandledCase } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useMemo, useState } from "react";
import {
  GetGlobalSearchInvestmentQuery,
  GetGlobalSearchCompanyQuery,
  GetGlobalSearchFirmQuery,
} from "src/graphql-types/graphql";
import { AutoComplete, AutoCompleteProps, Spin } from "antd";
import { useDebounce } from "use-debounce";
import { DefaultOptionType } from "rc-select/lib/Select";
import i18n from "i18next";

function renderGroupTitle(
  key: keyof FieldOwnerSearchResults,
  loading: boolean
) {
  const categoryLabelKey = (key: keyof FieldOwnerSearchResults) => {
    switch (key) {
      case "investment":
        return "investments";
      case "company":
        return "company";
      case "firm":
        return "firms";
      default:
        throw unhandledCase(key);
    }
  };
  return (
    <span>
      <span>{i18n.t(`global_search.groups.${categoryLabelKey(key)}`)}</span>
      {!loading ? null : (
        <span>
          {" "}
          <Spin size="small" />
        </span>
      )}
    </span>
  );
}

type FieldOwnerSearchResults = {
  investment: {
    loading: boolean;
    data?: GetGlobalSearchInvestmentQuery["investment"];
  };

  firm: {
    loading: boolean;
    data?: GetGlobalSearchFirmQuery["firm"];
  };

  company: {
    loading: boolean;
    data?: GetGlobalSearchCompanyQuery["company"];
  };
};

type FieldOwnerSearchItem = NonNullable<
  FieldOwnerSearchResults[keyof FieldOwnerSearchResults]["data"]
>["items"][number];

function renderItem(item: FieldOwnerSearchItem, ownerType: string) {
  return {
    value: item.name,
    label: <>{item.name}</>,
    data: { id: item.id, name: item.name, ownerType },
  };
}

function FieldsOwnerAutocomplete({
  addOwner,
  placeholder = "Search by owner",
}: {
  addOwner: (data: { id: number; name: string; ownerType: string }) => void;
  placeholder?: string;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  const queries = {
    investment: useQuery(GET_GLOBAL_SEARCH_INVESTMENT, {
      skip: !debouncedSearchTerm,
      variables: {
        searchFilters: [
          {
            SEARCH_TERM: {
              value: debouncedSearchTerm,
            },
          },
          {
            PORTFOLIO: {
              isPortfolio: false,
            },
          },
        ],
      },
    }),
    company: useQuery(GET_GLOBAL_SEARCH_COMPANY, {
      skip: !debouncedSearchTerm,
      variables: {
        companyFilters: [
          {
            NAME: debouncedSearchTerm,
          },
        ],
      },
    }),
    firm: useQuery(GET_GLOBAL_SEARCH_FIRM, {
      skip: !debouncedSearchTerm,
      variables: {
        firmFilter: {
          q: debouncedSearchTerm,
        },
      },
    }),
  };

  const getResult = (key: keyof typeof queries) => {
    const { loading, data } = queries[key];
    switch (key) {
      case "investment":
        return {
          loading,
          data: (data as GetGlobalSearchInvestmentQuery)?.investment,
        };
      case "company":
        return {
          loading,
          data: (data as GetGlobalSearchCompanyQuery)?.company,
        };
      case "firm":
        return {
          loading,
          data: (data as GetGlobalSearchFirmQuery)?.firm,
        };
      default:
        throw unhandledCase(key);
    }
  };

  const options = useMemo(
    () => {
      const results = {
        investment: getResult(
          "investment"
        ) as FieldOwnerSearchResults["investment"],
        company: getResult("company") as FieldOwnerSearchResults["company"],
        firm: getResult("firm") as FieldOwnerSearchResults["firm"],
      };

      const _options = Object.keys(results).reduce((acc, key) => {
        const { loading, data } = results[key as keyof typeof results];
        const groupTitle = renderGroupTitle(
          key as keyof FieldOwnerSearchResults,
          loading
        );

        const items = data?.items.map(item => renderItem(item, key)) || [];

        if (items.length === 0) return acc;

        return [
          ...(acc ?? []),
          {
            label: groupTitle,
            options: items,
          },
        ];
      }, [] as AutoCompleteProps["options"]);
      return _options;
    },
    Object.values(queries).map(({ data }) => data)
  );

  return (
    <AutoComplete
      placeholder={placeholder}
      id="addFieldOwnerSearch"
      data-testid="addFieldOwnerSearch"
      dropdownMatchSelectWidth={252}
      style={{ width: 300 }}
      onSearch={value => setSearchTerm(value)}
      onSelect={(_value: string | number, option: DefaultOptionType) => {
        addOwner(option.data);
        setSearchTerm("");
      }}
      options={options}
      value={searchTerm}
      data-cy="FieldOwner-Add-Search"
    />
  );
}

export default FieldsOwnerAutocomplete;
