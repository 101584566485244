import { useQuery } from "@apollo/client";
import { Authorized } from "Components/Authorized";
import { NOTES_SEARCH } from "Components/NotesIndex/graphql";
import { DOCUMENTS_SEARCH } from "Components/show/documents/graphql";
import * as types from "_graphql-types/graphql";
import classnames from "classnames";
import { useCurrentUser } from "frontend/src/utils/hooks";
import i18n from "i18next";
import { Link, useLocation } from "react-router-dom";
import { useGlobalCompanySearch } from "./CompanySearch.hooks";
import { COMPANY_PATHS } from "./GlobalSearchControls.component";
import {
  useGlobalInvestmentSearch,
  usePortfolioSearch,
} from "./InvestmentSearch.hooks";
import { COMPANY_SEARCH } from "./graphql";

function Tab({
  count,
  name,
  path,
}: {
  count?: number;
  name: string;
  path: string;
}) {
  const location = useLocation();

  return (
    <li
      className={classnames("main-tabs__tab", {
        "main-tabs__tab--active": path === location.pathname,
        company: COMPANY_PATHS.includes(location.pathname),
      })}
      data-cy={`${name}-tab`}
    >
      <Link to={path} style={{ color: "inherit" }}>
        {i18n.t(`category_tabs.${name}.title`, {
          count,
        })}
      </Link>
    </li>
  );
}

function Portfolios() {
  const { result } = usePortfolioSearch();
  if (!result?.total) return null;
  return <Tab count={result?.total} name="portfolios" path="/portfolios" />;
}

function InvestmentSearchTotals() {
  const { result } = useGlobalInvestmentSearch();
  return <Tab count={result?.total} name="investments" path="/investments" />;
}

function CompanySearchTotals() {
  const { variables } = useGlobalCompanySearch();

  const { data: companiesData } = useQuery<{ companyList: types.CompanyList }>(
    COMPANY_SEARCH,
    {
      variables: {
        searchFilters: variables.searchFilters,
        sort: [
          {
            field: "latestUpdate",
            order: types.SortInput.Desc,
          },
        ],
        page: {
          limit: 15,
          offset: 0,
        },
      },
    }
  );

  return (
    <>
      <Tab
        count={companiesData?.companyList.total}
        name="companies"
        path="/company"
      />
    </>
  );
}

function NoteSearchTotals() {
  const {
    variables: {
      searchFilters: investmentFilters,
      firmSearchFilters: firmFilters,
    },
  } = useGlobalInvestmentSearch();
  const {
    variables: { searchFilters: companyFilters },
  } = useGlobalCompanySearch();

  const { data: notesData } = useQuery<{
    notesList: types.NotesList;
  }>(NOTES_SEARCH, {
    variables: {
      filters: {
        investmentFilters,
        companyFilters,
        firmFilters,
      },
      sort: [
        {
          field: "id",
          order: types.SortInput.Desc,
        },
      ],
      page: {
        limit: 15,
        offset: 0,
      },
    },
  });

  return <Tab count={notesData?.notesList.total} name="notes" path="/notes" />;
}

function DocumentSearchTotals() {
  const {
    variables: {
      searchFilters: investmentFilters,
      firmSearchFilters: firmFilters,
    },
  } = useGlobalInvestmentSearch();
  const {
    variables: { searchFilters: companyFilters },
  } = useGlobalCompanySearch();

  const { clientView } = useCurrentUser();

  const { data: documentsData } = useQuery<{ documents: types.DocumentList }>(
    DOCUMENTS_SEARCH,
    {
      variables: {
        investmentFilters,
        firmFilters,
        companyFilters,
        ...(clientView && { accessLevel: 4 }),
        page: { limit: 0, offset: 0 },
        sort: {
          field: types.DocumentSortEnum.Date,
          order: types.SortInput.Desc,
        },
      },
    }
  );

  return (
    <Tab
      count={documentsData?.documents.total}
      name="documents"
      path="/documents"
    />
  );
}

function ChatTab() {
  return <Tab name="chat" path="/chat" />;
}

function DiligenceTab() {
  return <Tab name="diligence" path="/diligenceSearch" />;
}

function GlobalSearchTotals() {
  return (
    <div className="main-gf__categories-tabs-wrap">
      <div className="main-gf__categories-tabs">
        <ul className="pt-4 horizontal-scroll">
          <Portfolios />
          <Authorized checks={["investmentPageAccess"]}>
            <InvestmentSearchTotals />
          </Authorized>
          <Authorized checks={["isRockCreek"]}>
            <CompanySearchTotals />
          </Authorized>
          <NoteSearchTotals />
          <DocumentSearchTotals />
          <Authorized checks={["isRockCreek"]}>
            <DiligenceTab />
          </Authorized>
          <Authorized checks={["canSiriusChat"]}>
            <ChatTab />
          </Authorized>
        </ul>
      </div>
    </div>
  );
}

export default GlobalSearchTotals;
