import React, { Component } from "react";
import { Popover } from "antd";
import i18n from "i18next";
import config from "../config/config";

type CreationMenuState = {
  visible: boolean;
};

class CreationMenu extends Component<unknown, CreationMenuState> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      visible: false,
    };

    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
  }

  handleVisibleChange(visible: boolean) {
    this.setState({ visible });
  }

  hide() {
    this.handleVisibleChange(false);
  }

  renderContent() {
    return (
      <div onClick={this.hide} className="add-popup-dropdown">
        <p className="add-popup-dropdown__title">
          {i18n.t("creation_menu.title")}
        </p>

        <a
          href={`${config.RCG_DATA_COLLECTION_URI()}/firm/create`}
          target="_blank"
          rel="noopener noreferrer"
          className="add-popup-dropdown__item"
        >
          <span className="add-popup-dropdown__item-icon icon icon-fund" />
          <span className="add-popup-dropdown__item-text">
            {i18n.t("creation_menu.firm")}
          </span>
        </a>

        <a
          href={`${config.RCG_DATA_COLLECTION_URI()}/investment/create`}
          target="_blank"
          rel="noopener noreferrer"
          className="add-popup-dropdown__item"
        >
          <span className="add-popup-dropdown__item-icon icon icon-columns" />
          <span className="add-popup-dropdown__item-text">
            {i18n.t("creation_menu.investment")}
          </span>
        </a>

        <a
          href={`${config.RCG_DATA_COLLECTION_URI()}/company/create`}
          target="_blank"
          rel="noopener noreferrer"
          className="add-popup-dropdown__item"
        >
          <span className="add-popup-dropdown__item-icon icon icon-fund" />
          <span className="add-popup-dropdown__item-text">
            {i18n.t("creation_menu.company")}
          </span>
        </a>

        <a
          href={`${config.RCG_DATA_COLLECTION_URI()}/document/create`}
          target="_blank"
          rel="noopener noreferrer"
          className="add-popup-dropdown__item"
        >
          <span className="add-popup-dropdown__item-icon icon icon-docs" />
          <span className="add-popup-dropdown__item-text">
            {i18n.t("creation_menu.document")}
          </span>
        </a>
        <p className="add-popup-dropdown__item cursor-not-allowed">
          <span className="add-popup-dropdown__item-icon icon icon-team" />
          <span className="add-popup-dropdown__item-text">
            {i18n.t("creation_menu.person")}
          </span>
        </p>
      </div>
    );
  }

  render(): JSX.Element {
    const { visible } = this.state;

    return (
      <Popover
        placement="bottomRight"
        trigger="click"
        overlayClassName="nav-list"
        open={visible}
        onOpenChange={this.handleVisibleChange}
        getPopupContainer={triggerNode => triggerNode}
        content={this.renderContent()}
      >
        <div
          className="main-navbar__btn main-popup-dropdown"
          data-cy="creation-menu"
        >
          <span className="main-navbar__btn--add-icon icon icon-plus icon--28" />
        </div>
      </Popover>
    );
  }
}

export default CreationMenu;
