import React from "react";
import i18n from "i18next";

interface Props {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const AddNoteLink = ({ onClick, className }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className={`rounded-btn rounded-btn--white-transparent ml-15 mb-10 ${className}`}
  >
    <i className="rounded-btn--icon-icon icon icon-note-preview font-12" />

    <span className="rounded-btn--icon-txt">
      {i18n.t("overview.add_a_note")}
    </span>
  </button>
);

AddNoteLink.defaultProps = {
  className: "",
};

export default AddNoteLink;
