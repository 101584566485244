import { useContext } from "react";
import { DefaultTileBody } from "./defaultTileBody";
import { PortfolioTileBody } from "./portfolioTileBody";
import { InvestmentTileContext } from "../context";

export function InvestmentTileBody() {
  const { investment } = useContext(InvestmentTileContext);

  if (investment.isPortfolio) {
    return <PortfolioTileBody />;
  }
  return <DefaultTileBody />;
}
