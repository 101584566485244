import { gql } from "_graphql-types/gql";

export const AGENT_CHAT = gql(/* GraphQL */ `
  query AgentChat(
    $question: String!
    $conversationId: Int
    $chatContext: ChatContext
    $tools: [ChatToolType!]
    $sessionId: String!
  ) {
    chat(
      question: $question
      conversationId: $conversationId
      chatContext: $chatContext
      tools: $tools
      sessionId: $sessionId
    ) {
      answer
      conversationId
      tools {
        tool
        params
        results
      }
    }
  }
`);

export const CONTEXTUAL_CHAT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription ContextualChatSubscription($sessionId: String!) {
    contextualChatSubscription(sessionId: $sessionId) {
      answer
      conversationId
      tools {
        tool
        params
        results
      }
      sessionId
      messageStream {
        message
        messageIndex
        contentType
        blockIndex
        event
        id
      }
    }
  }
`);
