import React, { useMemo, useRef, useState } from "react";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts/highstock";
// Note that Highcharts has to be in the codebase already
// Highcharts more

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import { useDebouncedCallback } from "use-debounce";
import { growthOf1000InBounds } from "./recompute";
import { Bounds, SeriesData } from "../helpers";

HighCharts.setOptions({ lang: { thousandsSep: "," } });

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

// eslint-disable-next-line max-lines-per-function
function GrowthChart({ chartData }: { chartData?: SeriesData[] }): JSX.Element {
  const chartComponentRef = useRef<HighChartsReact.RefObject>(null);

  const [bounds, setBounds] = useState<Bounds>(null);

  const afterSetExtremes = useDebouncedCallback(() => {
    if (chartComponentRef.current) {
      setBounds({
        min: chartComponentRef.current.chart.xAxis[0].min ?? null,
        max: chartComponentRef.current.chart.xAxis[0].max ?? null,
      });
    }
  }, 100);

  const computedChartData = useMemo(() => {
    if (!chartData) return chartData;
    const clonedChartData = [...chartData];

    const rootDatum = clonedChartData.shift();
    if (!rootDatum) return [];

    const rootChartData = {
      name: rootDatum.name,
      data: growthOf1000InBounds(rootDatum.data, bounds),
    };

    return [
      rootChartData,
      ...clonedChartData.map(chartDatum => ({
        name: chartDatum.name,
        data: growthOf1000InBounds(chartDatum.data, bounds, rootChartData.data),
      })),
    ];
  }, [chartData, bounds]);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      rangeSelector: {
        selected: 4,
        buttons: [
          {
            type: "month",
            count: 6,
            text: "6m",
            title: "View 6 months",
          },
          {
            type: "year",
            count: 1,
            text: "1y",
            title: "View 1 year",
          },
          {
            type: "year",
            count: 3,
            text: "3y",
            title: "View 3 year",
          },
          {
            type: "all",
            text: "All",
            title: "View all",
          },
        ],
      },
      chart: {
        marginBottom: 100,
        zoomType: "x",
      },
      legend: {
        enabled: true,
        align: "center",
        x: 0,
        y: 0,
      },
      xAxis: {
        events: {
          afterSetExtremes,
        },
      },
      yAxis: {
        plotLines: [
          {
            value: 0,
            width: 2,
            color: "silver",
          },
        ],
      },
      tooltip: {
        pointFormat:
          // eslint-disable-next-line max-len
          '<span style="color:{series.color};z-index:99999">{series.name}</span>: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 2,
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      series: computedChartData,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              exporting: {
                enabled: false,
              },
              tooltip: {
                pointFormat:
                  '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:,.0f}</b>',
                valueDecimals: 2,
                split: true,
                xDateFormat: "%Y-%m-%d",
              },
              legend: {
                enabled: false,
              },
              rangeSelector: {
                selected: null,
                buttons: [],
              },
              chart: {
                height: 275,
                marginBottom: 0,
              },
              subtitle: {
                text: null,
              },
              navigator: {
                enabled: false,
              },
            },
          },
        ],
      },
    }),
    [computedChartData]
  );

  console.log(computedChartData);

  return (
    <HighChartsReact
      constructorType="stockChart"
      options={options}
      highcharts={HighCharts}
      ref={chartComponentRef}
    />
  );
}

export default GrowthChart;
