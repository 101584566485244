import React from "react";
import NavHeader from "Components/layout/NavHeader";
import classNames from "classnames";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import ToggleEditModeButtonComponent from "../../presenters/heading/ToggleEditModeButton/ToggleEditModeButton.component";
import { FIRM_HEADER } from "./graphql";

function FirmNavBar({ firmId }: { firmId: number }) {
  const { data } = useQuery(FIRM_HEADER, {
    variables: {
      id: firmId,
    },
  });

  const navigate = useNavigate();

  if (!data || !data.firm) return null;

  return (
    <NavHeader>
      <div className="invst-header">
        <div className="invst-header__col--left">
          <div className={classNames("invst-header__title-wrap")}>
            <i
              className="invst-header__back-icon icon icon-back icon--24"
              onClick={() => navigate(-1)}
            />

            <div className="invst-header__title">
              <div id="investment-name" className="invst-header__title-txt">
                {data.firm.name}
              </div>
            </div>
          </div>

          {data.firm.isStaffMWDBE && (
            <div className="fund-tile__market-flag-wrap">
              <div className="fund-tile__market-flag d-inline-block mr-8 p-relative">
                MWDBE
              </div>
            </div>
          )}
        </div>

        <div className="invst-header__col--right">
          <div className="text-right hidden-lg-down">
            <ToggleEditModeButtonComponent />
            <span className="no-reports__edit-toggle-spacer" />
          </div>
        </div>
      </div>
    </NavHeader>
  );
}

export default FirmNavBar;
