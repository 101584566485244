/* eslint-disable max-lines-per-function */
import { Table } from "antd";
import LoadingPanel from "Components/loading_panel";
import { formatStatValue } from "Helpers/investment_helpers";
import { DeepRequired } from "Helpers/types";
import React, { useContext, useMemo } from "react";
import { RelativePerformanceContext } from "./context";

const columns: Parameters<typeof Table>[0]["columns"] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "MTD",
    dataIndex: "compoundReturnMtd",
    key: "compoundReturnMtd",
  },
  {
    title: "QTD",
    dataIndex: "compoundReturnQtd",
    key: "compoundReturnQtd",
  },
  {
    title: "YTD",
    dataIndex: "compoundReturnYtd",
    key: "compoundReturnYtd",
  },
  {
    title: "6M",
    dataIndex: "compoundReturn6m",
    key: "compoundReturn6m",
  },
  {
    title: "1Y",
    dataIndex: "compoundReturn1y",
    key: "compoundReturn1y",
  },
  {
    title: "3Y",
    dataIndex: "compoundReturn3y",
    key: "compoundReturn3y",
  },
  {
    title: "5Y",
    dataIndex: "compoundReturn5y",
    key: "compoundReturn5y",
  },
  {
    title: "ITD",
    dataIndex: "compoundReturnItd",
    key: "compoundReturnItd",
  },
];

type DataType = {
  id?: number;
  name?: string;
  compoundReturnYtd?: number | string | null;
  compoundReturnMtd?: number | string | null;
  compoundReturnQtd?: number | string | null;
  compoundReturn6m?: number | string | null;
  compoundReturn1y?: number | string | null;
  compoundReturn3y?: number | string | null;
  compoundReturn5y?: number | string | null;
  compoundReturnItd?: number | string | null;
};

function BenchmarkReturnTable(): JSX.Element {
  const { investment, loading } = useContext(RelativePerformanceContext);

  const chartData: DataType[] | null = useMemo(() => {
    if (!investment) return null;
    const { stats } = investment.groupStats as DeepRequired<
      typeof investment.groupStats
    >;
    const result = investment.groupStats.items.map(({ id, name }, i) => ({
      id,
      name,
      compoundReturnYtd: formatStatValue(stats.compoundReturnYtd[i]),
      compoundReturnMtd: formatStatValue(stats.compoundReturnMtd[i]),
      compoundReturnQtd: formatStatValue(stats.compoundReturnQtd[i]),
      compoundReturn6m: formatStatValue(stats.compoundReturn6m[i]),
      compoundReturn1y: formatStatValue(stats.compoundReturn1y[i]),
      compoundReturn3y: formatStatValue(stats.compoundReturn3y[i]),
      compoundReturn5y: formatStatValue(stats.compoundReturn5y[i]),
      compoundReturnItd: formatStatValue(stats.compoundReturnItd[i]),
    }));
    return result;
  }, [investment]);

  return (
    <>
      <div className="summary-heading">
        <h3 className="invt-tab__title" id="benchmark_returns">
          {I18n.t("overview.benchmark_returns")}
        </h3>
      </div>
      <LoadingPanel loading={loading}>
        <Table
          columns={columns}
          dataSource={chartData ?? []}
          pagination={false}
          className="mb-24"
        />
      </LoadingPanel>
    </>
  );
}

export default BenchmarkReturnTable;
