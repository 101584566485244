import { gql } from "_graphql-types/gql";

export const GET_GLOBAL_SEARCH_INVESTMENT = gql(/* GraphQL */ `
  query GetGlobalSearchInvestment($searchFilters: [InvestmentSearchFilters!]) {
    investment: investmentList(
      page: { limit: 15, offset: 0 }
      sort: [{ field: nameSearchRank, order: ASC }]
      searchFilters: $searchFilters
    ) {
      items {
        id
        name
        firm {
          id
          name
        }
        isPortfolio
      }
    }
  }
`);

export const GET_GLOBAL_SEARCH_COMPANY = gql(/* GraphQL */ `
  query GetGlobalSearchCompany($companyFilters: [CompanySearchFilters!]) {
    company: companyList(
      page: { limit: 15, offset: 0 }
      sort: [{ field: nameSearchRank, order: ASC }]
      searchFilters: $companyFilters
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const GET_GLOBAL_SEARCH_FIRM = gql(/* GraphQL */ `
  query GetGlobalSearchFirm($firmFilter: FirmFilterInput) {
    firm: firmList(
      page: { limit: 15, offset: 0 }
      sort: [{ field: nameSearchRank, order: ASC }]
      filter: $firmFilter
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const GET_GLOBAL_SEARCH_NOTE = gql(/* GraphQL */ `
  query GetGlobalSearchNote($noteFilter: NotesFilter) {
    note: notesList(
      page: { limit: 15, offset: 0 }
      sort: [{ field: textSearchRank, order: ASC }]
      filter: $noteFilter
    ) {
      items {
        id
        investment {
          id
          name
        }
        company {
          id
          name
        }
        noteMeta {
          noteType {
            id
            noteType
          }
        }
        noteDate
        createDate
        modifyDate
        accessLevelId
      }
    }
  }
`);

export const GET_GLOBAL_SEARCH_DOCUMENT = gql(/* GraphQL */ `
  query GetGlobalSearchDocument($documentFilter: DocumentFilter) {
    document: documents(
      page: { limit: 15, offset: 0 }
      sort: [{ field: textSearchRank, order: ASC }]
      filter: $documentFilter
    ) {
      items {
        id
        name
        date
        modifyDate
        createDate
        firm {
          id
          name
        }
        investment {
          id
          name
        }
        company {
          id
          name
        }
        deal {
          id
          name
        }
        businessObject {
          id
          name
        }
        documentTypeEnum {
          id
          name
        }
      }
    }
  }
`);
