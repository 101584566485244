import { useQuery } from "@apollo/client";
import { CustomAnalyticsList } from "_graphql-types/graphql";
import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import { Col, Row } from "antd";
import LoadingPanel from "Components/loading_panel";
import { formatDate } from "frontend/src/utils/date";
import Chart from "./Chart";
import { CUSTOM_ANALYTICS_LIST } from "./graphql";

export function CustomAnalytics({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const { data, loading, error } = useQuery<{
    customAnalyticsList: CustomAnalyticsList;
  }>(CUSTOM_ANALYTICS_LIST, {
    variables: {
      page: { limit: 25, offset: 0 },
      filter: { ownerId: investmentId },
    },
  });

  if (error) displayErrorFlash();

  return (
    <>
      <h3 className="heading" data-cy="custom-analytics-heading">
        {I18n.t("analytics.custom.title")}
      </h3>

      <LoadingPanel loading={loading}>
        {data?.customAnalyticsList?.items &&
          data?.customAnalyticsList?.items?.map(item => (
            <div
              key={item.id}
              className="invt-tab__section overview-page__card"
            >
              <Row>
                <Col span={20}>
                  <h4
                    className="cms-subtitle"
                    data-cy="custom-analytics__chart-title"
                  >
                    {(item.value as Highcharts.Options).title?.text}
                  </h4>
                </Col>
                <Col span={4}>
                  <h5
                    className="main-input__sublabel"
                    data-cy="custom-analytics__chart-modifyUser"
                  >
                    {item.modifyUser}
                  </h5>
                  <h5
                    className="main-input__sublabel"
                    data-cy="custom-analytics__chart-modifyDate"
                  >
                    {formatDate(item.modifyDate)}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Chart chartOptions={item.value} />
                </Col>
              </Row>
            </div>
          ))}
      </LoadingPanel>
    </>
  );
}
