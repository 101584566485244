/* eslint-disable camelcase */
import * as types from "_graphql-types/graphql";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

type Person = types.FirmPartnersQuery["teamMemberList"]["items"][number];

const PartnerLink = ({ person }: { person: Person }) => {
  const { fullName, id } = person;

  const location = useLocation();
  const navigate = useNavigate();

  const handlePersonModal = () => {
    const [_, owner, ownerId] = location.pathname.split("/");
    navigate(`/${owner}/${ownerId}/team/${id}`);
  };

  return (
    <button
      type="button"
      data-cy="partner-link"
      className="button-as-link-text"
      onClick={handlePersonModal}
    >
      {fullName}
    </button>
  );
};

PartnerLink.propTypes = {
  person: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default PartnerLink;
