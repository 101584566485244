import classnames from "classnames";
import { useCallback, useState } from "react";
import { OnResizeCallback, useResizeDetector } from "react-resize-detector";
import GlobalSearchFilters from "./GlobalSearchFilters.component";
import GlobalSearchTotals from "./GlobalSearchTotals.component";

export const ROOT_PATH = "/";
export const INVESTMENT_PATHS = [
  "/investments",
  "/notes",
  "/documents",
  "/portfolios",
];
export const COMPANY_PATHS = ["/company"];
export const pathsWithGlobalSearch = [
  ROOT_PATH,
  ...INVESTMENT_PATHS,
  ...COMPANY_PATHS,
];

function GlobalSearchControls() {
  const [filterHeight, setFilterHeight] = useState(0);

  const onResize: OnResizeCallback = useCallback(
    ({ height }) => {
      if (typeof height === "number") {
        setFilterHeight(height);
      }
    },
    [setFilterHeight]
  );

  const { ref } = useResizeDetector({
    onResize,
    handleHeight: true,
    handleWidth: true,
    refreshMode: "debounce",
    refreshRate: 100,
  });

  return (
    <div className="p-relative" style={{ height: `${filterHeight}px` }}>
      <div ref={ref} className={classnames("main-gf")}>
        <GlobalSearchTotals />
        <GlobalSearchFilters />
      </div>
    </div>
  );
}

export default GlobalSearchControls;
