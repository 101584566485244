import React from "react";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import i18n from "i18next";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { INVESTMENT_ATTRIBUTES } from "./graphql";

const LiquidationTile = ({ investmentId }: { investmentId: number }) => {
  const { data } = useQuery(INVESTMENT_ATTRIBUTES, {
    variables: { id: investmentId },
  });
  if (!data?.investment.liquidationNote) {
    return null;
  }
  return (
    <div className="invt-tab__section">
      <h3 className="invt-tab__title">
        {i18n.t("investment_liquidation.title")}
      </h3>

      <div className="overview-page__card">
        <FieldInlinePreview
          className="cms-broke-long-value pr-10"
          displayName="Liquidation Date"
          content={get(data, "investment.liquidationDate", "-")}
        />
        <FieldInlinePreview
          className="cms-broke-long-value pr-10"
          displayName="Liquidation Note"
          content={get(data, "investment.liquidationNote", "-")}
        />
      </div>
    </div>
  );
};

export default LiquidationTile;
