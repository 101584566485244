import React from "react";
import i18n from "i18next";
import classNames from "classnames";
import { asPercent, toPercent, asMultiple } from "Helpers/index";

import { isNegativeValue } from "frontend/src/utils/number";
import { camelCase } from "lodash";

interface RangeProps {
  displayName: string;
  className?: string;
  startRange?: number | null;
  endRange?: number | null;
  numberFormat?: string;
}

const Field = ({ value }: { value: number | string }) => {
  const isNegative = isNegativeValue(value);

  return (
    <span
      className={classNames("cms-updates__value invst-table-tag", {
        negative: isNegative,
      })}
    >
      {value}
    </span>
  );
};

function FieldRange({
  displayName,
  className,
  startRange,
  endRange,
  numberFormat = "",
}: RangeProps) {
  const renderField = (value: number, format: string) => {
    if (format === "percent") {
      return <Field value={toPercent(value)} />;
    }
    if (format === "whole_percent") {
      return <Field value={asPercent(value)} />;
    }
    if (format === "multiple") {
      return <Field value={asMultiple(value)} />;
    }
    return <Field value={value} />;
  };

  return (
    <div className="flex-row" data-cy={`FieldRange__${camelCase(displayName)}`}>
      <div className="flex-1">
        <p className="pb-10 pr-4 in-secondary-blue">{displayName}</p>
      </div>

      <div
        className={classNames(
          "flex-1",
          "overview-page__invst-rating-txt-one-line",
          className
        )}
        style={{ marginRight: "12px" }}
      >
        <div className="fund-aum pb-10">
          <div className="rating-txt">
            <div className="overview-page__invst-rating-txt-one-line-value">
              {startRange ? renderField(startRange, numberFormat) : "-"}
            </div>
            <span className="mr-8 ml-8">{i18n.t("common.date_range.to")}</span>
            <div className="overview-page__invst-rating-txt-one-line-value">
              {endRange ? renderField(endRange, numberFormat) : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FieldRange.defaultProps = {
  className: "",
  numberFormat: "",
  startRange: undefined,
  endRange: undefined,
};

export default FieldRange;
