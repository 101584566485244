import { createContext } from "react";

type InvestmentControlsContextType = {
  selectedInvestmentIds: Set<number>;
  toggleInvestmentId: (_investmentId: number) => void;
  isSelected: (_investmentId: number) => boolean;
  clearSelected: () => void;
  isActive: boolean;
  selectAll: () => void;
};

export const InvestmentControlsContext =
  createContext<InvestmentControlsContextType>({
    selectedInvestmentIds: new Set(),
    toggleInvestmentId: _investmentId => {},
    isSelected: _investmentId => false,
    clearSelected: (): void => {},
    selectAll: (): void => {},
    isActive: false,
  });
