/* eslint-disable consistent-return */
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";
import { TOOLTIP_ATTRIBUTES } from "./graphql";
import InvestmentPreviewTooltip from "./investment_preview_tooltip";

const InvestmentTooltip = ({
  children,
  investmentId,
}: {
  investmentId: number;
  children: React.ReactElement;
}): JSX.Element | null => {
  const [fetchInvestmentData, { data, called }] = useLazyQuery(
    TOOLTIP_ATTRIBUTES,
    {
      variables: {
        id: investmentId,
      },
    }
  );

  const investmentAttributes = useMemo(() => {
    if (!data) return { id: investmentId };
    const { investment } = data;
    return {
      id: investmentId,
      name: investment.name,
      badgeColor: "",
      market: investment.market,
      hasIRRPerformance: !!investment.performancePrivate?.length,
      hasMTDPerformance: !!investment.performanceMTD?.length,
    };
  }, [data]);

  // TODO: This looks wrong -- it is used in two refs with different types
  const containerRef = useRef<any>(null);

  function fetchFundData() {
    fetchInvestmentData();
  }

  function fetchData(
    event:
      | React.FocusEvent<HTMLElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    if (event) event.preventDefault();

    if (!called) {
      return fetchFundData();
    }
  }

  if (!investmentId) return null;

  return (
    <div
      className="tooltip-trigger"
      onMouseOver={fetchData}
      onFocus={fetchData}
      ref={containerRef}
    >
      <InvestmentPreviewTooltip
        ref={containerRef}
        fund={investmentAttributes}
        alternatives={[]}
      >
        {children}
      </InvestmentPreviewTooltip>
    </div>
  );
};

InvestmentTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  investmentId: PropTypes.number.isRequired,
};

export default InvestmentTooltip;
