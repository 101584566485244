import { useQuery } from "@apollo/client";
import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import { useDispatch } from "src/react-redux/index.js";
import { HF_GET_SHARE_CLASSES } from "./graphql";
import { HfInvestmentTermsList } from "./hf_investment_terms_list.js";

export function HfContainer({ investmentId }: { investmentId: number }) {
  const { loading, error, data } = useQuery(HF_GET_SHARE_CLASSES, {
    variables: { investmentId },
    skip: !investmentId,
  });

  const dispatch = useDispatch();
  if (error) displayErrorFlash();

  const shareClasses = data?.shareClassList.items ?? [];

  return (
    <HfInvestmentTermsList shareClasses={shareClasses} loading={loading} />
  );
}
