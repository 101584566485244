import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import Pagination from "antd/es/pagination/Pagination.js";
import classnames from "classnames";
import { logException } from "Helpers/index";
import { range } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Document, DocumentProps, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Spinner } from "../../spinner.js.jsx";
import { getNextPage, getPageRange } from "./utils.js";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const docOptions: DocumentProps["options"] = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const PAGE_WIDTH = 800;
const PAGE_SIZE_OPTIONS = ["5", "10", "20"];

function PageControls({
  numPages,
  pageNumber,
  pageSize,
  changePage,
  changeSize,
}: {
  numPages: number;
  pageNumber: number;
  pageSize: number;
  changePage: (pageNumber: number, size: number) => void;
  changeSize: (current: number, size: number) => void;
}) {
  return (
    <div className="text-center mb-8">
      <Pagination
        pageSize={pageSize}
        defaultCurrent={pageNumber}
        onChange={changePage}
        showSizeChanger={numPages > 5}
        onShowSizeChange={changeSize}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        total={numPages}
        showLessItems
        pageSizeOptions={PAGE_SIZE_OPTIONS.filter(
          (size: string) => Number(size) <= numPages
        )}
      />
    </div>
  );
}

function PdfViewer({ signedUrl }: { signedUrl: string }) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const scrollWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // set focus so user can scroll document instead of entire application page
    if (scrollWindowRef.current) {
      scrollWindowRef.current.focus();
    }
  }, [scrollWindowRef]);

  useEffect(() => {
    if (numPages && numPages > 5) {
      setPageSize(5);
    }
  }, [numPages]);

  function changePage(pageOffset: number, pageLimit?: number) {
    const nextPage = getNextPage(pageOffset, pageLimit);
    console.log({ nextPage, pageOffset, pageLimit });
    setPageNumber(nextPage);
  }
  function changeSize(currentPageOffset: number, newSize: number) {
    setPageSize(newSize);
    changePage(currentPageOffset, newSize);
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error: Error): void {
    logException(error);
    displayErrorFlash();
  }

  const pageRange = useMemo(() => {
    const [start, end] = getPageRange(pageNumber, pageSize, numPages);
    console.log({ start, end, pageNumber, pageSize, numPages });
    return range(start, end + 1);
  }, [numPages, pageNumber, pageSize]);

  return (
    <>
      <div ref={scrollWindowRef} className="document-pdfViewer__card">
        <div className="document-pdfViewer__page-controls">
          {numPages ? (
            <PageControls
              numPages={numPages}
              pageNumber={pageNumber}
              pageSize={pageSize}
              changePage={changePage}
              changeSize={changeSize}
            />
          ) : null}
        </div>
        <div className="text-center mt-5">
          <Document
            file={signedUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Spinner />}
            onLoadError={onDocumentLoadError}
            options={docOptions}
          >
            <PageView pageNumber={pageNumber} pageRange={pageRange} />
          </Document>
        </div>
      </div>
    </>
  );
}

export function PageView({
  pageNumber,
  pageRange,
}: {
  pageNumber: number;
  pageRange: number[];
}) {
  console.log({ pageRange, pageNumber });
  return (
    <>
      {pageRange.map((_, i) => (
        <Page
          key={`page-${pageNumber + i}`}
          className={classnames("document-pdfViewer__page", {
            "document-pdfViewer__page--multiPage": pageRange.length > 1,
          })}
          data-cy="document-pdfViewer__page"
          width={PAGE_WIDTH}
          pageNumber={pageNumber + i}
          canvasBackground="white"
          loading={<Spinner />}
          onLoadError={error =>
            console.error(`Error Loading Page: ${pageNumber + i}`, error)
          }
        />
      ))}
    </>
  );
}

export default PdfViewer;
