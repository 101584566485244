import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Checkbox, Spin } from "antd";
import {
  FETCH_IMPACT_CATEGORIES,
  ImpactFilterInput,
} from "Components/common/ImpactFilter";
import i18n from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getCompanyFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { FilterInterface } from "../FilterInterface";
import { useDispatch } from "src/react-redux";

const defaultFilter: types.ImpactSearchFilter = { values: [] };

function CompanyImpactFilter(): JSX.Element {
  const companyFilters = useSelector(getCompanyFilters);

  const impactFilter: types.ImpactSearchFilter | null = useMemo(() => {
    const foundFilter = companyFilters.find(
      filterI => filterI.type === "IMPACT"
    );
    if (!foundFilter) return null;
    return foundFilter.data as types.ImpactSearchFilter;
  }, [companyFilters]);

  const { loading, data, error } = useQuery<types.GetImpactCategoriesQuery>(
    FETCH_IMPACT_CATEGORIES
  );

  const [localFilter, setLocalFilter] = useState<types.ImpactSearchFilter>(
    impactFilter ?? defaultFilter
  );

  const dispatch = useDispatch();

  const setFilter = () => {
    if (localFilter) {
      dispatch(
        updateFilter({
          type: "company",
          data: {
            type: "IMPACT",
            data: localFilter,
          },
        })
      );
    }
  };

  const reset = () => {
    setLocalFilter(defaultFilter);
    dispatch(
      removeFilter({
        type: "company",
        data: {
          type: "IMPACT",
          data: defaultFilter,
        },
      })
    );
  };

  useEffect(() => {
    setLocalFilter(impactFilter ?? defaultFilter);
  }, [impactFilter]);

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.impact")}
      count={localFilter.values.length}
      reset={reset}
      set={setFilter}
      additionalControls={
        <Checkbox
          checked={!!localFilter.isOr}
          onChange={() =>
            setLocalFilter({
              ...localFilter,
              isOr: !localFilter.isOr,
            })
          }
        >
          Match on any selection. (or)
        </Checkbox>
      }
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        {loading && <Spin size="small" />}
        {error && <span>Types failed to load</span>}
        {data && (
          <ImpactFilterInput
            availableImpactCategories={data}
            filter={localFilter}
            setFilter={setLocalFilter}
          />
        )}
      </div>
    </FilterInterface>
  );
}

export default CompanyImpactFilter;
