import React, { Component, useState, useEffect, useRef } from "react";
import createClass from "create-react-class";
import PropTypes from "prop-types";
import _ from "lodash";

export default class TableColumnVisibility extends Component {
  //  displayName: 'Common.TableColumnVisibility'

  propTypes = {
    scrollContainer: PropTypes.object.isRequired,
    columnWidth: PropTypes.number,
  };

  getDefaultProps = () => {
    return {
      columnWidth: 57,
    };
  };

  getInitialState = () => {
    return {
      active: 0,
      leftHidden: 0,
      rightHidden: 0,
    };
  };

  componentDidMount() {
    this._updateVisibility = _.throttle(this.updateVisibility, 100);
    this.tableHeaders = this.getTableHeaders();
    this.tableHeadersTail = _.tail(this.tableHeaders);

    this.props.scrollContainer.addEventListener(
      "scroll",
      this._updateVisibility
    );
    window.addEventListener("resize", this._updateVisibility);

    this.updateVisibility();
  }

  componentWillUnmount() {
    this.props.scrollContainer.removeEventListener(
      "scroll",
      this._updateVisibility
    );
    window.removeEventListener("resize", this._updateVisibility);
  }

  headerVisibility = (header, container) => {
    const columnWidth = this.columnWidth();
    const leftContainerEdge = container.x;
    const rightContainerEdge = leftContainerEdge + container.width;
    const leftHeaderEdge = header.getBoundingClientRect().x;
    const rigthHeaderEdge = leftHeaderEdge + columnWidth;
    const offset = columnWidth / 2;

    if (rigthHeaderEdge - offset < leftContainerEdge) {
      return "leftHidden";
    }
    if (leftHeaderEdge + offset > rightContainerEdge) {
      return "rightHidden";
    }
  };

  getTableHeaders = () => {
    return Array.from(this.props.scrollContainer.getElementsByTagName("th"));
  };

  columnWidth = () => {
    return this.props.columnWidth;
  };

  fixedColumnWidth = () => {
    const fixedColumn = _.head(this.tableHeaders);

    if (fixedColumn) {
      return fixedColumn.getBoundingClientRect().width;
    }
    return 0;
  };

  getActiveHeaders = () => {
    const offset = this.fixedColumnWidth();
    const container = this.props.scrollContainer.getBoundingClientRect();

    return _.round((container.width - offset) / this.columnWidth());
  };

  getHiddenHeaders = (side, container) => {
    return _.size(
      _.filter(
        this.tableHeadersTail,
        (header) => this.headerVisibility(header, container) === side
      )
    );
  };

  updateVisibility = () => {
    if (_.isEmpty(this.tableHeaders)) {
      return;
    }

    const container = this.props.scrollContainer.getBoundingClientRect();

    this.setState({
      active: this.getActiveHeaders(),
      leftHidden: this.getHiddenHeaders("leftHidden", container),
      rightHidden: this.getHiddenHeaders("rightHidden", container),
    });
  };

  renderLeftHidden = () => {
    return (
      this.state.leftHidden > 0 && <li className="scrollable-table__dots-dot" />
    );
  };

  renderRightHidden = () => {
    return (
      this.state.rightHidden > 0 && (
        <li className="scrollable-table__dots-dot" />
      )
    );
  };

  renderVisible = () => {
    return _.map(_.range(this.state.active), (dot) => (
      <li
        className="scrollable-table__dots-dot scrollable-table__dots-dot--current"
        key={dot}
      />
    ));
  };

  render() {
    return (
      <ul className="scrollable-table__dots hidden-lg-up">
        {this.renderLeftHidden()}
        {this.renderVisible()}
        {this.renderRightHidden()}
      </ul>
    );
  }
}

// export default TableColumnVisibility;
