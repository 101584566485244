import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Spin, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import i18n from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { FilterInterface } from "../FilterInterface";
import { FETCH_AVAILABLE_GEOGRAPHIES } from "../graphql";

const FILTER_TYPE = "PRIMARY_GEOGRAPHY";

function PrimaryGeographyFilterInput({
  filter,
  setFilter,
  availableGeographies,
}: {
  filter: types.EntitySearchFilter;
  setFilter: React.Dispatch<React.SetStateAction<types.EntitySearchFilter>>;
  availableGeographies: { name: string; id: number }[];
}): JSX.Element {
  const { treeData, optionKey } = useMemo<{
    treeData: DataNode[];
    optionKey: { [key: string]: string };
  }>(() => {
    const optionKeyMap: { [key: string]: string } = {};

    availableGeographies.forEach(option => {
      optionKeyMap[option.id] = option.name;
    });

    return {
      treeData: Object.entries(optionKeyMap).map(([typeId, typeName]) => ({
        key: typeId,
        title: typeName,
      })),
      optionKey: optionKeyMap,
    };
  }, [availableGeographies]);

  const setFilterFromKeys = (
    checkedKeys: { checked: React.Key[] } | React.Key[]
  ) => {
    if (!Array.isArray(checkedKeys))
      throw new Error("Unexpected type in type filter.. Antd!");

    setFilter({
      values: checkedKeys.map(key => ({
        id: Number(key),
        label: optionKey[String(key)],
      })),
    });
  };

  const checkedKeysFromFilter = useMemo(
    () => filter.values.map(({ id }) => String(id)),
    [filter]
  );

  return (
    <Tree
      checkable
      treeData={treeData}
      onCheck={setFilterFromKeys}
      checkedKeys={checkedKeysFromFilter}
    />
  );
}

const defaultFilter: types.EntitySearchFilter = { values: [] };

function PrimaryGeographyFilter(): JSX.Element {
  const investmentFilters = useSelector(getInvestmentFilters);

  const typeFilter: types.EntitySearchFilter | null = useMemo(() => {
    const foundFilter = investmentFilters.find(
      filterI =>
        filterI.type === "investment" && filterI.data.type === FILTER_TYPE
    );
    if (!foundFilter) return null;
    return foundFilter.data.data as types.EntitySearchFilter;
  }, [investmentFilters]);

  const { loading, data, error } =
    useQuery<types.GetAvailableGeographiesForFilterQuery>(
      FETCH_AVAILABLE_GEOGRAPHIES
    );

  const [localFilter, setLocalFilter] = useState<types.EntitySearchFilter>(
    typeFilter ?? defaultFilter
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setLocalFilter(typeFilter ?? defaultFilter);
  }, [typeFilter]);

  const setFilter = () => {
    if (localFilter) {
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: FILTER_TYPE,
              data: localFilter,
            },
          },
        })
      );
    }
  };

  const reset = () => {
    setLocalFilter(defaultFilter);
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: FILTER_TYPE,
            data: defaultFilter,
          },
        },
      })
    );
  };

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.geographicFocus")}
      count={localFilter.values.length}
      reset={reset}
      set={setFilter}
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        {loading && <Spin size="small" />}
        {error && <span>Types failed to load</span>}
        {data && (
          <PrimaryGeographyFilterInput
            availableGeographies={data.geographicFocusEnumList.items}
            filter={localFilter}
            setFilter={setLocalFilter}
          />
        )}
      </div>
    </FilterInterface>
  );
}

export default PrimaryGeographyFilter;
