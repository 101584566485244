/* eslint-disable max-lines-per-function */
import { Table } from "antd";
import LoadingPanel from "Components/loading_panel";
import { formatStatValue } from "Helpers/investment_helpers";
import React, { useContext, useMemo } from "react";
import { RelativePerformanceContext } from "./context";

const columns: Parameters<typeof Table>[0]["columns"] = [
  {
    title: "Fund",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "CAGR",
    dataIndex: "compoundReturn",
    key: "compoundReturn",
  },
  {
    title: "Standard Deviation",
    dataIndex: "stdDev",
    key: "stdDev",
  },
  {
    title: "Sharpe Ratio",
    dataIndex: "sharpeRatio",
    key: "sharpeRatio",
  },
  {
    title: "% Profitable Periods",
    dataIndex: "percentProfitablePeriods",
    key: "percentProfitablePeriods",
  },
  {
    title: "Skewness",
    dataIndex: "skewness",
    key: "skewness",
  },
  {
    title: "Kurtosis",
    dataIndex: "kurtosis",
    key: "kurtosis",
  },
  {
    title: "Max Drawdown",
    dataIndex: "maxDrawDown",
    key: "maxDrawDown",
  },
  {
    title: "Length of Max Drawdown",
    dataIndex: "lengthOfMaxDrawDown",
    key: "lengthOfMaxDrawDown",
  },
  {
    title: "Recovery from Max Drawdown",
    dataIndex: "recoveryFromMaxDrawDown",
    key: "recoveryFromMaxDrawDown",
  },
];

type StatDataType = {
  id?: number;
  name?: string;
  compoundReturn?: number | string | null;
  stdDev?: number | string | null;
  sharpeRatio?: number | string | null;
  percentProfitablePeriods?: number | string | null;
  skewness?: number | string | null;
  kurtosis?: number | string | null;
};

type DrawDownDataType = {
  maxDrawDown: number | string | null;
  lengthOfMaxDrawDown: number | string | null;
  recoveryFromMaxDrawDown: number | string | null;
};

function RiskStatisticsChart(): JSX.Element {
  const { investment, loading } = useContext(RelativePerformanceContext);

  const chartData: StatDataType[] | null = useMemo(() => {
    if (!investment) return null;
    const { stats } = investment.groupStats;
    const result = investment.groupStats.items.map(({ id, name }, i) => ({
      id,
      name,
      compoundReturn: formatStatValue(stats.compoundReturn[i], "0.00%"),
      stdDev: formatStatValue(stats?.stdDev?.[i], "0.00%"),
      sharpeRatio: formatStatValue(stats?.sharpeRatio?.[i], "0.00"),
      percentProfitablePeriods: formatStatValue(
        stats?.percentProfitablePeriods?.[i],
        "0.00%"
      ),
      skewness: formatStatValue(stats.skewness[i], "0.00"),
      kurtosis: formatStatValue(stats.kurtosis[i], "0.00"),
      maxDrawDown: formatStatValue(stats?.maxDrawDown?.[i], "0.00%"),
      lengthOfMaxDrawDown: stats?.lengthOfMaxDrawDown?.[i]
        ? `${formatStatValue(stats?.lengthOfMaxDrawDown?.[i], "0")} month${
            stats?.lengthOfMaxDrawDown?.[i] &&
            Math.round(stats?.lengthOfMaxDrawDown[i] as number) === 1
              ? ""
              : "s"
          }`
        : "",
      recoveryFromMaxDrawDown: stats.recoveryFromMaxDrawDown[i]
        ? `${formatStatValue(stats.recoveryFromMaxDrawDown[i], "0")} month${
            stats.recoveryFromMaxDrawDown[i] &&
            Math.round(stats.recoveryFromMaxDrawDown[i] as number) === 1
              ? ""
              : "s"
          }`
        : "",
    }));
    return result;
  }, [investment]);

  return (
    <>
      <LoadingPanel loading={loading}>
        <Table
          columns={columns}
          dataSource={chartData ?? []}
          pagination={false}
          className="mb-24"
        />
      </LoadingPanel>
    </>
  );
}

export default RiskStatisticsChart;
