import i18n from "i18next";
import { Spin, AutoComplete, Select } from "antd";
import React from "react";
import { useQuery } from "@apollo/client";
import { RawValueType } from "rc-select/lib/Select";
import { useDebounce } from "Helpers/hooks";
import { FETCH_INVESTMENT_SETS } from "./graphql";
import { FormData, TInvestmentSet } from "./InvestmentSetForm.component";

const { Option } = Select;

function InvestmentSetSelect({
  name,
  updateForm,
  setSelectedList,
  selectedList,
}: {
  name: string;
  selectedList?: TInvestmentSet | null;
  setSelectedList: (arg0: TInvestmentSet | null) => void;
  updateForm: ({ name }: Partial<FormData>) => void;
}) {
  const debouncedName = useDebounce(name, 250);
  const { data, loading } = useQuery(FETCH_INVESTMENT_SETS, {
    variables: {
      query: debouncedName,
    },
    fetchPolicy: "cache-and-network", // incase list is added
  });
  return (
    <div className="main-input">
      <div className="main-input__label">
        {selectedList
          ? `${i18n.t("funds.form.update_list_message")} ${selectedList.name}`
          : i18n.t("funds.form.new_list_message")}
      </div>

      <AutoComplete
        showSearch
        value={name}
        showArrow={false}
        searchValue={undefined}
        allowClear
        filterOption={false}
        defaultValue={name || undefined}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        onSearch={(value: string) => {
          updateForm({ name: value });
        }}
        onSelect={(value: RawValueType) => {
          const investmentSet =
            data?.investmentSetList.items.find(
              ({ id }: { id: number }) => id === Number(value)
            ) ?? null;
          setSelectedList(investmentSet);
        }}
        onClear={() => {
          setSelectedList(null);
        }}
        placeholder={
          selectedList
            ? `Editing ${selectedList.name}`
            : "Select a list to update"
        }
      >
        {data &&
          data.investmentSetList.items.map(investmentSet => {
            if (!investmentSet.id || !investmentSet.name) return null;
            return (
              <Option value={investmentSet.id} key={investmentSet.id}>
                <div>
                  <h4>{investmentSet.name}</h4>
                </div>
                <div>
                  {investmentSet.createUser}
                  <span className="text-right">{investmentSet.updateDate}</span>
                </div>
              </Option>
            );
          })}
      </AutoComplete>
    </div>
  );
}

export default InvestmentSetSelect;
