import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { isEmpty } from "lodash";
import { displayWorkExperience } from "./helpers.js";

const WorkExperience = ({ workExperience }) => (
  <div data-cy="person-card-employment" className="team-member__info">
    <p className="team-member__info-title">{i18n.t("team_summary.work")}</p>
    {!isEmpty(workExperience) &&
      workExperience.map(record => (
        <p key={record.id} className="team-member__info-value">
          {displayWorkExperience({
            title: record.title,
            company: record.company,
            fromYear: record.fromYear,
            toYear: record.toYear,
          })}
        </p>
      ))}
    {isEmpty(workExperience) && <p className="team-member__info-value">-</p>}
  </div>
);

WorkExperience.defaultProps = {
  workExperience: {
    company: {
      name: undefined,
    },
  },
};

WorkExperience.propTypes = {
  workExperience: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      fromYear: PropTypes.number,
      toYear: PropTypes.number,
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
};

export default WorkExperience;
