import React, { useMemo } from "react";
import { Radio, Select } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import {
  IOptions,
  defaultCompanyOptions,
  defaultFirmOptions,
} from "./Visualizations.component";
import { aggregateCompanyCharts, aggregateFirmCharts } from "./aggregations";
import {
  PieChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";

function ChartIconFor(chartType: IOptions["chartType"]) {
  switch (chartType) {
    case "pie":
      return PieChartOutlined;
    case "packedbubble":
      return DotChartOutlined;
    case "column":
      return BarChartOutlined;
    case "radial":
      return RadarChartOutlined;
    default:
      break;
  }

  return false;
}

function ChartIcon({ chartType }: { chartType: IOptions["chartType"] }) {
  const Icon = useMemo(() => ChartIconFor(chartType), [chartType]);
  if (!Icon) return null;

  return <Icon style={{ fontSize: "150%" }} />;
}

interface Option {
  id: IOptions["chartType"] | IOptions["category"];
  key: keyof IOptions;
  label: string;
}

interface ChartTypeControlProps {
  controlOptions: Option[];
  handleChange: (e: RadioChangeEvent) => void;
  selectedOptions: IOptions;
  defaultOptions: IOptions;
}

interface MeasureControlProps {
  controlOptions: Option[];
  handleChange: (value: IOptions["category"]) => void;
  selectedOptions: IOptions;
  defaultOptions: IOptions;
}

const chartTypeOptions: Option[] = [
  {
    id: "pie",
    label: "Pie",
    key: "chartType",
  },
  {
    id: "packedbubble",
    label: "Bubble",
    key: "chartType",
  },
  {
    id: "column",
    label: "Column",
    key: "chartType",
  },
  {
    id: "radial",
    label: "Radial",
    key: "chartType",
  },
];

function measureCategories(isCompany: boolean): Option[] {
  const aggregateCharts = isCompany
    ? aggregateCompanyCharts
    : aggregateFirmCharts;

  return aggregateCharts.map(category => ({
    id: category.key,
    key: "category",
    label: category.title,
  }));
}

function SelectMeasure({
  controlOptions,
  handleChange,
  defaultOptions,
}: MeasureControlProps) {
  return (
    <Select
      style={{ width: 300 }}
      defaultValue={defaultOptions.category}
      onChange={handleChange}
    >
      {controlOptions.map(({ id, label }) => (
        <Select.Option key={id} value={id}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

function ControlBar({
  controlOptions,
  handleChange,
  defaultOptions,
}: ChartTypeControlProps) {
  return (
    <Radio.Group
      buttonStyle="solid"
      name="chartType"
      defaultValue={defaultOptions.chartType}
      onChange={handleChange}
    >
      {controlOptions.map(({ id, key, label }) => (
        <Radio.Button key={id} name={key} value={id}>
          {label} <ChartIcon chartType={id as IOptions["chartType"]} />
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

interface VisualizationControlProps {
  setOptions: React.Dispatch<React.SetStateAction<IOptions>>;
  options: IOptions;
  isCompany: boolean;
}

function VisualizationControls({
  setOptions,
  options,
  isCompany,
}: VisualizationControlProps) {
  function handleChartTypeChange(e: RadioChangeEvent) {
    const { value } = e.target;
    setOptions({
      ...options,
      chartType: value,
    });
  }

  function handleCategoryChange(value: IOptions["category"]) {
    setOptions({
      ...options,
      category: value,
    });
  }

  const defaultOptions = isCompany ? defaultCompanyOptions : defaultFirmOptions;

  return (
    <div
      data-cy="firm-visualization-controls"
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
      }}
    >
      <ControlBar
        selectedOptions={options}
        controlOptions={chartTypeOptions}
        handleChange={handleChartTypeChange}
        defaultOptions={defaultOptions}
      />
      <SelectMeasure
        selectedOptions={options}
        controlOptions={measureCategories(isCompany)}
        handleChange={handleCategoryChange}
        defaultOptions={defaultOptions}
      />
    </div>
  );
}

export default VisualizationControls;
