/* eslint-disable max-lines-per-function */
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import i18n from "i18next";
import { SERVICE_PROVIDERS } from "./graphql/index";

interface Props {
  investmentId: number;
}

type Institution = NonNullable<
  types.GetServiceProvidersQuery["investment"]["investmentInstitutionsList"]
>["items"][number]["institution"];

const getServiceTypes = (institution: Institution): string[] =>
  institution.institutionsInstitutionTypeEnumList.items.map(
    ({ institutionTypeEnum }) => institutionTypeEnum.name
  );

function InvestmentServiceProviders({ investmentId }: Props) {
  const { data, loading } = useQuery(SERVICE_PROVIDERS, {
    variables: { id: investmentId },
  });

  const investmentInstitutions =
    data?.investment.investmentInstitutionsList?.items;

  const renderServiceTypeList = (institution: Institution) => {
    const serviceTypes = getServiceTypes(institution);
    return [...new Set(serviceTypes)].map((type, i) => (
      <div key={i} className="cms-service-tag cms-service-tag--service-type">
        {type}
      </div>
    ));
  };

  return (
    <div id="service_providers" data-cy="service_providers">
      <h3 className="invt-tab__title">{i18n.t("service_provider.title")}</h3>
      <div className="overview-page__card">
        {investmentInstitutions?.map(({ institution }) => {
          const { provider } =
            institution.globalProviderInstitutionsList?.items[0] ?? {};
          const level = provider?.tierLevel;
          const serviceTypeList = renderServiceTypeList(institution);
          return (
            <div key={institution.id} className="flex-row pb-10">
              <div className="flex-1 in-secondary-blue">
                <FieldInlinePreview
                  displayName="Service Types"
                  content={serviceTypeList}
                />
              </div>
              <div className="flex-1">
                <FieldInlinePreview
                  displayName="Provider Name"
                  content={provider?.name ?? "-"}
                />
              </div>
              <div className="flex-1 text-right">
                <div
                  data-cy="service-provider-tier"
                  className={`cms-service-tag cms-service-tag--${level}`}
                >
                  {i18n.t("service_provider.tier", {
                    level: `${level}`,
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InvestmentServiceProviders;
