import React, {
  useState,
  ReactNode,
  ReactEventHandler,
  ReactElement,
} from "react";
import classnames from "classnames";

function Option({
  children,
  disabled,
  className,
  onClick,
  active,
}: {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: ReactEventHandler;
  active?: boolean;
}) {
  const [hovered, setHovered] = useState(false);
  const classes = classnames(
    "ant-select-item",
    "ant-select-item-option",
    className,
    {
      "ant-select-item-option-disabled": disabled,
      "ant-select-item-option-active": hovered,
      "ant-select-item-option-selected": active,
      "font-weight-400": active,
    }
  );

  return (
    <div
      tabIndex={0}
      className={classes}
      onKeyPress={e => e.key === "Enter" && onClick && onClick(e)}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="ant-select-item-option-content">{children}</div>
      <span
        className="ant-select-item-option-state"
        unselectable="on"
        aria-hidden="true"
        style={{ userSelect: "none" }}
      />
    </div>
  );
}

Option.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: undefined,
  active: false,
};

function OptGroup({
  children,
  label,
  onClick,
}: {
  children: ReactElement | null | (ReactElement | null)[];
  label: ReactNode;
  onClick?: ReactEventHandler;
}) {
  return (
    <>
      <div
        tabIndex={0}
        onKeyPress={e => e.key === "Enter" && onClick && onClick(e)}
        onClick={onClick}
        className="ant-select-item ant-select-item-group"
      >
        {label}
      </div>
      {React.Children.map(
        children,
        child =>
          child &&
          React.cloneElement(child, {
            className: "ant-select-item-option-grouped",
          })
      )}
    </>
  );
}

OptGroup.defaultProps = {
  onClick: () => {},
};

export { OptGroup, Option };
