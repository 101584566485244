import { booleanToAnswer } from "Helpers/text_formatting";
import { HfGetShareClassesQuery } from "src/graphql-types/graphql";
import { FieldItem, percent, units } from "./FieldItem";
import { Fragment } from "react";

export function HfInvestmentTermItem({
  shareClass,
}: {
  shareClass: HfGetShareClassesQuery["shareClassList"]["items"][number];
}) {
  return (
    <div className="fund-term-row">
      <div className="font-weight-700 pb-16 fund-term-name">
        {shareClass.name}
      </div>

      <div className="flex-row">
        <div className="flex-1 mr-10">
          <FieldItem
            title="Open Status"
            value={shareClass.status || "-"}
            className="fund-term-open-closed"
          />

          <FieldItem
            title="Annual Reset"
            value={booleanToAnswer(shareClass.investmentFees?.annualReset)}
            className="annual-reset"
          />

          <FieldItem
            title="Management Fee"
            value={percent(shareClass.investmentFees?.mgmtFee)}
            className="fund-term-management-fee"
          />
          <FieldItem
            title="Incentive Fee"
            value={percent(shareClass.investmentFees?.incentiveFee)}
            className="fund-term-performance-fee"
          />

          <FieldItem
            title="Hurdle"
            value={booleanToAnswer(shareClass.investmentFees?.hurdle)}
            className="has-hurdle"
          />
          {shareClass.investmentFees?.hurdle && (
            <FieldItem
              title="Hurdle Benchmark"
              value={shareClass.investmentFees?.hurdleBenchmark || "-"}
              className="hurdle-benchmark"
            />
          )}
          <FieldItem
            title="Gate"
            value={booleanToAnswer(shareClass.liquidity?.hasGate)}
            className="fund-term-has-gate"
          />
          {shareClass.liquidity?.hasGate && (
            <>
              <FieldItem
                title="Investor Level Gate"
                value={percent(shareClass.liquidity?.gatePercentCapital)}
                className="fund-term-investor-gate"
              />
              <FieldItem
                title="Fund Level Gate"
                value={percent(shareClass.liquidity?.gatePercentNav)}
                className="fund-term-fund-gate"
              />
            </>
          )}
        </div>

        <div className="flex-1 ml-10">
          <FieldItem
            title="Is RockCreek Negotiated"
            value={booleanToAnswer(shareClass.isRcgNegotiated)}
            className="is-rcg-negotiated"
          />
          <FieldItem
            title="High-Water Mark"
            value={booleanToAnswer(shareClass.investmentFees?.highWaterMark)}
            className="high-water-mark"
          />
          <FieldItem
            title="Subscription Frequency"
            value={units(
              shareClass.liquidity?.subscriptionValue,
              shareClass.liquidity?.subscriptionUnit?.name
            )}
            className="fund-term-subscription-frequency"
          />
          <FieldItem
            title="Liquidity Period"
            value={units(
              shareClass.liquidity?.liquidityValue,
              shareClass.liquidity?.liquidityUnit?.name
            )}
            className="fund-term-liquidity"
          />
          <FieldItem
            title="Cash Availability (Days)"
            value={shareClass.liquidity?.cashAvailableDays || "-"}
            className="fund-term-cash-availability"
          />
          <FieldItem
            title="First Redemption on"
            value={shareClass.liquidity?.lockupEndValue?.name || "-"}
            className="fund-term-first-redemption"
          />
          <FieldItem
            title="Second Redemption on"
            value={shareClass.liquidity?.secondLockupEndValue?.name || "-"}
            className="fund-term-second-redemption"
          />
          <FieldItem
            title="Notice"
            value={units(shareClass.liquidity?.noticeValue, "Day")}
            className="fund-term-notice"
          />
          <FieldItem
            title="Lockup"
            value={booleanToAnswer(shareClass.liquidity?.hasLockup)}
            className="fund-term-has-lockup"
          />
          {shareClass.liquidity?.hasLockup && (
            <>
              <FieldItem
                title="Hard Lockup Period"
                value={units(shareClass.liquidity.lockupValue, "Month")}
                className="fund-term-lockup"
              />

              {shareClass.liquidity.softLockups.map(softLockup => (
                <Fragment key={softLockup.id}>
                  <FieldItem
                    title="Soft Lockup Period"
                    value={`Months ${softLockup.termBeginMonth}–${softLockup.termEndMonth}`}
                    className="fund-term-soft-lockup-period"
                  />

                  <FieldItem
                    title={"\xa0\xa0\u2022 Redemption Fee"}
                    value={percent(softLockup.redemptionFee)}
                    className="fund-term-soft-lockup-redemption-fee"
                  />
                </Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
