import { gql } from "_graphql-types/gql";

export const SAVE_STORED_PROMPT = gql(/* GraphQL */ `
  mutation AddStoredPrompts($input: StoredPromptsInput!) {
    addStoredPrompts(input: $input) {
      id
      prompt
    }
  }
`);

export const GET_STORED_PROMPTS_LIST = gql(/* GraphQL */ `
  query GetStoredPromptsList(
    $page: PageInput
    $sort: [StoredPromptsSort!]
    $filter: StoredPromptsFilter
  ) {
    storedPromptsList(page: $page, sort: $sort, filter: $filter) {
      items {
        id
        prompt
      }
      total
    }
  }
`);
