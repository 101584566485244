import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import NavHeader from "Components/layout/NavHeader";
import React from "react";
import { useNavigate } from "react-router-dom";
import ToggleEditModeButtonComponent from "../../presenters/heading/ToggleEditModeButton/ToggleEditModeButton.component";

const COMPANY_HEADER = gql(/* GraphQL */ `
  query CompanyNavBar($id: Int!) {
    company(id: $id) {
      id
      name
      firmId
      firmAttributes {
        id
        isStaffMWDBE
      }
    }
  }
`);

export default function CompanyPageNavBar({
  companyId,
}: {
  companyId: number;
}): JSX.Element | null {
  const { data } = useQuery(COMPANY_HEADER, {
    variables: {
      id: companyId,
    },
  });
  const navigate = useNavigate();
  if (!data || !data.company) return null;

  const { company } = data;

  return (
    <NavHeader>
      <div className="invst-header">
        <div className="invst-header__col--left">
          <div className={classNames("invst-header__title-wrap")}>
            <i
              className="invst-header__back-icon icon icon-back icon--24"
              onClick={() => navigate(-1)}
            />

            <div className="invst-header__title">
              <div id="investment-name" className="invst-header__title-txt">
                {company.name}
              </div>
            </div>
          </div>
          {company.firmAttributes.isStaffMWDBE && (
            <div className="fund-tile__market-flag-wrap">
              <div className="fund-tile__market-flag d-inline-block mr-8 p-relative">
                MWDBE
              </div>
            </div>
          )}
        </div>

        <div className="invst-header__col--right">
          <div className="text-right hidden-lg-down">
            <ToggleEditModeButtonComponent />
            <span className="no-reports__edit-toggle-spacer" />
          </div>
        </div>
      </div>
    </NavHeader>
  );
}
