import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import { getPieChartOptions } from "frontend/src/components/HighCharts";
import { PieChartSeries } from "frontend/src/components/HighCharts/dataSeriesFormatters";
import { asDisplayDate } from "Helpers/index";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { useQuery } from "@apollo/client";
import { FETCH_VISUALIZATION_DATA } from "Components/Visualizations/graphql";
import { aggregateFirmCharts } from "Components/Visualizations/aggregations";

interface ChartViewProps {
  dataSeries: PieChartSeries[];
}

function ChartView({ dataSeries }: ChartViewProps) {
  const chartOptions = getPieChartOptions({
    dataSeries,
    fontSize: 10,
    allowExport: false,
    allowDecimals: false,
    showAnimation: false,
    height: 300,
    width: 290,
    formatSuffix: "%",
    diameter: 200,
    legendMaxHeight: 75,
  });

  return (
    <div data-cy="diversity-pie-chart">
      <HighChartsReact
        options={chartOptions}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={false}
      />
    </div>
  );
}

const OWNERSHIP_MEASURE_IDS = aggregateFirmCharts.find(
  ({ title }) => title === "Ownership"
)?.measureEnumIds;

function EthnicityDiversity({
  data,
}: {
  data: types.DeiVisualizationDataQuery;
}) {
  const dataSeries: PieChartSeries[] = useMemo(() => {
    if (!data?.latestFirmDEI)
      return [
        {
          name: "Firm Ethnicity Ownership Diversity",
          colorByPoint: true,
          type: "pie",

          innerSize: "50%",
          data: [],
        },
      ];

    const firmDEIRecords = data?.latestFirmDEI?.firmDEIRecords ?? [];

    const summedCategories = firmDEIRecords.reduce<{ [key: string]: number }>(
      (accu, record) => {
        if (
          !record.deiCategory1Enum ||
          !OWNERSHIP_MEASURE_IDS?.includes(record.measureEnum.id)
        )
          return accu;

        accu[record.deiCategory1Enum.description] =
          accu[record.deiCategory1Enum.description] || 0;
        accu[record.deiCategory1Enum.description] += record.value || 0;

        return accu;
      },
      {}
    );

    return [
      {
        name: "Firm Ethnicity Ownership Diversity",
        colorByPoint: true,
        type: "pie",

        innerSize: "50%",
        data: Object.entries(summedCategories).map(([name, y]) => ({
          name,
          y: Math.round(y * 100) / 100,
        })),
      },
    ];
  }, [data]);

  if (!data || !data?.latestFirmDEI) return null;

  return <ChartView dataSeries={dataSeries} />;
}

function GenderDiversity({ data }: { data: types.DeiVisualizationDataQuery }) {
  const dataSeries: PieChartSeries[] = useMemo(() => {
    if (!data?.latestFirmDEI)
      return [
        {
          name: "Firm Gender Ownership Diversity",
          colorByPoint: true,
          type: "pie",
          innerSize: "50%",
          data: [],
        },
      ];

    const firmDEIRecords = data?.latestFirmDEI?.firmDEIRecords ?? [];

    const summedCategories = firmDEIRecords.reduce<{ [key: string]: number }>(
      (accu, record) => {
        if (
          !record.deiCategory2Enum ||
          !OWNERSHIP_MEASURE_IDS?.includes(record.measureEnum.id)
        ) {
          return accu;
        }

        accu[record.deiCategory2Enum.description] =
          accu[record.deiCategory2Enum.description] || 0;
        accu[record.deiCategory2Enum.description] += record.value || 0;

        return accu;
      },
      {}
    );

    return [
      {
        name: "Firm Gender Ownership Diversity",
        colorByPoint: true,
        type: "pie",

        innerSize: "50%",
        data: Object.entries(summedCategories).map(([name, y]) => ({
          name,
          y: Math.round(y * 100) / 100,
        })),
      },
    ];
  }, [data]);

  if (!data || !data?.latestFirmDEI) return null;

  return <ChartView dataSeries={dataSeries} />;
}

function FirmOverviewCharts({ firmId }: { firmId: number }) {
  const { data, loading, error } = useQuery<
    types.DeiVisualizationDataQuery,
    types.DeiVisualizationDataQueryVariables
  >(FETCH_VISUALIZATION_DATA, {
    variables: {
      entityId: firmId,
      isCompany: false,
      sort: [
        {
          field: types.DeiCategory1EnumSortEnum.Name,
          order: types.SortInput.Asc,
        },
      ],
    },
  });

  if (loading) return <></>;
  if (error) return <>Something went wrong loading charts.</>;

  if (!data?.latestFirmDEI) return null;

  return (
    <div className="invt-tab__section">
      <h3 className="invt-tab__title">Firm Ownership Diversity</h3>
      <p>{asDisplayDate(data.latestFirmDEI.asOfDate)}</p>

      <EthnicityDiversity data={data} />
      <GenderDiversity data={data} />
    </div>
  );
}

export default FirmOverviewCharts;
