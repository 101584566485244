import { Table as AntdTable } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import InvestmentLink from "Components/InvestmentLink";
import { eachMonthOfInterval } from "date-fns";
import { asDisplayDate } from "Helpers/index";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import Cell from "../PeerStatistics/Table/Cell";
import SummaryCalcs from "./SummaryCalcs";

interface Params {
  investments?: NonNullable<
    types.PeersReturnsStreamQuery["investment"]["peersStats"]
  >["items"];
  returnsStream?: (number | null | undefined)[][];
  startDate?: Date;
  endDate?: Date;
}

export type PeerInvestmentItem = {
  id: number;
  name?: string | null;
  isEntitled: boolean;
  returns: {
    date: Date;
    value?: number | null | undefined;
  }[];
};

function getTableColumns(
  data: PeerInvestmentItem[],
  dateInterval: Date[]
): ColumnsType<PeerInvestmentItem> {
  const focusedInvestment = data[0];
  const nameFilters: {
    filterMode: ColumnType<PeerInvestmentItem>["filterMode"];
    filters: ColumnType<PeerInvestmentItem>["filters"];
    onFilter: ColumnType<PeerInvestmentItem>["onFilter"];
  } = {
    filterMode: "tree",
    filters: data.slice(1, -1).map(datum => ({
      text: datum.name,
      value: datum.id,
    })),
    onFilter: (value, record: PeerInvestmentItem) => {
      if (record.id === focusedInvestment.id) return true;
      return record.id === value;
    },
  };

  const dateColumns: ColumnsType<PeerInvestmentItem> = dateInterval.map(
    (date, index) => ({
      title: asDisplayDate(date),
      // dataIndex: ["records", index.toString(), "value"],
      key: asDisplayDate(date),
      sorter: (a, b) =>
        (a.returns[index].value || -Infinity) -
        (b.returns[index].value || -Infinity),
      width: 85,
      render: (text, record) => <Cell value={record.returns[index].value} />,
    })
  );

  return [
    {
      title: I18n.t("analytics.peer_statistics.name"),
      dataIndex: "investment",
      key: "investment",
      width: 200,
      render: (text, record) => {
        const { id, name, isEntitled } = record;
        return (
          <InvestmentLink
            className="fund-link-wrapper"
            investmentId={id}
            isEntitled={isEntitled}
          >
            {name}
          </InvestmentLink>
        );
      },
      fixed: "left",
      ...nameFilters,
    },
    ...dateColumns,
  ];
}

const Table = ({
  investments,
  returnsStream,
  startDate,
  endDate,
}: Params): JSX.Element => {
  const baseInvestmentId = investments?.[0]?.id;
  const dateInterval = eachMonthOfInterval({
    start: startDate ?? 0,
    end: endDate ?? 0,
  });
  const data: PeerInvestmentItem[] | null = useMemo(() => {
    if (!investments || !returnsStream || isEmpty(returnsStream)) return null;

    return investments.map((investment, iIndex) => ({
      id: investment.id,
      name: investment.name,
      isEntitled: investment.isEntitled,
      returns: dateInterval.map((date, dIndex) => ({
        date,
        value: returnsStream[iIndex] ? returnsStream[iIndex][dIndex] : null,
      })),
    }));
  }, [investments, returnsStream, dateInterval]);

  const columns = useMemo(
    () => getTableColumns(data || [], dateInterval),
    [data]
  );

  return (
    <AntdTable
      rowKey={record => record.id}
      scroll={{ x: 1500 }}
      size="small"
      rowClassName={record =>
        (record.id === baseInvestmentId && "bg-blue-grey-50") || ""
      }
      data-cy="peer-returns-table"
      loading={!data}
      columns={columns}
      dataSource={data || []}
      pagination={false}
      summary={data => <SummaryCalcs dataPoints={data} dates={dateInterval} />}
    />
  );
};

export default Table;
