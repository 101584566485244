import { gql } from "_graphql-types/gql";

export const GET_RELATIVE_DATE_RANGES = gql(/* GraphQL */ `
  query GET_RELATIVE_PERF_DATE_RANGES($investmentId: Int) {
    investment(id: $investmentId) {
      id
      performancePeriodRange {
        start
        end
      }
      primaryBenchmark {
        id
        name
        performancePeriodRange {
          start
          end
        }
      }
      secondaryBenchmark {
        id
        name
        performancePeriodRange {
          start
          end
        }
      }
    }
  }
`);

export const GET_RELATIVE_STATISTICS = gql(/* GraphQL */ `
  query getRelativeStatistics(
    $investmentId: Int!
    $asOfDate: Date!
    $endDate: DateTime!
    $rangeOffset: Int
    $rangeUnit: StatsRangeUnit
    $primaryBenchmarkId: Int
    $secondaryBenchmarkId: Int
  ) {
    investment(id: $investmentId) {
      id
      name
      performanceMTD(
        filter: { asOfDateRange: { end: $endDate } }
        sort: [{ field: asOfDate, order: DESC }]
      ) {
        returnYear
        returnMonth
        return
        returnType
      }
      secondaryBenchmark {
        id
        name
      }
      primaryBenchmark {
        id
        name
        performanceMTD(
          filter: { asOfDateRange: { end: $endDate } }
          sort: [{ field: asOfDate, order: DESC }]
        ) {
          returnYear
          returnMonth
          return
          returnType
        }
      }
      riskStatsBenchmarkPrimary: groupStats(
        asOfDate: $asOfDate
        includeBenchmarks: true
        wrtInvestmentId: $primaryBenchmarkId
      ) {
        stats {
          alpha: alpha(rangeUnit: I, annualized: false)
          alphaAnnualized: alpha(rangeUnit: I, annualized: true)
          beta(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          correlation(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          trackingError(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          infoRatio(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
        }
      }
      riskStatsBenchmarkSecondary: groupStats(
        asOfDate: $asOfDate
        includeBenchmarks: true
        wrtInvestmentId: $secondaryBenchmarkId
      ) {
        stats {
          alpha: alpha(rangeUnit: I, annualized: false)
          alphaAnnualized: alpha(rangeUnit: I, annualized: true)
          beta(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          correlation(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          trackingError(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          infoRatio(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
        }
      }
      groupStats(asOfDate: $asOfDate, includeBenchmarks: true) {
        items {
          id
          name
        }
        stats {
          asOfDate
          compoundReturnYtd: compoundReturn(rangeUnit: Y, annualized: false)
          compoundReturnMtd: compoundReturn(annualized: false)
          compoundReturnQtd: compoundReturn(rangeUnit: Q, annualized: false)
          compoundReturn6m: compoundReturn(rangeOffset: 5, annualized: false)
          compoundReturn1y: compoundReturn(rangeOffset: 11, annualized: false)
          compoundReturn3y: compoundReturn(rangeOffset: 35, annualized: true)
          compoundReturn5y: compoundReturn(rangeOffset: 59, annualized: true)
          compoundReturnItd: compoundReturn(rangeUnit: I, annualized: true)
          compoundReturn(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          stdDev(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          sharpeRatio(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          percentProfitablePeriods(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
          )
          skewness(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          kurtosis(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          maxDrawDown(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          lengthOfMaxDrawDown(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          recoveryFromMaxDrawDown(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
          )
        }
      }
    }
  }
`);
