import { gql } from "_graphql-types/gql";

export const GET_BENCHMARK_MONTHLY_RETURNS = gql(/* GraphQL */ `
  query GetBenchmarkMonthlyReturns($investmentId: Int!) {
    investment(id: $investmentId) {
      name
      performanceMTD(sort: [{ field: asOfDate, order: DESC }]) {
        returnYear
        returnMonth
        return
        returnType
      }
      primaryBenchmark {
        id
        name
        performanceMTD(sort: [{ field: asOfDate, order: DESC }]) {
          returnYear
          returnMonth
          return
          returnType
        }
      }
    }
  }
`);
