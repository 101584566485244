import React, { useMemo } from "react";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
// Note that Highcharts has to be in the codebase already
// Highcharts more

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import { Maybe } from "_graphql-types/graphql";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

interface SeriesData {
  name: string;
  data: (Maybe<number> | undefined)[];
}

function ColumnChart({
  categories,
  chartData,
  title,
}: {
  title: string;
  categories: string[];
  chartData?: SeriesData[];
}): JSX.Element {
  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: {
        text: title,
      },
      xAxis: {
        categories,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "% Return",
        },
        min: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: chartData,
    }),
    [chartData]
  );

  return (
    <HighChartsReact
      constructorType="chart"
      options={options}
      highcharts={HighCharts}
    />
  );
}

export default ColumnChart;
