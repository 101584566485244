import React, { useState } from "react";
import * as types from "_graphql-types/graphql";
import { InfiniteScroller } from "Components/InfiniteScroller";
import { InvestmentTiles } from "Components/InvestmentTiles";
import { usePortfolioSearch } from "./InvestmentSearch.hooks";

export function PortfolioWorkspace() {
  const { result, error, loading } = usePortfolioSearch();
  const [fetching, setFetching] = useState(false);

  if (error) {
    return (
      <div data-cy="global-search-errors" data-id="global-search-errors">
        Something went wrong
      </div>
    );
  }

  return (
    <>
      <InvestmentTiles investmentIds={result?.investmentIds ?? []} />
      <InfiniteScroller
        isLoading={!!(loading || fetching)}
        hasMore={!!result?.nextPage?.hasMore}
        loadMore={() => {
          if (!result) return;
          console.log("result.items.length ->", result.items.length);
          console.log("result.total ->", result.total);
          if (result.items.length >= result.total) {
            return;
          }
          setFetching(true);
          result.fetchMore({
            variables: {
              page: {
                limit: 24,
                offset: result.items.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              try {
                if (!fetchMoreResult) return prev;
                const { items, ...result } =
                  fetchMoreResult.investmentList as types.InvestmentList;
                const prevItems = prev.investmentList?.items;
                return {
                  investmentList: {
                    ...result,
                    items: [...(prevItems || []), ...items],
                  },
                };
              } finally {
                setFetching(false);
              }
            },
          });
        }}
      />
    </>
  );
}
