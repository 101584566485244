import * as types from "_graphql-types/graphql";
import { DatePicker as AntDatePicker, Form } from "antd";
import momentConfig from "rc-picker/lib/generate/moment";
/* eslint-disable max-lines-per-function */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getCompanyFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { isNumber } from "../..";
import { FilterInterface } from "../FilterInterface";

const DatePicker = AntDatePicker.generatePicker(momentConfig);

const { Item } = Form;

export function YearFoundedFilter() {
  const investmentFilters = useSelector(getCompanyFilters);

  const dispatch = useDispatch();

  const yearFoundedFilter = useMemo(() => {
    for (const filter of investmentFilters) {
      if (filter.type === "YEAR_FOUNDED") {
        return filter.data;
      }
    }
  }, [investmentFilters]);

  const defaultFilter: types.IntRangeFilter = {};

  const [localFilter, setLocalFilter] = useState(
    yearFoundedFilter ?? defaultFilter
  );

  const reset = () => {
    dispatch(
      removeFilter({
        type: "company",
        data: {
          type: "YEAR_FOUNDED",
          data: defaultFilter,
        },
      })
    );
  };

  const set = () => {
    if (isNumber(localFilter.min) || isNumber(localFilter.max)) {
      dispatch(
        updateFilter({
          type: "company",
          data: {
            type: "YEAR_FOUNDED",
            data: localFilter,
          },
        })
      );
    }
  };

  return (
    <FilterInterface
      label="Year Founded"
      count={yearFoundedFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown-datepicker"
    >
      <div className="main-dropdown__menu-body">
        <div className="filters__interseption-datepicker-wrap">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Item label="Start">
              <DatePicker
                data-cy="Year-Founded-Start"
                onChange={(_, dateString) => {
                  setLocalFilter({
                    ...localFilter,
                    min: parseInt(String(dateString)),
                  });
                }}
                picker="year"
              />
            </Item>
            <Item label="End">
              <DatePicker
                data-cy="Year-Founded-End"
                onChange={(_, dateString) => {
                  setLocalFilter({
                    ...localFilter,
                    max: parseInt(String(dateString)),
                  });
                }}
                picker="year"
              />
            </Item>
          </Form>
        </div>
      </div>
    </FilterInterface>
  );
}
