import { gql } from "_graphql-types/gql";

export const PresignedTempUrl = gql(/* GraphQL */ `
  query PresignedTempUrl($input: PresignedTempUrlInput!) {
    presignedTempUrl(input: $input) {
      bucket
      key
      url
    }
  }
`);

export const TempTableExtraction = gql(/* GraphQL */ `
  query TempTableExtraction($key: String!) {
    tempTableExtraction(key: $key) {
      statusCode
      message
      body
    }
  }
`);
