import React, { ReactElement } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";

import InvestmentPage from "./InvestmentPage.container";

function componentRouter(): ReactElement<any, any> | null {
  const { investmentId } = useParams();

  if (!investmentId) return null;

  return (
    <Routes>
      <Route
        path=":selectedTabName/*"
        element={<InvestmentPage investmentId={investmentId} />}
      />

      <Route path="" element={<InvestmentPage investmentId={investmentId} />} />
    </Routes>
  );
}

export default componentRouter;
