export function booleanToAnswer(bool) {
  if (!bool) return "-";
  return bool === true ? "Yes" : "No";
}

export function underscore(string) {
  return string
    .replace(/([A-Z])/g, "_$1")
    .replace(/([a-z])(\d)/g, "$1_$2")
    .toLowerCase();
}

export function abbreviation(string) {
  if (!string) {
    return "";
  }

  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join("");
}
