import React, { Suspense, lazy, useMemo } from "react";
import { Route, Routes, useParams, Navigate } from "react-router-dom";
import * as types from "_graphql-types/graphql";
import FirmTeam from "Components/FirmTeam";
import DocumentsPage from "Components/show/documents";
import Template from "frontend/src/components/Template";
import { editMode as getEditMode } from "Selectors/investment_edit_mode_selectors";
import { useSelector } from "react-redux";
import FirmOrCompanyInvestments from "Components/FirmOrCompanyInvestments";
import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import NotFound from "Components/show/not_found.js";
import FirmPageTabs from "./FirmPageTabs";
import FirmPageNavBar from "./FirmPageNavBar";
import FirmOverview from "./FirmOverview";
import FirmNotes from "./FirmPageNotes";
import FirmOrCompanyVisualizations from "../Visualizations";
import { Spinner } from "Components/show/spinner.js";

const FirmRouterContent = () => {
  const params = useParams<{ selectedTabName: string; firmId: string }>();
  const Template = useMemo(
    () => lazy(() => import("frontend/src/components/Template")),
    []
  );

  if (!params.firmId) return null;

  const firmId = Number(params.firmId);

  const editMode = useSelector(state => getEditMode(state));

  switch (params.selectedTabName) {
    case "team":
      return <FirmTeam firmId={firmId} />;
    case "investments":
      return <FirmOrCompanyInvestments entityId={firmId} />;
    case "DEI":
      return <FirmOrCompanyVisualizations entityId={firmId} />;
    case "documents":
      return <DocumentsPage firmId={firmId} showChat={true} />;
    case "notes":
      return <FirmNotes firmId={firmId} />;
    case "operations":
      return (
        <div style={{ paddingTop: "17px" }}>
          <Suspense fallback={<Spinner />}>
            <Template
              editMode={editMode}
              ownerType={types.FieldOwnerType.Firm}
              ownerId={firmId}
              name={types.FieldSetType.Operations}
              key={`${types.FieldSetType.Operations}::${firmId}`}
            />
          </Suspense>
        </div>
      );
    case "impact":
      return (
        <div style={{ paddingTop: "17px" }}>
          <Suspense fallback={<Spinner />}>
            <Template
              editMode={editMode}
              ownerType={types.FieldOwnerType.Firm}
              ownerId={firmId}
              name={types.FieldSetType.Impact}
              key={`${types.FieldSetType.Impact}::${firmId}`}
            />
          </Suspense>
        </div>
      );
    default:
      return <FirmOverview firmId={firmId} />;
  }
};

const FIRM_ACCESS = gql(/* GraphQL */ `
  query FirmAccess($id: Int!) {
    firm(id: $id) {
      id
      companyId
      isCompany
      access {
        read
        preview
      }
    }
  }
`);

function componentRouter(): JSX.Element | null {
  const { firmId: firmIdPath } = useParams<{
    selectedTabName: string;
    firmId: string;
  }>();

  const firmId = Number(firmIdPath);

  const { data, loading, error } = useQuery(FIRM_ACCESS, {
    variables: {
      id: firmId,
    },
  });

  if (loading) return null;

  if (error) return <>Something went wrong loading the firm</>;
  if (!data?.firm) return <NotFound />;

  if (data?.firm.companyId && data?.firm.isCompany)
    // always display company page if firm is a company
    return <Navigate to={`/company/${data.firm.companyId}`} />;

  if (data.firm.access.read === false && data.firm.access.preview === true) {
    return (
      <>
        <FirmPageNavBar firmId={firmId} />
        <FirmRouterContent />
        <FirmOrCompanyInvestments entityId={firmId} disableActions />;
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path=":selectedTabName/*"
          element={
            <>
              <FirmPageNavBar firmId={firmId} />
              <FirmPageTabs firmId={firmId} />
              <FirmRouterContent />
            </>
          }
        />

        <Route
          path=""
          element={
            <>
              <FirmPageNavBar firmId={firmId} />
              <FirmPageTabs firmId={firmId} />
              <FirmRouterContent />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default componentRouter;
