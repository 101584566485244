import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Input, Modal, Select, Spin, Tabs } from "antd";
import { MODIFY_USER_SETTINGS } from "Components/GlobalSearchWorkspace/graphql";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { gql } from "_graphql-types/gql";
import { useDebounce } from "Helpers/hooks";
import { useCurrentUser } from "frontend/src/utils/hooks";
import i18n from "i18next";
import { difference, uniq } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import * as types from "_graphql-types/graphql";
import { useQueryWithSkip } from "frontend/src/utils/graphql";
import {
  CREATE_INVESTMENT_SET,
  FETCH_INVESTMENT_SET,
  UPDATE_INVESTMENT_SET,
} from "./graphql";
import InvestmentSetSelect from "./InvestmentSetSelect";

const { TextArea } = Input;

enum ACCESS_LEVEL {
  Organization = 1,
  Group = 2,
  User = 3,
  Public = 4,
}

const { Option } = Select;

export type TInvestmentSet = types.InvestmentSetQuery["investmentSet"];

const FETCH_INVESTMENTS = gql(/* GraphQL */ `
  query getInvestments($filter: [InvestmentSearchFilters!]!) {
    investmentList(page: { limit: 100 }, searchFilters: $filter) {
      items {
        id
        name
      }
      total
      nextPage {
        offset
        limit
        hasMore
      }
    }
  }
`);

function nonNull<T>(ts: T[]) {
  return ts.filter(t => t != null) as NonNullable<T>[];
}

function InvestmentsInput({
  investmentIds,
  selectedList,
  updateForm,
}: {
  investmentIds: number[] | null | undefined;
  selectedList: TInvestmentSet | null | undefined;
  updateForm: ({ investmentIds }: Partial<FormData>) => void;
}) {
  const [query, setQuery] = useState("");

  const filter = useMemo(() => {
    if (!query && investmentIds) {
      return [
        {
          INVESTMENT_ENTITY: {
            values: investmentIds.map(id => ({
              id,
              label: `Investment-${id}`,
            })),
          },
        },
      ];
    }
    return [
      {
        SEARCH_TERM: {
          value: query,
        },
      },
    ];
  }, [query, investmentIds]);

  const debouncedFilter = useDebounce(filter, 1000);

  const { data, loading } = useQuery(FETCH_INVESTMENTS, {
    variables: {
      filter: debouncedFilter,
    },
  });

  useEffect(() => {
    // prevent backspace from clearing investments
    if (global && global.document) {
      const handleBackspace = (e: any) => {
        if (e.keyCode === 8 && e.target.value === "") {
          e.stopPropagation();
        }
      };
      const selector = "#set-select input.ant-select-selection-search-input";
      const element = global.document.querySelector(selector);
      if (element) element.addEventListener("keydown", handleBackspace);
      return () => window.removeEventListener("keydown", handleBackspace);
    }
    return () => {};
  }, []);

  const investmentSet = useMemo(() => new Set(investmentIds), [investmentIds]);

  const selectedListInvestmentIds = useMemo(
    () =>
      (selectedList?.investmentSetItems ?? []).map(
        ({ investmentId }) => investmentId
      ),
    [selectedList]
  );

  const investmentsToRemove = useMemo(
    () => difference(selectedListInvestmentIds, investmentIds || []).length,
    [selectedListInvestmentIds, investmentIds]
  );

  const investmentsToAdd = useMemo(
    () => difference(investmentIds || [], selectedListInvestmentIds).length,
    [selectedListInvestmentIds, investmentIds]
  );

  return (
    <div className="main-input" id="set-select">
      <Select
        mode="multiple"
        value={investmentIds || []}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        className="investment-set-list-select"
        filterOption={false}
        onSearch={setQuery}
        maxTagCount={0}
        // there is a problem with the antd type for multiples where value can't be number[] if onSelect is number
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect={(value: any) => {
          updateForm({ investmentIds: Array.from(investmentSet.add(value)) });
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onDeselect={(value: any) => {
          // only allow deselection for investments we have access to
          if (
            data?.investmentList.items.find(
              ({ id }: { id: number }) => id === value
            )
          ) {
            investmentSet.delete(value);
            updateForm({ investmentIds: Array.from(investmentSet) });
          }
        }}
        placeholder="Select Investments"
      >
        {data &&
          data.investmentList.items.map(({ name, id }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
      </Select>
      <div>{`Investments Added (${investmentsToAdd})`}</div>
      <div>{`Investments Removed (${investmentsToRemove})`}</div>
    </div>
  );
}

const FETCH_SET = gql(/* GraphQL */ `
  query getSets($filter: SetFilter!) {
    setList(page: { limit: 100 }, filter: $filter) {
      items {
        id
        name
      }
      total
      nextPage {
        offset
        limit
        hasMore
      }
    }
  }
`);

function SetInput({
  setId,
  updateForm,
}: {
  setId: number | null | undefined;
  updateForm: ({ setId }: Partial<FormData>) => void;
}) {
  const [query, setQuery] = useState("");

  const filter = useMemo(() => {
    return {
      q: query,
      setType: types.SetType.Investment,
      subscriptionLevelEnumId: types.SetSubscriptionLevel.Subscribeable,
    };
  }, [query]);

  const debouncedFilter = useDebounce(filter, 1000);

  const { data, loading } = useQuery(FETCH_SET, {
    variables: {
      filter: debouncedFilter,
    },
  });

  useEffect(() => {
    // prevent backspace from clearing investments
    if (global && global.document) {
      const handleBackspace = (e: any) => {
        if (e.keyCode === 8 && e.target.value === "") {
          e.stopPropagation();
        }
      };
      const selector = "#set-select input.ant-select-selection-search-input";
      const element = global.document.querySelector(selector);
      if (element) element.addEventListener("keydown", handleBackspace);
      return () => window.removeEventListener("keydown", handleBackspace);
    }
    return () => {};
  }, []);

  return (
    <div className="main-input" id="set-select">
      <Select
        showSearch
        value={setId}
        showArrow={false}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        className="set-list-select"
        filterOption={false}
        onSearch={setQuery}
        allowClear
        onSelect={(value: any) => {
          updateForm({ setId: value });
        }}
        onClear={() => {
          updateForm({ setId: null });
        }}
        placeholder="Base list off of existing Set"
      >
        {data &&
          data.setList.items.map(({ name, id }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
      </Select>
    </div>
  );
}

function DescriptionInput({
  description,
  updateForm,
}: {
  description: string | null | undefined;
  updateForm: ({ description }: Partial<FormData>) => void;
}) {
  return (
    <div className="main-input">
      <div className="main-input__label">Description</div>
      <TextArea
        value={description || ""}
        rows={2}
        onChange={({ target: { value } }) => updateForm({ description: value })}
      />
    </div>
  );
}

function AccessLevelInput({
  accessLevelId,
  updateForm,
}: {
  accessLevelId: number;
  updateForm: ({ accessLevelId }: Partial<FormData>) => void;
}) {
  return (
    <div className="main-input">
      <div className="main-input__label">Access Level</div>

      <Select
        value={accessLevelId}
        onChange={(value: number) => updateForm({ accessLevelId: value })}
      >
        <Option value={ACCESS_LEVEL.Organization}>Organization</Option>
        <Option value={ACCESS_LEVEL.User}>User</Option>
        <Option value={ACCESS_LEVEL.Public}>Public</Option>
      </Select>
    </div>
  );
}

const FETCH_USER_GROUPS = gql(/* GraphQL */ `
  query getUserGroups($currentUserId: Int!) {
    userList(filter: { isGroup: true, groupMembers: [$currentUserId] }) {
      items {
        id
        userName
      }
    }
  }
`);

function GroupSubscriptionInput({
  subscriptionGroupIds,
  updateForm,
}: {
  subscriptionGroupIds: number[] | null | undefined;
  updateForm: ({ subscriptionGroupIds }: Partial<FormData>) => void;
}) {
  const currentUser = useCurrentUser();

  const { data, loading } = useQuery(FETCH_USER_GROUPS, {
    variables: {
      currentUserId: currentUser.id,
    },
  });

  const groupSet = useMemo(
    () => new Set(subscriptionGroupIds),
    [subscriptionGroupIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        Subscribed Groups
        {` (${(subscriptionGroupIds && subscriptionGroupIds.length) || 0})`}
      </div>

      <Select
        mode="multiple"
        value={subscriptionGroupIds || []}
        notFoundContent={loading ? <Spin size="small" /> : null}
        style={{ width: "100%" }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect={(value: any) => {
          updateForm({ subscriptionGroupIds: Array.from(groupSet.add(value)) });
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onDeselect={(value: any) => {
          // only allow deselection for groups we are a member of.
          if (
            data?.userList.items.find(({ id }: { id: number }) => id === value)
          ) {
            groupSet.delete(value);
            updateForm({ subscriptionGroupIds: Array.from(groupSet) });
          }
        }}
        placeholder="Select Groups"
      >
        {data &&
          data.userList.items.map(({ userName, id }) => (
            <Option value={id} key={id}>
              {userName}
            </Option>
          ))}
      </Select>
    </div>
  );
}

function OrganizationSubscriptionInput({
  subscriptionOrganizationIds,
  updateForm,
}: {
  subscriptionOrganizationIds: number[] | null | undefined;
  updateForm: ({ subscriptionOrganizationIds }: Partial<FormData>) => void;
}) {
  const currentUser = useCurrentUser();

  const organizationSet = useMemo(
    () => new Set(subscriptionOrganizationIds),
    [subscriptionOrganizationIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        {`Organization Subscriptions (${
          (subscriptionOrganizationIds && subscriptionOrganizationIds.length) ||
          0
        })`}
      </div>
      <Checkbox
        checked={organizationSet.has(currentUser.organization.id)}
        onChange={() => {
          if (organizationSet.has(currentUser.organization.id)) {
            organizationSet.delete(currentUser.organization.id);
            updateForm({
              subscriptionOrganizationIds: Array.from(organizationSet),
            });
          } else {
            organizationSet.add(currentUser.organization.id);
            updateForm({
              subscriptionOrganizationIds: Array.from(organizationSet),
            });
          }
        }}
      >
        Subscribe my Organization
      </Checkbox>
    </div>
  );
}

function UserSettingsInput({
  updateForm,
  selectedListId,
}: {
  updateForm: ({ updateUserSettings }: { updateUserSettings: boolean }) => void;
  selectedListId?: number | null;
}) {
  const currentUser = useCurrentUser();
  const settings = currentUser.userSettings?.data?.defaultInvestmentView;

  const isDefaultView = useMemo(
    () =>
      !!selectedListId &&
      settings?.type === types.SettingEntityType.InvestmentSet &&
      settings.id === selectedListId,
    [selectedListId, currentUser.userSettings]
  );

  const [checked, setChecked] = useState(isDefaultView);

  return (
    <div className="main-input" data-cy="favoritesListModal__userSettingsInput">
      <div className="main-input__label">User Settings</div>
      <Checkbox
        checked={checked}
        onChange={e => {
          updateForm({
            updateUserSettings: e.target.checked,
          });
          setChecked(e.target.checked);
        }}
      >
        Set as My Default View
      </Checkbox>
    </div>
  );
}

function UserSubscriptionInput({
  subscriptionUserIds,
  updateForm,
}: {
  subscriptionUserIds: number[];
  updateForm: ({ subscriptionUserIds }: Partial<FormData>) => void;
}) {
  const currentUser = useCurrentUser();

  const userSet = useMemo(
    () => new Set(subscriptionUserIds),
    [subscriptionUserIds]
  );

  return (
    <div className="main-input">
      <div className="main-input__label">
        {`User Subscriptions (${subscriptionUserIds.length})`}
      </div>
      <Checkbox
        checked={userSet.has(currentUser.id)}
        onChange={() => {
          if (userSet.has(currentUser.id)) {
            userSet.delete(currentUser.id);
            updateForm({
              subscriptionUserIds: Array.from(userSet),
            });
          } else {
            userSet.add(currentUser.id);
            updateForm({
              subscriptionUserIds: Array.from(userSet),
            });
          }
        }}
      >
        Subscribe myself
      </Checkbox>
    </div>
  );
}

interface InvestmentSetFormProps {
  handleCancel: () => void;
  visible: boolean;
  selectedList?: TInvestmentSet;
}

export type FormData = types.InvestmentSetInput & {
  updateUserSettings: boolean;
};

/* eslint max-lines-per-function: ["error", 300] */
function InvestmentSetForm({
  handleCancel,
  visible,
  selectedList: selectedListProp,
}: InvestmentSetFormProps) {
  const { selectedInvestmentIds, clearSelected } = useContext(
    InvestmentControlsContext
  );
  const currentUser = useCurrentUser();

  const defaultFormState: FormData = {
    name: "",
    description: "",
    accessLevelId: ACCESS_LEVEL.Organization,
    investmentIds: [...selectedInvestmentIds],
    subscriptionOrganizationIds: [],
    subscriptionGroupIds: [],
    subscriptionUserIds: [currentUser.id],
    updateUserSettings: false,
    setId: null,
  };

  const [formData, setFormData] = useState<FormData>(defaultFormState);

  const [selectedList, setSelectedList] = useState<TInvestmentSet | null>(
    selectedListProp || null
  );

  const isDynamic = !!selectedList?.isDynamic;

  const updateFormData = (newData: Partial<FormData>) => {
    setFormData({ ...formData, ...newData });
  };

  useEffect(() => {
    if (!selectedList) {
      updateFormData({
        accessLevelId: defaultFormState.accessLevelId,
        subscriptionGroupIds: defaultFormState.subscriptionGroupIds,
        subscriptionUserIds: defaultFormState.subscriptionUserIds,
        subscriptionOrganizationIds:
          defaultFormState.subscriptionOrganizationIds,
        investmentIds: defaultFormState.investmentIds,
        setId: defaultFormState.setId,
      });
    } else {
      updateFormData({
        name: selectedList.name || defaultFormState.name,
        accessLevelId:
          selectedList.accessLevel?.id || defaultFormState.accessLevelId,
        description: selectedList.description || defaultFormState.description,
        investmentIds: uniq([
          ...(formData.investmentIds || []),
          ...(selectedList?.investmentSetItems || []).map(
            ({ investmentId }) => investmentId
          ),
        ]),
        subscriptionGroupIds: uniq([
          ...(formData.subscriptionGroupIds ?? []),
          ...nonNull(
            (selectedList?.groupSubscriptions ?? []).map(
              ({ group }) => group?.id
            )
          ),
        ]),
        subscriptionUserIds: uniq([
          ...nonNull(
            (selectedList?.userSubscriptions || []).map(({ user }) => user?.id)
          ),
        ]),
        subscriptionOrganizationIds: uniq([
          ...(formData.subscriptionOrganizationIds || []),
          ...nonNull(
            (selectedList?.organizationSubscriptions || []).map(
              ({ organization }) => organization?.id
            )
          ),
        ]),
        setId: selectedList.setId || defaultFormState.setId,
      });
    }
  }, [selectedList]);

  const [
    addInvestmentSet,
    { error: addError, loading: isAdding, data: addInvestmentSetData },
  ] = useMutation(CREATE_INVESTMENT_SET, {
    refetchQueries: ["subscribedLists"],
  });

  const [updateInvestmentSet, { error: updateError, loading: isUpdating }] =
    useMutation(UPDATE_INVESTMENT_SET, {
      refetchQueries: [
        "subscribedLists", // subscription change
        "fetchInvestmentSetInvestments", // list change
      ],
    });

  const [modifyUserSettings, { error: modifyUserSettingsError }] =
    useMutation(MODIFY_USER_SETTINGS);

  const errors = useMemo(() => {
    const accuErrors = [updateError, addError, modifyUserSettingsError].filter(
      Boolean
    );

    if (accuErrors.length === 0) {
      return false;
    }
    return accuErrors;
  }, [updateError, addError, modifyUserSettingsError]);

  const handleSubmit = async () => {
    const { updateUserSettings, ...data } = formData;
    try {
      if (selectedList) {
        await updateInvestmentSet({
          variables: {
            id: selectedList.id,
            input: data,
          },
        });
      } else {
        await addInvestmentSet({
          variables: {
            input: data,
          },
        });
      }

      clearSelected();
      handleCancel();
    } catch (e) {
      console.error(e);
    }

    if (
      updateUserSettings &&
      (selectedList ?? addInvestmentSetData?.addInvestmentSet.id)
    ) {
      try {
        await modifyUserSettings({
          variables: {
            input: {
              userId: currentUser.id,
              data: {
                defaultInvestmentView: {
                  id: selectedList
                    ? selectedList.id
                    : (addInvestmentSetData?.addInvestmentSet.id as number),
                  type: types.SettingEntityType.InvestmentSet,
                },
              },
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Modal
      title={
        selectedList
          ? i18n.t("funds.form.update_list_message")
          : i18n.t("funds.form.new_list_message")
      }
      okText={selectedList ? "Update" : "Create"}
      open={visible}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !!isUpdating || !!isAdding || !formData.name,
      }}
      onCancel={handleCancel}
    >
      <div id="add-fund-to-favorite-list">
        {errors && (
          <div
            id="favorite-list-name-error"
            className="info-message info-message--red"
          >
            <i className="info-message__icon icon icon-info" />
            <p>{JSON.stringify(errors)}</p>
          </div>
        )}

        <InvestmentSetSelect
          updateForm={updateFormData}
          name={formData.name ?? ""}
          setSelectedList={setSelectedList}
          selectedList={selectedList}
        />
        <DescriptionInput
          updateForm={updateFormData}
          description={formData.description}
        />
        {
          // don't let non creators change access level.
          (!selectedList ||
            selectedList?.createUser === currentUser.username) && (
            <AccessLevelInput
              updateForm={updateFormData}
              accessLevelId={formData.accessLevelId ?? ACCESS_LEVEL.Public}
            />
          )
        }
        {selectedList &&
          selectedList?.createUser.toLowerCase() !==
            (currentUser.username as string).toLowerCase() && (
            <div className="main-input">
              <div className="main-input__label">
                Only the creator of list may change access level:
              </div>
              {selectedList?.createUser}
            </div>
          )}
        {formData.accessLevelId === ACCESS_LEVEL.Organization && (
          <GroupSubscriptionInput
            updateForm={updateFormData}
            subscriptionGroupIds={formData.subscriptionGroupIds}
          />
        )}
        <div className="d-flex">
          {formData.accessLevelId === ACCESS_LEVEL.Organization && (
            <div className="mr-20">
              <OrganizationSubscriptionInput
                updateForm={updateFormData}
                subscriptionOrganizationIds={
                  formData.subscriptionOrganizationIds
                }
              />
            </div>
          )}

          <UserSubscriptionInput
            updateForm={updateFormData}
            subscriptionUserIds={formData.subscriptionUserIds ?? []}
          />
        </div>

        <UserSettingsInput
          updateForm={updateFormData}
          selectedListId={selectedList?.id}
        />
        {!isDynamic && (
          <Tabs
            defaultActiveKey="1"
            style={{ height: 140 }}
            items={[
              {
                label: `Set ${formData.setId ? `(${formData.setId})` : ""}`,
                key: "1",
                children: (
                  <SetInput
                    updateForm={updateFormData}
                    setId={formData.setId}
                  />
                ),
              },
              {
                label: `Investments ${
                  !formData.setId
                    ? `(${formData.investmentIds?.length || 0})`
                    : ""
                }`,
                key: "2",
                disabled: !!formData.setId,
                children: (
                  <InvestmentsInput
                    updateForm={updateFormData}
                    investmentIds={formData.investmentIds}
                    selectedList={selectedList}
                  />
                ),
              },
            ]}
          />
        )}
        {isDynamic && "List is dynamically built."}
      </div>
    </Modal>
  );
}

InvestmentSetForm.defaultProps = {
  selectedList: undefined,
};

function EagerLoadedInvestmentSetForm({
  selectedListId,
  ...otherProps
}: {
  selectedListId?: number;
} & InvestmentSetFormProps) {
  const { data } = useQueryWithSkip(
    FETCH_INVESTMENT_SET,
    selectedListId
      ? {
          investmentSetId: selectedListId,
        }
      : undefined
  );

  const selectedList = useMemo(() => {
    if (!data) return undefined;
    return data.investmentSet;
  }, [data]);

  if (selectedList || !selectedListId) {
    return (
      <InvestmentSetForm
        selectedList={selectedList}
        handleCancel={otherProps.handleCancel}
        visible={otherProps.visible}
      />
    );
  }

  return null;
}

EagerLoadedInvestmentSetForm.defaultProps = {
  selectedListId: undefined,
};

export default EagerLoadedInvestmentSetForm;
