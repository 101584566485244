import { FieldsIndex } from "Components/FieldsIndex";
import NavHeader from "Components/layout/NavHeader";
import React from "react";
import { useNavigate } from "react-router-dom";

export function GlobalFieldsComponent() {
  const navigate = useNavigate();

  return (
    <>
      <NavHeader>
        <div className="invst-header">
          <div className="invst-header__col--left">
            <div className="invst-header__title-wrap">
              <i
                className="invst-header__back-icon icon icon-back icon--24"
                onClick={() => navigate(-1)}
              />

              <div className="invst-header__title">
                <div id="investment-name" className="invst-header__title-txt">
                  Diligence
                </div>
              </div>
            </div>
          </div>

          <div className="invst-header__col--right">
            <div className="text-right hidden-lg-down">
              <span className="no-reports__edit-toggle-spacer" />
            </div>
          </div>
        </div>
      </NavHeader>
      <div>
        <div className="main-content main-content--lg">
          <FieldsIndex />
        </div>
      </div>
    </>
  );
}
