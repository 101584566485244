import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import i18n from "i18next";
import * as types from "_graphql-types/graphql";
import { get } from "lodash";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import FieldRange from "Components/show/overview/FieldRange";
import { formatLargeCurrency } from "Helpers/index";
import { PRIVATE_INVESTMENT_ATTRIBUTES } from "./graphql";

function PrivateInvestmentCard({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [attributes, setAttributes] =
    useState<types.GetPrivateInvestmentQuery["investment"]>();
  const { data, loading } = useQuery(PRIVATE_INVESTMENT_ATTRIBUTES, {
    variables: { id: investmentId },
  });

  useEffect(() => {
    if (!loading && data) {
      setAttributes(data.investment);
    }
  }, [data, loading]);

  const cardFields = [
    {
      key: "investment.private.fundNumber",
      displayName: "Fund Number",
      content: attributes?.peFundNumber?.name,
    },
    {
      key: "investment.private.vintage",
      displayName: "Vintage",
      content: attributes?.vintageYear,
    },
    {
      key: "investment.private.targetSize",
      displayName: "Target Size",
      content: formatLargeCurrency(attributes?.targetRaise),
    },
    {
      key: "investment.private.hardCap",
      displayName: "Fund Hard Cap",
      content: formatLargeCurrency(attributes?.hardCap),
    },
    {
      key: "investment.capital.committed.value",
      displayName: "Committed Capital",
      content: formatLargeCurrency(
        attributes?.committedCapital,
        attributes?.committedCapitalCurrency?.name
      ),
    },
    {
      key: "investment.capital.gp_committed.value",
      displayName: "GP Commitment",
      content: formatLargeCurrency(
        attributes?.gpCommitted,
        attributes?.gpCommittedCurrency?.name
      ),
    },
    {
      key: "investment.family.name",
      displayName: "Family Name",
      content: get(attributes, "family.name", "-"),
    },
  ];

  const rangeFields = [
    {
      key: "investment.performance.net_irr",
      displayName: "Target IRR",
      rangeStart: get(attributes, "minTargetNetIRR"),
      rangeEnd: get(attributes, "maxTargetNetIRR"),
      numberFormat: "whole_percent",
    },
    {
      key: "investment.performance.moic",
      displayName: "Target MOIC",
      rangeStart: get(attributes, "minTargetNetMOIC"),
      rangeEnd: get(attributes, "maxTargetNetMOIC"),
      numberFormat: "multiple",
    },
  ];

  return (
    <div
      className="overview-page__two-equal-col invt-tab__section"
      id="investment"
    >
      <h3 className="invt-tab__title">{i18n.t("fund_summary.title")}</h3>
      <div className="overview-page__card">
        {cardFields.map(field => (
          <FieldInlinePreview
            key={field.key}
            className="cms-broke-long-value"
            displayName={field.displayName}
            content={field.content}
          />
        ))}
        {rangeFields.map(field => (
          <FieldRange
            key={field.key}
            className="cms-broke-long-value"
            displayName={field.displayName}
            startRange={field.rangeStart}
            endRange={field.rangeEnd}
            numberFormat={field.numberFormat}
          />
        ))}
      </div>
    </div>
  );
}

export default PrivateInvestmentCard;
