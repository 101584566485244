import React from "react";
import { InvestmentFilter } from "Reducers/globalSearchV2/globalSearchV2.types";
import { EntityFilterTag } from "./EntityFilterTag";
import HeadquartersFilterTag from "./HeadquartersFilterTag";
import InceptionDateFilterTag from "./InceptionDateFilterTag";
import InvestmentSetFilterTag from "./InvestmentSetFilterTag";
import SetFilterTag from "./SetFilterTag";
import MwdbeFilterTag from "./MwdbeFilterTag";
import PerformanceFilterTag from "./PerformanceFilterTag";
import PerformancePrivateFilterTag from "./PerformancePrivateFilterTag";
import SearchTermFilterTag from "./SearchTermFilterTag";
import { SizeFilterTag } from "./SizeFilterTag";
import VintageYearFilterTag from "./VintageYearFilterTag";
import AssetClassStrategyFilterTag from "./AssetClassStrategyFilterTag";
import ImpactFilterTag from "./ImpactFilterTag";
import AccessFilterTag from "./AccessFilterTag";
import InactiveInvestmentFilterTag from "./InactiveInvestmentFilterTag";
import VolatilityFilterTag from "./VolatilityFilterTag";
import VolatilityPrivateFilterTag from "./VolatilityPrivateFilterTag";
import PortfolioFilterTag from "./PortfolioFilterTag";

function InvestmentFilterTag({
  filter,
}: {
  filter: InvestmentFilter;
}): JSX.Element | null {
  switch (filter.type) {
    case "investment": {
      const filter2 = filter.data;
      switch (filter2.type) {
        case "INVESTMENT_ENTITY":
        case "FIRM_ENTITY":
        case "TAG_ENTITY":
        case "TYPE":
        case "PRIMARY_GEOGRAPHY":
          return <EntityFilterTag type={filter2.type} filter={filter2.data} />;
        case "IMPACT":
          return <ImpactFilterTag filter={filter2.data} />;
        case "SEARCH_TERM":
          return <SearchTermFilterTag filter={filter2.data} />;
        case "SIZE":
          return <SizeFilterTag filter={filter2.data} />;
        case "PERFORMANCE":
          return <PerformanceFilterTag filter={filter2.data} />;
        case "VOLATILITY":
          return <VolatilityFilterTag filter={filter2.data} />;
        case "INCEPTION_DATE":
          return <InceptionDateFilterTag filter={filter2.data} />;
        case "PERFORMANCE_PRIVATE":
          return <PerformancePrivateFilterTag filter={filter2.data} />;
        case "VOLATILITY_PRIVATE":
          return <VolatilityPrivateFilterTag filter={filter2.data} />;
        case "VINTAGE_YEAR":
          return <VintageYearFilterTag filter={filter2.data} />;
        case "INVESTMENT_SET":
          return <InvestmentSetFilterTag filter={filter2.data} />;
        case "SET":
          return <SetFilterTag filter={filter2.data} />;
        case "ASSET_CLASS_STRATEGY":
          return <AssetClassStrategyFilterTag filter={filter2.data} />;
        case "DYNAMIC_LIST":
        case "INVESTMENT_LIST":
          return null;
        case "ACCESS":
          return <AccessFilterTag filter={filter2.data} />;
        case "INACTIVE":
          return <InactiveInvestmentFilterTag filter={filter2.data} />;
        case "PORTFOLIO":
          return <PortfolioFilterTag filter={filter2.data} />;
        default:
          const unreachable: never = filter2;
          throw new Error("unhandled case");
      }
    }
    case "firm": {
      const filter2 = filter.data;
      switch (filter2.type) {
        case "HEADQUARTERS":
          return <HeadquartersFilterTag filter={filter2.data} />;
        case "MWDBE":
          return <MwdbeFilterTag filter={filter2.data} />;
        case "DEI":
          return null;
        case "ACCESS":
          return <AccessFilterTag filter={filter2.data} />;
        default:
          const unreachable: never = filter2;
          throw new Error("unhandled case");
      }
    }
    default:
      const unreachable: never = filter;
      throw new Error("unhandled case");
  }
}

export default InvestmentFilterTag;
