/* eslint-disable no-console */
import * as types from "_graphql-types/graphql";
import { InfiniteScroller } from "Components/InfiniteScroller";
import { InvestmentControls } from "Components/InvestmentControls";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  addSort,
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useGlobalInvestmentSearch } from "./InvestmentSearch.hooks";

import { useDispatch } from "src/react-redux";
import { Sort, SortContext } from "../GlobalSearch/context";

const AccessFilterControl = () => {
  const investmentFilters = useSelector(getInvestmentFilters);
  const accessFilter = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of investmentFilters) {
      if (filter.type === "investment" && filter.data.type === "ACCESS") {
        return filter.data.data.values.find(v => v === types.Prud.Read);
      }
    }
  }, [investmentFilters]);
  const dispatch = useDispatch();

  const toggle = () => () => {
    if (accessFilter) {
      return dispatch(
        removeFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: "ACCESS",
              data: { values: [] },
            },
          },
        })
      );
    }
    return dispatch(
      updateFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "ACCESS",
            data: { values: [types.Prud.Read] },
          },
        },
      })
    );
  };
  return (
    <div className="main-select-actions__item" onClick={toggle()}>
      <span className="main-select-actions__text">{`${
        accessFilter ? "Show" : "Hide"
      } Previews`}</span>
    </div>
  );
};

const InactiveInvestmentFilterControl = () => {
  const investmentFilters = useSelector(getInvestmentFilters);
  const inactiveInvestmentFilter = useMemo(() => {
    const filter = investmentFilters.find(
      f => f.type === "investment" && f.data.type === "INACTIVE"
    )?.data.data as types.InactiveInvestmentFilter;
    return filter && filter.hideInactive;
  }, [investmentFilters]);
  const dispatch = useDispatch();

  const toggle = () => () => {
    if (inactiveInvestmentFilter) {
      return dispatch(
        removeFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: "INACTIVE",
              data: { hideInactive: false },
            },
          },
        })
      );
    }
    return dispatch(
      updateFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "INACTIVE",
            data: { hideInactive: true },
          },
        },
      })
    );
  };
  return (
    <div className="main-select-actions__item" onClick={toggle()}>
      <span className="main-select-actions__text">{`${
        inactiveInvestmentFilter ? "Show" : "Hide"
      } Inactives`}</span>
    </div>
  );
};

export function InvestmentSearchWorkspace() {
  const { loading, error, result, preloadedInvestmentKeys } =
    useGlobalInvestmentSearch();

  const [fetching, setFetching] = useState(false);

  const dispatch = useDispatch();
  const [currentSort, setCurrentSort] = useState<Sort>();

  const handleSort = (sort: Sort) => {
    setCurrentSort(sort);
    dispatch(addSort({ type: "investment", data: sort }));
  };

  return (
    <>
      {!!error && (
        <div data-cy="global-search-errors" data-id="global-search-errors">
          {JSON.stringify(error)}
        </div>
      )}
      {!error && (
        <>
          <SortContext.Provider value={{ addSort: handleSort, currentSort }}>
            <InvestmentControls
              investmentIds={result?.investmentIds ?? []}
              allSelectableInvestmentIds={preloadedInvestmentKeys.map(
                ({ id }: { id: number }) => id
              )}
              ExtraControls={() => (
                <>
                  <AccessFilterControl />
                  <InactiveInvestmentFilterControl />
                </>
              )}
            />
          </SortContext.Provider>
          <InfiniteScroller
            isLoading={!!(loading || fetching)}
            hasMore={!!result?.nextPage?.hasMore}
            loadMore={() => {
              if (!result) return;
              console.log("result.items.length ->", result.items.length);
              console.log("result.total ->", result.total);
              if (result.items.length >= result.total) {
                return;
              }
              setFetching(true);
              result.fetchMore({
                variables: {
                  page: {
                    limit: 24,
                    offset: result.items.length,
                  },
                },
                updateQuery: (
                  prev: { investmentList: types.InvestmentList },
                  { fetchMoreResult }
                ) => {
                  try {
                    if (!fetchMoreResult) return prev;
                    const { items, ...result } =
                      fetchMoreResult.investmentList as types.InvestmentList;
                    const prevItems = prev.investmentList?.items;
                    return {
                      investmentList: {
                        ...result,
                        items: [...(prevItems || []), ...items],
                      },
                    };
                  } finally {
                    setFetching(false);
                  }
                },
              });
            }}
          />
        </>
      )}
    </>
  );
}
