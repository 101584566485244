import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { sortBy } from "lodash";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export interface Props {
  filter: types.ImpactSearchFilter;
}

function ImpactFilterTag({ filter }: Props) {
  const dispatch = useDispatch();

  if (!filter || !filter.values.length) return null;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="impact-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "IMPACT",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      Impact: {sortBy(filter.values, ({ id }) => id)[0].label}{" "}
      {filter.values.length - 1 >= 1 && (
        <>
          {(filter.isOr && "or") || "and"} {filter.values.length - 1} other
          {filter.values.length - 1 > 1 && "s"}
        </>
      )}
    </Tag>
  );
}

export default ImpactFilterTag;
