import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";

const ChartTicker = ({ groupName, isMain, statistic }) => {
  const title = i18n.t(`team_summary.${groupName}`);
  const categoryEmployeesCount = statistic[groupName] || "-";

  return isMain ? (
    <div data-cy="team-structure-total" className="team-structure__total">
      {categoryEmployeesCount}

      <p className="team-structure__total-txt">{title}</p>
    </div>
  ) : (
    <div
      data-cy="team-structure-categories"
      className="team-structure__data-item"
    >
      <p className="team-structure__data-count">{categoryEmployeesCount}</p>

      <p className="team-structure__data-title">{title}</p>

      <div className={`team-structure__data-value ${groupName}`} />
    </div>
  );
};

ChartTicker.propTypes = {
  isMain: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  statistic: PropTypes.shape({
    analyst: PropTypes.number,
    trader: PropTypes.number,
    operations: PropTypes.number,
    portfolioManager: PropTypes.number,
    admin: PropTypes.number,
  }),
};

ChartTicker.defaultProps = {
  isMain: false,
};

export default ChartTicker;
