import { useLazyQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { useFirmFromInvestmentId } from "Helpers/get_firm_hook";
import { useEffect, useState } from "react";
import { FETCH_FIRM_CONTACT } from "./graphql";

interface LocationProps {
  country: string;
  city: string;
  state: string;
  line1: string;
  line2: string;
  line3: string;
  zipCode: number | string;
}

interface ItemProps {
  displayName: string;
  content: any;
}

const websiteFormat = (website: string | null) =>
  website
    ? `https://${website.replace("https://", "").replace("http://", "")}`
    : "";

const countryFormat = (country: string) =>
  country
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const LocationLine = ({ line }: { line: string }) => (
  <div className="d-flex flex-wrap">
    <div>{line}</div>
  </div>
);

const RenderLocation = ({
  country,
  state,
  city,
  line1,
  line2,
  line3,
  zipCode,
}: LocationProps) => (
  <div className="flex-row">
    <div className="flex-1">
      <div className="pb-10 in-secondary-blue">Location</div>
    </div>
    <div className="flex-2 cms-broke-long-value">
      <div className="pb-10">
        <LocationLine line={line1} />
        <LocationLine line={line2} />
        <LocationLine line={line3} />
        <LocationLine
          line={
            city + (state ? `, ${state}` : "") + (zipCode ? ` ${zipCode}` : "")
          }
        />
        <LocationLine line={countryFormat(country)} />
      </div>
    </div>
  </div>
);

const RenderItem = ({ displayName, content }: ItemProps) => (
  <div className="flex-row">
    <div className="flex-1">
      <div className="pb-10 in-secondary-blue">{displayName}</div>
    </div>
    <div className="flex-2 cms-broke-long-value">
      <div className="pb-10">{content}</div>
    </div>
  </div>
);

type Address = NonNullable<types.FirmContactsQuery["firm"]>["primaryAddress"];

function SummaryContactTile({ investmentId }: { investmentId: number }) {
  const [address, setAddress] = useState<Address>();
  const { firmId, loading: firmLoading } =
    useFirmFromInvestmentId(investmentId);
  const [getFirmContacts, { loading, data, called }] =
    useLazyQuery(FETCH_FIRM_CONTACT);

  useEffect(() => {
    if (firmId && !called) {
      getFirmContacts({ variables: { id: firmId } });
    }
  }, [firmId, called, firmLoading]);

  useEffect(() => {
    if (called && !loading && data) {
      setAddress(data.firm?.primaryAddress ?? void 0);
    }
  }, [data, loading, called]);

  return (
    <>
      <h3 className="invt-tab__title">Contact</h3>
      <div className="overview-page__card" id="contact">
        <div className="flex-row">
          <div className="contact-table-col">
            <RenderItem displayName="Phone" content={address?.phone ?? "-"} />
            <RenderItem
              displayName="Email"
              content={
                data?.firm?.firmIrEmail ||
                address?.email ||
                data?.firm?.email ||
                "-"
              }
            />
          </div>
          <div className="contact-table-col">
            <RenderItem
              displayName="Website"
              content={
                !address?.website ? (
                  "-"
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={websiteFormat(address.website)}
                  >
                    {address.website
                      .replace("https://", "")
                      .replace("http://", "")}
                  </a>
                )
              }
            />
            <RenderLocation
              country={address?.country?.name ?? "-"}
              state={address?.state?.name ?? "-"}
              city={address?.city ?? "-"}
              zipCode={address?.zipCode ?? "-"}
              line1={address?.line1 || "-"}
              line2={address?.line2 ?? "-"}
              line3={address?.line3 || "-"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SummaryContactTile;
