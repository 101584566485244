import { useApolloClient } from "@apollo/client";
import { TEAM_MEMBER } from "Components/graphql/fragments/Person";
import Modal from "Components/modal.jsx";
import Profile from "Components/PersonCard/PersonModal/profile.jsx";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";

function PartnerProfile({
  selectedPersonId,
}: {
  selectedPersonId: number | null;
}) {
  const client = useApolloClient();

  if (!selectedPersonId) return;

  const person = client.readFragment({
    id: `Person:${selectedPersonId}`,
    fragment: TEAM_MEMBER,
    fragmentName: "TeamMember",
  });
  if (!person) return;

  const navigate = useNavigate();

  const closeProfile = () => {
    navigate(-1);
  };

  return (
    <Modal
      visible
      title={i18n.t("team_summary.profile")}
      onCancel={closeProfile}
      width="840px"
    >
      <Profile person={person} />
    </Modal>
  );
}

export default PartnerProfile;
