import React from "react";
import { ChatToolType } from "_graphql-types/graphql";
import { FileOutlined, FormOutlined } from "@ant-design/icons";

type DocumentsRagToolParams = {
  query: string;
};

const DocumentsRagTool = ({ params }: { params: DocumentsRagToolParams }) => {
  return (
    <div
      data-cy="documents-rag-tool"
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <div>
        <FileOutlined />
        <span style={{ marginLeft: 5 }}>Document RAG</span>
      </div>
      <div style={{ fontSize: 8, color: "gray" }}>{params.query}</div>
    </div>
  );
};

type NotesRagToolParams = {
  query: string;
  "Min-Date": string;
};

const NotesRagTool = ({ params }: { params: NotesRagToolParams }) => {
  return (
    <div
      data-cy="notes-rag-tool"
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <div>
        <FormOutlined />
        <span style={{ marginLeft: 5 }}>Note RAG</span>
      </div>
      <div style={{ fontSize: 8, color: "gray" }}>{params.query}</div>
      {params["Min-Date"] && (
        <div
          style={{ fontSize: 8, color: "gray" }}
        >{`Min Date: ${params["Min-Date"]}`}</div>
      )}
    </div>
  );
};

export const ToolDisplays = ({
  tool,
  params,
}:
  | {
      tool: ChatToolType.DocumentsRagTool;
      params: DocumentsRagToolParams;
    }
  | {
      tool: ChatToolType.NotesRagTool;
      params: NotesRagToolParams;
    }) => {
  switch (tool) {
    case ChatToolType.DocumentsRagTool:
      return <DocumentsRagTool {...{ params }} />;
    case ChatToolType.NotesRagTool:
      return <NotesRagTool {...{ params }} />;
    default:
      return <div>Unknown tool</div>;
  }
};
