import { gql } from "_graphql-types/gql";

export const GET_GROWTH_OF_1000 = gql(/* GraphQL */ `
  query GET_GROWTH_OF_1000(
    $investmentId: Int!
    $benchmarkIds: [Int!]
    $priorTrackRecord: Boolean
  ) {
    investment(id: $investmentId) {
      id
      name
      performanceAnalysis {
        timeSeriesData: growth1000(
          benchmarkIds: $benchmarkIds
          defaultBenchmarks: true
          priorTrackRecord: $priorTrackRecord
        ) {
          root {
            year
            month
            value
          }
          benchmarks {
            id
            name
            records {
              year
              month
              value
            }
          }
        }
      }
    }
  }
`);

export const GET_CUMULATIVE_RETURNS = gql(/* GraphQL */ `
  query GET_CUMULATIVE_RETURNS(
    $investmentId: Int!
    $benchmarkIds: [Int!]
    $priorTrackRecord: Boolean
  ) {
    investment(id: $investmentId) {
      id
      name
      performanceAnalysis {
        timeSeriesData: cumulative(
          benchmarkIds: $benchmarkIds
          defaultBenchmarks: true
          priorTrackRecord: $priorTrackRecord
        ) {
          root {
            year
            month
            value
          }
          benchmarks {
            id
            name
            records {
              year
              month
              value
            }
          }
        }
      }
    }
  }
`);
