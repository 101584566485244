import React from "react";
import {
  CompanyFilter,
  unhandledCase,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { EntityFilterTag } from "./EntityFilterTag";
import HeadquartersFilterTag from "./HeadquartersFilterTag";
import ImpactFilterTag from "./ImpactFilterTag";
import NameFilterTag from "./NameFilterTag";
import YearFoundedFilterTag from "./YearFoundedFilterTag";

function CompanyFilterTag({ filter }: { filter: CompanyFilter }) {
  switch (filter.type) {
    case "TAG_ENTITY":
    case "COMPANY_ENTITY":
      return <EntityFilterTag type={filter.type} filter={filter.data} />;
    case "HEADQUARTERS":
      return <HeadquartersFilterTag filter={filter.data} />;
    case "YEAR_FOUNDED":
      return <YearFoundedFilterTag filter={filter.data} />;
    case "NAME":
      return <NameFilterTag filter={filter.data} />;
    case "IMPACT":
      return <ImpactFilterTag filter={filter.data} />;

    default:
      throw unhandledCase(filter);
  }
}

export default CompanyFilterTag;
