import React, { useMemo, useState } from "react";
import { InfiniteScroller } from "Components/InfiniteScroller";
import { useQuery } from "@apollo/client";
import { InvestmentControls } from "Components/InvestmentControls";
import * as types from "_graphql-types/graphql";

import { gql } from "_graphql-types/gql";
import { Sort, SortContext } from "Components/GlobalSearch/context";

const COMPANY_OR_FIRM_INVESTMENT_TILES = gql(`
  query COMPANY_OR_FIRM_INVESTMENT_TILES(
    $searchFilters: [InvestmentSearchFilters!]
    $filter: InvestmentFilterInput
    $page: PageInput!
    $sort: [InvestmentSort!]
  ) {
    investmentList(
      page: $page
      searchFilters: $searchFilters
      filter: $filter
      sort: $sort
    ) {
      items {
        ...InvestmentTile
      }
      total
      nextPage {
        offset
        limit
        hasMore
      }
    }
  }
`);

function FirmOrCompanyInvestments({
  entityId,
  isCompany = false,
  disableActions = false,
}: {
  entityId: number;
  isCompany?: boolean;
  disableActions?: boolean;
}): JSX.Element {
  const [currentSort, setCurrentSort] = useState<Sort>({
    field: types.InvestmentSortEnum.ModifyDate,
    order: types.SortInput.Desc,
  });

  const handleSort = (sort: Sort) => {
    setCurrentSort(sort);
  };

  type Filters =
    | {
        filter: types.InvestmentFilterInput;
      }
    | {
        searchFilters: types.InvestmentSearchFilters[];
      };

  const filters: Filters = isCompany
    ? { filter: { companyId: entityId } }
    : {
        searchFilters: [
          { FIRM_ENTITY: { values: [{ id: entityId, label: "" }] } },
        ],
      };

  const variables = useMemo(
    () => ({
      page: {
        limit: 24,
        offset: 0,
      },
      ...(currentSort && { sort: [currentSort] }),
      ...filters,
    }),
    [currentSort]
  );

  const { data, loading, fetchMore, error } = useQuery(
    COMPANY_OR_FIRM_INVESTMENT_TILES,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );

  const investmentIds = useMemo(
    () =>
      (data && data.investmentList.items.map(investment => investment.id)) ||
      [],
    [data]
  );

  return (
    <div className="overview-page-wrap">
      <div className="overview-page__content">
        <div className="overview-page__top-info">
          <div className="invt-tab__section">
            <h3 className="invt-tab__title">Investments</h3>

            {error && (
              <div data-cy="global-search-errors">{JSON.stringify(error)}</div>
            )}
            {!error && (
              <>
                <SortContext.Provider
                  value={{ addSort: handleSort, currentSort }}
                >
                  <InvestmentControls
                    disableActions={disableActions}
                    investmentIds={investmentIds}
                    allSelectableInvestmentIds={investmentIds}
                  />
                </SortContext.Provider>
                <InfiniteScroller
                  isLoading={loading}
                  hasMore={!!data && data.investmentList.nextPage.hasMore}
                  loadMore={() => {
                    fetchMore({
                      variables: {
                        page: {
                          limit: 24,
                          offset: data?.investmentList.items.length,
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const newInvestments =
                          fetchMoreResult?.investmentList.items;
                        // https://github.com/apollographql/apollo-client/issues/5897
                        const oldInvestments = prev
                          ? prev.investmentList.items
                          : [];
                        return {
                          investmentList: {
                            ...fetchMoreResult.investmentList,
                            items: [...oldInvestments, ...newInvestments],
                          },
                        };
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirmOrCompanyInvestments;
