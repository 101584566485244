import { useQuery } from "@apollo/client";
import LoadingPanel from "Components/loading_panel";
import React from "react";
import * as types from "_graphql-types/graphql";
import { GET_BENCHMARK_MONTHLY_RETURNS } from "./graphql";
import UpDownCaptureChart from "./UpDownCaptureChart.component";

function UpDownCapture({ investmentId }: { investmentId: number }) {
  const { data, loading, error } = useQuery<{
    investment: types.GetBenchmarkMonthlyReturnsQuery["investment"];
  }>(GET_BENCHMARK_MONTHLY_RETURNS, {
    variables: {
      investmentId,
    },
  });

  return (
    <div className="invt-tab__section overview-page__card">
      <LoadingPanel loading={loading} error={error}>
        <UpDownCaptureChart investment={data?.investment} loading={loading} />
      </LoadingPanel>
    </div>
  );
}

export default UpDownCapture;
