/* eslint-disable max-lines-per-function */
import {
  InvestmentFirmEmployment,
  TeamMemberFragment,
} from "_graphql-types/graphql";
import { getInitials } from "Helpers/index";
import i18n from "i18next";
import { get, uniqBy } from "lodash";
import config from "../../config/config";
import Diversity from "./diversity.jsx";
import SocialLinks from "./social_links";

const About = ({ person }: { person: TeamMemberFragment }): JSX.Element => {
  const hasInvestmentLevelPosition = (
    person.firmEmployment[0].investmentFirmEmployments || []
  ).some(record => record?.employeeRole != null);

  let currentPosition;

  if (hasInvestmentLevelPosition) {
    const firmEmployments = uniqBy(
      person.firmEmployment[0].investmentFirmEmployments,
      record => record?.employeeRole?.name
    );
    currentPosition = firmEmployments
      .filter((r): r is InvestmentFirmEmployment => !!r)
      .map(record => (
        <p key={record.id} className="team-member__info-value">
          {record.employeeRole?.name}
          {record.startDate &&
            `, ${new Date(record.startDate).getUTCFullYear()}`}
        </p>
      ));
  } else {
    currentPosition = (
      <p key={person.firmEmployment[0].id} className="team-member__info-value">
        {person.firmEmployment[0].employeeRole?.name}
        {person.firmEmployment[0].startDate &&
          `, 
        ${new Date(person.firmEmployment[0].startDate).getUTCFullYear()}`}
      </p>
    );
  }

  const { email, phone, phone2, fullName, photoLink, pronouns } = person;

  return (
    <>
      <div data-cy="person-card-name" className="team-member__about">
        <div />
        <p className="team-member__about-name">
          {fullName} {pronouns && ` (${pronouns})`}
        </p>
      </div>

      {photoLink && (
        <img
          alt="profile"
          src={`${config.RCG_SIRIUS_URI()}${photoLink}`}
          style={{
            borderRadius: "50%",
            width: 130,
            height: 130,
            objectFit: "cover",
          }}
        />
      )}
      {!photoLink && (
        <span
          className="cms-overview-advisor__img main-userpic main-userpic--150"
          style={{
            width: 130,
            height: 130,
            objectFit: "cover",
            lineHeight: "130px",
            fontSize: "36px",
          }}
        >
          {getInitials(fullName)}
        </span>
      )}
      <div data-cy="person-card-current-position" className="team-member__info">
        <p className="team-member__info-title">Current Position</p>
        {currentPosition}
      </div>
      <div data-cy="person-card-contacts" className="team-member__info">
        <p className="team-member__info-title">
          {i18n.t("team_summary.contacts")}
        </p>

        {email && (
          <a
            href={`mailto:${email}`}
            className="team-member__info-value d-flex word-break-word"
          >
            <span className="team-member__info-icon icon icon-mail" />
            {get(person, "email", "")}
          </a>
        )}

        {phone && (
          <p className="team-member__info-value d-flex word-break-word">
            <span className="team-member__info-icon icon icon-phone" />
            {phone}
          </p>
        )}

        {phone2 && (
          <p className="team-member__info-value d-flex word-break-word">
            <span className="team-member__info-icon icon icon-phone" />
            {phone2}
          </p>
        )}
      </div>
      <Diversity diversity={get(person, "diversity", undefined)} />
      <div className="team-member__social-links">
        <SocialLinks person={person} />
      </div>
    </>
  );
};

export default About;
