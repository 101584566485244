import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import InvestmentTooltip from "Components/InvestmentTooltip/investment_tooltip_presenter";

const InvestmentLink = ({
  investmentId,
  className,
  children,
  isEntitled,
}: {
  investmentId: number;
  className?: string;
  children: React.ReactNode;
  isEntitled: boolean;
}): JSX.Element => {
  const linkClassName = classNames(className, { "fund-link": isEntitled });

  return (
    <div draggable="false" className="fund-link-wrapper">
      {!isEntitled && <div className={linkClassName}>{children}</div>}
      {isEntitled && (
        <InvestmentTooltip investmentId={investmentId}>
          <Link className={linkClassName} to={`/investments/${investmentId}`}>
            {children}
          </Link>
        </InvestmentTooltip>
      )}
    </div>
  );
};

export default InvestmentLink;
