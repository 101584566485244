/* eslint-disable camelcase */
import React, { useMemo } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { useQuery } from "@apollo/client";
import { FETCH_FIRM_CONTACTS } from "./graphql";
/*
1: Data
2: New Business
3: Data/New Business
4: Investment
5: Other
6: Ops DD
*/

type DataSource = {
  firmId: number;
  key: number;
  is_partner: boolean | null | undefined;
  name: string | null | undefined;
  phone: string | null | undefined;
  email: string | null | undefined;
  contact_type: string | null | undefined;
};

export const CONTACT_TYPES = [1, 2, 3, 4, 5, 6];
const columns: ColumnsType<DataSource> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name: string, record) => (
      <Link to={`/firms/${record.firmId}/team/${record.key}`}>{name}</Link>
    ),
  },
  {
    title: "Partner",
    dataIndex: "is_partner",
    key: "is_partner",
    render: (isPartner: boolean) => <span>{isPartner ? "Yes" : "No"}</span>,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    render: (phone: string) => <a href={`tel:${phone}`}>{phone}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (email: string) => <a href={`mailto: ${email}`}>{email}</a>,
  },
  {
    title: "Contact Type",
    dataIndex: "contact_type",
    key: "contact_type",
  },
];

function FirmContacts({ firmId }: { firmId: number }): JSX.Element | null {
  const { error, data, loading } = useQuery(FETCH_FIRM_CONTACTS, {
    skip: !firmId,
    variables: {
      filter: {
        firmEmployment: {
          isCurrent: true,
          firmId,
          contactTypeEnumIds: CONTACT_TYPES,
        },
      },
      page: {
        offset: 0,
        limit: 10,
      },
    },
  });

  const dataSource = useMemo(() => {
    if (!data) return undefined;

    return data.teamMemberList.items.reduce((accu: DataSource[], person) => {
      const contactEmployment = person.firmEmployment.find(
        employment => !!employment.contactType
      );
      if (!contactEmployment) return accu;
      return [
        ...accu,
        {
          firmId,
          key: person.id,
          is_partner: contactEmployment.isFounder,
          name: person.fullName,
          phone: person.phone,
          email: person.email,
          contact_type: contactEmployment.contactType?.name,
        },
      ];
    }, [] as DataSource[]);
  }, [data]);

  if (error)
    return (
      <span>{JSON.stringify(error)}Something went wrong loading Contacts.</span>
    );

  if (loading) return <span>loading</span>;
  if (!data) return <span>nothing</span>;

  return <Table columns={columns} dataSource={dataSource} pagination={false} />;
}

export default FirmContacts;
