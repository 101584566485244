import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { camelCase } from "lodash";

const FieldInlinePreview = ({ displayName, content, className }) => (
  <div
    className="flex-row"
    data-cy={`fieldInlinePreview__${camelCase(displayName)}`}
  >
    <div className="flex-1">
      <p className="pb-10 pr-4 in-secondary-blue">{displayName}</p>
    </div>

    <div className={classNames("flex-1", className)}>
      <div className="fund-aum pb-10">{content}</div>
    </div>
  </div>
);

FieldInlinePreview.propTypes = {
  displayName: PropTypes.string,
  content: PropTypes.node,
  className: PropTypes.string,
};

FieldInlinePreview.defaultProps = {
  displayName: "",
  className: "",
  content: undefined,
};

export default FieldInlinePreview;
