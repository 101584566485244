import React, { useState } from "react";
import * as types from "_graphql-types/graphql";
import { InfiniteScroller } from "Components/InfiniteScroller";
import { CompanyControls } from "Components/CompanyControls";
import { useGlobalCompanySearch } from "./CompanySearch.hooks";

export function CompanySearchWorkspace() {
  const { loading, error, result } = useGlobalCompanySearch();

  const [fetching, setFetching] = useState(false);

  return (
    <>
      {error && (
        <div data-cy="global-search-errors">{JSON.stringify(error)}</div>
      )}
      {!!result && !error && (
        <>
          <CompanyControls companyIds={result.companyIds} />
          <InfiniteScroller
            isLoading={loading || fetching}
            hasMore={!!result.nextPage.hasMore}
            loadMore={() => {
              if (result.items.length >= result.total) {
                return;
              }
              setFetching(true);
              result.fetchMore({
                variables: {
                  page: {
                    limit: 24,
                    offset: result.items.length,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  const { items, ...res } =
                    fetchMoreResult.companyList as types.CompanyList;
                  const prevItems = prev.companyList.items;
                  setFetching(false);
                  return {
                    companyList: {
                      ...res,
                      items: [...prevItems, ...items],
                    },
                  };
                },
              });
            }}
          />
        </>
      )}
      {loading && (
        <div className=" main-spinner">
          <i id="loading-more-spinner" className="main-spinner__icon" />
        </div>
      )}
    </>
  );
}
