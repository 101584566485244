import React, { useMemo } from "react";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import { groupBy } from "lodash";

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

// interface SeriesData {
//   name: string;
//   data: [string, number][];
// }

interface RawData {
  year: number;
  month: number;
  weight: number;
  name: string;
}

function ExposureColumnCharts({
  rawData,
  label,
  percentDisplay,
}: {
  label: string;
  rawData: RawData[];
  percentDisplay: boolean;
}): JSX.Element {
  const chartData = useMemo(() => {
    if (!rawData) return undefined;
    if (rawData.length === 0) return [];
    const fullRecords = Object.entries(groupBy(rawData, "name"))
      .map(([name, values]) => ({
        name,
        data: values
          .map(
            ({ year, month, weight }) =>
              [new Date(year, month - 1).getTime(), weight] as [number, number]
          )
          .sort((point1, point2) => point2[0] - point1[0]),
      }))
      .filter(
        category => !category.data.map(value => value[1]).every(value => !value)
      ) // some empty sets are returned filter them
      .sort((set1, set2) => set2.data[0][0] - set1.data[0][0]);
    const oneYearDataThreshold = new Date(fullRecords[0].data[0][0]);
    oneYearDataThreshold.setFullYear(oneYearDataThreshold.getFullYear() - 1);
    oneYearDataThreshold.setMonth(oneYearDataThreshold.getMonth() + 1); // Show data for past 12 months instead of 13
    return fullRecords
      .map(({ name, data }) => ({
        name,
        data: data.filter(point => point[0] >= oneYearDataThreshold.getTime()),
      }))
      .filter(
        category => !category.data.map(value => value[1]).every(value => !value)
      ); // some empty sets are returned filter them
  }, [rawData]);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text: `${label} - One Year`,
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
        dateTimeLabelFormats: {
          month: "%b '%y",
        },
      },
      yAxis: {
        labels: {
          format: "{value}%",
        },
        title: {
          enabled: false,
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: `<tr><td style="color:{series.color};padding:0">{series.name}: </td>${
          percentDisplay
            ? '<td style="padding:0"><b>{point.percentage:.2f}%</b></td></tr>'
            : '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>'
        }`,
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        area: {
          stacking: percentDisplay ? "percent" : "normal",
        },
        column: {
          pointPadding: 0.1,
          pointRange: 30 * 24 * 3600 * 1000,
          borderWidth: 0,
          stacking: percentDisplay ? "percent" : "normal",
        },
      },
      series: chartData,
    }),
    [chartData, percentDisplay]
  );

  return (
    <HighChartsReact
      constructorType="chart"
      options={options}
      highcharts={HighCharts}
    />
  );
}

export default ExposureColumnCharts;
