/* eslint-disable max-lines-per-function */

import {
  COMPANY_PATHS,
  INVESTMENT_PATHS,
} from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { Dropdown } from "antd";
import classnames from "classnames";
import i18n from "i18next";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { extractSearchTerms } from "./GlobalSearch.helpers";
import { GlobalSearchDropdown } from "./GlobalSearchDropdown.component";
import { Option } from "./GlobalSearchDropdownOptions.component";
import { useDispatch } from "src/react-redux";

const MAX_ALLOWABLE_SEARCH_TERM_LENGTH = 200;

function onClickOutsideNode(ref: any, perform: Function) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        perform(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function Overlay({
  children,
  setFocus,
}: {
  children: ReactNode;
  setFocus: (arg0: boolean) => any;
}) {
  const wrapperRef = useRef(null);
  const perform = (event: any) => {
    setFocus(event);
  };
  onClickOutsideNode(wrapperRef, perform);

  return (
    <div
      ref={wrapperRef}
      style={{
        maxHeight: "500px",
        overflowY: "auto",
      }}
    >
      {children}
    </div>
  );
}

export interface SearchBarProps {
  onClear: () => void;
  handleSubmit: (searchTerm: string) => void;
  dropdownRender: (searchTerm: string, blur: () => void) => React.ReactElement;
  placeholder: string;
}

function SearchBar({
  onClear,
  handleSubmit,
  dropdownRender,
  placeholder,
}: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [focus, setFocus] = useState(false);
  const navigate = useNavigate();
  const selectClasses = useMemo(
    () =>
      classnames(
        "ant-select",
        "ant-select-lg",
        "ant-select-auto-complete",
        "search-bar__autocomplete",
        "ant-select-customize-input",
        "ant-select-single",
        "ant-select-show-search",
        {
          "ant-select-focused": focus,
        }
      ),
    [focus]
  );

  const clearTerm = () => {
    setFocus(false);
    onClear();
    setSearchTerm("");
  };

  const location = useLocation();

  const setSearchFocus = useCallback(
    (event: any) => {
      if (!document.querySelector("#search-form")?.contains(event.target))
        setFocus(false);
    },
    [setFocus]
  );

  return (
    <Dropdown
      open={focus}
      trigger={["click"]}
      overlay={
        <Overlay setFocus={setSearchFocus}>
          {dropdownRender(searchTerm, clearTerm)}
        </Overlay>
      }
      overlayClassName="search-form__dropdown-menu ant-select-dropdown"
      placement="bottomCenter"
    >
      <div
        id="search-form"
        className={classnames("search-form", {
          company: COMPANY_PATHS.some(p => location.pathname.startsWith(p)),
        })}
      >
        <div className={selectClasses}>
          <div
            className="ant-select-selector"
            style={{
              ...(searchTerm.length >= MAX_ALLOWABLE_SEARCH_TERM_LENGTH
                ? { border: "red solid " }
                : {}),
            }}
          >
            <span className="ant-select-selection-search">
              <input
                placeholder={placeholder}
                tabIndex={0}
                onChange={e => {
                  setFocus(true);
                  setSearchTerm(
                    e.target.value.slice(0, MAX_ALLOWABLE_SEARCH_TERM_LENGTH)
                  );
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleSubmit(searchTerm);
                    setFocus(false);
                    navigate(INVESTMENT_PATHS[0]);
                  }
                }}
                data-cy="global-search-bar"
                aria-label={searchTerm}
                aria-required="true"
                onFocus={() => setFocus(true)}
                value={searchTerm}
                className="search-bar__autocomplete ant-select-selection-search-input ant-select-customize-input"
                style={{ width: "calc(100% - 40px)" }}
              />
            </span>
          </div>
        </div>

        <button
          aria-labelledby="Search"
          type="button"
          className="search-form__icon-loupe icon icon-loupe"
          onClick={e => {
            handleSubmit(searchTerm);
            setFocus(false);
            navigate(INVESTMENT_PATHS[0]);
          }}
        />

        <button
          aria-labelledby="Clear Search"
          type="button"
          className="search-form__icon-clear icon icon--24 icon-close-xs hidden-xs-down"
          onClick={clearTerm}
        />

        <button
          type="button"
          className="search-form__cancel-btn hidden-xs-up"
          onClick={clearTerm}
        >
          {i18n.t("global_search.cancel")}
        </button>
      </div>
    </Dropdown>
  );
}

export function GlobalSearch() {
  const dispatch = useDispatch();

  const onClearInvestment = () => {
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "SEARCH_TERM",
            data: {
              value: "",
            },
          },
        },
      })
    );
  };

  const onClearCompany = () => {
    dispatch(
      removeFilter({
        type: "company",
        data: {
          type: "NAME",
          data: "",
        },
      })
    );
  };

  const handleSubmitInvestment = (value: string) => {
    dispatch(
      updateFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "SEARCH_TERM",
            data: {
              value: extractSearchTerms(value).searchTerm,
            },
          },
        },
      })
    );
  };

  const handleSubmitCompany = (value: string) => {
    dispatch(
      updateFilter({
        type: "company",
        data: {
          type: "NAME",
          data: extractSearchTerms(value).searchTerm,
        },
      })
    );
  };

  return (
    <div className="main-navbar__search">
      <SearchBar
        handleSubmit={value => {
          handleSubmitInvestment(value);
          handleSubmitCompany(value);
        }}
        onClear={() => {
          onClearInvestment();
          onClearCompany();
        }}
        placeholder="Search"
        dropdownRender={(value: string, blur: () => void) => {
          if (value.length >= MAX_ALLOWABLE_SEARCH_TERM_LENGTH) {
            return (
              <Option>
                <span className="flex-grow text-truncate min-width-0">
                  Search Term is too long
                </span>
              </Option>
            );
          }
          return <GlobalSearchDropdown searchTerm={value} blur={blur} />;
        }}
      />
    </div>
  );
}
