import { ActionType } from "../actions/action_types";
import { combineReducers } from "redux";

const isExpanded = (
  state = false,
  action: {
    type: ActionType.SET_NAVIGATION_SIDEBAR_IS_EXPANDED;
    payload?: {
      isExpanded?: boolean;
    };
  }
) => {
  switch (action.type) {
    case ActionType.SET_NAVIGATION_SIDEBAR_IS_EXPANDED:
      return action?.payload?.isExpanded ?? state;

    default:
      return state;
  }
};

export default combineReducers({
  isExpanded,
});
