import React, { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Spinner } from "Components/show/spinner.js.jsx";
import * as PropTypes from "prop-types";

export const InfiniteScroller = ({
  hasMore,
  loadMore,
  isLoading,
}: {
  hasMore: boolean;
  loadMore: () => void;
  isLoading: boolean;
}): JSX.Element => {
  const onScrollHandler = useCallback(() => {
    // added 1 because some value is getting rounded scroll Height is getting rounded?
    const endOfPage =
      global.innerHeight + global.scrollY + 1 >= document.body.scrollHeight;
    if (endOfPage && hasMore && !isLoading) {
      loadMore();
    }
  }, [hasMore, isLoading, loadMore]);

  const debouncedScrollHandler = useCallback(
    debounce(() => {
      onScrollHandler();
    }, 150),
    [onScrollHandler]
  );

  useEffect(() => {
    debouncedScrollHandler(); // keep loading until we are no longer at end of page.
    global.addEventListener("scroll", debouncedScrollHandler);
    return () => {
      debouncedScrollHandler.cancel();
      global.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [debouncedScrollHandler]);

  return (
    <div data-testid="infinite-scroller" style={{ height: 75 }}>
      {!!isLoading && <Spinner />}
      {!hasMore && !isLoading && (
        <div style={{ marginTop: "20px" }}>End of results</div>
      )}
    </div>
  );
};

InfiniteScroller.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
