import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export interface Props {
  filter: types.InactiveInvestmentFilter;
}

export default function InactiveInvestmentFilterTag({ filter }: Props) {
  const dispatch = useDispatch();

  if (!filter || !filter.hideInactive) return null;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="inactive-investment-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "INACTIVE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {`Active`}
    </Tag>
  );
}
