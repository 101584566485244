/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import { useApolloClient } from "@apollo/client";
import { InvestmentControlsContext } from "Components/InvestmentControls/context";
import { INVESTMENT_TILE } from "Components/graphql/fragments/Investment";
import { badgeColorClass } from "Helpers/investment";
import { getSearchResultDetails } from "Helpers/investment_helpers";
import classnames from "classnames";
import { get } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { InvestmentTileBody } from "./Body";
import { InvestmentTileFooter } from "./Footer";
import { InvestmentTileContext } from "./context";

interface PropsInterface {
  id: number;
}

function InvestmentTileComponent({ id }: PropsInterface) {
  const client = useApolloClient();

  const {
    toggleInvestmentId,
    isActive: InvestmentControlsAreAvailable,
    isSelected,
  } = useContext(InvestmentControlsContext);

  const investment = client.readFragment({
    id: `Investment:${id}`,
    fragment: INVESTMENT_TILE,
  });

  const investmentClasses = useMemo(
    () =>
      classnames(
        "fund-tile-container",
        badgeColorClass(get(investment, "assetClass.name") as any),
        {
          "fund-tile-selected": investment && isSelected(investment.id),
        }
      ),
    [
      get(investment, "assetClass.name"),
      investment && isSelected(investment.id),
    ]
  );

  const link = useMemo(() => `/investments/${id}`, [id]);

  const details = useMemo(
    () => investment && getSearchResultDetails(investment),
    [investment]
  );

  const toggleInvestment: (event: React.MouseEvent) => void = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      return toggleInvestmentId(id);
    },
    [toggleInvestmentId, id]
  );

  const [isHovered, setHover] = useState<boolean>(false);

  if (!investment || !details) return <></>;

  return (
    <InvestmentTileContext.Provider
      value={{
        investment,
        details,
        isHovered,
        InvestmentControlsAreAvailable,
        isSelected,
        toggleInvestment,
      }}
    >
      <div className={investmentClasses}>
        <Link
          style={{
            textDecoration: "none",
            ...(investment ? {} : { pointerEvents: "none" }),
          }}
          className="fund fund-tile -full-access"
          to={link}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          data-cy={`investment-tile-${id}`}
        >
          <InvestmentTileBody />
          <InvestmentTileFooter />
        </Link>
      </div>
    </InvestmentTileContext.Provider>
  );
}

export const InvestmentTile = InvestmentTileComponent;
