import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import LoadingPanel from "Components/loading_panel";
import { round } from "lodash";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

function getCaptures({
  performance,
  benchmarkPerformance,
}: {
  performance: Partial<types.PerformanceMtd>[] | null | undefined;
  benchmarkPerformance: Partial<types.PerformanceMtd>[] | null | undefined;
}): [number, number] {
  if (!performance?.length || !benchmarkPerformance?.length) {
    return [0, 0];
  }

  let [upCaptureVal, upCaptureCount, downCaptureVal, downCaptureCount] =
    benchmarkPerformance.reduce(
      (accu, curr) => {
        if (!curr.return) return accu;

        const point = performance.find(
          perf =>
            perf.returnYear === curr.returnYear &&
            perf.returnMonth === curr.returnMonth
        );

        if (curr.return > 0) {
          accu[0] += point?.return ?? 0;
          accu[1] += point?.return ? 1 : 0;
        } else {
          // curr.return < 0
          accu[2] += point?.return ?? 0;
          accu[3] += point?.return ? 1 : 0;
        }
        return accu;
      },
      [0, 0, 0, 0]
    );

  upCaptureVal /= upCaptureCount ?? 1;
  downCaptureVal /= downCaptureCount ?? 1;

  return [round(upCaptureVal ?? 0, 2), round(downCaptureVal ?? 0, 2)];
}

function UpDownCaptureChart({
  investment,
  loading,
}: {
  investment?: types.GetBenchmarkMonthlyReturnsQuery["investment"];
  loading: boolean;
}): JSX.Element {
  const chartData = useMemo(() => {
    if (!investment?.performanceMTD || !investment?.primaryBenchmark)
      return { type: "column", data: [] };

    return [
      {
        name: investment?.name,
        custom: {
          type: "Fund - Primary Benchmark",
        },
        data: getCaptures({
          performance: investment?.performanceMTD,
          benchmarkPerformance: investment?.primaryBenchmark?.performanceMTD,
        }),
        showInLegend: !!investment?.name,
        color: "#418CF0",
      },
      {
        name: investment?.primaryBenchmark?.name,
        custom: {
          type: "Primary Benchmark - Primary Benchmark",
        },
        data: getCaptures({
          performance: investment?.primaryBenchmark?.performanceMTD,
          benchmarkPerformance: investment?.primaryBenchmark?.performanceMTD,
        }),
        showInLegend: !!investment?.primaryBenchmark?.name,
        color: "#E0400A",
      },
    ];
  }, [investment]);

  const chartOptions = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: {
        text: "Upside/Downside Capture",
      },
      xAxis: {
        categories: ["Up", "Down"],
      },
      yAxis: {
        title: {
          text: "Avg. Monthly Returns",
        },
        labels: {
          format: "{text} %",
        },
      },
      series: chartData,
      plotOptions: {
        column: {
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.1,
          tooltip: {
            headerFormat:
              '<span style="font-size:10px">{series.options.custom.type}</span></br>',
            pointFormat:
              '<span style="padding:0">{point.category} Capture: <b>{point.y}%</b></span>',
            useHTML: true,
          },
        },
      },
      exporting: {
        filename: `${investment?.name}_up_down_capture`,
      },
      lang: {
        noData: !investment?.primaryBenchmark
          ? "No benchmark defined"
          : "No data to display",
      },
    }),
    [chartData]
  );

  return (
    <>
      <LoadingPanel loading={loading}>
        <HighChartsReact
          constructorType="chart"
          options={chartOptions}
          highcharts={HighCharts}
        />
      </LoadingPanel>
    </>
  );
}

export default UpDownCaptureChart;
