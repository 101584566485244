import {
  CloseCircleOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Tooltip, notification } from "antd";
import React from "react";
import { VECTORIZE_DOCUMENT } from "./graphql";
import { extname } from "path";

export const VectorizedIndicator = ({
  vectorized,
  doc,
}: {
  vectorized: boolean;
  doc: { name: string; id: number };
}) => {
  const [vectorize, { loading, data }] = useMutation(VECTORIZE_DOCUMENT);
  const contentType = extname(doc.name);

  if (contentType !== ".pdf") {
    return null;
  }

  return (
    <Tooltip
      title={
        !vectorized && !data?.generateEmbeddings
          ? `Vectorize document (document will be available to answer questions in document chat)`
          : `This Document is available in data chat`
      }
    >
      <Button
        type="primary"
        shape="circle"
        icon={
          vectorized || data?.generateEmbeddings ? (
            <QuestionCircleOutlined />
          ) : loading ? (
            <LoadingOutlined />
          ) : (
            <CloseCircleOutlined />
          )
        }
        size="small"
        onClick={
          !vectorized
            ? () => {
                vectorize({ variables: { documentId: doc.id } }).catch(e => {
                  notification.error({
                    message: "There was an error vectorizing the document",
                    duration: 5,
                  });
                });
              }
            : undefined
        }
        style={{
          fontSize: 14,
          margin: "3px 3px",
          ...(data?.generateEmbeddings
            ? { backgroundColor: "green", borderWidth: 0 }
            : {}),
        }}
      />
    </Tooltip>
  );
};
