import { gql } from "_graphql-types/gql";

export const FIRM_HEADER = gql(/* GraphQL */ `
  query FirmNavBar($id: Int!) {
    firm(id: $id) {
      id
      name
      isStaffMWDBE
    }
  }
`);

export const FIRM_OVERVIEW_DATA = gql(/* GraphQL */ `
  query FirmOverviewData($firmId: Int!) {
    firm(id: $firmId) {
      id
      name
      employeeTotal
      yearFounded
      isStaffMWDBE
      email
      firmIrEmail
      crd
      lei
      cik
      ukHfsbSignatory
      registeredWithOther
      fields {
        firmId
        state
        firmAum {
          id
          asOfDate
          ownerId
          ownerType
          state
          fieldKey
          value {
            aum
            asOfDate
          }
        }
      }
      primaryAddress {
        id
        line1
        line2
        line3
        city
        state {
          name
          id
        }
        country {
          name
          id
        }
        zipCode
        phone
        fax
        email
        website
      }
      registrations {
        id
        name
      }
      investments(page: { limit: 1, offset: 0 }) {
        items {
          id
          name
          latestFirmAUM {
            value
            date
          }
          investmentRegulatorsList(sort: [{ field: name, order: ASC }]) {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`);
