import { gql } from "_graphql-types/gql";

export const FETCH_TEAM_STRUCTURE = gql(/* GraphQL */ `
  query TeamStructure($id: Int!) {
    firmTeamStructure(id: $id) {
      analyst
      trader
      operations
      portfolioManager
      total
      admin
      investment
      legal
      investorRelations
    }
  }
`);
