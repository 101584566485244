/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { useApolloClient } from "@apollo/client";
import {
  EditToggleCompanyFragment,
  EditToggleFirmFragment,
  EditToggleFragment,
} from "_graphql-types/graphql";
import { setEditMode } from "_sirius/src/actions/investment/edit_mode_actions";
import classNames from "classnames";
import { EDIT_TOGGLE_COMPANY } from "Components/graphql/fragments/Company";
import { EDIT_TOGGLE_FIRM } from "Components/graphql/fragments/Firm";
import { EDIT_TOGGLE_INVESTMENT } from "Components/graphql/fragments/Investment";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { singularizeOwnerType } from "Helpers/utils";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { editMode as getEditMode } from "Selectors/investment_edit_mode_selectors";
import config from "../../../config/config";

function generateFragmentByOwnerType(ownerType: string, ownerId: string) {
  if (ownerType === "firms") {
    return {
      id: `Firm:${ownerId}`,
      fragment: EDIT_TOGGLE_FIRM,
    };
  }
  if (ownerType === "company") {
    return {
      id: `Company:${ownerId}`,
      fragment: EDIT_TOGGLE_COMPANY,
    };
  }
  return {
    id: `Investment:${ownerId}`,
    fragment: EDIT_TOGGLE_INVESTMENT,
  };
}

function isInvestment(
  entity:
    | EditToggleCompanyFragment
    | EditToggleFirmFragment
    | EditToggleFragment
): entity is EditToggleFragment {
  return entity.__typename === "Investment";
}

function isCompany(
  entity:
    | EditToggleCompanyFragment
    | EditToggleFirmFragment
    | EditToggleFragment
): entity is EditToggleCompanyFragment {
  return entity.__typename === "Company";
}

function ToggleEditModeButtonComponent(): JSX.Element | null {
  const params = useParams<{ selectedTabName: string }>();
  const { hash, pathname } = useLocation();
  const { selectedTabName } = params;

  const dispatch = useDispatch();
  const editMode = useSelector(state => getEditMode(state));
  const toggleEditMode = (mode: boolean) => dispatch(setEditMode(mode));

  const client = useApolloClient();

  const [, ownerType, ownerId, tabName] = pathname.split("/");

  const entity = client.readFragment<
    EditToggleCompanyFragment | EditToggleFirmFragment | EditToggleFragment,
    unknown
  >(generateFragmentByOwnerType(ownerType, ownerId));
  const currentUser = useCurrentUser();

  const xddTabs = ["operations", "investment", "impact"];
  const RCG_ORG_ID = 1;
  const isRcgUser = currentUser && currentUser.organizationId === RCG_ORG_ID;

  const openDataCollectionWindow = (route: string) => {
    window.open(`${config.RCG_DATA_COLLECTION_URI()}/${route}`, "_blank");
  };

  const handleToggleDataCollection = useCallback(
    (
      _entity:
        | EditToggleCompanyFragment
        | EditToggleFirmFragment
        | EditToggleFragment
    ) => {
      const key = hash || tabName || selectedTabName;
      switch (key) {
        case "team":
          if (isCompany(_entity)) {
            openDataCollectionWindow(
              `${singularizeOwnerType(
                ownerType
              )}/${ownerId}#companyEmploymentList`
            );
          } else {
            openDataCollectionWindow(
              `${singularizeOwnerType(ownerType)}/${ownerId}#${
                ownerType === "firms"
                  ? "firmEmploymentList"
                  : "investmentAssignmentList"
              }`
            );
          }
          break;
        case "DEI":
          if (ownerType === "firms") {
            openDataCollectionWindow(`firmFirmDEI/${ownerId}`);
          } else {
            openDataCollectionWindow(
              `${singularizeOwnerType(ownerType)}/${ownerId}`
            );
          }
          break;
        case "#performance":
          openDataCollectionWindow(`performance/${ownerId}`);
          break;
        case "#performanceprivate":
          openDataCollectionWindow(`performancePrivate/${ownerId}`);
          break;
        case "#family_performance":
          if (!isInvestment(_entity)) {
            openDataCollectionWindow(`firm/${ownerId}#firmFamilyList`);
          } else if (isInvestment(_entity) && _entity.familyId) {
            openDataCollectionWindow(`family/${_entity.familyId}`);
          } else {
            openDataCollectionWindow(`firm/${_entity.firmId}#firmFamilyList`);
          }
          break;
        case "#custom":
          if (isInvestment(_entity)) {
            openDataCollectionWindow(`investmentCustomAnalytics/${ownerId}`);
          }
          break;
        case "documents":
          openDataCollectionWindow(
            `${singularizeOwnerType(ownerType)}Document/${ownerId}`
          );
          break;
        case "deals":
          openDataCollectionWindow(
            `${singularizeOwnerType(ownerType)}/${ownerId}#dealList`
          );
          break;
        case "#terms":
          openDataCollectionWindow(`investmentTerms/${ownerId}`);
          break;
        default:
          openDataCollectionWindow(
            `${singularizeOwnerType(ownerType)}/${ownerId}`
          );
      }
    },
    [hash, selectedTabName, ownerType, tabName]
  );

  if (!entity) return null;
  if (!isRcgUser) return null;

  const handleClick = () => {
    if (selectedTabName !== undefined && xddTabs.includes(selectedTabName)) {
      toggleEditMode(!editMode);
    } else {
      handleToggleDataCollection(entity);
    }
  };

  return (
    <span>
      <button
        type="button"
        onClick={handleClick}
        className="round-icon-btn round-icon-btn--36 round-icon-btn--transparent-white mr-15 mb-10"
        data-cy={editMode ? "view-button" : "edit-button"}
        data-testid={editMode ? "view-button" : "edit-button"}
      >
        <i
          className={classNames("icon font-12", {
            "icon-edit": !editMode,
            "icon-eye": editMode,
          })}
        />
      </button>

      <div className="invst-header__vert-divider mb-10" />
    </span>
  );
}

export default ToggleEditModeButtonComponent;
