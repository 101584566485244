// initial centralization of fields table logic.
export default class FieldTable {
  constructor(definition) {
    this.definition = definition;
  }

  static sortedTablePropertiesValues(tableProperties) {
    return this.sortedTableProperties(tableProperties).valueSeq();
  }

  static sortedTablePropertiesKeys(tableProperties) {
    return this.sortedTableProperties(tableProperties).keySeq();
  }

  static sortedTableProperties(tableProperties) {
    return tableProperties.filter((v, k) => {
      const display_order = tableProperties.getIn([k, 'display_order']);
      return display_order || display_order === 0;
    }).sortBy((v, k) => (tableProperties.getIn([k, 'display_order'])));
  }

  tableDefinition() {
    return this.definition.getIn(['schema', 'anyOf']).find((item) => (item.get('type') === 'object'));
  }

  primaryKey() {
    let min = Infinity;
    let key;
    this.tableProperties().forEach((v, k) => {
      if (v.get('display_order') < min) {
        min = v.get('display_order');
        key = k;
      }
    });
    return key;
  }

  displayOrder() {
    return this.definition.get('display_order');
  }

  tableProperties() {
    return this.tableDefinition().getIn(['properties', this.displayKey(), 'items', 'properties']);
  }

  tableDefinition() {
    return this.definition.getIn(['schema', 'anyOf']).find((item) => (item.get('type') === 'object'));
  }

  sortedTablePropertiesValues() {
    return FieldTable.sortedTablePropertiesValues(this.tableProperties());
  }

  sortedTableProperties() {
    return FieldTable.sortedTableProperties(this.tableProperties());
  }

  displayKey() {
    return this.definition.get('display_data');
  }
}
