import React, { useContext } from "react";
import { Button, Spin } from "antd";
import { gql, useMutation } from "@apollo/client";
import { InvestmentTileContext } from "../context";

const REQUEST_ACCESS = gql(/* GraphQL */ `
  mutation addInvestmentAccessRequest($input: InvestmentAccessRequestInput!) {
    addInvestmentAccessRequest(input: $input) {
      id
    }
  }
`);

function PreviewFooter() {
  const { details } = useContext(InvestmentTileContext);

  const accessRequest = details?.accessRequest;
  const investmentId = details?.id;

  const [requestAccess, { data, loading }] = useMutation<{
    input: { investmentId: number };
  }>(REQUEST_ACCESS, {
    variables: { input: { investmentId } },
  });

  return (
    <div className="request_access tile-footer -padded">
      {loading ? (
        <Spin size="large" />
      ) : accessRequest || data ? (
        <div
          data-cy={`requested-icon-${investmentId}`}
          className="requested_icon"
        >
          Requested
        </div>
      ) : (
        <Button
          data-cy={`requested-button-${investmentId}`}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            requestAccess();
          }}
          style={{ pointerEvents: "all" }}
        >
          Request Access
        </Button>
      )}
    </div>
  );
}

export default PreviewFooter;
