import React, { useEffect, useMemo, useState } from "react";
import "frontend/src/components/HighCharts/initializeLibrary";
import { useLazyQuery, useQuery } from "@apollo/client";
import { get } from "lodash";
import { Select } from "antd";
import * as types from "_graphql-types/graphql";
import { Spinner } from "Components/show/spinner.js";
import { ensureDefined } from "frontend/src/utils/helpers";
import ChartViewBase from "./ChartViewBase";
import { FETCH_VISUALIZATION_DATA, GEOGRAPHY_ENUMS } from "./graphql";
import {
  aggregateFirmCharts,
  aggregateCompanyCharts,
  AggregateCategory,
} from "./aggregations";
import { PartialFirmDEIRecord, calculateAggregatedData } from "./helpers";
import VisualizationControls from "./Controls";
import DataTableView from "./DataTableView";

interface PageContentProps {
  isCompany: boolean;
  deiCategory1List: types.DeiCategory1Enum[];
  deiCategory2List: types.DeiCategory2Enum[];
  aggregatedData: PartialFirmDEIRecord[];
  title: string;
  totalTeamSize: number | boolean;
  isPercent: boolean;
  chartType: IOptions["chartType"];
  loading: boolean;
  data: unknown;
  setOptions: React.Dispatch<React.SetStateAction<IOptions>>;
  options: IOptions;
}

function PageContent({
  isCompany,
  loading,
  deiCategory1List,
  data,
  deiCategory2List,
  aggregatedData,
  title,
  totalTeamSize,
  isPercent,
  chartType,
  setOptions,
  options,
}: PageContentProps) {
  if (loading || !deiCategory1List.length || !data) {
    return (
      <div className="overview-page__card">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="overview-page__card">
        <VisualizationControls
          setOptions={setOptions}
          options={options}
          isCompany={isCompany}
        />
        <ChartViewBase
          deiCategory1List={deiCategory1List}
          deiCategory2List={deiCategory2List}
          aggregatedData={aggregatedData}
          title={title}
          teamSize={totalTeamSize}
          isPercent={isPercent}
          chartType={chartType}
          allowChartUpdate
        />
      </div>
      <DataTableView
        chartData={aggregatedData}
        showAdditionalChart={chartType === "pie"}
        isPercent={isPercent}
      />
    </>
  );
}

export interface IOptions {
  chartType: "pie" | "packedbubble" | "column" | "radial";
  category: AggregateCategory["key"];
  asOfDate?: Date;
}

export const defaultFirmOptions: IOptions = {
  chartType: "packedbubble",
  category: "ownership",
};

export const defaultCompanyOptions: IOptions = {
  chartType: "packedbubble",
  category: "companyLeadershipCSuite",
};

function FirmVisualizations({
  entityId,
  isCompany = false,
}: {
  entityId: number;
  isCompany?: boolean;
}): JSX.Element {
  const [options, setOptions] = useState<IOptions>(
    isCompany ? defaultCompanyOptions : defaultFirmOptions
  );
  const [selectedGeographyEnumId, setSelectedGeographyEnumId] = useState<
    number | null
  >(null);

  const { data: geographyEnumData } = useQuery(GEOGRAPHY_ENUMS);

  const [fetchData, { data, loading, called, refetch }] = useLazyQuery(
    FETCH_VISUALIZATION_DATA
  );

  useEffect(() => {
    const variables = {
      entityId,
      isCompany,
      geographyEnumId: selectedGeographyEnumId,
      sort: [
        {
          field: types.DeiCategory1EnumSortEnum.Name,
          order: types.SortInput.Asc,
        },
      ],
    };
    if (called && refetch) {
      refetch(variables);
    } else {
      fetchData({ variables });
    }
  }, [called, refetch, selectedGeographyEnumId]);

  useEffect(() => {
    if (data?.latestFirmDEI?.geographyEnumId) {
      setSelectedGeographyEnumId(data.latestFirmDEI.geographyEnumId);
    }
  }, [data]);

  const deiCategory1List: types.DeiCategory1Enum[] = get(
    data,
    "deiCategory1EnumList.items",
    []
  );
  const deiCategory2List: types.DeiCategory2Enum[] = get(
    data,
    "deiCategory2EnumList.items",
    []
  );

  const { aggregatedData, chartType, isPercent, title, totalTeamSize } =
    useMemo(() => {
      const aggregateCharts = isCompany
        ? aggregateCompanyCharts
        : aggregateFirmCharts;

      const selectedCategory = ensureDefined(
        aggregateCharts.find(c => c.key === options.category)
      );

      const result = calculateAggregatedData({
        record: data?.latestFirmDEI,
        measureEnumIds: selectedCategory.measureEnumIds,
        isPercent: selectedCategory.isPercent,
      });

      return {
        aggregatedData: result.aggregatedData,
        isPercent: selectedCategory.isPercent,
        title: selectedCategory.title,
        chartType: options.chartType,
        totalTeamSize: result.totalTeamSize,
      };
    }, [options, data]);

  if (data) debugger;

  return (
    <div
      className="invt-tab__section"
      id="firm_visualizations"
      data-cy="firm_visualizations"
    >
      <div className="flex-row justify-between align-center margin-b-sm">
        <h3 data-cy="firm-visualization-heading" className="invt-tab__title">
          Data Visualizations: Diversity, Equity & Inclusion
        </h3>
        <div className="flex-column">
          <Select
            style={{ width: 300 }}
            value={selectedGeographyEnumId}
            onChange={(selectedValue: number) =>
              setSelectedGeographyEnumId(selectedValue)
            }
            data-cy="geographyEnum_Selector"
          >
            {(geographyEnumData?.geographyEnumList.items ?? []).map(option => (
              <Select.Option key={option.id} value={option.id}>
                {option.description}
              </Select.Option>
            ))}
          </Select>
          <p className="helperText">
            Geography of Team Members Indicated (Offices)
          </p>
        </div>
      </div>

      <PageContent
        isCompany={isCompany}
        deiCategory1List={deiCategory1List}
        deiCategory2List={deiCategory2List}
        aggregatedData={aggregatedData}
        title={title}
        totalTeamSize={totalTeamSize}
        isPercent={isPercent}
        chartType={chartType}
        loading={loading}
        data={data}
        setOptions={setOptions}
        options={options}
      />
    </div>
  );
}

export default FirmVisualizations;
