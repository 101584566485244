const Filter = class {
  static sectionTypes() {
    return {
      checkBox: 'checkBox',
      radioButton: 'radioButton',
      radioSection: 'radioSection',
      checkBoxSection: 'checkBoxSection'
    };
  }
};

export default Filter
