import _ from "lodash";
import i18n from "i18next";
const ErrorHandler = function (record) {
  if (record instanceof Error) throw record;
  this.record = record;
};

ErrorHandler.prototype = {
  get errors() {
    return this.record.data.errors;
  },

  messages() {
    try {
      return _.reduce(
        this.errors,
        (join, error) => {
          if (!join) return this._error_message(error);
          return `${join}\n${this._error_message(error)}`;
        },
        ""
      );
    } catch (e) {
      try {
        Helpers.logException(error, this);
      } finally {
        return i18n.t("errors.default");
      }
    }
  },

  _attribute_for(error) {
    return error.source.pointer.split("/").pop();
  },

  _error_message(error) {
    return `${_.capitalize(this._attribute_for(error))} ${error.detail}.`;
  },
};

const factory = function (record) {
  return new ErrorHandler(record);
};

factory.type = ErrorHandler;

export default factory;
