import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";
import { isNumber } from "../..";

function PerformancePrivateFilterTag({
  filter,
}: {
  filter: types.PerformancePrivateSearchFilter;
}) {
  const dispatch = useDispatch();
  const { statType, max, min } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="performance-private-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "PERFORMANCE_PRIVATE",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {statType}{" "}
      {isNumber(min) && isNumber(max) ? (
        <>
          between {min}% and {max}%
        </>
      ) : isNumber(min) ? (
        <>p&ge; {min}%</>
      ) : isNumber(max) ? (
        <>&le; {max}%</>
      ) : null}
    </Tag>
  );
}

export default PerformancePrivateFilterTag;
