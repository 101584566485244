import { get, Map } from "immutable";
import { createSelector } from "reselect";

const rootSelector = createSelector(
  state => get(state, "fieldOptions", Map({})),
  fieldOptions => fieldOptions
);

export const priorTrackRecordSelector = createSelector(
  rootSelector,
  fieldOptions => fieldOptions.get("priorTrackRecord", false)
);
