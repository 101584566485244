import { round } from "lodash";
import { Bounds, inBounds } from "../helpers";

function getIndividualReturn(originalValue: number, newValue: number): number {
  const currentCumulativeReturn = newValue + 1;
  const previousCumulativeReturn = originalValue + 1;

  return currentCumulativeReturn / previousCumulativeReturn - 1;
}

// Some inaccuracy due to floating point precision.
// Perhaps all calculations should use original returns.
export function cumulateReturnsInBounds(
  data: [number, number][],
  bounds: Bounds,
  boundedCumulativeRootData?: [number, number | null][]
): [number, number | null][] {
  let cumulativeReturn = 1;
  let rootMatch: [number, number] | null;
  return data.map(([x, y], i) => {
    if (inBounds(x, bounds)) {
      let individualReturn: number | null;
      const previousValue = data[i - 1];

      if (!rootMatch && boundedCumulativeRootData) {
        rootMatch = boundedCumulativeRootData.find(([rootX]) => rootX === x) as
          | [number, number]
          | null;

        if (rootMatch) {
          // eslint-disable-next-line prefer-destructuring
          cumulativeReturn = rootMatch[1] / 100 + 1;
          return [x, rootMatch[1]];
        }

        return [x, null];
      }

      if (previousValue) {
        individualReturn = getIndividualReturn(previousValue[1], y);
      } else {
        individualReturn = y;
      }

      cumulativeReturn *= 1 + individualReturn;
      const value = cumulativeReturn - 1;
      return [x, round(value * 100, 4)];
    }

    return [x, null];
  });
}
