/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { InvestmentSort } from "_graphql-types/graphql";

export type Sort = InvestmentSort;

type SortContextType = {
  addSort?: (sort: Sort) => void;
  currentSort?: InvestmentSort;
};
export const SortContext = createContext<SortContextType>({
  addSort: undefined,
  currentSort: undefined,
});
