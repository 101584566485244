import { useQuery } from "@apollo/client";
import { DateSelector } from "Components/DateRangeSelector";
import InvestmentLink from "Components/InvestmentLink";
import {
  DownloadButtons,
  SheetFormat,
  downloadSpreadsheet,
} from "Helpers/downloadSpreadsheet";
import * as types from "_graphql-types/graphql";
import { Select, Table } from "antd";
import classNames from "classnames";
import { parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import {
  FETCH_INVESTMENTS_FROM_SET,
  PEERS_ROOT,
} from "../PeerStatistics/graphql";
import { INVESTMENT_SET_CORRELATION, PEERS_CORRELATION } from "./graphql";

const rangeOffsetOptions = [
  {
    label: "3 Months",
    value: 2,
  },
  {
    label: "6 Months",
    value: 5,
  },
  {
    label: "9 Months",
    value: 8,
  },
  {
    label: "1 Year",
    value: 11,
  },
  {
    label: "18 Months",
    value: 17,
  },
  {
    label: "2 Years",
    value: 23,
  },
  {
    label: "3 Years",
    value: 35,
  },
  {
    label: "4 Years",
    value: 47,
  },
  {
    label: "5 Years",
    value: 59,
  },
  {
    label: "6 Years",
    value: 71,
  },
  {
    label: "7 Years",
    value: 83,
  },
  {
    label: "8 Years",
    value: 95,
  },
  {
    label: "9 Years",
    value: 107,
  },
  {
    label: "10 Years",
    value: 119,
  },
];

interface Props {
  investmentId: number;
  investmentSetId?: number;
}

type InvestmentInfo =
  | types.PeersCorrelationQuery["investment"]["peersStats"]["items"]
  | types.InvestmentSetCorrelationQuery["investment"]["groupStats"]["items"]
  | undefined;

type CorrelationStats =
  | types.PeersCorrelationQuery["investment"]["peersStats"]["stats"]
  | types.InvestmentSetCorrelationQuery["investment"]["groupStats"]["stats"]
  | undefined;

function useGetCorrelationData({
  investmentId,
  investmentSetId,
  selectedDate,
  selectedRangeOffset,
}: {
  investmentId: number;
  investmentSetId?: number;
  selectedDate?: Date;
  selectedRangeOffset?: number;
}): [boolean | undefined, InvestmentInfo, CorrelationStats] {
  const { data: investmentSet } =
    useQuery<types.FetchInvestmentSetInvestmentsQuery>(
      FETCH_INVESTMENTS_FROM_SET,
      {
        variables: {
          searchFilters: investmentSetId
            ? [{ INVESTMENT_SET: { investmentSetId } }]
            : [],
        },
      }
    );

  const { data: investmentSetData, loading: investmentSetLoading } = useQuery<
    types.InvestmentSetCorrelationQuery,
    types.InvestmentSetCorrelationQueryVariables
  >(INVESTMENT_SET_CORRELATION, {
    variables: {
      id: investmentId,
      wrtInvestmentId: 3108,
      dependentIds:
        investmentSet?.investmentList?.items
          ?.map(i => i.id)
          .filter(id => id !== investmentId) ?? [],
      // component will rerender when the date changes
      asOfDate: selectedDate?.toISOString().slice(0, 10),
      rangeUnit: types.StatsRangeUnit.M,
      rangeOffset: selectedRangeOffset,
    },
    skip: !selectedDate || !investmentSetId, // render if using investment set for comparison
  });

  const { data: peersData, loading: peersLoading } = useQuery<
    types.PeersCorrelationQuery,
    types.PeersCorrelationQueryVariables
  >(PEERS_CORRELATION, {
    variables: {
      id: investmentId,
      wrtInvestmentId: 3108,
      // component will rerender when the date changes
      asOfDate: selectedDate?.toISOString().slice(0, 10),
      rangeUnit: types.StatsRangeUnit.M,
      rangeOffset: selectedRangeOffset,
      filter: {
        active: true,
      },
    },
    skip: !selectedDate || !!investmentSetId, // render if using peers list for comparison
  });

  if (investmentSetId) {
    const investmentInfo = investmentSetData?.investment.groupStats.items;
    const stats = investmentSetData?.investment.groupStats.stats;

    return [investmentSetLoading, investmentInfo, stats];
  }

  const investmentInfo = peersData?.investment.peersStats.items;
  const stats = peersData?.investment.peersStats.stats;

  return [peersLoading, investmentInfo, stats];
}

function PeerCorrelationStats({ investmentId, investmentSetId }: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedRangeOffset, setSelectedRangeOffset] = useState<
    number | undefined
  >(11);
  const [focusedCol, setFocusedCol] = useState<number | undefined>(undefined);

  const { data: rootData } = useQuery<
    types.InvestmentPeersRootQuery,
    types.InvestmentPeersRootQueryVariables
  >(PEERS_ROOT, {
    variables: { id: investmentId },
  });

  const [startPeriodDate, endPeriodDate] = useMemo(() => {
    if (!rootData) return [];
    const { start, end } = rootData.investment.performancePeriodRange ?? {};

    return [start, end].map(d =>
      d ? parseISO(parseISO(d).toISOString().slice(0, 10)) : undefined
    );
  }, [rootData]);

  useEffect(() => {
    setSelectedDate(endPeriodDate);
  }, [endPeriodDate]);

  const [loading, investmentInfo, stats] = useGetCorrelationData({
    investmentId,
    investmentSetId,
    selectedDate,
    selectedRangeOffset,
  });

  const tableData = (investmentInfo || []).reduce(
    (acc, { id, name, isEntitled }, i) => ({
      ...acc,
      [name]: (investmentInfo || []).reduce(
        (acc, { id }, j) => {
          const correlationMatrix = stats?.correlationMatrix;
          const x = correlationMatrix && correlationMatrix[i];
          if (!x) {
            return { ...acc, [id]: null };
          }

          return {
            ...acc,
            [id]: x[j] ? x[j]?.toFixed(2) : null,
          };
        },
        { id, isEntitled }
      ),
    }),
    {}
  );

  function handleRangeOffsetChange(rangeOffset?: number | string) {
    if (rangeOffset === undefined) return;

    if (typeof rangeOffset === "number" && rangeOffset >= 0) {
      setSelectedRangeOffset(rangeOffset);
    }
  }

  const download = (format: SheetFormat) =>
    tableData &&
    rootData &&
    downloadSpreadsheet(
      [
        {
          name: "Peers",
          data: [
            ["Name", ...Object.keys(tableData)],
            ...Object.entries(tableData).map(([name, v]: [string, any]) => [
              name,
              ...Object.values(tableData)
                .map(({ id }: any) => id)
                .map(id => v[id]),
            ]),
          ],
        },
      ],
      `${rootData.investment.name} Correlation Matrix`,
      format
    );

  return (
    <>
      <div className="summary-heading" data-cy="correlation-statistics">
        <h3 className="invt-tab__title" id="correlation_statistics">
          Correlation Stats
        </h3>
        <div className="summary-heading__end-col">
          <div
            id="peer-statistics__date-selector"
            className={classNames("flex-row", "align-center")}
          >
            <p className="summary-heading__desc">As of</p>
            <DateSelector
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              range={
                startPeriodDate && endPeriodDate
                  ? { start: startPeriodDate, end: endPeriodDate }
                  : undefined
              }
            />
          </div>
          <div
            id="peer-statistics__rangeOffset-selector"
            className={classNames("flex-row", "align-center")}
          >
            <p className="summary-heading__desc">
              {I18n.t("analytics.peer_statistics.range_offset")}
            </p>
            <Select
              onChange={(value?: number | string) =>
                handleRangeOffsetChange(value)
              }
              options={rangeOffsetOptions}
              value={
                selectedRangeOffset !== undefined
                  ? selectedRangeOffset
                  : "inception"
              }
              style={{ width: 120 }}
            />
          </div>
        </div>
      </div>
      <Table
        columns={[{ id: "name", name: "Name" }, ...(investmentInfo || [])].map(
          ({ id, name }, i) => ({
            title: (
              <div className={name !== "Name" ? "investment-name-header" : ""}>
                {name}
              </div>
            ),
            key: id,
            dataIndex: id,
            width: 50,
            className: i === focusedCol ? "selected-col" : "",
            onCell: (x, y) => ({
              onMouseEnter: () => {
                setFocusedCol(i);
              },
              onMouseLeave: () => {
                setFocusedCol(undefined);
              },
            }),
          })
        )}
        dataSource={Object.entries(tableData).map(
          ([name, cors]: [name: string, cors: any], i) => ({
            name: (
              <InvestmentLink
                className="fund-link-wrapper small"
                investmentId={cors.id}
                isEntitled={cors.isEntitled}
              >
                {name}
              </InvestmentLink>
            ),
            ...cors,
          })
        )}
        sticky
        size="small"
        pagination={false}
        scroll={{ x: 1500 }}
        className="correlationMatrix"
        loading={loading}
      />
      <DownloadButtons {...{ download }} />
    </>
  );
}

export default PeerCorrelationStats;
