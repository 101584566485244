import { createContext } from "react";
import * as types from "_graphql-types/graphql";

export const RelativePerformanceContext = createContext<{
  investment?: types.GetRelativeStatisticsQuery["investment"];
  loading: boolean;
  isActiveContext: boolean;
}>({
  investment: undefined,
  loading: true,
  isActiveContext: false,
});
