/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react";
import _, { reduce, has } from "lodash";
import classNames from "classnames";
import i18n from "i18next";

import * as types from "_graphql-types/graphql";
import { Link, useParams, useLocation } from "react-router-dom";
import NavHeader from "Components/layout/NavHeader";
import InvestmentNameView from "Components/investment/investment_name_view.js.jsx";
import Tabs from "Components/layout/Tabs";
import InvestmentHeadingActions from "Components/show/investment_heading_actions";
import RcTooltip from "rc-tooltip";

interface Props {
  updateSelectedTabName: any;
  defaultSelectedTab: string;
  tabContents: any;
  isImpact: boolean;
  investmentId: number;
  investmentName: string;
  investmentShortName?: string;
  profileType: string;
  odcSourced: boolean;
  isInActive: boolean;
  canGenerateReport?: boolean;
  canAddNote?: boolean;
}

function SummaryContainer({
  updateSelectedTabName,
  defaultSelectedTab,
  tabContents,
  isImpact,
  investmentId,
  investmentName,
  investmentShortName,
  profileType = "PublicInvestment",
  odcSourced,
  isInActive,
  canGenerateReport = true,
  canAddNote = true,
}: Props) {
  const { selectedTabName } = useParams<{ selectedTabName: string }>();
  const query = useLocation().search;
  const selectedTab = () => selectedTabName || defaultSelectedTab || "overview";

  const renderTabs = () =>
    reduce(
      tabContents,
      (tabs: any[], value, key) => {
        if (value.hidden) {
          return tabs;
        }

        const tabName = key;
        let classes = "nav-tab";
        const label = i18n.t(`main_tabs.${tabName}.tab_label`);
        if (selectedTab() === tabName) {
          classes += " nav-tab--selected";
        }
        const tabPath = `/investments/${investmentId}/${tabName}`;
        tabs.push(
          <Link
            to={tabPath}
            id={`${tabName}-tab`}
            key={tabName}
            data-tab-name={tabName}
            className={classes}
          >
            {label}
          </Link>
        );
        return tabs;
      },
      []
    );

  const renderChild = () => {
    const currentSelectedTab = selectedTab();

    if (has(tabContents, currentSelectedTab)) {
      return (
        <div className={classNames("invt-tab", `inv-tab--${selectedTab()}`)}>
          {tabContents[selectedTab()](query)}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    updateSelectedTabName(selectedTab());
  }, [selectedTabName]);

  return (
    <div className="app-row">
      <NavHeader>
        <div className="invst-header">
          <div
            className="invst-header__col--left"
            style={{ display: "grid", gridTemplateRows: "30px 15px" }}
          >
            <div>
              <InvestmentNameView
                isImpact={isImpact || false}
                investmentName={investmentName}
              />

              {isImpact && (
                <div className="fund-tile__flag-wrap">
                  <div className="fund-tile__impact-flag d-inline-block mr-8 p-relative">
                    {i18n.t("fund_tile.impact")}
                  </div>

                  <div className="invst-header__vert-divider mr-16 ml-8" />
                </div>
              )}

              {profileType && (
                <div className="fund-tile__flag-wrap">
                  <div className="fund-tile__market-flag d-inline-block mr-8 p-relative">
                    {i18n.t(`fund_tile.${profileType.toLowerCase()}`)}
                  </div>

                  <div className="invst-header__vert-divider mr-16 ml-8" />
                </div>
              )}

              {odcSourced && (
                <div className="fund-tile__flag-wrap">
                  <div className="fund-tile__data-source-flag d-inline-block mr-8 p-relative">
                    {i18n.t(`fund_tile.dataSourceShortform`)}
                  </div>

                  <div className="invst-header__vert-divider mr-16 ml-8" />
                </div>
              )}
              {isInActive && (
                <div className="fund-tile__flag-wrap">
                  <div className="fund-tile__inactive-flag d-inline-block mr-8 p-relative">
                    {i18n.t("fund_tile.inactive")}
                  </div>

                  <div className="invst-header__vert-divider mr-16 ml-8" />
                </div>
              )}
            </div>
            <div className="invst-header__subtitle">
              <RcTooltip
                overlay={investmentName}
                placement="bottom"
                mouseEnterDelay={0.25}
                destroyTooltipOnHide
              >
                <p
                  id="investment-shortName"
                  className="invst-header__title-txt"
                >
                  <small>{investmentShortName}</small>
                </p>
              </RcTooltip>
            </div>
          </div>

          <InvestmentHeadingActions
            canGenerateReport={canGenerateReport}
            investmentId={investmentId}
            canAddNote={canAddNote}
          />
        </div>
      </NavHeader>

      <Tabs>{renderTabs()}</Tabs>

      {renderChild()}
    </div>
  );
}

export default SummaryContainer;
