import { gql } from "_graphql-types/gql";

export const FIELDS_SEARCH = gql(/* GraphQL */ `
  query fetchFieldsList(
    $filters: FieldFilter
    $sort: [FieldSort!]
    $page: PageInput
  ) {
    fieldList(filter: $filters, sort: $sort, page: $page) {
      items {
        id
        ownerType
        owner {
          id
          name
        }
        fieldKey
        state
        value
        createDate
        createUser
        modifyDate
        modifyUser
        deleteDate
        ownerId
        fieldMeta
        notApplicable
      }
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);

export const FIELDS_DOWNLOAD = gql(/* GraphQL */ `
  query fetchFieldsDownloadList(
    $filters: FieldFilter
    $sort: [FieldSort!]
    $page: PageInput
  ) {
    fieldListDownload(filter: $filters, sort: $sort, page: $page) {
      items {
        id
        ownerType
        owner {
          id
          name
        }
        fieldKey
        state
        value
        createDate
        createUser
        modifyDate
        modifyUser
        deleteDate
        ownerId
        fieldMeta
        notApplicable
      }
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);

export const QUESTION_SEARCH = gql(/* GraphQL */ `
  query questionTextSearch($q: String!) {
    fieldKeysByQuestion(q: $q) {
      fieldKey
      questionText
    }
  }
`);
