import { gql } from "_graphql-types/gql";

export const COMPANY_TILE = gql(/* GraphQL */ `
  fragment CompanyTile on Company {
    id
    name
    valuations(
      sort: [{ field: asOfDate, order: DESC }]
      page: { offset: 0, limit: 1 }
    ) {
      asOfDate
      valuation
    }
    firmAttributes {
      yearFounded
      primaryAddress {
        city
        state {
          code
        }
        otherState
        country {
          name
        }
      }
      firmEmployments(
        filter: { isPartner: true }
        page: { offset: 0, limit: 2 }
      ) {
        person {
          firstName
          middleName
          lastName
        }
      }
      firmTags(
        filter: { tagClassIds: ["22"] }
        sort: [{ field: name, order: ASC }]
        page: { offset: 0, limit: 1 }
      ) {
        tag {
          id
          name
        }
      }
      impactTags {
        id
        name
        impactEnumCategoryId
        impactCategory {
          id
          name
        }
      }
    }
  }
`);

export const EDIT_TOGGLE_COMPANY = gql(/* GraphQL */ `
  fragment EditToggleCompany on Company {
    id
    name
    firmId
  }
`);
