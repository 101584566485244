import React, { Component } from "react";
import PropTypes from "prop-types";
import RcTooltip from "rc-tooltip";
import { getTagInitials } from "Helpers/index";
import _ from "lodash";

export class CompanyTag extends Component {
  static get propTypes() {
    return {
      extraClassName: PropTypes.string,
      truncate: PropTypes.bool,
      truncatingLength: PropTypes.number,
      title: PropTypes.string.isRequired,
      truncateWithInitials: PropTypes.bool,
    };
  }

  truncateTitle() {
    const { truncateWithInitials, title, truncatingLength } = this.props;
    if (truncateWithInitials) {
      return getTagInitials(title);
    }
    return _.truncate(title, { length: truncatingLength });
  }

  isSuitableForTruncation() {
    const { title, truncatingLength } = this.props;

    return title.length > truncatingLength;
  }

  render() {
    const { title, extraClassName, truncate, truncateWithInitials } =
      this.props;

    return truncate &&
      (this.isSuitableForTruncation() || truncateWithInitials) ? (
      <RcTooltip
        overlay={title}
        placement="top"
        prefixCls="fund-tag-tooltip"
        align={{ useCssBottom: true }}
        overlayStyle={{ position: "fixed" }}
        destroyTooltipOnHide
      >
        <div
          className={`ant-tag ant-tag--fund ${extraClassName}`}
          ref={node => {
            this.node = node;
          }}
        >
          {this.truncateTitle()}
        </div>
      </RcTooltip>
    ) : (
      <div
        className={`ant-tag ant-tag--fund ${extraClassName}`}
        ref={node => {
          this.node = node;
        }}
      >
        {title}
      </div>
    );
  }
}

CompanyTag.defaultProps = {
  extraClassName: "",
  truncate: true,
  truncatingLength: 13,
  truncateWithInitials: false,
};
