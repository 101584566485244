import { addFlashNotification } from "_sirius/src/actions/flash_actions";
import { setEditMode } from "_sirius/src/actions/investment/edit_mode_actions";
import { updateSelectedTabName } from "_sirius/src/actions/investment_profile_actions/selected_tab_name_actions";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { editMode } from "Selectors/investment_edit_mode_selectors";
import InvestmentPageComponent from "./InvestmentPage.component";

interface ContainerProps {
  investmentId: string;
}

interface State {
  editMode: boolean;
}

interface DispatchProps {
  addFlashNotification: (options: unknown[]) => void;
  setInvestmentViewMode: () => void;
  updateSelectedTabName: (selectedTabName?: string) => void;
}

const mapStateToProps = (state: unknown, props: ContainerProps): State => ({
  editMode: editMode(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): DispatchProps => ({
  addFlashNotification(options: any) {
    dispatch(addFlashNotification(options));
  },
  setInvestmentViewMode() {
    dispatch(setEditMode(false));
  },
  updateSelectedTabName(selectedTabName?: string) {
    dispatch(updateSelectedTabName(selectedTabName));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector> | any;

export default connector(InvestmentPageComponent);
