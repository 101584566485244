import React, { useMemo } from "react";
import { Table } from "antd";
import { camelCase } from "lodash";
import { ColumnsType } from "antd/lib/table";
import { TChartData, TDataSourceAdditional, TDataSourceMain } from "./types";
import {
  sortNumColumns,
  sortStringColumns,
  renderCell,
  filterAdditonalMinorityGroup,
} from "./utils";

function MainChart({ dataSource }: { dataSource: TDataSourceMain[] }) {
  const columns: ColumnsType<TDataSourceMain> = [
    {
      title: "Race/Ethnicity",
      dataIndex: "category1",
      key: "category1",
      sorter: (a, b) => sortStringColumns(a.category1, b.category1),
    },
    {
      title: "Gender",
      dataIndex: "category2",
      key: "category2",
      sorter: (a, b) => sortStringColumns(a.category2, b.category2),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: text => renderCell(text),
      sorter: (a, b) => sortNumColumns(a.value, b.value),
    },
  ];

  return <Table pagination={false} dataSource={dataSource} columns={columns} />;
}

function AdditionalChart({
  dataSource,
}: {
  dataSource: TDataSourceAdditional[];
}) {
  const columns: ColumnsType<TDataSourceAdditional> = [
    {
      title: "LGBTQ+",
      dataIndex: "lgbtq",
      key: "lgbtq",
      render: (text: unknown) => renderCell(text),
      sorter: (a, b) => sortNumColumns(a.lgbtq, b.lgbtq),
    },
    {
      title: "Veterans",
      dataIndex: "veterans",
      key: "veterans",
      render: (text: unknown) => renderCell(text),
      sorter: (a, b) => sortNumColumns(a.veterans, b.veterans),
    },
    {
      title: "Persons with Disabilities",
      dataIndex: "personsWithDisabilities",
      key: "personsWithDisabilities",
      render: (text: unknown) => renderCell(text),
      sorter: (a, b) =>
        sortNumColumns(a.personsWithDisabilities, b.personsWithDisabilities),
    },
  ];

  return <Table pagination={false} dataSource={dataSource} columns={columns} />;
}

export default function DataTableView({
  chartData,
  isPercent,
  showAdditionalChart = false,
}: {
  chartData: TChartData[];
  isPercent: boolean;
  showAdditionalChart?: boolean;
}): JSX.Element {
  const { main, additional } = useMemo(() => {
    const additionalChartData = chartData.filter(filterAdditonalMinorityGroup);
    const mainChartData = chartData.filter(
      d => !filterAdditonalMinorityGroup(d)
    );

    const dataSourceMain: TDataSourceMain[] = mainChartData.map(d => {
      const { deiCategory1Enum, deiCategory2Enum, value, percentValue } = d;
      return {
        value: isPercent ? value : percentValue,
        category1: deiCategory1Enum.description,
        category2: deiCategory2Enum.description,
        key: `${camelCase(deiCategory1Enum.description)}::${camelCase(
          deiCategory2Enum.description
        )}`,
      };
    });

    const dataSourceAdditional: TDataSourceAdditional[] =
      additionalChartData.reduce((accu, curr) => {
        const { deiCategory1Enum, value, percentValue } = curr;
        const dataKey = camelCase(deiCategory1Enum.description);

        return [
          Object.assign(
            {},
            {
              [dataKey as keyof TDataSourceAdditional]: isPercent
                ? value
                : percentValue,
              key: `${dataKey}::additional`,
            },
            ...accu
          ),
        ];
      }, [] as TDataSourceAdditional[]);

    return {
      main: dataSourceMain,
      additional: dataSourceAdditional,
    };
  }, [chartData, isPercent]);

  return (
    <>
      {showAdditionalChart && (
        <div
          data-cy="firmDei_additionalDataChart"
          style={{ marginTop: "1em" }}
          className="overview-page__card"
        >
          <AdditionalChart dataSource={additional} />
        </div>
      )}
      <div
        data-cy="firmDei_mainDataChart"
        style={{ marginTop: "1em" }}
        className="overview-page__card"
      >
        <MainChart dataSource={main} />
      </div>
    </>
  );
}
