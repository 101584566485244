import React, { useMemo, useState } from "react";

import Performance from "Components/Performance";
import { Grid, Box } from "@mui/material";

import DistributionOfReturns from "./components/distributionOfReturns";
import Drawdowns from "./components/drawdowns";
import UpDownCapture from "./components/upDownCapture";
import BenchmarkTimeSeriesChart from "./components/BenchmarkTimeSeriesChart";

//display in dashboard view.
const PerformanceSubtabPage = ({ investmentId }: { investmentId: number }) => {
  return (
    <Box id="performance-subtab-page" p={4}>
      <Grid container spacing={1}>
        {/* Main area for the selected chart */}
        <Grid item xs={12}>
          <Box>
            <BenchmarkTimeSeriesChart
              investmentId={investmentId}
              type="cumulative"
            />
          </Box>
        </Grid>

        {/* Inactive charts horizontally below */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <Box>
                <DistributionOfReturns investmentId={investmentId} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box>
                <Drawdowns investmentId={investmentId} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box>
                <UpDownCapture investmentId={investmentId} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Performance investmentId={investmentId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PerformanceSubtabPage;
