import { ImageResponseCard } from "@aws-sdk/client-lex-runtime-v2";
import { Card, List } from "antd";
import { ChatMarkdown } from "frontend/src/components/Chat/ChatMarkdown";
import { useState } from "react";

export function ResponseCard({
  imageResponseCard: { title, buttons, imageUrl, subtitle },
  onResponseButtonClick,
}: {
  imageResponseCard: ImageResponseCard;
  onResponseButtonClick?: (value?: string) => void;
}) {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  return (
    <Card
      hoverable
      style={{ width: "fit-content" }}
      bodyStyle={{ padding: 0 }}
      cover={imageUrl ? <img src={imageUrl} /> : void 0}
    >
      <Card.Meta
        title={title}
        description={subtitle && <ChatMarkdown>{subtitle}</ChatMarkdown>}
        style={{ padding: 24 }}
      />
      <div>
        <List
          size="small"
          style={{ borderTop: "solid 1px rgb(240,240,240)" }}
          dataSource={buttons}
          renderItem={(item, i) => (
            <List.Item
              style={{
                backgroundColor: selectedIndex === i ? "#eee" : "initial",
              }}
            >
              {selectedIndex < 0 ? (
                <a
                  href="#"
                  onClick={e => {
                    onResponseButtonClick?.(item.value);
                    setSelectedIndex(i);
                  }}
                >
                  {item.text}
                </a>
              ) : (
                <span>{item.text}</span>
              )}
            </List.Item>
          )}
        />
      </div>
    </Card>
  );
}
