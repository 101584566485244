import { ActionType } from "../actions/action_types";

interface State {
  favoritesSidebarExpanded: boolean;
}

interface Action {
  type: ActionType.TOGGLE_EXPANDED_FAVORITES_SIDEBAR;
}

const defaultState = Object.freeze({
  favoritesSidebarExpanded: window.innerWidth < 720 ? false : true,
});

function appReducer(state: State, action: Action) {
  if (!state) {
    return defaultState;
  }

  switch (action.type) {
    case ActionType.TOGGLE_EXPANDED_FAVORITES_SIDEBAR:
      return {
        ...state,
        favoritesSidebarExpanded: !state.favoritesSidebarExpanded,
      };
    default:
      return state;
  }
}

export default appReducer;
