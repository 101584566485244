import { gql } from "_graphql-types/gql";

export const GET_DRAWDOWN = gql(/* GraphQL */ `
  query GET_DRAWDOWN($investmentId: Int!) {
    investment(id: $investmentId) {
      id
      name
      performanceAnalysis {
        drawdown {
          year
          month
          value
        }
      }
    }
  }
`);
