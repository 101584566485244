import React from "react";
import i18n from "i18next";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { CaretRightOutlined } from "@ant-design/icons";

const Diversity = ({ diversity }) => {
  let diversityKeys;
  let diversityValues;
  if (!isEmpty(diversity)) {
    diversityKeys = Object.keys(diversity).filter(
      key => key !== "__typename" && key !== "id"
    );
    diversityValues = diversityKeys.map(key => diversity[key]);
  }

  return (
    <div data-cy="person-card-diversity" className="team-member__info">
      <p className="team-member__info-title">Diversity</p>
      {!isEmpty(diversityKeys) &&
        diversityKeys.map(key => (
          <>
            {diversity[key] && (
              <p key={key} className="team-member__info-value">
                <CaretRightOutlined />
                {i18n.t(`team_member.diversity.${key}`)}
              </p>
            )}
          </>
        ))}
      {(isEmpty(diversity) ||
        !diversityValues.filter(value => !!value).length) && (
        <p className="team-member__info-value">-</p>
      )}
    </div>
  );
};

Diversity.defaultProps = {
  diversity: null,
};

Diversity.propTypes = {
  diversity: PropTypes.shape({
    africanAmerican: PropTypes.bool,
    asian: PropTypes.bool,
    hispanic: PropTypes.bool,
    lgbtq: PropTypes.bool,
    nativeAmerican: PropTypes.bool,
    other: PropTypes.bool,
    veteran: PropTypes.bool,
    disabled: PropTypes.bool,
    nonBinary: PropTypes.bool,
    hawaiianOrPacificIslander: PropTypes.bool,
    woman: PropTypes.bool,
  }),
};

export default Diversity;
