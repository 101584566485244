import React, { useMemo } from "react";
import { startCase } from "lodash";
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import * as types from "_graphql-types/graphql";
import { COMPANY_ASSIGNMENTS } from "./graphql";
import config from "../../../config/config";
import ProfileImage from "./ProfileImage";

function SummaryCompanyAdvisor({
  companyId,
  className,
}: {
  companyId: number;
  className?: string;
}): JSX.Element {
  const { data } = useQuery<{
    company: types.CompanyAssignmentQuery["company"];
  }>(COMPANY_ASSIGNMENTS, {
    variables: {
      id: companyId,
    },
  });

  const primaryAdvisor = useMemo(
    () => data?.company?.primaryCompanyAdvisor,
    [data]
  );
  const companyManager = useMemo(
    () => data?.company?.faceOfCompany ?? data?.company?.primaryCompanyManager,
    [data]
  );

  const mainClassNames = classNames(
    "cms-overview-advisor__img",
    "main-userpic",
    "main-userpic--100"
  );

  const companyManagerPhotoLink = companyManager?.person.photoLink
    ? `${config.RCG_SIRIUS_URI()}${companyManager.person.photoLink}?size=small`
    : "";
  const advisorPhotoLink = primaryAdvisor?.imageType
    ? `${config.RCG_SIRIUS_URI()}/api/users/${primaryAdvisor.userName}/image`
    : "";

  return (
    <div data-cy="company-assignments">
      <div className="fund-advisor" data-test-id="company-advisor-ceo">
        {!!companyManager && (
          <>
            <ProfileImage
              personName={companyManager.person.fullName}
              personId={companyManager.person.id}
              photoLink={companyManagerPhotoLink}
              mainClassName={mainClassNames}
              fallbackClassName={mainClassNames}
            />
            <div className="mb-10">
              <p className="fund-advisor__name">
                {companyManager.person.fullName &&
                  startCase(companyManager.person.fullName)}
              </p>

              <p className="fund-advisor__position">
                {companyManager.employeeRole?.name}
              </p>
            </div>
          </>
        )}
      </div>
      <div
        className={`fund-advisor ${className}`}
        data-test-id="company-advisor"
      >
        {!!primaryAdvisor && (
          <>
            <ProfileImage
              personName={primaryAdvisor.commonName}
              personId={primaryAdvisor.userName}
              photoLink={advisorPhotoLink}
              mainClassName={mainClassNames}
              fallbackClassName={mainClassNames}
            />

            <div className="mb-10">
              <p className="fund-advisor__name">
                {startCase(primaryAdvisor.commonName)}
              </p>

              <p className="fund-advisor__position">
                {I18n.t("primary_company_advisor.job_title")}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SummaryCompanyAdvisor;
