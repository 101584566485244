import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import { FETCH_FIRM_PEOPLE } from "Components/FirmTeam/graphql";
import Teams from "Components/Team";

const COMPANY_FIRM_ID = gql(/* GraphQL */ `
  query CompanyFirmID($id: Int!) {
    company(id: $id) {
      id
      firmId
    }
  }
`);

export default function CompanyTeam({
  companyId,
}: {
  companyId: number;
}): JSX.Element {
  const { data: companyData } = useQuery(COMPANY_FIRM_ID, {
    variables: { id: companyId },
  });

  const { data, loading, error, fetchMore } = useQuery(FETCH_FIRM_PEOPLE, {
    skip: !companyData?.company?.firmId,
    variables: {
      filter: {
        firmEmployment: {
          isCurrent: true,
          firmId: companyData?.company?.firmId,
        },
      },
      page: {
        offset: 0,
        limit: 25,
      },
    },
  });

  if (error) return <span>Something went wrong loading team.</span>;

  const loadMore = () =>
    fetchMore({
      variables: {
        page: {
          limit: 25,
          offset: data?.teamMemberList.items.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          teamMemberList: {
            ...fetchMoreResult.teamMemberList,
            items: [
              ...fetchMoreResult.teamMemberList.items,
              ...prev.teamMemberList.items,
            ],
          },
        };
      },
    });

  return (
    <Teams
      people={data?.teamMemberList.items ?? []}
      hasMore={!!data && data.teamMemberList.nextPage.hasMore}
      loading={loading}
      fetchMore={loadMore}
    />
  );
}
