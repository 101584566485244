import { CompanyList } from "Components/CompanyList";
import { CompanyTiles } from "Components/CompanyTiles";
import { Layout, LayoutSwitcher } from "Components/layoutswitcher";
import React, { useState } from "react";
import { CompanyControlsActions } from "./CompanyControlsActions";
import { CompanyControlsContext } from "./context";

interface PropViewInterface {
  companyIds: number[];
  layout: Layout;
}

function CompanyView({ companyIds, layout }: PropViewInterface) {
  switch (layout) {
    case Layout.list:
      return <CompanyList companyIds={companyIds} />;
    case Layout.tile:
    default:
      return <CompanyTiles companyIds={companyIds} />;
  }
}

interface PropControlsInterface {
  companyIds: number[];
}

export function CompanyControls({ companyIds }: PropControlsInterface) {
  const [layout, setLayout] = useState(Layout.tile);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState(
    new Set<number>()
  );

  const toggleCompanyId = (companyId: number) => {
    const newSet = new Set(selectedCompanyIds);
    if (selectedCompanyIds.has(companyId)) selectedCompanyIds.delete(companyId);
    else selectedCompanyIds.add(companyId);
    setSelectedCompanyIds(newSet);
  };

  const isSelected = (companyId: number) => selectedCompanyIds.has(companyId);

  const clearSelected = () => setSelectedCompanyIds(new Set());

  return (
    <CompanyControlsContext.Provider
      value={{
        selectedCompanyIds,
        toggleCompanyId,
        isSelected,
        clearSelected,
        isActive: true,
      }}
    >
      <div className="d-flex">
        <div className="flex-grow">
          <CompanyControlsActions />
        </div>

        <LayoutSwitcher
          layout={layout}
          changeLayout={setLayout}
          layoutOptions={[Layout.tile, Layout.list]}
        />
      </div>

      <CompanyView layout={layout} companyIds={companyIds} />
    </CompanyControlsContext.Provider>
  );
}
