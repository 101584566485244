import React from "react";
import Teams from "Components/Team";
import { get } from "lodash";
import { useQuery } from "@apollo/client";
import { FETCH_FIRM_PEOPLE } from "./graphql";

function FirmTeam({ firmId }: { firmId: number }): JSX.Element {
  const { loading, error, data, fetchMore } = useQuery(FETCH_FIRM_PEOPLE, {
    skip: !firmId,
    variables: {
      filter: {
        firmEmployment: {
          isCurrent: true,
          firmId,
        },
      },
      page: {
        offset: 0,
        limit: 25,
      },
    },
  });

  if (error) return <span>Something went wrong loading team.</span>;

  const loadMore = () =>
    fetchMore({
      variables: {
        page: {
          limit: 25,
          offset: data?.teamMemberList.items.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          teamMemberList: {
            ...fetchMoreResult.teamMemberList,
            items: [
              ...fetchMoreResult.teamMemberList.items,
              ...prev.teamMemberList.items,
            ],
          },
        };
      },
    });

  return (
    <Teams
      firmId={firmId}
      people={get(data, "teamMemberList.items", [])}
      hasMore={!!data && data.teamMemberList.nextPage.hasMore}
      loading={loading}
      fetchMore={loadMore}
    />
  );
}

export default FirmTeam;
