/* eslint-disable react/forbid-prop-types */

import React, { Component } from "react";
import PropTypes from "prop-types";
import Exposure from "./components/exposure";
import { PorfolioExposure } from "./components/exposure/PortfolioChart";

class ExposureSubtabPage extends Component {
  render() {
    const { investmentId } = this.props;

    return (
      <div>
        <PorfolioExposure investmentId={investmentId} />
        <Exposure
          investmentId={investmentId}
          exposureType="assetClass"
          label="Asset Class Exposure"
          chartType="area"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="sector"
          label="Sector Exposure"
          chartType="area"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="sector"
          label="Sector Exposure"
          chartType="pie"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="sector"
          label="Sector Exposure"
          chartType="column"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="regional"
          label="Region Exposure"
          chartType="area"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="regional"
          label="Region Exposure"
          chartType="pie"
        />
        <Exposure
          investmentId={investmentId}
          exposureType="regional"
          label="Region Exposure"
          chartType="column"
        />
      </div>
    );
  }
}

ExposureSubtabPage.propTypes = {
  investmentId: PropTypes.number.isRequired,
};

export default ExposureSubtabPage;
