import _ from "lodash";
import "frontend/src/legacyStyles/application.scss";
import initializeI18n from "../config/initializeTranslations";
import i18next from "i18next";

initializeI18n(i18next)
  .then(() => {
    console.log("I18N Initialized");
  })
  .catch(error => {
    console.log(error);
  });

globalThis.I18n = i18next;
window.global = window;
