import React, { useMemo } from "react";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import { groupBy } from "lodash";

import { getPieChartSeries } from "frontend/src/components/HighCharts/dataSeriesFormatters";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

interface RawData {
  year: number;
  month: number;
  weight: number;
  name: string;
}

function ExposurePieChart({
  rawData,
  label,
  percentDisplay,
}: {
  label: string | undefined;
  rawData: RawData[];
  percentDisplay: boolean;
}): JSX.Element {
  const chartData = useMemo(() => {
    if (!rawData) return undefined;
    const seriesCategories = Object.entries(groupBy(rawData, "name")).map(
      ([name]) => ({
        id: name,
        name,
      })
    );
    const records = rawData
      .map(values => ({
        name: values.name,
        date: new Date(values.year, values.month - 1).getTime(),
        weight: values.weight,
      }))
      .sort((point1, point2) => point2.date - point1.date);
    const pieChartSeries = getPieChartSeries(
      records.filter(category => category.date === records[0].date),
      "weight",
      seriesCategories,
      "name"
    );
    if (records.length > 0) {
      pieChartSeries[0].name = HighCharts.dateFormat("%B %Y", records[0].date);
    }
    return pieChartSeries;
  }, [rawData]);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
      },
      title: {
        text: `${label} ${
          chartData?.[0].name ? `- ${chartData?.[0].name}` : ""
        }`,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: `<tr><td style="color:{series.color};padding:0">{series.name}: </td>${
          percentDisplay
            ? '<td style="padding:0"><b>{point.percentage:.2f}%</b></td></tr>'
            : '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>'
        }`,
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            filter: {
              property: "percentage",
              operator: ">",
              value: 0,
            },
          },
        },
      },
      series: chartData,
    }),
    [chartData, percentDisplay]
  );
  return (
    <HighChartsReact
      constructorType="chart"
      options={options}
      highcharts={HighCharts}
    />
  );
}
export default ExposurePieChart;
