import { ChatMessage } from "Components/ChatBox/types";
import { SetStateAction } from "react";
import { MessageStream, MessageStreamEvent } from "src/graphql-types/graphql";

export function formatMessage(stream: MessageStream): ChatMessage {
  return {
    type: "agent",
    id: stream.id,
    data: {
      type: "markdown",
      message: stream.message ?? "",
    },
  };
}

export function handleMessageStream({
  messageStream,
  setFetchingResponse,
  updatePrintedMessages,
  handleStopStream,
  streamingId,
  setStreamingId,
}: {
  messageStream: MessageStream;
  setFetchingResponse: (fetchingResponse: boolean) => void;
  updatePrintedMessages: (value: SetStateAction<ChatMessage[]>) => void;
  handleStopStream?: () => void;
  streamingId?: string;
  setStreamingId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  if (messageStream.event === MessageStreamEvent.Start) {
    setStreamingId?.(messageStream.id);
    // rendering the agent chat header
    setFetchingResponse(false);
  }
  if (messageStream.event === MessageStreamEvent.Stop) {
    setStreamingId?.(void 0);
    handleStopStream?.();
  }
  if (messageStream.event === MessageStreamEvent.Message) {
    // used for mid-stream cancellations, only check if setStreamingId is passed in
    if (setStreamingId && streamingId !== messageStream.id) return;

    updatePrintedMessages(messages => {
      let existing = false;
      const newMessages = messages.map(message => {
        if (messageStream.id !== message.id) {
          return message;
        }
        existing = true;
        if (message.data.type !== "markdown") {
          throw Error(
            "Expecting existing message block to be of 'markdown' message type"
          );
        }
        return {
          ...message,
          data: {
            ...message.data,
            message: message.data.message + messageStream.message,
          },
        };
      });
      if (!existing) {
        newMessages.push(formatMessage(messageStream));
      }
      return newMessages;
    });
  }
}
