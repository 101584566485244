/* eslint-disable max-lines-per-function */
import InvestmentLink from "Components/InvestmentLink";
import { getListItemDetails, renderSize } from "Helpers/investment_helpers";
import i18n from "i18next";
import numeral from "numeral";
import * as types from "_graphql-types/graphql";
import React, { useMemo } from "react";

interface investmentProps {
  investment: NonNullable<types.InvestmentTileFragment>;
}

export const FavoriteInvestment = ({ investment }: investmentProps) => {
  if (!investment) return null;

  const details = useMemo(() => getListItemDetails(investment), [investment]);
  const {
    size,
    sizeCurrency,
    performanceDate,
    performanceType,
    performanceValue,
  } = details;

  const { id, name } = investment;

  function renderPerformance() {
    let performanceColor = "";
    if (Number(performanceValue) > 0) {
      performanceColor = "in-green-500";
    } else if (Number(performanceValue) < 0) {
      performanceColor = "in-pink-500";
    } else {
      performanceColor = "in-secondary-blue";
    }

    return (
      <span className={`font-14 font-weight-300 pr-4 ${performanceColor}`}>
        {numeral(performanceValue).format("0.00%")}
      </span>
    );
  }

  return (
    <InvestmentLink investmentId={id} isEntitled>
      <div className="main-tree__investment-item">
        <p className="main-tree__investment-title">{name}</p>
        <div className="font-12">
          <span className="d-inline-block mr-10">
            <span>
              {renderPerformance()}
              <span className="d-inline-block in-blue-grey-300 mr-4 mb-4">
                {performanceType}
              </span>
            </span>
            <span className="d-inline-block in-blue-grey-300 mr-4 mb-4">
              {performanceDate}
            </span>
          </span>
          <span>
            <span className="d-inline-block mr-4 mb-4">
              {renderSize(size, sizeCurrency)}
            </span>
            <span className="d-inline-block in-blue-grey-300 mr-4 mb-4">
              {i18n.t("favorites_panel.investment_tile.aum_label")}
            </span>
          </span>
        </div>
      </div>
    </InvestmentLink>
  );
};
