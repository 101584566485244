import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

function SearchTermFilterTag({ filter }: { filter: types.QuerySearchFilter }) {
  const dispatch = useDispatch();
  const { value } = filter;
  return (
    <Tag
      className="ant-tag--blue ant-tag--close"
      data-cy="search-term-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "SEARCH_TERM",
                data: filter,
              },
            },
          })
        );
      }}
      closable
    >
      {value}
    </Tag>
  );
}

export default SearchTermFilterTag;
