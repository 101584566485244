import * as types from "_graphql-types/graphql";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import { gql } from "_graphql-types/gql";

export const FETCH_FIRM_BY_INVESTMENT = gql(/* GraphQL */ `
  query FetchInvestmentFirm($id: Int!) {
    investment(id: $id) {
      id
      firm {
        id
        name
      }
    }
  }
`);

export function useFirmFromInvestmentId(investmentId: number) {
  const { data, loading } = useQuery<types.FetchInvestmentFirmQuery>(
    FETCH_FIRM_BY_INVESTMENT,
    {
      variables: { id: investmentId },
    }
  );
  const firmId = useMemo(() => {
    if (data) {
      return data.investment.firm.id;
    }
    return undefined;
  }, [data]);

  return { firmId, loading };
}
