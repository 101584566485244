import { InvestmentList } from "Components/InvestmentList";
import { InvestmentTiles } from "Components/InvestmentTiles";
import { Layout, LayoutSwitcher } from "Components/layoutswitcher";
import React, { useState } from "react";
import { InvestmentControlsContext } from "./context";

import { InvestmentControlsActions } from "./InvestmentControlsActions";

interface PropViewInterface {
  investmentIds: number[];
  layout: Layout;
}

function InvestmentsView({
  investmentIds,
  layout,
}: PropViewInterface): JSX.Element | null {
  if (!investmentIds) return null;
  switch (layout) {
    case Layout.list:
      return <InvestmentList investmentIds={investmentIds} />;
    case Layout.tile:
    default:
      return <InvestmentTiles investmentIds={investmentIds} />;
  }
}

interface PropControlsInterface {
  investmentIds: number[];
  allSelectableInvestmentIds: number[];
  ExtraControls?: () => JSX.Element;
  disableActions?: boolean;
}

export function InvestmentControls({
  investmentIds,
  allSelectableInvestmentIds,
  ExtraControls,
  disableActions = false,
}: PropControlsInterface): JSX.Element {
  const [layout, setLayout] = useState(Layout.tile);
  const [selectedInvestmentIds, setSelectedInvestmentIds] = useState(
    new Set<number>()
  );

  const toggleInvestmentId = (investmentId: number) => {
    const newSet = new Set(selectedInvestmentIds);
    if (newSet.has(investmentId)) {
      newSet.delete(investmentId);
    } else if (selectedInvestmentIds.size + 1 <= 100) {
      newSet.add(investmentId);
    }
    setSelectedInvestmentIds(newSet);
  };

  const isSelected = (investmentId: number) =>
    selectedInvestmentIds.has(investmentId);

  const clearSelected = () => {
    setSelectedInvestmentIds(new Set());
  };

  const selectAll = () => {
    setSelectedInvestmentIds(new Set(allSelectableInvestmentIds.slice(0, 250)));
  };

  return (
    <InvestmentControlsContext.Provider
      value={{
        selectedInvestmentIds,
        toggleInvestmentId,
        isSelected,
        clearSelected,
        isActive: true,
        selectAll,
      }}
    >
      <div className="d-flex">
        {!disableActions && (
          <div className="flex-grow">
            <InvestmentControlsActions ExtraControls={ExtraControls} />
          </div>
        )}

        <LayoutSwitcher
          layout={layout}
          changeLayout={setLayout}
          layoutOptions={[Layout.tile, Layout.list]}
        />
      </div>

      <InvestmentsView layout={layout} investmentIds={investmentIds} />
    </InvestmentControlsContext.Provider>
  );
}
