import { ApolloError, useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getInvestmentFilters,
  getInvestmentSort,
  untagged,
} from "Reducers/globalSearchV2/globalSearchV2.selectors";
import {
  InvestmentFilter,
  InvestmentSort,
  unhandledCase,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { INVESTMENT_SEARCH, INVESTMENT_PRELOAD_IDS } from "./graphql";

export const RCG_FIRM_ID = 438;

function formatInvestmentVariablesForGQL(
  investmentFilters: InvestmentFilter[],
  investmentSort: InvestmentSort | undefined,
  page?: types.PageInput
) {
  const sort = investmentSort;
  const searchFilters: types.InvestmentSearchFilters[] = [];
  const firmSearchFilters: types.FirmSearchFilters[] = [];
  investmentFilters.forEach(filter => {
    switch (filter.type) {
      case "investment":
        searchFilters.push(untagged(filter.data));
        break;
      case "firm":
        firmSearchFilters.push(untagged(filter.data));
        break;
      default:
        throw unhandledCase(filter);
    }
  });

  const variables = {
    page: {
      offset: page?.offset || 0,
      limit: page?.limit || 24,
    },
    sort,
    searchFilters,
    firmSearchFilters,
  };
  return variables;
}

export function usePortfolioSearch(config?: {
  page?: {
    limit?: number;
    offset?: number;
  };
  skip?: boolean;
}) {
  const investmentSort = useSelector(state => getInvestmentSort(state as any));
  const variables: types.InvestmentSearchQueryVariables = useMemo(
    () => ({
      searchFilters: [
        {
          PORTFOLIO: {
            isPortfolio: true,
          },
        },
        {
          FIRM_ENTITY: {
            values: [
              {
                id: RCG_FIRM_ID,
                label: "RockCreek",
              },
            ],
          },
        },
      ],
      sort: investmentSort,
      page: {
        offset: config?.page?.offset || 0,
        limit: config?.page?.limit || 24,
      },
    }),
    [investmentSort, config]
  );

  const { data, error, loading, fetchMore } = useQuery<{
    investmentList: types.InvestmentList;
  }>(INVESTMENT_SEARCH, {
    variables,
    skip: config?.skip,
  });

  const result = useMemo(() => {
    if (data) {
      const list = data.investmentList;
      return {
        ...list,
        fetchMore,
        investmentIds: list.items.map(({ id }) => id),
      };
    }
  }, [data]);

  return {
    variables,
    loading,
    error,
    result,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, max-lines-per-function
export function useGlobalInvestmentSearch(config?: {
  page?: {
    limit?: number;
    offeset?: number;
  };
  skip?: boolean;
}) /* best to have type inferred here */ {
  const investmentSearchFilters = useSelector(state =>
    getInvestmentFilters(state as any)
  );

  const investmentSort = useSelector(state => getInvestmentSort(state as any));

  const variables: types.InvestmentSearchQueryVariables = useMemo(
    () =>
      formatInvestmentVariablesForGQL(
        investmentSearchFilters,
        investmentSort,
        config?.page
      ),
    [investmentSearchFilters, investmentSort, config]
  );

  const { data, error, loading, fetchMore } = useQuery<{
    investmentList: types.InvestmentList;
  }>(INVESTMENT_SEARCH, {
    variables,
    skip: config?.skip,
  });

  const {
    data: preLoadData,
    error: preLoadError,
    loading: preLoading,
  } = useQuery<types.InvestmentPreloadIdsQuery>(INVESTMENT_PRELOAD_IDS, {
    variables: {
      limit: 250,
      sort: variables.sort,
      searchFilters: variables.searchFilters,
      firmSearchFilters: variables.firmSearchFilters,
    },
    skip: config?.skip,
  });

  const result = useMemo(() => {
    if (data) {
      const list = data.investmentList;
      return {
        ...list,
        fetchMore,
        investmentIds: list.items.map(({ id }) => id),
      };
    }
  }, [data]);

  return {
    variables,
    loading: preLoading || loading,
    error: preLoadError || error,
    result,
    preloadedInvestmentKeys: preLoadData?.investmentBulk || [],
  };
}
