import React, { useContext, useMemo } from "react";
import * as types from "_graphql-types/graphql";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import LoadingPanel from "Components/loading_panel";
import { RelativePerformanceContext } from "./context";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

function MonthlyReturnsChart(): JSX.Element {
  const { investment, loading } = useContext(RelativePerformanceContext);

  const chartData: HighCharts.SeriesScatterOptions = useMemo(() => {
    if (!investment?.performanceMTD) return { type: "scatter", data: [] };

    const data = investment?.performanceMTD.reduce((accu, curr) => {
      const benchmarkReturn = investment.primaryBenchmark?.performanceMTD?.find(
        perf =>
          perf.returnYear === curr.returnYear &&
          perf.returnMonth === curr.returnMonth
      );
      if (benchmarkReturn?.return && curr.return) {
        accu.push({
          x: Number(benchmarkReturn.return.toFixed(2)),
          y: Number(curr.return.toFixed(2)),
          description: `${curr.returnYear}-${curr.returnMonth}`,
        });
      }
      return accu;
    }, [] as { x: number; y: number; description: string }[]);

    return {
      type: "scatter",
      name: `${investment.name}`,
      data,
    };
  }, [investment]);

  const chartOptions: HighCharts.Options = {
    chart: {
      type: "scatter",
    },
    title: {
      text: "Monthly Returns vs. Benchmark (%)",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: investment?.primaryBenchmark?.name,
      },
    },
    yAxis: {
      title: {
        text: investment?.name,
      },
    },
    series: [chartData],
    plotOptions: {
      scatter: {
        tooltip: {
          headerFormat: "",
          pointFormat:
            "Primary Benchmark: <b>{point.x}%</b></br>Fund: <b>{point.y}%</b></br>{point.description}",
        },
      },
    },
    exporting: {
      filename: `${investment?.name}_vs_${investment?.primaryBenchmark?.name}`,
    },
    lang: {
      noData: !investment?.primaryBenchmark
        ? "No benchmark defined"
        : "No data to display",
    },
    credits: {
      enabled: false,
    },
  };

  if (!investment?.primaryBenchmark) return <></>;

  return (
    <>
      <LoadingPanel loading={loading}>
        <HighChartsReact
          constructorType="chart"
          options={chartOptions}
          highcharts={HighCharts}
        />
      </LoadingPanel>
    </>
  );
}

export default MonthlyReturnsChart;
