import { gql } from "_graphql-types/gql";

export const PERFORMANCE_STATS_GROUP = gql(/* GraphQL */ `
  query StatsGroup($investmentIds: [Int!]!, $asOfDate: DateTime!) {
    statsGroup(
      groupKey: { investmentIds: $investmentIds, asOfDate: $asOfDate }
    ) {
      compoundReturn1m: compoundReturn(annualized: false)
      compoundReturn3m: compoundReturn(rangeOffset: 2, annualized: false)
      compoundReturn1yAnnualized: compoundReturn(
        rangeOffset: 11
        annualized: true
      )
      compoundReturn3yAnnualized: compoundReturn(
        rangeOffset: 35
        annualized: true
      )
      compoundReturn5yAnnualized: compoundReturn(
        rangeOffset: 59
        annualized: true
      )
      compoundReturnYtd: compoundReturn(rangeUnit: Y, annualized: false)
      compoundReturnItdAnnualized: compoundReturn(
        rangeUnit: I
        annualized: true
      )
    }
  }
`);

export const LOAD_INVESTMENTS_FOR_DOWNLOAD = gql(/* GraphQL */ `
  query loadInvestmentsForDownload($investmentIds: [Int!]!) {
    investmentMany(ids: $investmentIds) {
      ...InvestmentTile
    }
  }
`);
