/* eslint-disable max-len */
import React from "react";
import i18n from "i18next";

export function CompanySummaryTile({
  summary,
}: {
  summary?: string | null;
}): JSX.Element {
  return (
    <div className="invt-tab__section" data-cy="company-summary-tile">
      <h3 className="invt-tab__title">{i18n.t("company_summary.title")}</h3>

      <div className="overview-page__card">
        <div className="overview-page__card-text">
          <div className="rating-txt">
            <div className="cms-updates-wrap">
              <div className="cms-updates__field cms-updates__field--history cms-updates__field--note">
                <div data-cy="company_summary" className="rating-txt">
                  {summary}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
