import React, { useRef, useMemo } from "react";
import { FETCH_PERFORMANCE_PRIVATE } from "./graphql";
import { useQuery } from "@apollo/client";
import LoadingPanel from "../../loading_panel";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts/highstock";
// Note that Highcharts has to be in the codebase already
// Highcharts more

import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

HighCharts.setOptions({ lang: { thousandsSep: "," } });

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

export default function PerformancePrivateGraph({ id }: { id: number }) {
  const chartComponentRef = useRef<HighChartsReact.RefObject>(null);
  const { loading, data } = useQuery(FETCH_PERFORMANCE_PRIVATE, {
    variables: { id },
  });
  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      rangeSelector: {
        selected: 3,
        buttons: [
          {
            type: "year",
            count: 1,
            text: "1y",
            title: "View 1 year",
          },
          {
            type: "year",
            count: 3,
            text: "3y",
            title: "View 3 year",
          },
          {
            type: "all",
            text: "All",
            title: "View all",
          },
        ],
      },
      chart: {
        marginBottom: 100,
        zoomType: "x",
      },
      legend: {
        enabled: true,
        align: "center",
        x: 0,
        y: 0,
      },
      xAxis: {
        tickInterval: 0.01,
        events: {
          // afterSetExtremes,
        },
      },
      yAxis: {
        tickInterval: 0.1,
        plotLines: [
          {
            value: 0,
            width: 2,
            color: "silver",
          },
        ],
      },
      tooltip: {
        pointFormat:
          // eslint-disable-next-line max-len
          '<span style="color:{series.color};z-index:99999">{series.name}</span>: <b>{point.y}%</b><br/>',
        valueDecimals: 2,
        split: true,
        xDateFormat: "%Y-%m-%d",
      },
      series: [
        {
          name: data?.investment.name,
          data: (data?.investment.performancePrivate || []).map(
            ({ returnYear, returnQuarter, netIRR }) => [
              Number(new Date(returnYear, returnQuarter * 3 - 1 + 0)),
              netIRR,
            ]
          ),
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              exporting: {
                enabled: false,
              },
              tooltip: {
                pointFormat:
                  '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}%!!!</b>',
                valueDecimals: 2,
                split: true,
                xDateFormat: "%Y-%m-%d",
              },
              legend: {
                enabled: false,
              },
              rangeSelector: {
                selected: null,
                buttons: [],
              },
              chart: {
                height: 275,
                marginBottom: 0,
              },
              subtitle: {
                text: null,
              },
              navigator: {
                enabled: false,
              },
            },
          },
        ],
      },
    }),
    [data]
  );

  return (
    <div>
      <LoadingPanel loading={loading}>
        <HighChartsReact
          constructorType="stockChart"
          options={options}
          highcharts={HighCharts}
          ref={chartComponentRef}
        />
      </LoadingPanel>
    </div>
  );
}
