/* eslint-disable no-console */
import { useQuery } from "@apollo/client";
import PartnerLink from "Components/PartnerLink";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import PartnerProfile from "./FirmPartnerProfile";
import { FETCH_FIRM_PARTNERS } from "./graphql";

const FirmTilePartners = ({ firmId }: { firmId: number }) => {
  const { personId } = useParams<{ personId: string }>();

  const { data, error } = useQuery(FETCH_FIRM_PARTNERS, {
    variables: {
      filter: {
        firmEmployment: {
          isCurrent: true,
          isPartner: true,
          firmId,
        },
      },
      page: {
        limit: 5,
        offset: 0,
      },
    },
  });

  const persons = useMemo(() => data?.teamMemberList.items, [data]);

  if (error) return <span>Error</span>;
  return (
    <>
      {persons?.map(person => (
        <div key={`partner-${person.id}`} className="pb-10">
          <PartnerLink person={person} />
        </div>
      ))}
      <PartnerProfile selectedPersonId={personId ? Number(personId) : null} />
    </>
  );
};

export default FirmTilePartners;
