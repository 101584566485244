import React from "react";
import i18n from "i18next";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { flow } from "lodash";
import { DynamicLinks } from "./dynamicLinks";
import { useCurrentUser } from "frontend/src/utils/hooks";

function NavSidebarActions(props: {
  onSearchDiscoveryClick: () => void;
  handleClose: () => void;
}) {
  const { onSearchDiscoveryClick, handleClose } = props;
  const user = useCurrentUser();

  const { pathname: currentPage } = useLocation();

  const activeClassName = (pagePath: string) =>
    currentPage === pagePath ? "active" : "";

  const handleItemClick = flow([handleClose]);

  return (
    <div>
      <div
        className={classNames(
          "nav-sidebar__list-item nav-sidebar__list-item--discovery",
          activeClassName("")
        )}
        onClick={onSearchDiscoveryClick}
      >
        <span className="nav-sidebar__list-item-icon icon icon-discovery" />

        <span className="nav-sidebar__list-item-text">
          {i18n.t("navigation_sidebar.discovery")}
        </span>
      </div>

      <Link
        to="/notes"
        onClick={handleItemClick}
        className={classNames(
          "nav-sidebar__list-item nav-sidebar__list-item--notes",
          activeClassName("notes")
        )}
      >
        <span className="nav-sidebar__list-item-icon icon icon-notes" />

        <span className="nav-sidebar__list-item-text">
          {i18n.t("navigation_sidebar.notes")}
        </span>
      </Link>

      <Link
        to="/documents"
        onClick={handleItemClick}
        className={classNames(
          "nav-sidebar__list-item nav-sidebar__list-item--documents",
          activeClassName("documents")
        )}
      >
        <span className="nav-sidebar__list-item-icon icon icon-docs" />

        <span className="nav-sidebar__list-item-text">
          {i18n.t("navigation_sidebar.documents")}
        </span>
      </Link>

      {user.flags.isRockCreek && (
        <Link
          to="/internalDashboards"
          onClick={handleItemClick}
          className={classNames(
            "nav-sidebar__list-item nav-sidebar__list-item--people",
            activeClassName("documents")
          )}
        >
          <span className="nav-sidebar__list-item-icon icon icon-table" />
          <span className="nav-sidebar__list-item-text">
            {I18n.t("navigation_sidebar.internalDashboards")}
          </span>
        </Link>
      )}

      <a
        href="https://storyboard.rcgproduct.com/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClose}
        className="nav-sidebar__list-item nav-sidebar__list-item--solutions"
      >
        <span className="nav-sidebar__list-item-icon icon icon-graph" />

        <span className="nav-sidebar__list-item-text">
          {i18n.t("navigation_sidebar.storyBoard")}
        </span>
      </a>
      <DynamicLinks handleClose={handleClose} />
    </div>
  );
}

export default NavSidebarActions;
