import classnames from "classnames";
import numeral from "numeral";
import { useContext } from "react";
import { InvestmentTileContext } from "../context";

export function DefaultTileFooter() {
  const { details } = useContext(InvestmentTileContext);

  return (
    <div className="tile-footer -padded">
      <div className="tile-footer__col">
        <div
          className={classnames("tile-footer__col-string", "fund-performance", {
            negative: Number(details?.performance) < 0,
            positive: Number(details?.performance) > 0,
            neutral: !details?.performance,
          })}
        >
          {details?.performance
            ? numeral(details.performance).format("0.00%")
            : "-"}
        </div>

        <div className="fund-performance-type tile-footer__col-string tile-footer-medium">
          {details?.performanceType}
        </div>
        <div className="fund-performance-date tile-footer__col-string tile-footer-small">
          {details?.performanceDate}
        </div>
      </div>

      <div className="tile-footer__col text-right">
        <div className="asset-value tile-footer__col-string fund-aum fund-tile-aum">
          {details?.assetValue
            ? numeral(details.assetValue).format("0.0a").slice(0, -1)
            : "-"}
          <span className="font-weight-400 font-14 in-secondary-blue pl-4">
            {details?.assetValue &&
              `${numeral(details.assetValue).format("0.0a").slice(-1)} ${
                details.assetValueCurrency ?? ""
              }`}
          </span>
        </div>

        <div className="asset-value-type tile-footer__col-string tile-footer-medium">
          {details?.assetValueType}
        </div>
        <div className="asset-value-date tile-footer__col-string tile-footer-small">
          {details?.assetValueDate}
        </div>
      </div>
    </div>
  );
}
