import i18next, { InitOptions, i18n } from "i18next";

type ESModule = Record<string, unknown> & { default: Record<string, unknown> };
export function isESModule(module: unknown): module is ESModule {
  return typeof module === "object" && module !== null && "default" in module;
}

function importResources() {
  const content = import.meta.glob("./locales/**/*.{yml,yaml}", {
    eager: true,
  });
  const resources: InitOptions["resources"] = {};
  Object.entries(content).forEach(([path, module]) => {
    if (!isESModule(module)) throw new Error(`${path} is not an ES module`);
    const [_path, language, namespace] =
      path.match(/locales\/(.*)\/(.*).yml/) || [];
    resources[language] = {
      ...resources[language],
      [namespace]: module.default,
    };
  });
  return resources;
}

export default function initializeI18n(i18nxt: i18n = i18next) {
  console.log("Initializing i18n");
  const resources = importResources();
  const options: InitOptions = {
    lng: "en",
    fallbackLng: "en",
    defaultNS: "translations",
    ns: ["translations", "disclaimer"],
    debug: true,
    interpolation: {
      defaultVariables: {
        count: "-",
      },
    },
    resources,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.warn(`Missing translation key: ${lng}:${ns}:${key}`);
      return `${key}`;
    },
  };
  return new Promise((resolve, reject) => {
    i18nxt.init(options, (err, t) => {
      if (err) {
        console.error("ERROR Initializing I18next", err);
      }
      resolve(t);
    });
  });
}
