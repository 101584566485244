import { useLocation } from "react-router-dom";

export const ClientViewCheck = () => {
  const { search } = useLocation();

  if (search.includes("clientView=true")) {
    window.sessionStorage.setItem("clientView", "true");
  }

  if (search.includes("clientView=false")) {
    window.sessionStorage.setItem("clientView", "false");
  }

  return true;
};
