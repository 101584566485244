import { gql } from "_graphql-types/gql";

export const FETCH_INVESTMENT_SET = gql(/* GraphQL */ `
  query investmentSet($investmentSetId: Int!) {
    investmentSet(id: $investmentSetId) {
      ...InvestmentSetAttributes
    }
  }
`);

export const FETCH_INVESTMENT_SETS = gql(/* GraphQL */ `
  query investmentSets($query: String) {
    investmentSetList(filter: { q: $query }, page: { limit: 30 }) {
      items {
        ...InvestmentSetAttributes
      }
      total
    }
  }
`);

export const CREATE_INVESTMENT_SET = gql(/* GraphQL */ `
  mutation createInvestmentSet($input: InvestmentSetInput!) {
    addInvestmentSet(input: $input) {
      ...InvestmentSetAttributes
    }
  }
`);

export const UPDATE_INVESTMENT_SET = gql(/* GraphQL */ `
  mutation updateInvestmentSet($id: Int!, $input: InvestmentSetInput!) {
    updateInvestmentSet(id: $id, input: $input) {
      ...InvestmentSetAttributes
    }
  }
`);
