import { gql } from "_graphql-types/gql";

export const SAVED_ENTITY_SEARCH_QUERY = gql(/* GraphQL */ `
  query SavedEntitySearch($id: Int!) {
    savedEntitySearch(id: $id) {
      ...SavedEntitySearchAttributes
    }
  }
`);

export const INVESTMENT_SET_QUERY = gql(/* GraphQL */ `
  query InvestmentSetSearch($id: Int!) {
    investmentSet(id: $id) {
      ...InvestmentSetAttributes
    }
  }
`);

export const CLEAR_USER_SETTING = gql(/* GraphQL */ `
  mutation ClearUserSetting($input: ClearSettingInput!) {
    clearUserSetting(input: $input) {
      ...UserSettingsAttributes
    }
  }
`);
