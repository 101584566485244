import { createContext } from "react";

type CompanyControlsContextType = {
  selectedCompanyIds: Set<number>;
  toggleCompanyId: (companyId: number) => void;
  isSelected: (companyId: number) => boolean;
  clearSelected: () => void;
  isActive: boolean;
};

export const CompanyControlsContext = createContext<CompanyControlsContextType>(
  {
    selectedCompanyIds: new Set(),
    toggleCompanyId: companyId => {},
    isSelected: companyId => false,
    clearSelected: (): void => {},
    isActive: false,
  }
);
