/* eslint-disable consistent-return */
import { get, isEmpty } from 'lodash'

export function hasWords(summary) {
  return /[A-z]/m.test(summary)
}

export function truncateRecords(records) {
  return records.slice(0, 2);
}

export function truncateWithEllipsis(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength - 1)  }...`;
}

export function displayEducation({ school, degree, graduationYear }) {

  if (!school && !degree && !graduationYear) {
    return '-'
  }
  if ((school.name || degree.name) && graduationYear) {
    return `${get(school, 'name', '')} ${get(degree, 'name', '')}, ${graduationYear}`
  }

  if ((school.name || degree.name) && !graduationYear) {
    return `${get(school, 'name', '')} ${get(degree, 'name', '')}`
  }

  if (graduationYear && (!school.name && !degree.name)) {
    return `${graduationYear}`
  }

}

export function displayWorkExperience({ title, company, fromYear, toYear }) {
  let position;
  let workExperience;

  if (!title && isEmpty(company) && !toYear && !fromYear) return '-';

  if (title && !isEmpty(company)) {
    position = `${title} at ${company.name}`
  } else if (title && isEmpty(company)) {
    position = title
  } else if (!title && !isEmpty(company)) {
    position = company.name
  }

  if (position && fromYear && toYear) {
    workExperience = `${position} from ${fromYear} to ${toYear}`
  } else if (position && fromYear && !toYear) {
    workExperience = `${position} from ${fromYear} to --`
  } else if (position && !fromYear && toYear) {
    workExperience = `${position} until ${toYear}`
  } else if (position && !fromYear && !toYear) {
    workExperience = position
  }

  return workExperience;

}