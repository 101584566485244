import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { gql } from "_graphql-types/gql";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as types from "_graphql-types/graphql";
import { omit, xor } from "lodash";
import { Badge, Spin } from "antd";
import i18n from "i18next";
import { notesIndexContext } from "./NotesIndex.context";
import { NOTES_META_COUNTS } from "./graphql";

function NoteType({
  id,
  isActive,
  label,
  total,
}: {
  id: number;
  isActive: (id: number) => boolean;
  label: string;
  total?: number;
}) {
  const { updateNotesFilter, notesFilters } = useContext(notesIndexContext);

  const active = useMemo(() => isActive(id), [id, isActive]);
  const handleFilterClick = useCallback(
    () =>
      updateNotesFilter({
        ...(notesFilters || {}),
        ...{
          noteMetaIds: [Number(id)],
        },
      }),
    [id, updateNotesFilter, notesFilters]
  );

  const toggleTag: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      e.stopPropagation();

      const newFilter = xor((notesFilters && notesFilters.noteMetaIds) || [], [
        Number(id),
      ]);

      return updateNotesFilter({
        ...omit(notesFilters || {}, "noteMetaIds"),
        ...(newFilter.length > 0 && {
          noteMetaIds: newFilter,
        }),
      });
    },
    [updateNotesFilter, id, notesFilters]
  );

  return (
    <li
      onClick={handleFilterClick}
      className={classNames("notes-category__item", "add-page-nav__item", {
        active,
      })}
    >
      <span className="text-truncate">
        {total !== undefined && (
          <Badge
            count={total}
            style={{ backgroundColor: "#1890ff" }}
            showZero
          />
        )}
        {"  "}
        {label}
      </span>

      <button onClick={toggleTag} className="add-as-tag">
        <span className="add-as-tag__txt">
          {i18n.t(`fund_notes.tag_categories.active_toggle.${active}`)}
        </span>
        <i className="add-as-tag__icon icon icon-tag icon--24" />
      </button>
    </li>
  );
}

export default function NotesCategoriesFilter(): JSX.Element | null {
  const { notesFilters } = useContext(notesIndexContext);

  // const { data: resultData, error, loading } = useQuery(GET_NOTE_TYPES);

  const {
    data: resultData,
    error,
    loading,
  } = useQuery<types.NotesMetaCountsQuery, types.NotesMetaCountsQueryVariables>(
    NOTES_META_COUNTS,
    {
      variables: { filter: notesFilters },
    }
  );

  const [optionData, setOptionData] =
    useState<types.NotesMetaCountsQuery["notesMetaCounts"]>();

  useEffect(() => {
    if (resultData && !optionData) {
      setOptionData(resultData.notesMetaCounts);
    } else if (optionData && resultData) {
      // If a change of filter causes more categories to be available, go ahead and display the options

      const missingOptions = resultData.notesMetaCounts.filter(
        ({ id }) => !optionData.find(({ id: resultId }) => resultId === id)
      );

      if (missingOptions.length > 0) {
        setOptionData(optionData.concat(missingOptions));
      }
    }
  }, [resultData, setOptionData, optionData]);

  const noteMetaIds = useMemo(
    () => (notesFilters && notesFilters.noteMetaIds) || [],
    [notesFilters]
  );

  const data = useMemo(
    () =>
      optionData &&
      optionData
        .map(({ noteMeta, id }) => {
          const result = resultData?.notesMetaCounts.find(
            ({ id: resultId }) => resultId === id
          );

          return {
            noteMeta,
            id,
            count: result ? result.count : undefined,
          };
        })
        .sort((a, b) => {
          // Sort by label
          if (a.noteMeta.label && b.noteMeta.label) {
            return a.noteMeta.label.localeCompare(b.noteMeta.label);
          }
          return 0;
        }),
    [resultData, optionData, noteMetaIds]
  );

  const isActive = useCallback(
    (metaId: number) => noteMetaIds.includes(metaId),
    [noteMetaIds]
  );

  if (error) return <>Error loading categories.</>;
  if ((loading && !data) || !data) return <Spin />;

  return (
    <ul>
      {data.map(({ noteMeta, id, count }) => {
        if (!noteMeta || !noteMeta.label) return null;

        return (
          <NoteType
            key={id}
            id={id}
            label={noteMeta.label}
            isActive={isActive}
            total={count}
          />
        );
      })}
    </ul>
  );
}
