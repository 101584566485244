import * as types from "_graphql-types/graphql";
import {
  EntityFilter,
  Filter,
  FilterState,
  GlobalSearchAction,
  GlobalSearchState,
} from "./globalSearchV2.types";

export const defaultState: GlobalSearchState = {
  filters: {
    "investment::investment::INACTIVE": {
      type: "investment",
      data: {
        type: "investment",
        data: {
          type: "INACTIVE",
          data: { hideInactive: true },
        },
      },
    },
  },
  sorts: {
    investment: {
      field: types.InvestmentSortEnum.ModifyDate,
      order: types.SortInput.Desc,
    },
  },
};

const getFilterKey = (filter: Filter) => {
  switch (filter.type) {
    case "investment":
      return `${filter.type}::${filter.data.type}::${filter.data.data.type}`;
    case "company":
      return `${filter.type}::${filter.data.type}`;
    default:
      const unreachable: never = filter;
      throw new Error("unhandled case");
  }
};

const updateEntityFilter = (
  filters: FilterState,
  entity: EntityFilter,
  isRemove: boolean
): [string, Filter | undefined] => {
  switch (entity.type) {
    case "company": {
      const keys = ["company", entity.data.type] as const;
      const values = (
        Object.values(filters)
          .map(filter =>
            filter.type === keys[0] && filter.data.type === keys[1]
              ? filter.data.data.values
              : undefined
          )
          .find(Boolean) ?? []
      ).filter(({ id }) => id !== entity.data.data.id);
      if (!isRemove) {
        values.push(entity.data.data);
      }

      return [
        keys.join("::"),
        !values.length
          ? undefined
          : {
              type: keys[0],
              data: {
                type: keys[1],
                data: { values },
              },
            },
      ];
    }
    case "investment": {
      const keys = ["investment", "investment", entity.data.type] as const;
      const values = (
        Object.values(filters)
          .map(filter =>
            filter.type === keys[0] &&
            filter.data.type === keys[1] &&
            filter.data.data.type === keys[2]
              ? filter.data.data.data.values
              : undefined
          )
          .find(Boolean) ?? []
      ).filter(({ id }) => id !== entity.data.data.id);
      if (!isRemove) {
        values.push(entity.data.data);
      }

      return [
        keys.join("::"),
        !values.length
          ? undefined
          : {
              type: keys[0],
              data: {
                type: keys[1],
                data: {
                  type: keys[2],
                  data: { values },
                },
              },
            },
      ];
    }
  }
};

const reducer = (
  state: GlobalSearchState = defaultState,
  action: GlobalSearchAction
): GlobalSearchState => {
  switch (action.type) {
    case "GLOBAL_SEARCH": {
      const { data } = action;
      switch (data.type) {
        case "ADD_FILTER": {
          const key = getFilterKey(data.data);
          const filters = { ...state.filters, [key]: data.data };
          return { ...state, filters };
        }
        case "REMOVE_FILTER": {
          const key = getFilterKey(data.data);
          const { [key]: _, ...filters } = state.filters;
          return { ...state, filters };
        }
        case "ADD_TO_ENTITY_FILTER": {
          const [key, filter] = updateEntityFilter(
            state.filters,
            data.data,
            false
          );
          const { [key]: _, ...filters } = state.filters;
          if (filter) filters[key] = filter;
          return { ...state, filters };
        }
        case "REMOVE_FROM_ENTITY_FILTER": {
          const [key, filter] = updateEntityFilter(
            state.filters,
            data.data,
            true
          );
          const { [key]: _, ...filters } = state.filters;
          if (filter) filters[key] = filter;
          return { ...state, filters };
        }
        case "CLEAR_INVESTMENT_FILTERS": {
          const filters = Object.fromEntries(
            Object.entries(state.filters).filter(
              ([, { type }]) => type !== "investment"
            )
          );
          return { ...state, filters };
        }
        case "CLEAR_COMPANY_FILTERS": {
          const filters = Object.fromEntries(
            Object.entries(state.filters).filter(
              ([, { type }]) => type !== "company"
            )
          );
          return { ...state, filters };
        }
        case "ADD_SORT": {
          switch (data.data.type) {
            case "investment": {
              const investment = data.data.data;
              return { ...state, sorts: { ...state.sorts, investment } };
            }
            case "company": {
              const company = data.data.data;
              return { ...state, sorts: { ...state.sorts, company } };
            }
            default:
              const unreachable: never = data.data;
              throw new Error("unhandled case");
          }
        }
        case "REMOVE_SORT": {
          switch (data.data.type) {
            case "investment": {
              const { investment, ...sorts } = state.sorts;
              return { ...state, sorts };
            }
            case "company": {
              const { company, ...sorts } = state.sorts;
              return { ...state, sorts };
            }
            default:
              const unreachable: never = data.data;
              throw new Error("unhandled case");
          }
        }
        default:
          const unreachable: never = data;
          throw new Error("unhandled case");
      }
    }
    default:
      return state;
  }
};

export default reducer;
