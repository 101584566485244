// Models should not be used with redux.. Or be immutable.
// Redux state should be serializerable, and therefor should not use models ?
import ErrorHandler from "./error_handler";
import Filter from "./filter";
import Firm from "./firm";
import Fund from "./fund";
import Selection from "./selection";
import UniversalFund from "./universal_fund";
import riskBudget from "./risk_budget";
import FieldTable from "./field_table";

import ContentItem from "./content_item";
import TableLayoutTypes from "./table_layout_types";

export default {
  ErrorHandler,
  Filter,
  Firm,
  Fund,
  Selection,
  UniversalFund,
  riskBudget,

  FieldTable,
  ContentItem,
  TableLayoutTypes,
};
