class BenchmarkLayoutTypes {
  static get validTypes() {
    return [
      this.combination,
      this.delta
    ];
  }

  static get combination() {
    return 'combination';
  }

  static get delta() {
    return 'delta';
  }

  static get defaultType() {
    return this.combination;
  }

  static get typesWithDescription() {
    return [
      { type: this.combination, description: 'Investment & Benchmark Performance' },
      { type: this.delta, description: 'Difference between Investment and benchmarks' }
    ];
  }

  static isCombination(layoutType) {
    return layoutType === this.combination;
  }

  static isDelta(layoutType) {
    return layoutType === this.delta;
  }

  static isBenchmarkLayout(layoutType) {
    return this.validTypes.includes(layoutType);
  }
}

export default BenchmarkLayoutTypes;
