import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { SortInput } from "_graphql-types/graphql";
import { createContext, useCallback, useMemo, useState } from "react";
import { NOTES_SEARCH } from "./graphql";
import { NotesIndexContextInterface } from "./types";

// eslint-disable-next-line max-lines-per-function
export function useNotesIndex({
  notesFilters,
}: {
  notesFilters?: types.NotesFilter;
}): NotesIndexContextInterface {
  const [notesFiltersState, setNotesFiltersState] = useState<
    types.NotesFilter | undefined
  >(undefined);

  const [notesSort, setNotesSort] = useState<types.NotesSort>({
    field: types.NotesSortEnum.Id,
    order: SortInput.Desc,
  });

  // note filters from outer context take precedence
  const filters = useMemo(
    () => ({ ...notesFiltersState, ...notesFilters }),
    [notesFilters, notesFiltersState]
  );

  const { data, loading, error, fetchMore } = useQuery(NOTES_SEARCH, {
    variables: {
      filters,
      sort: [notesSort],
      page: {
        offset: 0,
        limit: 15,
      },
    },
  });

  const {
    items: notes,
    total,
    nextPage,
  } = useMemo(
    () =>
      data?.notesList ?? {
        items: [] as types.FetchNotesListQuery["notesList"]["items"],
        total: 0,
        nextPage: { offset: 0, limit: 0, hasMore: false },
      },
    [data]
  );

  const loadMoreNotes = useCallback(() => {
    fetchMore({
      variables: {
        page: {
          limit: nextPage?.limit,
          offset: (nextPage?.offset ?? 0) + (nextPage?.limit ?? 15),
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newNotes = fetchMoreResult?.notesList.items;
        const oldNotes = prev ? prev.notesList.items : [];
        // https://github.com/apollographql/apollo-client/issues/5897
        return {
          notesList: {
            ...fetchMoreResult.notesList,
            items: [...oldNotes, ...newNotes],
          },
        };
      },
    });
  }, [fetchMore, nextPage]);

  const contextValue = useMemo(
    () => ({
      notes,
      total,
      loading,
      error,
      notesFilters: filters,
      notesSort,
      fetchMore: loadMoreNotes,
      updateNotesFilter: setNotesFiltersState,
      updateNotesSort: setNotesSort,
      nextPage,
    }),
    [
      total,
      notes,
      loading,
      error,
      filters,
      notesSort,
      nextPage,
      loadMoreNotes,
      setNotesFiltersState,
      setNotesSort,
    ]
  );

  return contextValue;
}

export const notesIndexContext = createContext<NotesIndexContextInterface>({
  total: undefined,
  notes: undefined,
  error: undefined,
  loading: false,
  investmentFilters: undefined,
  notesFilters: undefined,
  nextPage: undefined,
  notesSort: {
    field: types.NotesSortEnum.Id,
    order: SortInput.Desc,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchMore: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateNotesFilter: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateNotesSort: () => {},
});
