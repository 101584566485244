import { gql } from "_graphql-types/gql";

export const PEERS_RETURNS_STREAM = gql(/* GraphQL */ `
  query PeersReturnsStream(
    $id: Int!
    $asOfDate: Date!
    $rangeOffset: Int!
    $filter: InvestmentFilterInput
  ) {
    investment(id: $id) {
      id
      name
      peersStats(asOfDate: $asOfDate, filter: $filter) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          returnsStream: returnsStream(rangeOffset: $rangeOffset)
        }
      }
    }
  }
`);

export const INVESTMENT_SET_RETURNS_STREAM = gql(/* GraphQL */ `
  query InvestmentSetReturnsStream(
    $id: Int!
    $dependentIds: [Int!]!
    $asOfDate: Date!
    $rangeOffset: Int!
  ) {
    investment(id: $id) {
      id
      name
      groupStats(dependentIds: $dependentIds, asOfDate: $asOfDate) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          returnsStream: returnsStream(rangeOffset: $rangeOffset)
        }
      }
    }
  }
`);
