import { InvestmentTile } from "Components/InvestmentTile";
import React from "react";

interface Props {
  investmentIds: number[];
}

export function InvestmentTiles({ investmentIds }: Props) {
  return (
    <div className="main-tile-list">
      {investmentIds.map(id => (
        <div key={id} className="main-tile-list__item">
          <InvestmentTile id={id} />
        </div>
      ))}
    </div>
  );
}
