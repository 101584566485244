import React from "react";
import { useQuery } from "@apollo/client";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import * as types from "_graphql-types/graphql";
import { formatLargeCurrency } from "Helpers/index";
import { get } from "lodash";
import { Space, Tag } from "antd";
import FirmContacts from "Components/FirmContacts";
import AumTableStatic from "frontend/src/components/Template/Static/AumTableStatic";
import NotFound from "Components/show/not_found.js.jsx";
import FirmOverviewCharts from "./FirmOverviewCharts";
import { FIRM_OVERVIEW_DATA } from "./graphql";

function FirmRegistrations({
  registrations,
}: {
  registrations: { name: string; id: number | string }[];
}) {
  if (registrations.length === 0) return null;
  return (
    <Space className="invt-tab__section d-flex">
      {registrations.map(({ id, name }) => (
        <Tag key={id}>{name}</Tag>
      ))}
    </Space>
  );
}

// prepending // seemed ot have issues with https:// links.
const getClickableLink = (link: string) =>
  link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;

function firmEmail(firm: NonNullable<types.FirmOverviewDataQuery["firm"]>) {
  return (
    get(firm, "firmIrEmail") ||
    get(firm, "primaryAddress.email", "-") ||
    get(firm, "email")
  );
}

function FirmLocation({
  firm,
}: {
  firm: NonNullable<types.FirmOverviewDataQuery["firm"]>;
}) {
  if (!firm.primaryAddress) return null;
  return (
    <div className="invt-tab__section mt-20">
      <h3 className="invt-tab__title">Location</h3>
      <div className="overview-page__card">
        {firm.primaryAddress.website && (
          <FieldInlinePreview
            displayName="Website"
            content={
              <a
                href={`${getClickableLink(firm.primaryAddress.website)}`}
                target="_blank"
                rel="noreferrer"
              >
                {firm.primaryAddress.website}
              </a>
            }
          />
        )}
        {firmEmail(firm) && (
          <FieldInlinePreview
            displayName="Email"
            content={
              <a href={`mailto: ${firmEmail(firm)}`}>{firmEmail(firm)}</a>
            }
          />
        )}
        {firm.primaryAddress.phone && (
          <FieldInlinePreview
            displayName="Phone"
            content={
              <a href={`tel:${firm.primaryAddress.phone}`}>
                {firm.primaryAddress.phone}
              </a>
            }
          />
        )}
        {firm.primaryAddress.fax && (
          <FieldInlinePreview
            displayName="Fax"
            content={firm.primaryAddress.fax}
          />
        )}
        {firm.primaryAddress.line1 && (
          <FieldInlinePreview
            displayName="Address"
            content={
              <>
                <p>{firm.primaryAddress.line1}</p>
                {firm.primaryAddress.line2 && (
                  <p>{firm.primaryAddress.line2}</p>
                )}
                {firm.primaryAddress.line3 && (
                  <p>{firm.primaryAddress.line3}</p>
                )}
              </>
            }
          />
        )}
        {firm.primaryAddress.city && (
          <FieldInlinePreview
            displayName="City"
            content={firm.primaryAddress.city}
          />
        )}
        {firm.primaryAddress.zipCode && (
          <FieldInlinePreview
            displayName="ZIP"
            content={firm.primaryAddress.zipCode}
          />
        )}
        {firm.primaryAddress.state && (
          <FieldInlinePreview
            displayName="State"
            content={firm.primaryAddress.state.name || "-"}
          />
        )}
        {firm.primaryAddress.country && (
          <FieldInlinePreview
            displayName="Country"
            content={firm.primaryAddress.country.name || "-"}
          />
        )}
      </div>
    </div>
  );
}

function FirmOverview({ firmId }: { firmId: number }): JSX.Element | null {
  const { data } = useQuery(FIRM_OVERVIEW_DATA, { variables: { firmId } });
  if (!data) return null;
  const { firm } = data;
  if (!firm || !firm.investments) return <NotFound />;
  const [investment] = firm.investments.items;

  return (
    <div className="main-content main-content--lg">
      <div className="overview-page-wrap">
        <div className="overview-page__content">
          <div className="overview-page__top-info">
            <div className="overview-page__top-info-left">
              <FirmRegistrations
                registrations={[
                  ...(firm.registrations || []),
                  ...((firm.registeredWithOther && [
                    {
                      name: firm.registeredWithOther,
                      id: firm.registeredWithOther,
                    },
                  ]) ||
                    []),
                ]}
              />
              <div className="invt-tab__section">
                <h3 className="invt-tab__title">Firm Overview</h3>
                <div className="overview-page__card">
                  <FieldInlinePreview
                    displayName="Year Founded"
                    content={firm.yearFounded || "-"}
                  />
                  {investment && (
                    <FieldInlinePreview
                      displayName="Firm AUM"
                      content={formatLargeCurrency(
                        get(investment, ["latestFirmAUM", "value"])
                      )}
                    />
                  )}
                  <FieldInlinePreview
                    displayName="Employees"
                    content={firm.employeeTotal || "-"}
                  />
                  <FieldInlinePreview
                    displayName="MWDBE"
                    content={(firm.isStaffMWDBE && "yes") || "-"}
                  />
                  <FieldInlinePreview
                    displayName="LEI"
                    content={firm.lei || "-"}
                  />
                  <FieldInlinePreview
                    displayName="CRD"
                    content={firm.crd || "-"}
                  />
                  <FieldInlinePreview
                    displayName="SEC CIK"
                    content={firm.cik || "-"}
                  />
                  <FieldInlinePreview
                    displayName="SBAI Signatory"
                    content={firm.ukHfsbSignatory ? "yes" : "-"}
                  />
                </div>
                <FirmLocation firm={firm} />
                <div className="invt-tab__section mt-20">
                  <h3 className="invt-tab__title">Contacts</h3>
                  <FirmContacts firmId={firmId} />
                </div>
              </div>
              {investment && investment.latestFirmAUM && (
                <div className="invt-tab__section">
                  <h3 className="invt-tab__title">Firm AUM</h3>
                  <AumTableStatic
                    value={firm.fields?.firmAum?.value || []}
                    metadata={{
                      displayName: `Firm  AUM`,
                      displayType: "aum_table",
                      value: {
                        arrayDepth: 1,
                        propertyKey: "value",
                        returnTypeName: `Firm AUM`,
                        isScalar: false,
                        nullable: true,
                        props: [],
                      },
                    }}
                  />
                </div>
              )}
              <div className="invt-tab__section hidden-md-up">
                <FirmOverviewCharts firmId={firmId} />
              </div>
            </div>
            <div className="overview-page__top-info-right hidden-md-down">
              <FirmOverviewCharts firmId={firmId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirmOverview;
