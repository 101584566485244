import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

function NameFilterTag({ filter }: { filter: string }) {
  const dispatch = useDispatch();
  return (
    <Tag
      className="ant-tag--green ant-tag--close"
      data-cy="name-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "company",
            data: {
              type: "NAME",
              data: filter,
            },
          })
        );
      }}
      closable
    >
      {filter}
    </Tag>
  );
}

export default NameFilterTag;
