/* eslint-disable max-lines-per-function */
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import InvestmentLink from "Components/InvestmentLink";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { formatLargeCurrency } from "Helpers/index";
import { booleanToAnswer } from "Helpers/text_formatting";
import i18n from "i18next";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import FirmPartners from "./FirmTilePartners";
import { FIRM_ATTRIBUTES } from "./graphql";

type Firm = types.FetchInvestmentFirmAttributesQuery["investment"]["firm"];
type Investments = NonNullable<Firm["investments"]>["items"];

const renderFundLinks = (investments?: Investments) => {
  if (!investments?.length) return null;

  return investments.map(investment => (
    <div key={investment.id} className="pb-10">
      <InvestmentLink
        className="in-blue-500 peer-link"
        investmentId={investment.id}
        isEntitled
      >
        {investment.name}
      </InvestmentLink>
    </div>
  ));
};

const renderInvestmentRegulators = (firm: Firm) => {
  const regulatorNames = new Set<string>();
  if (!firm?.investments?.items) return [];

  firm?.investments.items.forEach(investment => {
    investment.investmentRegulatorsList?.items.forEach(({ name }) => {
      if (name) regulatorNames.add(name);
    });
  });

  return [...regulatorNames].map(name => <p key={name}>{name}</p>);
};

function InvestmentFirmTile({ investmentId }: { investmentId: number }) {
  const { data, loading } = useQuery(FIRM_ATTRIBUTES, {
    variables: { id: investmentId },
  });

  const investment = useMemo(
    () => data?.investment,
    [data, loading, investmentId]
  );

  const firm = useMemo(() => investment?.firm, [loading, investment]);

  return (
    <div
      id="firm"
      className="overview-page__two-equal-col invt-tab__section"
      data-cy="firm-tile"
    >
      <h3 className="invt-tab__title">{i18n.t("firm_summary.title")}</h3>

      {firm && (
        <div className="overview-page__card">
          <Link to={`/firms/${investment?.firm.id}`}>
            <FieldInlinePreview
              className="cms-broke-long-value pr-10"
              displayName="Firm Name"
              content={firm.name ?? "-"}
            />
          </Link>

          <FieldInlinePreview
            className="cms-broke-long-value"
            displayName="Firm AUM"
            content={formatLargeCurrency(
              investment?.latestFirmAUM?.value,
              investment?.latestFirmAUM?.currency?.name
            )}
          />

          <div className="flex-row">
            <div className="flex-1">
              <p className="pb-10 pr-4 in-secondary-blue">
                {i18n.t("firm_summary.partners")}
              </p>
            </div>

            <div className="flex-1 cms-broke-long-value mr-10">
              {firm && <FirmPartners firmId={firm.id} />}
            </div>
          </div>

          <FieldInlinePreview
            className="cms-broke-long-value"
            displayName="Employees"
            content={firm.employeeTotal ?? "-"}
          />
          <FieldInlinePreview
            className="cms-broke-long-value"
            displayName="Year Founded"
            content={firm.yearFounded ?? "-"}
          />
          <FieldInlinePreview
            className="cms-broke-long-value mr-10"
            displayName="Regulators"
            content={renderInvestmentRegulators(firm)}
          />
          <FieldInlinePreview
            className="cms-broke-long-value"
            displayName="MWDBE"
            content={booleanToAnswer(!!firm.isStaffMWDBE)}
          />

          <div className="flex-row">
            <div className="flex-1">
              <p className="pb-10 pr-4 in-secondary-blue">
                {i18n.t("firm_summary.other_funds")}
              </p>
            </div>

            <div className="flex-1 cms-broke-long-value mr-10">
              {renderFundLinks(firm.investments?.items)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvestmentFirmTile;
