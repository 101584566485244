/* eslint-disable max-lines-per-function */
import { DownloadOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { Layout } from "Components/layoutswitcher";
import { RefreshingDocumentSignedUrl } from "Helpers/utils";
import { Button } from "antd";
import { DocumentProcessButton } from "frontend/src/components/DocumentProcess";
import DocumentsIcon from "frontend/src/components/Documents/DocumentsIcon";
import { downloadFileLink } from "frontend/src/utils/helpers";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { lookup } from "mime-types";
import { extname } from "path";
import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChatSelecter } from "./SpecificChatDocumentPicker";
import { VectorizedIndicator } from "./VectorizedIndicator";
import { Document } from "./types";
import { ChatMarkdown } from "frontend/src/components/Chat/ChatMarkdown";
/**
 * If the date string has no time zone, then it will parse as UTC.
 * So you need to output the UTC date string, otherwise it will format in LOCAL TIME
 * and be off by one day.
 * In general, it will always work this way for dates from graphql,
 * since we only work with two date formats in graphql:
 * - ISO datetime string ending with 'Z' (UTC)
 * - ISO date string (no time nor timezone)
 * @param date
 * @returns
 */
function formatDate(date?: string): string {
  return date ? new Date(date).toISOString().slice(0, 10) : "";
}

const DocumentLink: FC<{
  id: number;
  name: string;
  signedUrl?: RefreshingDocumentSignedUrl | null;
  children: React.ReactNode;
}> = ({ id, name, signedUrl, children }) => {
  if (!signedUrl?.current) return <>{children}</>;
  const contentType = extname(name);
  if (contentType === ".pdf") {
    return <a href={`/documents/${id}`}>{children}</a>;
  }
  return (
    <a
      href={signedUrl.current}
      download={name}
      onClick={e => {
        e.preventDefault();
        signedUrl.getActive().then(url => {
          if (url) downloadFileLink(url, name);
        });
      }}
    >
      {children}
    </a>
  );
};

function DocumentComponent({
  doc,
  layout,
  addToBulk,
  removeFromBulk,
  bulkIds,
  setSpecificChatDocumentId,
}: {
  doc: Document;
  layout: Layout;
  addToBulk: (id: number) => void;
  removeFromBulk: (id: number) => void;
  bulkIds: number[];
  setSpecificChatDocumentId?: (id: number) => void;
}): JSX.Element {
  const {
    id,
    name,
    signedThumbnailUrl,
    investment,
    createUser,
    createDate,
    date,
    firm,
    documentTypeEnum,
    isVectorized,
  } = doc;

  const apolloClient = useApolloClient();

  const { flags } = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signedUrl = useMemo(
    () => new RefreshingDocumentSignedUrl(id, doc.signedUrl, apolloClient),
    [doc.signedUrl]
  );

  if (layout === Layout.list) {
    return (
      <div className="doc-list">
        <div className="doc-list__name flex-2">
          <input
            type="checkbox"
            className="doc-list__add-to-bulk"
            onChange={() =>
              bulkIds.includes(id) ? removeFromBulk(id) : addToBulk(id)
            }
            checked={bulkIds.includes(id)}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <DocumentLink id={id} name={name} signedUrl={signedUrl}>
            {name}
          </DocumentLink>
        </div>
        <div className="flex-1">{investment?.name || firm?.name}</div>
        <div className="flex-1">{flags.isRockCreek ? createUser : ""} </div>
        <div className="flex-1">
          {/* If you parse a date without */}
          {formatDate(date)}{" "}
        </div>
        <div className="flex-1 doc-list__creation">
          <span>
            {signedUrl.current && (
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadOutlined />}
                size="small"
                style={{ fontSize: 14, margin: "0 3px" }}
                onClick={() =>
                  signedUrl.getActive().then(url => {
                    if (url) downloadFileLink(url, name);
                  })
                }
              />
            )}
            <DocumentProcessButton
              documentId={id}
              apolloClient={apolloClient}
              markdownComponent={ChatMarkdown}
            />

            {flags.isRockCreek && (
              <VectorizedIndicator doc={doc} vectorized={!!isVectorized} />
            )}
            {flags.isRockCreek && setSpecificChatDocumentId && (
              <ChatSelecter
                doc={doc}
                setSpecificChatDocumentId={setSpecificChatDocumentId}
              />
            )}
          </span>
        </div>
      </div>
    );
  }

  if (layout === Layout.category) {
    return (
      <div className="doc-list doc-list__dense">
        <div className="doc-list__name flex-2">
          <input
            type="checkbox"
            className="doc-list__add-to-bulk"
            onChange={() =>
              bulkIds.includes(id) ? removeFromBulk(id) : addToBulk(id)
            }
            checked={bulkIds.includes(id)}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <DocumentLink id={id} name={name} signedUrl={signedUrl}>
            {name}
          </DocumentLink>
          <p className="doc-list__date">{formatDate(date)} </p>
        </div>
        <div className="flex-1 doc-list__creation">
          {flags.isRockCreek ? createUser : <span></span>}{" "}
          <span>
            {doc.signedUrl && (
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadOutlined />}
                size="small"
                style={{ fontSize: 14, margin: "0 3px" }}
                onClick={() =>
                  signedUrl.getActive().then(url => {
                    if (url) downloadFileLink(url, name);
                  })
                }
              />
            )}
            <DocumentProcessButton
              documentId={id}
              apolloClient={apolloClient}
              markdownComponent={ChatMarkdown}
            />

            {flags.isRockCreek && (
              <VectorizedIndicator doc={doc} vectorized={!!isVectorized} />
            )}
            {flags.isRockCreek && setSpecificChatDocumentId && (
              <ChatSelecter
                doc={doc}
                setSpecificChatDocumentId={setSpecificChatDocumentId}
              />
            )}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="main-tile-list__item"
      key={`document_${id}`}
      data-cy={`${id}-tile`}
    >
      <div className="doc-tile">
        <div className="doc-tile__preview">
          <DocumentLink id={id} name={name} signedUrl={signedUrl}>
            {signedThumbnailUrl ? (
              <div
                className="doc-tile__preview-img"
                style={{ backgroundImage: `url('${signedThumbnailUrl}')` }}
              >
                <input
                  type="checkbox"
                  className="doc-tile__add-to-bulk"
                  onChange={e => {
                    bulkIds.includes(id) ? removeFromBulk(id) : addToBulk(id);
                  }}
                  checked={bulkIds.includes(id)}
                />
              </div>
            ) : (
              <div className="doc-tile__preview-descr">
                <input
                  type="checkbox"
                  className="doc-tile__add-to-bulk"
                  onChange={e => {
                    e.stopPropagation();
                    bulkIds.includes(id) ? removeFromBulk(id) : addToBulk(id);
                  }}
                  checked={bulkIds.includes(id)}
                />
                <DocumentsIcon
                  iconClassName="doc-tile__preview-icon"
                  mimeType={lookup(name)}
                />
              </div>
            )}
          </DocumentLink>
        </div>
        <div className="doc-tile__info">
          <div className="min-width-0 flex-grow full-width">
            <p className="doc-tile__info-title text-truncate">{name}</p>
            <p className="doc-tile__info-string text-truncate">
              {investment?.name}
            </p>
            <p className="doc-tile__info-string text-truncate">
              {formatDate(date)}
            </p>
            <p className="doc-tile__info-string text-truncate">{createUser} </p>
          </div>
          <div className="doc-tile__icons">
            <div>
              {signedUrl && (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  size="small"
                  style={{ fontSize: 14, margin: "3px 3px" }}
                  onClick={() =>
                    signedUrl.getActive().then(url => {
                      if (url) downloadFileLink(url, name);
                    })
                  }
                />
              )}

              <DocumentProcessButton
                documentId={id}
                apolloClient={apolloClient}
                markdownComponent={ChatMarkdown}
              />
            </div>
            <div>
              {flags.isRockCreek && (
                <VectorizedIndicator doc={doc} vectorized={!!isVectorized} />
              )}
              {flags.isRockCreek && setSpecificChatDocumentId && (
                <ChatSelecter
                  doc={doc}
                  setSpecificChatDocumentId={setSpecificChatDocumentId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentComponent;
