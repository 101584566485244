import React from "react";
import { useQuery } from "@apollo/client";
import {
  Maybe,
  StageEnum,
  ResultEnum,
  CompanyDealsQuery,
} from "_graphql-types/graphql";
import { Table, TableProps } from "antd";
import numeral from "numeral";
import { COMPANY_DEALS } from "./graphql";

function dealSource(deal: CompanyDealsQuery["dealList"]["items"][number]) {
  const sources = [deal.rcSourceUser?.commonName, deal.source].filter(
    (source): source is string => !!source
  );
  if (sources.length > 1) return sources.join("/");
  return sources.join("");
}

interface TableData {
  key: string;
  name?: Maybe<string>;
  closeDate: string;
  fundingRound?: string;
  firm: string;
  dealAmount?: Maybe<number>;
  totalRoundSize?: Maybe<number>;
  preMoneyValuation?: Maybe<number>;
  sources: string;
  dealStructure?: string;
  stage?: Maybe<StageEnum>;
  result?: Maybe<ResultEnum>;
  coInvestors?: Maybe<string>;
}

const tableColumns: TableProps<TableData>["columns"] = [
  {
    dataIndex: "key",
    title: "ID",
    width: 50,
  },
  {
    dataIndex: "closeDate",
    title: "Close Date",
    width: 150,
    sorter: (a, b) =>
      new Date(a.closeDate).getTime() - new Date(b.closeDate).getTime(),
  },
  {
    dataIndex: "fundingRound",
    title: "Funding Round",
    width: 150,
  },
  { dataIndex: "firm", title: "Firm", width: 200 },
  {
    dataIndex: "dealAmount",
    title: "Amount",
    width: 150,
    sorter: (a, b) => (a.dealAmount ?? 0) - (b.dealAmount ?? 0),
    render: dealAmount => numeral(dealAmount).format("0,0"),
  },
  {
    dataIndex: "totalRoundSize",
    title: "Total Round Size",
    width: 200,
    sorter: (a, b) => (a.totalRoundSize ?? 0) - (b.totalRoundSize ?? 0),
    render: totalRoundSize => numeral(totalRoundSize).format("0,0"),
  },
  {
    dataIndex: "preMoneyValuation",
    title: "Pre-money Valuation",
    width: 200,
    sorter: (a, b) => (a.preMoneyValuation ?? 0) - (b.preMoneyValuation ?? 0),
    render: preMoneyValuation => numeral(preMoneyValuation).format("0,0"),
  },
  { dataIndex: "sources", title: "Source(s)" },
  {
    dataIndex: "dealStructure",
    width: 200,
    title: "Deal Structure",
    sorter: (a, b) =>
      (a.dealStructure?.length ?? 0) - (b.dealStructure?.length ?? 0),
  },
  {
    dataIndex: "stage",
    title: "Stage",
    render: stage => stage?.name,
    defaultSortOrder: "ascend",
    width: 200,
    sorter: (a, b) =>
      (Number(a.stage?.displayOrder) ?? 0) -
      (Number(b.stage?.displayOrder) ?? 0),
  },
  {
    dataIndex: "result",
    title: "Result",
    render: result => result?.name,

    sorter: (a, b) => (Number(a.result?.id) ?? 0) - (Number(b.result?.id) ?? 0),
  },
  { dataIndex: "coInvestors", title: "Co-Investors", width: 250 },
];

function formatTableData(
  deals: CompanyDealsQuery["dealList"]["items"]
): TableData[] {
  return deals.map(deal => ({
    key: `${deal.id}`,
    closeDate: deal.expectedCloseDate,
    fundingRound: deal.fundingRound?.name || undefined,
    firm: deal.firm?.name || "",
    dealAmount: deal.amount,
    totalRoundSize: deal.roundSize,
    preMoneyValuation: deal.preMoneyValuation,
    sources: dealSource(deal),
    dealStructure: deal.dealStructure?.name,
    stage: deal.stage,
    result: deal.result,
    coInvestors: deal.coInvestors,
  }));
}

export default function CompanyDeals({
  companyId,
}: {
  companyId: number;
}): JSX.Element | null {
  const { data } = useQuery(COMPANY_DEALS, {
    variables: { filter: { companyId }, page: { limit: 24, offset: 0 } },
    notifyOnNetworkStatusChange: true,
  });
  if (!data) return null;
  const tableData = formatTableData(data.dealList.items);

  return (
    <div>
      <div className="overview-page-wrap">
        <div className="overview-page__content">
          <div className="overview-page__top-info">
            <div className="invt-tab__section">
              <h3 className="invt-tab__title">Deals</h3>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                size="middle"
                tableLayout="auto"
                bordered
                data-cy="deals_table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
