import { Table as AntdTable } from "antd";
import React, { useMemo } from "react";
import Cell from "../PeerStatistics/Table/Cell";
import { PeerInvestmentItem } from "./Table";

interface Params {
  dataPoints: readonly PeerInvestmentItem[];
  dates: Date[];
}

export const getStatResult = (
  stat: string,
  data: (number | null | undefined)[]
): number | null => {
  if (!data || !data.length || !data.filter(rec => !!rec).length) return null;

  switch (stat) {
    case "High":
      return data.reduce((max: number, curr): number => {
        const ret = (curr as number) ?? null;
        return !!ret && ret > max ? ret : max;
      }, -Infinity);
    case "Mean":
      const rets = data.filter(ret => typeof ret === "number") as number[];
      return (
        rets.reduce((total: number, curr): number => {
          return total + curr;
        }, 0) / rets.length
      );
    case "Median":
      const sortedRets = (
        data.filter(ret => typeof ret === "number") as number[]
      ).sort((a, b) => a - b);
      const count = sortedRets.length;
      return count % 2 === 1
        ? sortedRets[Math.floor(count / 2)]
        : (sortedRets[count / 2] + sortedRets[count / 2 - 1]) / 2;
    case "Low":
      return data.reduce((min: number, curr): number => {
        const ret = (curr as number) ?? null;
        return !!ret && ret < min ? ret : min;
      }, Infinity);
    default:
      return null;
  }
};

const SummaryCalcs = ({ dataPoints, dates }: Params): JSX.Element => {
  const summaries = useMemo(() => {
    const data = dataPoints
      .map(({ returns }) => ({ returns }))
      .reduce(
        (accu, curr): { date: Date; value?: number | null | undefined }[] => {
          return [...accu, ...curr.returns];
        },
        [] as { date: Date; value?: number | null | undefined }[]
      );

    return dates.map(currDate => {
      const filteredData = data
        .filter(({ date }) => currDate === date)
        .map(({ value }) => value);

      return {
        date: currDate,
        high: getStatResult("High", filteredData),
        mean: getStatResult("Mean", filteredData),
        median: getStatResult("Median", filteredData),
        low: getStatResult("Low", filteredData),
      };
    });
  }, [dataPoints, dates]);

  return (
    <>
      <AntdTable.Summary fixed>
        <AntdTable.Summary.Row className="bg-grey-10">
          <AntdTable.Summary.Cell index={0}>High</AntdTable.Summary.Cell>
          {dates.map((currDate, index) => (
            <AntdTable.Summary.Cell index={index + 1} key={index}>
              <Cell
                value={summaries.find(({ date }) => date === currDate)?.high}
              />
            </AntdTable.Summary.Cell>
          ))}
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row className="bg-grey-10">
          <AntdTable.Summary.Cell index={0}>Mean</AntdTable.Summary.Cell>
          {dates.map((currDate, index) => (
            <AntdTable.Summary.Cell index={index + 1} key={index}>
              <Cell
                value={summaries.find(({ date }) => date === currDate)?.mean}
              />
            </AntdTable.Summary.Cell>
          ))}
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row className="bg-grey-10">
          <AntdTable.Summary.Cell index={0}>Median</AntdTable.Summary.Cell>
          {dates.map((currDate, index) => (
            <AntdTable.Summary.Cell index={index + 1} key={index}>
              <Cell
                value={summaries.find(({ date }) => date === currDate)?.median}
              />
            </AntdTable.Summary.Cell>
          ))}
        </AntdTable.Summary.Row>
        <AntdTable.Summary.Row className="bg-grey-10">
          <AntdTable.Summary.Cell index={0}>Low</AntdTable.Summary.Cell>
          {dates.map((currDate, index) => (
            <AntdTable.Summary.Cell index={index + 1} key={index}>
              <Cell
                value={summaries.find(({ date }) => date === currDate)?.low}
              />
            </AntdTable.Summary.Cell>
          ))}
        </AntdTable.Summary.Row>
      </AntdTable.Summary>
    </>
  );
};

export default SummaryCalcs;
