import Icon, { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";

import { DataNode } from "antd/lib/tree";
import React, { ReactNode, useMemo } from "react";

export const FETCH_IMPACT_CATEGORIES = gql(/* GraphQL */ `
  query GetImpactCategories {
    impactCategoryEnumList {
      items {
        id
        name
        impactEnums {
          id
          name
        }
      }
    }
  }
`);

export const ICONS: { [key: string]: ReactNode } = {
  1: <CheckOutlined />,
  2: <Icon component={() => <i className="icon icon-fund" />} />,
  3: <Icon component={() => <i className="icon icon-wind-turbine" />} />,
  4: <Icon component={() => <i className="icon icon-mountains" />} />,
  5: <Icon component={() => <i className="fa fa-eye" />} />,
  6: <Icon component={() => <i className="icon icon-agriculture" />} />,
  7: <Icon component={() => <i className="icon icon-library" />} />,
  8: <Icon component={() => <i className="icon icon-coin-dollar" />} />,
  9: <Icon component={() => <i className="icon icon-graduation-cap" />} />,
  10: <StopOutlined />,
};

export function ImpactFilterInput({
  filter,
  setFilter,
  availableImpactCategories,
}: {
  filter: types.ImpactSearchFilter;
  setFilter: React.Dispatch<React.SetStateAction<types.ImpactSearchFilter>>;
  availableImpactCategories: types.GetImpactCategoriesQuery;
}): JSX.Element {
  const {
    impactCategoryEnumList: { items: impactCategories },
  } = availableImpactCategories;

  const treeData = useMemo<DataNode[]>(
    () =>
      impactCategories.map(category => ({
        title: (
          <>
            {ICONS[`${category.id}`]} {category.name}
          </>
        ),
        key: `${category.id}`,
        treeIcon: ICONS[`${category.id}`],
        children: category.impactEnums.map(impactEnum => ({
          title: impactEnum.name,
          key: `${category.id}::${impactEnum.id}`,
        })),
      })),
    [impactCategories]
  );
  const setFilterFromKeys = (
    checkedKeys: { checked: React.Key[] } | React.Key[]
  ) => {
    if (!Array.isArray(checkedKeys)) throw new Error("Unexpected type.. Antd!");
    const values = checkedKeys.reduce<types.EntityKey[]>((accu, checkedKey) => {
      const [categoryId, impactEnumId] = (checkedKey as string)
        .split("::")
        .map(Number);

      if (impactEnumId) {
        const category = impactCategories.find(({ id }) => id === categoryId);
        if (category) {
          const impactEnum = category.impactEnums.find(
            ({ id }) => id === impactEnumId
          );
          if (impactEnum) {
            accu.push({
              id: impactEnumId,
              label: impactEnum.name,
            });
          } else {
            throw new Error(
              `unexpected null selected impact ${JSON.stringify({
                impactEnumId,
                categoryId,
                impactCategories,
              })}`
            );
          }
        } else {
          throw new Error(
            `unexpected null selected category ${JSON.stringify({
              impactEnumId,
              categoryId,
              impactCategories,
            })}`
          );
        }
      }
      return accu;
    }, []);

    setFilter({
      ...filter,
      values,
    });
  };

  const checkedKeysFromFilter = useMemo(() => {
    const accu: string[] = [];
    impactCategories.forEach(({ id: categoryId, impactEnums }) => {
      impactEnums.forEach(({ id: impactId }) => {
        if (filter.values.find(({ id }) => id === impactId)) {
          accu.push(`${categoryId}::${impactId}`);
        }
      });
    });

    return accu;
  }, [filter, impactCategories]);

  return (
    <Tree
      checkable
      treeData={treeData}
      onCheck={setFilterFromKeys}
      checkedKeys={checkedKeysFromFilter}
    />
  );
}
