import { gql } from "_graphql-types/gql";

export const CUSTOM_ANALYTICS_LIST = gql(/* GraphQL */ `
  query GetCustomAnalyticsList(
    $page: PageInput
    $sort: [CustomAnalyticsSort!]
    $filter: CustomAnalyticsFilter
  ) {
    customAnalyticsList(page: $page, sort: $sort, filter: $filter) {
      items {
        id
        ownerId
        ownerType
        value
        investment {
          id
          name
        }
        asOfDate
        createDate
        createUser
        modifyDate
        modifyUser
      }
      total
    }
  }
`);
