import React, { Component } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { curry, uniq, each, size, takeRight, isEqual } from "lodash";
import { CompanyTag } from "./company_tag.js.jsx";
import RcTooltip from "rc-tooltip";

export class CompanyTagList extends Component {
  static get propTypes() {
    return {
      tags: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
      truncateWithInitials: PropTypes.arrayOf(PropTypes.number),
    };
  }

  static get defaultProps() {
    return {
      tags: [],
      truncateWithInitials: [],
    };
  }

  constructor(props) {
    super(props);

    this.saveRef = curry(this.saveRef.bind(this));
    this.tagsRefs = [];
    this.state = {
      visibleTags: props.tags,
    };
  }

  componentDidMount() {
    this.setVisibleTags();
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;
    if (!isEqual(prevProps.tags, tags)) {
      this.setVisibleTags();
    }
  }

  get containerWidth() {
    if (this.node) {
      return this.node.clientWidth;
    }
    return 0;
  }

  setVisibleTags() {
    const { tags } = this.props;
    this.setState({ visibleTags: tags }, () => {
      const visibleTags = this.getVisibleTags();
      this.setState({ visibleTags });
    });
  }

  getTagWidth(tag) {
    const tagRef = this.tagsRefs[tag];
    if (!tagRef || !tagRef.node) {
      return 0;
    }
    return $(tagRef.node).outerWidth(true);
  }

  getVisibleTags() {
    const { tags } = this.props;
    const tooltipTriggerWidth = 35;
    const containerWidth = this.containerWidth - tooltipTriggerWidth;

    const visibleTags = [];
    let occupiedWidth = 0;

    each(tags, tag => {
      occupiedWidth += this.getTagWidth(tag);
      if (occupiedWidth < containerWidth) {
        visibleTags.push(tag);
      }
    });

    return visibleTags;
  }

  getHiddenTags() {
    const { tags } = this.props;
    const { visibleTags } = this.state;
    const hiddenTagsCount = size(tags) - size(visibleTags);

    return takeRight(tags, hiddenTagsCount);
  }

  saveRef(tag, ref) {
    if (!ref) {
      return null;
    }
    this.tagsRefs[tag] = ref;
    return ref;
  }

  renderHiddenTags() {
    const hiddenTags = this.getHiddenTags();

    if (hiddenTags.length === 0) {
      return null;
    }

    const title = hiddenTags.join(", ");

    return (
      <RcTooltip
        overlay={title}
        placement="top"
        prefixCls="fund-tag-tooltip"
        align={{ useCssBottom: true }}
        overlayStyle={{ position: "fixed" }}
        destroyTooltipOnHide
      >
        <div className="ant-tag ant-tag--fund">...</div>
      </RcTooltip>
    );
  }

  render() {
    const { visibleTags: tags } = this.state;
    const { truncateWithInitials } = this.props;

    return (
      <div
        className="tile-tags"
        ref={node => {
          this.node = node;
        }}
      >
        {uniq(tags).map((tag, index) => (
          <CompanyTag
            title={tag}
            key={tag}
            ref={this.saveRef(tag)}
            truncateWithInitials={truncateWithInitials.indexOf(index) !== -1}
          />
        ))}

        {this.renderHiddenTags()}
      </div>
    );
  }
}
