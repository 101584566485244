/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import { useApolloClient } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import classnames from "classnames";
import { CompanyControlsContext } from "Components/CompanyControls/context";
import { CompanyTagList } from "Components/CompanyTile/company_tag_list.js";
import { COMPANY_TILE } from "Components/graphql/fragments/Company";
import { renderIconGrid } from "frontend/src/components/SDGIcons";
import { formatDate } from "frontend/src/utils/date";
import { badgeColorClass } from "Helpers/company";
import { camelCase, get, startCase, truncate } from "lodash";
import numeral from "numeral";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

function CompanyTileFooter({
  valuation,
  valuationDate,
}: {
  valuation?: number | null;
  valuationDate?: Date;
}): JSX.Element {
  return (
    <div className="tile-footer -padded">
      <div className="tile-footer__col text-right">
        <div className="asset-value tile-footer__col-string fund-aum fund-tile-aum">
          {valuation ? numeral(valuation).format("0.0a").slice(0, -1) : "-"}
          <span className="font-weight-400 font-14 in-secondary-blue pl-4">
            {!!valuation && numeral(valuation).format("0.0a").slice(-1)}
          </span>
        </div>

        <div className="asset-value-type tile-footer__col-string tile-footer-medium">
          Valuation
        </div>
        <div className="asset-value-date tile-footer__col-string tile-footer-small">
          {!!valuationDate && formatDate(valuationDate, "yyyy-MM-dd")}
        </div>
      </div>
    </div>
  );
}

function CompanyTileBody({
  company,
}: {
  company: types.CompanyTileFragment;
}): JSX.Element {
  const { name } = company;

  const [founder1, founder2] =
    company.firmAttributes.firmEmployments?.map(({ person }) => person) || [];

  const location = [
    company?.firmAttributes?.primaryAddress?.city,
    company?.firmAttributes?.primaryAddress?.state?.code ??
      company?.firmAttributes?.primaryAddress?.otherState,
    company?.firmAttributes?.primaryAddress?.country?.name &&
      startCase(
        camelCase(company?.firmAttributes?.primaryAddress?.country?.name)
      ),
  ]
    .filter(Boolean)
    .join(", ");

  const industry = company.firmAttributes.firmTags?.[0]?.tag.name;
  const tags =
    company.firmAttributes.impactTags?.map(({ name: _name }) => _name) ?? [];

  const truncatedName = useMemo(
    () =>
      truncate(name, {
        length: 50,
        separator: " ",
      }),
    [name]
  );

  return (
    <div className="fund-tile-background">
      <div className="tile-body">
        <div className="tile-header">
          <h4 className="tile-heading fund-name">{truncatedName}</h4>
        </div>
        <div className="mb-8 p-relative">
          <div className="tile-detail">
            <span className="icon icon-calendar" />
            <span className="inception-date tile-detail__txt">
              Founded in {company.firmAttributes.yearFounded}
            </span>
          </div>

          {[founder1, founder2].map(founder => (
            <div
              key={`founder-tile-detail__${founder?.firstName}-${founder?.lastName}`}
              className="tile-detail"
            >
              <span className="icon icon-user" />
              <span className="portfolio-manager tile-detail__txt">
                {founder?.firstName} {founder?.middleName} {founder?.lastName}
              </span>
            </div>
          ))}

          <div className="tile-detail">
            <span className="icon icon-pin" />
            <span className="location tile-detail__txt">{location}</span>
          </div>

          <div className="tile-detail">
            <span className="icon icon-portfolio" />
            <span className="tile-detail__txt">{industry ?? "-"}</span>
          </div>
        </div>
      </div>
      <CompanyTagList tags={tags} />
      <div className="tile-detail">
        <div className="tile-sdgs">
          {renderIconGrid({
            impactTags:
              company.firmAttributes?.impactTags?.filter(
                tag => tag.impactCategory?.name === "SDGs"
              ) ?? [],
            chunkSize: 12,
          })}
        </div>
      </div>
    </div>
  );
}

interface PropsInterface {
  id: string | number;
}

function CompanyTileComponent({ id }: PropsInterface) {
  const client = useApolloClient();

  const company = client.readFragment({
    id: `Company:${id}`,
    fragment: COMPANY_TILE,
  });

  if (!company) return null;

  const { valuation, asOfDate: valuationDate } =
    (company.valuations && company.valuations[0]) ?? {};

  const { isSelected } = useContext(CompanyControlsContext);
  const companyClasses = useMemo(
    () =>
      classnames("fund-tile-container", badgeColorClass(), {
        "fund-tile-selected": isSelected(company.id),
      }),
    [isSelected(company.id)]
  );

  const link = useMemo(() => `/company/${id}`, [id]);

  const navigate = useNavigate();

  return (
    <div className={companyClasses}>
      <div
        style={{ textDecoration: "none" }}
        className="fund fund-tile -full-access"
        onClick={_e => navigate(link)}
        data-cy={`investment-tile-${id}`}
      >
        <CompanyTileBody {...{ company }} />
        <CompanyTileFooter {...{ valuation, valuationDate }} />
      </div>
    </div>
  );
}

export const CompanyTile = CompanyTileComponent;
