export const getTypeCode = (assetClass?: string, strategy?: string): string => {
  if (!assetClass) return "all";
  if (assetClass === "Private Equity") {
    if (strategy && ["Growth Equity, Venture Capital"].includes(strategy)) {
      return "vc";
    }
    return "pe";
  }
  if (assetClass === "Private Credit") return "pc";
  if (assetClass === "Real Asset") return "ra";
  if (assetClass === "Real Estate") return "re";
  if (assetClass === "Hedge Fund") return "hf";
  if (["Fixed Income", "Equity"].includes(assetClass)) return "trad";
  return "all";
};

export function tagsToList(tags: Record<"name", string>[]): string {
  if (!tags || tags.length < 1) return "-";
  return tags.map(tag => tag && tag.name).join(", ");
}
