import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
} from "redux";

import createDebounce from "redux-debounced";
import { thunk } from "redux-thunk";
import { reducer } from "Reducers/reducers";

export const composeEnhancers =
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === "development"
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

export const middlewares = composeEnhancers(
  applyMiddleware(createDebounce() as any, thunk)
);

export const store = reduxCreateStore(reducer, {}, middlewares);
