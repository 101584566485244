/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
function getConfig(
  key: string,
  required?: boolean
): string | (typeof required extends false ? undefined : never);
function getConfig(key: string, required = true) {
  const value: string | undefined = (window as any)[key];
  if (required && !value) {
    throw new Error(`Missing config '${key}'`);
  }
  return value;
}

export class Config {
  OKTA_ISSUER() {
    return getConfig("OKTA_ISSUER");
  }

  OKTA_DOMAIN() {
    return getConfig("OKTA_DOMAIN");
  }

  OKTA_CLIENT_ID() {
    return getConfig("OKTA_CLIENT_ID");
  }

  HEAP_APP_ID() {
    return getConfig("HEAP_APP_ID", false);
  }

  RCG_GRAPHQL_URI() {
    return getConfig("RCG_GRAPHQL_URI");
  }

  RCG_SIRIUS_URI() {
    return getConfig("RCG_SIRIUS_URI");
  }

  RCG_DATA_COLLECTION_URI() {
    return getConfig("RCG_DATA_COLLECTION_URI");
  }

  RCG_SOLUTIONS_URI() {
    return getConfig("RCG_SOLUTIONS_URI");
  }

  SENTRY_ENVIRONMENT() {
    return getConfig("SENTRY_ENVIRONMENT", false);
  }

  SENTRY_RELEASE() {
    return getConfig("SENTRY_RELEASE", false);
  }

  SENTRY_DSN() {
    return getConfig("SENTRY_DSN", false);
  }

  SIRIUS_APP_ID() {
    return 404;
  }

  SENTRY_TUNNEL() {
    const url = this.RCG_GRAPHQL_URI();
    const apiGateway = url.slice(0, url.lastIndexOf("/graphql"));
    return `${apiGateway}/sentry`;
  }

  RCG_SIRIUS_WS_URI() {
    return getConfig("RCG_SIRIUS_WS_URI");
  }
}

export const SIRIUS_CONFIG = "SIRIUS_CONFIG";

class ConfigWrapper extends Config {
  has(key: keyof Config) {
    return !!(window as any)[SIRIUS_CONFIG]?.[key];
  }
}

const config = new ConfigWrapper();

export default config;
