import * as types from "_graphql-types/graphql";
import { DatePicker, Form } from "antd";
import i18n from "i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { FilterInterface } from "../FilterInterface";

const { Item } = Form;

function InceptionDateFilter() {
  const investmentFilters = useSelector(getInvestmentFilters);

  const inceptionDateFilter: types.DateRangeFilter | undefined = useMemo(() => {
    for (const filter of investmentFilters) {
      if (
        filter.type === "investment" &&
        filter.data.type === "INCEPTION_DATE"
      ) {
        return filter.data.data;
      }
    }
  }, [investmentFilters]);

  const defaultFilter: types.DateRangeFilter = {};

  const [localFilter, setLocalFilter] = useState(
    inceptionDateFilter ?? defaultFilter
  );

  const dispatch = useDispatch();

  const set = () => {
    if (localFilter.min || localFilter.max)
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: "INCEPTION_DATE",
              data: localFilter,
            },
          },
        })
      );
  };

  const reset = () => {
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "INCEPTION_DATE",
            data: defaultFilter,
          },
        },
      })
    );
  };

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.inception_date")}
      count={inceptionDateFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown-datepicker"
    >
      <div className="main-dropdown__menu-body">
        <div className="filters__interseption-datepicker-wrap">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Item label="Start">
              <DatePicker
                data-cy="Inception-Date-From"
                onChange={(_, dateString) => {
                  setLocalFilter({
                    ...localFilter,
                    min: `${dateString}T00:00:00Z`,
                  });
                }}
              />
            </Item>
            <Item label="End">
              <DatePicker
                data-cy="Inception-Date-To"
                onChange={(_, dateString) => {
                  setLocalFilter({
                    ...localFilter,
                    max: `${dateString}T00:00:00Z`,
                  });
                }}
              />
            </Item>
          </Form>
        </div>
      </div>
    </FilterInterface>
  );
}

export default InceptionDateFilter;
