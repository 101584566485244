import { AccessToken, OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import config from "Config/config";
import getAuth from "Services/auth";
import { notification } from "antd";
import { RCGApolloProvider } from "frontend/src/components/RCGApolloProvider";
import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { store } from "../stores/store";
import { ApolloProviderFallback } from "./ApolloProviderFallback";
import { HistoryLogger } from "./HistoryLogger";
import { RequiredAuth } from "./RequiredAuth";
import MercuryApp from "./mercury_app";

interface Services {
  auth: OktaAuth;
  graphqlUrl: string;
  translationLoaded?: boolean;
  webSocketUrl: string;
}

const AppBase = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin));
  };

  const [services, setServices] = useState<Services>();

  useEffect(() => {
    const auth = getAuth(config.OKTA_ISSUER(), config.OKTA_CLIENT_ID());
    setServices({
      auth,
      graphqlUrl: config.RCG_GRAPHQL_URI(),
      translationLoaded: i18next.hasLoadedNamespace("translations"),
      webSocketUrl: config.RCG_SIRIUS_WS_URI(),
    });

    // enable okta auto refresh and such.
    auth.start();

    return () => {
      auth.stop();
    };
  }, []);

  const getAccessToken = useCallback(async () => {
    if (!services) return undefined;
    const { tokenManager } = services.auth;
    const accessToken = (await tokenManager.get("accessToken")) as
      | AccessToken
      | undefined;
    if (!accessToken) throw Error("No access token");
    if (
      tokenManager.hasExpired(accessToken) ||
      tokenManager.getExpireTime(accessToken) <
        (Date.now() + 1000 * 60 * 5) / 1000
    ) {
      try {
        await tokenManager.renew("accessToken");
        const newAccessToken = (await tokenManager.get(
          "accessToken"
        )) as AccessToken;

        if (tokenManager.hasExpired(newAccessToken)) {
          throw Error("Failed to renew token");
        }
        return newAccessToken.accessToken;
      } catch {
        notification.info({
          message: "Session expired, logging out, unable to renew..",
          duration: 30,
          key: "signout",
        });
        services.auth.signOut();
      }
    }
    return accessToken.accessToken;
  }, [services?.auth]);

  // we still need to wait for config to be loaded to load the inside here
  return !services ? null : (
    <Security oktaAuth={services.auth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/implicit/callback" element={<LoginCallback />} />

        <Route path="*" element={<RequiredAuth />}>
          <Route
            path="*"
            element={
              <RCGApolloProvider
                url={services.graphqlUrl}
                getToken={getAccessToken}
                FallbackComponent={ApolloProviderFallback}
                webSocketUrl={services.webSocketUrl}
              >
                <HistoryLogger auth={services.auth} />
                <ReduxProvider store={store}>
                  <MercuryApp />
                </ReduxProvider>
              </RCGApolloProvider>
            }
          />
        </Route>
      </Routes>
    </Security>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppBase />
    </BrowserRouter>
  );
}

App.displayName = "App";

export default App;
