import React, { useMemo } from "react";
import { useDebounce } from "use-debounce";
import { extractSearchTerms } from "./GlobalSearch.helpers";
import { InvestmentDropdown } from "./GlobalSearchEntityDropdown";
import { InvestmentTagDropdown } from "./GlobalSearchTagDropdown";

type GlobalSearchDropdownProps = {
  searchTerm: string;
  blur: () => void;
};

export function GlobalSearchDropdown({
  searchTerm,
  blur,
}: GlobalSearchDropdownProps) {
  const [debounceSearchTerm] = useDebounce(searchTerm.trim(), 300);
  const tagSearchTerm = useMemo(() => {
    return extractSearchTerms(searchTerm).tagSearchTerm;
  }, [debounceSearchTerm]);

  if (typeof tagSearchTerm === "string") {
    return <InvestmentTagDropdown searchTerm={tagSearchTerm} />;
  }

  return <InvestmentDropdown searchTerm={debounceSearchTerm} blur={blur} />;
}
