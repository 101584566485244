/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from "react";
import Slick from "react-slick";
import { useQuery } from "@apollo/client";
import { INVESTMENT_GALLERY } from "Components/show/documents/graphql";
import { extname } from "path";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const imageExtensions = [".jpg", ".png"];
const videoExtensions = [".mp4"];
const supportedExtensions = [...imageExtensions, ...videoExtensions];

enum Direction {
  left = "left",
  right = "right",
}

const DirectionButton = ({
  direction,
  click,
}: {
  direction: Direction;
  click: () => void;
}) => (
  <button
    type="button"
    onClick={click}
    className={`main-gallery__control main-gallery__control-${direction}`}
  >
    <i className={`icon icon-angle-${direction}`} />
  </button>
);

const Image = ({ url, name }: { url: string; name: string }) => (
  <div className="main-gallery__image-wrap">
    <img
      data-testid="gallery-image"
      src={url}
      alt={name}
      className="main-gallery__image"
    />
  </div>
);

const Video = ({ url }: { url: string }) => (
  <div className="main-gallery__image-wrap">
    <video
      data-testid="gallery-video"
      src={url}
      preload="true"
      controls
      className="main-gallery__modal-body-video"
    />
  </div>
);

const Thumbnail = ({
  url,
  click,
  name,
}: {
  url: string;
  click: () => void;
  name: string;
}) => (
  <div className="main-gallery__thumbnail" onClick={click}>
    <img
      alt={name}
      className="main-gallery__thumbnail-img main-gallery__thumbnail-bg"
      src={url}
    />
  </div>
);

const InvestmentGallery = ({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element | null => {
  const { data, loading } = useQuery(INVESTMENT_GALLERY, {
    variables: { investmentId },
  });
  const items = data?.documents?.items?.filter(({ name }) =>
    supportedExtensions.includes(extname(name))
  );
  const sliderRef = useRef<Slick>(null);
  if (loading) return null;
  if (!items?.length) {
    return (
      <div
        className="main-gallery__wrap"
        style={{
          backgroundColor: "lightgray",
          height: "200px",
          textAlign: "center",
          fontSize: "30px",
        }}
      >
        No Gallery Images
      </div>
    );
  }

  return (
    <div className="main-gallery__wrap">
      <DirectionButton
        direction={Direction.left}
        click={() => {
          if (sliderRef?.current) sliderRef.current.slickPrev();
        }}
      />
      <DirectionButton
        direction={Direction.right}
        click={() => {
          if (sliderRef?.current) sliderRef.current.slickNext();
        }}
      />
      {items && (
        <>
          <Slick
            initialSlide={0}
            arrows={false}
            infinite
            slidesToScroll={1}
            slidesToShow={1}
            speed={500}
            ref={sliderRef}
          >
            {items.map(doc => (
              <div key={doc.id}>
                {imageExtensions.includes(extname(doc.name)) && (
                  <Image name={doc.name} url={doc.signedUrl || ""} />
                )}
                {videoExtensions.includes(extname(doc.name)) && (
                  <Video url={doc.signedUrl || ""} />
                )}
              </div>
            ))}
          </Slick>
          <div
            className="main-gallery__thumbnails-container"
            style={{ position: "relative" }}
          >
            <div className="main-gallery__thumbnails-cell">
              <div className="main-gallery__thumbnails-wrap">
                <div
                  style={{ marginLeft: 0 }}
                  className="main-gallery__thumbnails"
                >
                  {items.map(
                    ({ signedThumbnailUrl, signedUrl, id, name }, i) => (
                      <Thumbnail
                        key={`thumbnail::${id}`}
                        name={name}
                        url={signedThumbnailUrl || signedUrl || ""}
                        click={() => {
                          sliderRef.current?.slickGoTo(i);
                        }}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvestmentGallery;
