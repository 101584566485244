import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { useDispatch } from "src/react-redux";

export default function HeadquartersFilterTag({
  filter,
}: {
  filter: types.LocationFilter;
}) {
  const dispatch = useDispatch();
  const { city, stateEnum, countryEnum } = filter;
  return (
    <Tag
      className="ant-tag--green ant-tag--close"
      data-cy="headquarters-filter-tag"
      onClose={() => {
        dispatch(
          removeFilter({
            type: "company",
            data: {
              type: "HEADQUARTERS",
              data: filter,
            },
          })
        );
      }}
      closable
    >
      {`Headquarters ${city ?? ""}`}
    </Tag>
  );
}
