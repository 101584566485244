import { gql } from "_graphql-types/gql";

export const PRIVATE_PEERS_ROOT = gql(/* GraphQL */ `
  query InvestmentPrivatePeersRoot($id: Int!) {
    investment(id: $id) {
      ...PrivateInvestmentPeersFragment
      performancePrivate(sort: { field: asOfDate, order: DESC }) {
        investmentId
        returnYear
        returnQuarter
        netIRR
        tvpi
        dpi
      }
    }
  }
`);

export const PRIVATE_PEERS_STATS = gql(/* GraphQL */ `
  query InvestmentPrivatePeersStats(
    $id: Int!
    $performancePrivateFilter: PerformancePrivateFilter
    $investmentPeersFilter: InvestmentPeersFilterInput
    $investmentSort: [InvestmentSort!]
  ) {
    investment(id: $id) {
      id
      name
      peersList(sort: $investmentSort, filter: $investmentPeersFilter) {
        items {
          ...PrivateInvestmentPeersFragment
          performanceDataRange: performancePrivate(
            sort: { field: asOfDate, order: DESC }
          ) {
            returnYear
            returnQuarter
          }
          performancePrivate(
            sort: { field: asOfDate, order: DESC }
            filter: $performancePrivateFilter
          ) {
            investmentId
            returnYear
            returnQuarter
            netIRR
            tvpi
            dpi
          }
        }
      }
    }
  }
`);
