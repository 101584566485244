import {
  EntityFilter,
  Filter,
  GlobalSearchAction,
  Sort,
} from "./globalSearchV2.types";

export const updateFilter = (data: Filter): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "ADD_FILTER",
    data,
  },
});

export const removeFilter = (data: Filter): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "REMOVE_FILTER",
    data,
  },
});

export const clearAllInvestmentFilters = (): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "CLEAR_INVESTMENT_FILTERS",
  },
});

export const clearAllCompanyFilters = (): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "CLEAR_COMPANY_FILTERS",
  },
});

export const addSort = (data: Sort): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "ADD_SORT",
    data,
  },
});

export const removeSort = (data: Sort): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "REMOVE_SORT",
    data,
  },
});

export const addToEntityFilter = (data: EntityFilter): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "ADD_TO_ENTITY_FILTER",
    data,
  },
});

export const removeFromEntityFilter = (
  data: EntityFilter
): GlobalSearchAction => ({
  type: "GLOBAL_SEARCH",
  data: {
    type: "REMOVE_FROM_ENTITY_FILTER",
    data,
  },
});
