import React from "react";
import { getBubbleChartOptions } from "frontend/src/components/HighCharts";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import * as types from "_graphql-types/graphql";
import { getTwoCategorySeries } from "./helpers";

interface BubbleChartProps {
  aggregatedData: Partial<types.FirmDeiRecord>[];
  title?: string;
  deiCategory1List: types.DeiCategory1Enum[];
  deiCategory2List: types.DeiCategory2Enum[];
  isPercent: boolean;
  subTitle?: string;
  callback?: (chart: any) => void;
  fontSize?: number;
  height?: number;
  width?: number;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  allowChartUpdate?: boolean;
}

function BubbleChartView({
  aggregatedData,
  title,
  deiCategory1List,
  deiCategory2List,
  isPercent,
  subTitle,
  callback,
  fontSize = 10,
  height = 500,
  width = 800,
  legendSymbolHeight,
  legendItemMarginTop,
  allowChartUpdate,
}: BubbleChartProps) {
  const rValueKey: "percentValue" | "value" = isPercent
    ? "value"
    : "percentValue";
  const dataSeries: any = getTwoCategorySeries(
    deiCategory1List,
    deiCategory2List,
    aggregatedData,
    rValueKey,
    "packedbubble"
  );
  const options: HighCharts.Options = getBubbleChartOptions({
    highChartType: "packedbubble",
    dataSeries,
    height,
    width,
    fontSize,
    subTitle,
    allowExport: false,
    dataLabelFormat: "{point.name}: {point.value}%",
    formatSuffix: "%",
    allowDecimals: false,
    title,
    legendSymbolHeight,
    legendItemMarginTop,
  });

  if (callback) {
    return (
      <HighChartsReact
        data-cy="firm-vis-bubblechart"
        options={options}
        highcharts={HighCharts}
        immutable
        allowChartUpdate={false}
        callback={callback}
      />
    );
  }

  return (
    <HighChartsReact
      data-cy="firm-vis-bubblechart"
      options={options}
      highcharts={HighCharts}
      immutable
      allowChartUpdate={allowChartUpdate}
    />
  );
}

export default BubbleChartView;
