export interface State {
  user: {
    fullName: string;
    email: string;
    id: number;
    organizationId: number;
  };
}

export const fullName = (state: State) => state.user.fullName;
export const email = (state: State) => state.user.email;
export const id = (state: State) => state.user.id;
export const organizationId = (state: State) => state.user.organizationId;
