import React from "react";
import Teams from "Components/Team";
import { get } from "lodash";
import { useQuery } from "@apollo/client";
import { useFirmFromInvestmentId } from "Helpers/get_firm_hook";
import { FETCH_INVESTMENT_PEOPLE } from "./graphql";

function InvestmentPage({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element | null {
  const { firmId } = useFirmFromInvestmentId(investmentId);

  const { loading, error, data, fetchMore } = useQuery(
    FETCH_INVESTMENT_PEOPLE,
    {
      skip: !firmId,
      variables: {
        filter: {
          firmEmployment: {
            isCurrent: true,
            investmentIdOrIsKeyPersonnel: {
              investmentId,
            },
            firmId,
          },
        },
        page: {
          offset: 0,
          limit: 25,
        },
      },
    }
  );

  if (error) return <span>Something went wrong loading team.</span>;

  const loadMore = () =>
    fetchMore({
      variables: {
        page: {
          limit: 25,
          offset: data?.teamMemberList.items.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          teamMemberList: {
            ...fetchMoreResult.teamMemberList,
            items: [
              ...fetchMoreResult.teamMemberList.items,
              ...prev.teamMemberList.items,
            ],
          },
        };
      },
    });

  if (!firmId) return null;

  return (
    <Teams
      firmId={firmId}
      people={get(data, "teamMemberList.items", [])}
      hasMore={(data && data.teamMemberList.nextPage.hasMore) || false}
      loading={loading}
      fetchMore={loadMore}
    />
  );
}

export default InvestmentPage;
