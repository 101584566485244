/* eslint-disable react/no-array-index-key */
import React from "react";
import i18n from "i18next";
import { partition, reverse, camelCase } from "lodash";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { useQuery } from "@apollo/client";
import { tagsToList } from "Helpers/investment_tag_helpers";
import { impactField } from "frontend/src/components/SDGIcons";
import { COMPANY_CLASSIFICATIONS } from "./graphql";

type EntityTags = {
  tag: {
    name: string;
    tagClass: {
      name: string;
      id: number;
    };
    id: number;
    tagClassId: number;
  };
};

function tagField(companyTags: EntityTags[], displayName: string) {
  return {
    displayName,
    content: tagsToList(
      companyTags
        .filter(companyTag => companyTag.tag.tagClass?.name === displayName)
        .map(firmTag => firmTag.tag)
    ),
  };
}

function Classifications({ companyId }: { companyId: number }): JSX.Element {
  const { data } = useQuery(COMPANY_CLASSIFICATIONS, {
    variables: { id: companyId },
  });
  const company = data?.company;
  const companyTags = company?.firmAttributes.firmTags ?? [];
  const sdgTags =
    company?.firmAttributes?.impactTags?.filter(
      t => t.impactEnumCategoryId === 11
    ) ?? [];

  const cardFields: {
    displayName: string;
    content: JSX.Element[] | string;
    className?: string;
  }[] = [tagField(companyTags, "Industry"), impactField(sdgTags, "SDGs")];

  const columnize = (nodes: JSX.Element[]) => (
    <div className="overview-page__classifications">
      {reverse(
        partition(nodes, item => nodes.indexOf(item) % 2).map((node, index) => (
          <div className="overview-page__classifications-col" key={index}>
            {node}
          </div>
        ))
      )}
    </div>
  );

  return (
    <div
      className="invt-tab__section"
      id="classifications"
      data-cy="company_classifications"
    >
      <h3 className="invt-tab__title">
        {i18n.t("company.classifications.title")}
      </h3>

      <div data-cy="company_classifications" className="overview-page__card">
        {columnize(
          cardFields.map(({ displayName, content, className = "" }) => (
            <FieldInlinePreview
              key={`${camelCase(displayName)}::Field`}
              displayName={displayName}
              content={content}
              className={className}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Classifications;
