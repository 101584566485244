/* eslint-disable max-lines-per-function */
import { useApolloClient, useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";
import { AddSavedEntitySearchMutation } from "_graphql-types/graphql";
import {
  AutoComplete,
  Checkbox,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import Tag from "antd/lib/tag";
import classnames from "classnames";
import { Authorized } from "Components/Authorized";
import { ensureDefined } from "frontend/src/utils/helpers";
import { useCurrentUser } from "frontend/src/utils/hooks";
import i18n from "i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  clearAllCompanyFilters,
  clearAllInvestmentFilters,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import {
  getCompanyFilters,
  getInvestmentFilters,
} from "Reducers/globalSearchV2/globalSearchV2.selectors";
import {
  CompanyFilter,
  InvestmentFilter,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";
import { useDebouncedCallback } from "use-debounce";
import { logException } from "../../helpers";
import { CompanyHeadquartersFilter } from "./Filters/Company/CompanyHeadquartersFilter";
import CompanyImpactFilter from "./Filters/Company/CompanyImpactFilter";
import { IndustryFilter } from "./Filters/Company/IndustryFilter";
import { YearFoundedFilter } from "./Filters/Company/YearFoundedFilter";
import AssetClassStrategyFilter from "./Filters/Investment/AssetClassStrategyFilter";
import HeadquartersFilter from "./Filters/Investment/HeadQuartersFilter";
import InceptionDateFilter from "./Filters/Investment/InceptionDateFilter";
import InvestmentImpactFilter from "./Filters/Investment/InvestmentImpactFilter";
import MwdbeFilter from "./Filters/Investment/MwdbeFilter";
import PerformanceFilter from "./Filters/Investment/PerformanceFilter";
import PrimaryGeographyFilter from "./Filters/Investment/PrimaryGeographyFilter";
import { SavedEntitySearchFilter } from "./Filters/Investment/SavedEntitySearchFilter";
import { SizeFilter } from "./Filters/Investment/SizeFilter";
import TypeFilter from "./Filters/Investment/TypeFilter";
import VintageYearFilter from "./Filters/Investment/VintageYearFilter";
import VolatilityFilter from "./Filters/Investment/VolatilityFilter";
import CompanyFilterTag from "./FilterTags/Company/CompanyFilterTag";
import InvestmentFilterTag from "./FilterTags/Investment/InvestmentFilterTag";
import { MODIFY_USER_SETTINGS } from "./graphql";

export const ADD_ENTITY_SEARCH = gql(/* GraphQL */ `
  mutation addSavedEntitySearch($input: SavedEntitySearchInput!) {
    addSavedEntitySearch(input: $input) {
      id
    }
  }
`);

export const UPDATE_ENTITY_SEARCH = gql(/* GraphQL */ `
  mutation updateSavedEntitySearch($id: Int!, $input: SavedEntitySearchInput!) {
    updateSavedEntitySearch(id: $id, input: $input) {
      id
    }
  }
`);

export const ENTITY_SEARCH_LIST = gql(/* GraphQL */ `
  query savedEntitySearchListGlobal(
    $organizationId: Int!
    $userId: Int!
    $name: String
    $nameSearch: String
  ) {
    savedEntitySearchList(
      filter: {
        organizationId: $organizationId
        userId: $userId
        entityType: investment
        name: $name
        nameSearch: $nameSearch
      }
      sort: [{ field: nameSearchRank, order: ASC }]
      page: { offset: 0, limit: 20 }
    ) {
      items {
        id
        entityType
        name
        data
      }
    }
  }
`);
function InvestmentFilters() {
  const filters = [
    TypeFilter,
    AssetClassStrategyFilter,
    SizeFilter,
    PrimaryGeographyFilter,
    PerformanceFilter,
    VolatilityFilter,
    InvestmentImpactFilter,
    MwdbeFilter,
    InceptionDateFilter,
    VintageYearFilter,
    HeadquartersFilter,
    SavedEntitySearchFilter,
  ];

  return (
    <div data-cy="investment-filter-container">
      {filters.map((GlobalFilter, index) => (
        <GlobalFilter key={index} />
      ))}
    </div>
  );
}

type TSavedEntitySearch = {
  id: number;
  entityType: string;
  name: string;
  data: string;
};

interface ModalState {
  open: boolean;
  isUpdate: boolean;
  nameSearch: string;
  value: string;
  optionPick?: TSavedEntitySearch;
  name: string;
  saveError: string;
}

const initModal = (): ModalState => ({
  isUpdate: false,
  name: "",
  nameSearch: "",
  open: false,
  saveError: "",
  value: "",
});

function FilterTags() {
  const investmentFilters: InvestmentFilter[] =
    useSelector(getInvestmentFilters);
  const companyFilters: CompanyFilter[] = useSelector(getCompanyFilters);
  const dispatch = useDispatch();

  const filterCount = investmentFilters.length + companyFilters.length;
  if (!filterCount) return null;

  return (
    <div className="main-gf__marks-list">
      {!!filterCount && (
        <Row>
          <Col span={20}>
            {investmentFilters.map((filter: InvestmentFilter, index) => (
              <InvestmentFilterTag key={index} filter={filter} />
            ))}
            {companyFilters.map((filter: CompanyFilter, index) => (
              <CompanyFilterTag key={index} filter={filter} />
            ))}
          </Col>
          <Col span={4}>
            <Tag
              className="ant-tag--reset"
              data-cy="clear-all-filters"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(clearAllInvestmentFilters());
                dispatch(clearAllCompanyFilters());
              }}
            >
              {i18n.t("filters.reset_all")}
            </Tag>
          </Col>
        </Row>
      )}
    </div>
  );
}

function InvestmentFilterTags() {
  const client = useApolloClient();
  const user = useCurrentUser();
  const [modalState, setModalState] = useState(initModal());
  const [updateUserInvestmentView, setUpdateUserInvestmentView] =
    useState(false);

  const { data, refetch } = useQuery(ENTITY_SEARCH_LIST, {
    variables: {
      organizationId: Number(user.organizationId),
      userId: user.id,
      nameSearch: modalState.nameSearch,
    },
    skip: !modalState.open,
  });
  const options = useMemo(
    () =>
      data?.savedEntitySearchList.items.map(({ id, name }) => ({
        value: String(id),
        label: <div>{name}</div>,
      })) ?? [],
    [data]
  );
  const refetchDebounced = useDebouncedCallback(variables => {
    refetch?.(variables);
  }, 300);

  const filters: InvestmentFilter[] = useSelector(getInvestmentFilters);
  const dispatch = useDispatch();

  if (filters.length === 0) return null;

  return (
    <div className="main-gf__marks-list">
      {!!filters.length && (
        <Row>
          <Col span={20}>
            {filters.map((filter: InvestmentFilter, index) => (
              <InvestmentFilterTag key={index} filter={filter} />
            ))}
          </Col>
          <Col span={4}>
            <Tag
              className="ant-tag--reset"
              data-cy="clear-all-filters"
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(clearAllInvestmentFilters())}
            >
              {i18n.t("filters.reset_all")}
            </Tag>
            <Tag
              className="ant-tag--reset"
              data-cy="save-filters"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModalState({
                  ...modalState,
                  open: true,
                });
              }}
            >
              Save Filters...
            </Tag>
          </Col>
        </Row>
      )}
      <Modal
        data-cy="save-filter-modal"
        title="Save Filter"
        okText="Save"
        open={modalState.open}
        okButtonProps={{
          disabled: modalState.isUpdate
            ? !modalState.value || !modalState.name
            : !modalState.name,
        }}
        onOk={async () => {
          const input = {
            organizationId: Number(user.organizationId),
            userId: user.id,
            entityType: types.SavedEntitySearchEntityTypeEnum.Investment,
            name: modalState.name,
            data: JSON.stringify(filters),
          };
          {
            const { data: searchListData } = await client.query({
              query: ENTITY_SEARCH_LIST,
              variables: {
                organizationId: Number(user.organizationId),
                userId: user.id,
                name: modalState.name,
              },
            });
            if (
              searchListData.savedEntitySearchList.items.some(
                item => item.id !== modalState.optionPick?.id
              )
            ) {
              setModalState({
                ...modalState,
                saveError: "The name is already in use",
              });
              return;
            }
          }
          const { errors, data: addOrUpdateData } = modalState.isUpdate
            ? await client.mutate({
                mutation: UPDATE_ENTITY_SEARCH,
                variables: {
                  id: ensureDefined(modalState.optionPick).id,
                  input,
                },
              })
            : await client.mutate({
                mutation: ADD_ENTITY_SEARCH,
                variables: {
                  input,
                },
              });

          if (
            updateUserInvestmentView &&
            (modalState.optionPick?.id ??
              (!modalState.isUpdate &&
                (addOrUpdateData as AddSavedEntitySearchMutation)
                  ?.addSavedEntitySearch.id))
          ) {
            const { errors: userSettingErrors } = await client.mutate({
              mutation: MODIFY_USER_SETTINGS,
              variables: {
                input: {
                  userId: user.id,
                  data: {
                    defaultInvestmentView: {
                      id: modalState.isUpdate
                        ? ensureDefined(modalState.optionPick).id
                        : (addOrUpdateData as AddSavedEntitySearchMutation)
                            ?.addSavedEntitySearch.id,
                      type: types.SettingEntityType.SavedEntitySearch,
                    },
                  },
                },
              },
            });

            if (userSettingErrors?.length) {
              errors?.concat(userSettingErrors);
            }
          }

          if (errors?.length) {
            logException(errors);
            setModalState({
              ...modalState,
              saveError: "An error has occurred",
            });
          } else {
            setModalState(initModal());
          }
        }}
        onCancel={() => {
          setModalState(initModal());
        }}
      >
        <Space
          data-cy="save-filter-modal__body"
          direction="vertical"
          style={{ width: "100%" }}
        >
          <Row align="middle">
            <Col xs={4}>Save as:</Col>
            <Col xs={20}>
              <Radio.Group
                onChange={e => {
                  setModalState({
                    ...initModal(),
                    isUpdate: e.target.value,
                    open: true,
                  });
                }}
                data-cy="save-as-group"
                value={modalState.isUpdate}
              >
                <Radio value={false}>New</Radio>
                <Radio value>Update Existing</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={16}>Set as My Default View:</Col>
            <Col xs={8}>
              <Checkbox
                data-cy="save-filter-modal__setAsDefaultView"
                checked={!!updateUserInvestmentView}
                onChange={e => {
                  setUpdateUserInvestmentView(e.target.checked);
                }}
              />
            </Col>
          </Row>
          {modalState.isUpdate ? (
            <>
              <Row>
                <Col xs={4}>Select:</Col>
                <Col xs={20}>
                  <AutoComplete
                    className="auto-complete-with-clearable-input-search-child"
                    options={options}
                    value={modalState.value}
                    onChange={(value: string) => {
                      setModalState({
                        ...modalState,
                        value,
                      });
                    }}
                    onSelect={(value: string) => {
                      const optionPick = value
                        ? data?.savedEntitySearchList.items.find(
                            item => item.id === Number(value)
                          )
                        : undefined;
                      setModalState({
                        ...modalState,
                        optionPick,
                        value: optionPick?.name ?? "",
                        name: optionPick?.name ?? "",
                      });
                      setUpdateUserInvestmentView(
                        !!optionPick?.id &&
                          user.userSettings?.data?.defaultInvestmentView?.id ===
                            optionPick.id &&
                          user.userSettings?.data?.defaultInvestmentView
                            ?.type === types.SettingEntityType.SavedEntitySearch
                      );
                    }}
                    onSearch={(nameSearch: string) => {
                      refetchDebounced?.({
                        organizationId: Number(user.organizationId),
                        userId: user.id,
                        nameSearch,
                      });
                    }}
                  >
                    <Input.Search
                      data-cy="name-search"
                      placeholder="select existing; type to filter"
                      enterButton
                      allowClear
                    />
                  </AutoComplete>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Rename:</Col>
                <Col xs={20}>
                  <Input
                    data-cy="name"
                    className="globalSearchFilter__rename"
                    placeholder="name"
                    value={modalState.name}
                    onChange={e => {
                      setModalState({
                        ...modalState,
                        name: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Input
              data-cy="name"
              placeholder="name"
              value={modalState.name}
              onChange={e => {
                setModalState({
                  ...modalState,
                  name: e.target.value,
                });
              }}
            />
          )}
          {!modalState.saveError ? null : (
            <p style={{ color: "red" }}>{modalState.saveError}</p>
          )}
        </Space>
      </Modal>
    </div>
  );
}

function CompanyFilters() {
  const filters = [
    CompanyHeadquartersFilter,
    YearFoundedFilter,
    IndustryFilter,
    CompanyImpactFilter,
  ];

  return (
    <div data-cy="compony-filter-container">
      {filters.map((GlobalFilter, index) => (
        <GlobalFilter key={index} />
      ))}
    </div>
  );
}

function CompanyFilterTags() {
  const filters: CompanyFilter[] = useSelector(getCompanyFilters);
  const dispatch = useDispatch();
  if (filters.length === 0) return null;

  return (
    <div className="main-gf__marks-list">
      {!!filters.length && (
        <Row>
          <Col span={20}>
            {filters.map((filter: CompanyFilter, index) => (
              <CompanyFilterTag key={index} filter={filter} />
            ))}
          </Col>
          <Col span={4}>
            <Tag
              className="ant-tag--reset"
              data-cy="clear-all-filters"
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(clearAllCompanyFilters())}
            >
              {i18n.t("filters.reset_all")}
            </Tag>
          </Col>
        </Row>
      )}
    </div>
  );
}

interface FilterHandleProps {
  toggleExpanded: () => void;
  expanded: boolean;
  filterCount: number;
}

const FilterDrawerHandle = ({
  filterCount,
  toggleExpanded,
  expanded,
}: FilterHandleProps) => (
  <div className="main-content main-content--lg">
    <button
      onClick={toggleExpanded}
      className="main-gf__toggle-md-up main-gf__toggle-md-up--in"
      type="button"
    >
      <span className="d-inline-block va-middle">
        {i18n.t("search_discovery.controls.toggle")}
      </span>
      {filterCount > 0 && (
        <span className="main-gf__toggle-md-up-counter">{filterCount}</span>
      )}
      <i
        className={classnames("main-gf__toggle-md-up-icon icon", {
          "icon-angle-up": expanded,
          "icon-angle-down": !expanded,
        })}
      />
    </button>
  </div>
);

function GlobalSearchFilters() {
  const [expanded, setExpanded] = useState(true);
  const { pathname } = useLocation();

  const { length: companyFilterCount } = useSelector(getCompanyFilters);
  const { length: investmentFilterCount } = useSelector(getInvestmentFilters);

  return (
    <>
      {expanded && (
        <>
          {["/", "/investments"].includes(pathname) && (
            <>
              <InvestmentFilters />
              <InvestmentFilterTags />
            </>
          )}

          {["/company"].includes(pathname) && (
            <Authorized checks={["isRockCreek"]}>
              <CompanyFilters />
              <CompanyFilterTags />
            </Authorized>
          )}

          {["/notes", "/documents"].includes(pathname) && <FilterTags />}
        </>
      )}
      {["/", "/investments"].includes(pathname) && (
        <FilterDrawerHandle
          filterCount={investmentFilterCount}
          toggleExpanded={() => setExpanded(!expanded)}
          expanded={expanded}
        />
      )}

      {["/company"].includes(pathname) && (
        <FilterDrawerHandle
          filterCount={companyFilterCount}
          toggleExpanded={() => setExpanded(!expanded)}
          expanded={expanded}
        />
      )}

      {["/notes", "/documents"].includes(pathname) && (
        <FilterDrawerHandle
          filterCount={companyFilterCount + investmentFilterCount}
          toggleExpanded={() => setExpanded(!expanded)}
          expanded={expanded}
        />
      )}
    </>
  );
}

export default GlobalSearchFilters;
