import { Tooltip } from "antd";
import i18n from "i18next";
import { truncate } from "lodash";
import { styled } from "@mui/material/styles";
import React, { useContext, useMemo } from "react";
import { InvestmentTileContext } from "../context";

const TileHeaderContent = styled('div')({
  display: 'flex',
  height: '3rem',
  justifyContent: 'space-between',
});

export function InvestmentTileHeading() {
  const {
    investment: { name, id, isInActive },
    isHovered,
    isSelected,
    toggleInvestment,
    InvestmentControlsAreAvailable,
  } = useContext(InvestmentTileContext);

  const truncatedFundName = useMemo(
    () =>
      truncate(name ?? "", {
        length: 55,
        separator: " ",
      }),
    [name]
  );

  return (
    <div className="tile-header">
      <TileHeaderContent>
        <h4 className="tile-heading fund-name flex-grow">
          {name.length > 50 && (
            <Tooltip placement="bottom" title={name} trigger="hover">
              {truncatedFundName}
            </Tooltip>
          )}

          {name.length <= 50 && `${truncatedFundName}`}
        </h4>
        <div className="ml-auto">
          {isInActive && !isHovered && (
            <div className="fund-tile__header-inactive-flag">
              {i18n.t("fund_tile.inactive")}
            </div>
          )}
          {InvestmentControlsAreAvailable && (
            <div className="fund-select" onClick={toggleInvestment}>
              <input
                id={`fund-tile-select-${id}`}
                type="checkbox"
                className="fund-tile-select"
                name="fund-tile-select"
                checked={isSelected(id)}
                readOnly
              />
              <label htmlFor={`fund-tile-select-${id}`} />
            </div>
          )}
        </div>
      </TileHeaderContent>
    </div>
  );
}
