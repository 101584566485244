import { ActionType } from "../actions/action_types";

export interface State {
  organizationName: string | null;
  isRockCreek: boolean;
  isAdvisorOrganization: boolean;
  organizationMembersIds?: string[];
}

export interface Action {
  type: ActionType.UPDATE_ORGANIZATION;
  organization: State;
}

const defaultState = Object.freeze({
  organizationName: null,
  isRockCreek: false,
  isAdvisorOrganization: false,
  organizationMembersIds: [],
});

const organizationReducer = (state: State, action: Action) => {
  if (!state) return defaultState;

  switch (action.type) {
    case ActionType.UPDATE_ORGANIZATION:
      return { ...state, ...action.organization };
  }

  return state;
};

export default organizationReducer;
