import { LazyQueryExecFunction, useQuery } from "@apollo/client";
import {
  DatePicker as AntdDatePicker,
  Col,
  Drawer,
  Dropdown,
  Input,
  Menu,
  Select,
} from "antd";
import { ensureDefined } from "frontend/src/utils/helpers";
import { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import React, { Fragment } from "react";
import {
  BulkDownloadQuery,
  DocumentSort,
  DocumentSortEnum,
  SortInput,
} from "src/graphql-types/graphql";
import { DebouncedState } from "use-debounce/dist/useDebouncedCallback";
import { DOCUMENT_EXTENSIONS } from "./graphql";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

export const BulkDownloadDrawer = ({
  bulkIds,
  bulkDownload,
  bulkLoading,
  setBulkIds,
}: {
  bulkIds: number[];
  bulkLoading: boolean;
  setBulkIds: React.Dispatch<React.SetStateAction<number[]>>;
  bulkDownload: LazyQueryExecFunction<
    BulkDownloadQuery,
    {
      ids: number | number[];
    }
  >;
}) => (
  <Drawer
    placement="bottom"
    open={bulkIds.length > 0}
    closable={false}
    mask={false}
    drawerStyle={{ paddingLeft: "60px" }}
    height={125}
  >
    <button
      onClick={() => {
        bulkDownload({ variables: { ids: bulkIds } });
      }}
      type="button"
      className="rounded-btn rounded-btn--blue mr-16"
      data-cy="bulk-download"
    >
      {bulkLoading ? (
        <i className="rounded-btn__spinner" style={{ color: "white" }} />
      ) : (
        `Download ${bulkIds.length} files`
      )}
    </button>
    <button
      onClick={() => setBulkIds([])}
      className="rounded-btn rounded-btn--grey mr-10"
      type="button"
    >
      Clear
    </button>
  </Drawer>
);

export const SearchWithinText = ({
  debouncedText,
}: {
  debouncedText: DebouncedState<(_text: string) => void>;
}) => (
  <Col xs={24}>
    <Input
      placeholder="Search within text"
      onInput={e => {
        debouncedText(e.currentTarget.value);
      }}
    />
  </Col>
);

export const SearchByName = ({
  debouncedName,
}: {
  debouncedName: DebouncedState<(_name: string) => void>;
}) => (
  <Col xs={24} md={8}>
    <Input
      placeholder="Filter by name"
      onInput={e => {
        debouncedName(e.currentTarget.value);
      }}
    />
  </Col>
);

export const SearchByExtensions = ({
  debouncedExtensions,
}: {
  debouncedExtensions: DebouncedState<(_extensions: string[]) => void>;
}) => {
  const { data } = useQuery<{ documentFileExtensions: string[] }>(
    DOCUMENT_EXTENSIONS
  );

  const options = data?.documentFileExtensions.map(x => ({
    label: x,
    value: x,
  }));

  return (
    <Col xs={24} md={8}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Filter by file type"
        onChange={(extensions: string[]) => {
          debouncedExtensions(extensions);
        }}
        options={options}
      />
    </Col>
  );
};

export const SearchByDate = ({
  setMinDate,
  setMaxDate,
}: {
  setMinDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setMaxDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}) => (
  <Col xs={24} md={8}>
    <DatePicker.RangePicker
      allowClear
      allowEmpty={[true, true]}
      onChange={(dateRange: [Moment | null, Moment | null] | null) => {
        const _minDate = dateRange?.[0]?.toDate();
        const _maxDate = dateRange?.[1]?.toDate();
        setMinDate(_minDate);
        setMaxDate(_maxDate);
      }}
      style={{ width: "100%" }}
    />
  </Col>
);

type SortDict = {
  [key in DocumentSortEnum]: {
    [SortInput.Asc]: string;
    [SortInput.Desc]: string;
  };
};

export const DocumentSortDropdown = ({
  sort,
  setSort,
  en,
}: {
  sort: DocumentSort;
  setSort: React.Dispatch<React.SetStateAction<DocumentSort>>;
  en: Partial<SortDict>;
}) => (
  <Col xs={12} md={5} style={{ textAlign: "right" }}>
    <Dropdown
      overlay={
        <Menu
          onClick={({ key }: { key: string }) => {
            const [field, order] = key.split(",", 2);
            setSort({
              field: field as DocumentSortEnum,
              order: order as SortInput,
            });
          }}
          selectedKeys={[`${sort.field},${sort.order}`]}
        >
          {Object.keys(en).map(field => (
            <Fragment key={`${field}__sort_options`}>
              <Menu.Item key={`${field},${SortInput.Desc}`}>
                {ensureDefined(en[field as keyof SortDict])[SortInput.Desc]}
              </Menu.Item>
              <Menu.Item key={`${field},${SortInput.Asc}`}>
                {ensureDefined(en[field as keyof SortDict])[SortInput.Asc]}
              </Menu.Item>
            </Fragment>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#">
        {sort &&
          (en as Record<DocumentSortEnum, Record<SortInput, string>>)[
            sort.field
          ][sort.order]}{" "}
        <i className="main-dropdown__arrow icon icon-arrow" />
      </a>
    </Dropdown>
  </Col>
);
