import { createSelector } from "reselect";
import {
  CompanyFilter,
  Filter,
  GlobalSearchState,
  InvestmentFilter,
  Tagged,
  unhandledCase,
} from "./globalSearchV2.types";

/**
 * Converts from adjacently tagged type that conforms to Typescript
 * to externally tagged union type (as in Graphql input).
 * @param graphqlUnion
 * @returns
 */
export function untagged<T>(taggedInput: Tagged<T>) {
  const keys = Object.keys(taggedInput);
  if (
    !(
      keys.length === 2 &&
      "type" in (taggedInput as any) &&
      "data" in (taggedInput as any)
    )
  ) {
    throw new Error(
      `Wrong shape for tagged input: ${JSON.stringify(taggedInput)}`
    );
  }
  const { type, data } = taggedInput;
  const untagged = { [type]: data };
  return untagged as {
    [K in keyof T]: NonNullable<T[K]>;
  };
}

export const getInvestmentFilters = createSelector(
  (state: { globalSearchV2: GlobalSearchState }) =>
    state.globalSearchV2.filters,
  filters =>
    (Object.values(filters) as Filter[])
      .map(filter => {
        switch (filter.type) {
          case "investment":
            return filter.data;
        }
      })
      .filter(Boolean) as InvestmentFilter[]
);

export const getCompanyFilters = createSelector(
  (state: { globalSearchV2: GlobalSearchState }) =>
    state.globalSearchV2.filters,
  filters =>
    (Object.values(filters) as Filter[])
      .map(filter => {
        switch (filter.type) {
          case "company":
            return filter.data;
        }
      })
      .filter(Boolean) as CompanyFilter[]
);

export const getInvestmentSort = createSelector(
  (state: { globalSearchV2: GlobalSearchState }) => state.globalSearchV2.sorts,
  sorts => sorts.investment
);

export const getCompanySort = createSelector(
  (state: { globalSearchV2: GlobalSearchState }) => state.globalSearchV2.sorts,
  sorts => sorts.company
);

export const getTagEntityFilter = (filterType: Filter["type"]) => {
  switch (filterType) {
    case "investment":
      return createSelector(
        getInvestmentFilters,
        (investmentFilters: InvestmentFilter[]) => {
          for (const filter of investmentFilters) {
            if (
              filter.type === "investment" &&
              filter.data.type === "TAG_ENTITY"
            )
              return filter.data.data;
          }
        }
      );
    case "company":
      return createSelector(
        getCompanyFilters,
        (companyFilters: CompanyFilter[]) => {
          for (const filter of companyFilters) {
            if (filter.type === "TAG_ENTITY") return filter.data;
          }
        }
      );
    default:
      throw unhandledCase(filterType);
  }
};
