import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Radio } from "antd";

interface TooltipContentProps {
  keyName: string;
  formatters?: { [key: string]: (value: any) => string };
  sort: { key: string | null; order: string };
  changeSort: ({ key, order }: { key: string; order: string }) => void;
  close: () => void;
  filterOptions: any[];
  addFilter: (keyName: string, values: any[]) => void;
  removeFilter: (keyName: string, value: any) => void;
  localFilter: { key: string; values: any[] };
  clearFilter: () => void;
}

const TooltipContent = ({
  keyName,
  formatters,
  sort,
  changeSort,
  close,
  filterOptions,
  addFilter,
  removeFilter,
  localFilter,
  clearFilter,
}: TooltipContentProps) => {
  const { key, order } = sort;
  return (
    <div className="summary-table-tooltip" data-testid="filter-tooltip">
      <div className="close" onClick={() => close()} data-testid="filter-close">
        <CloseOutlined />
      </div>
      <div className="section-label">Sort</div>
      <Radio.Group
        className="sort-control"
        value={keyName === key ? order : null}
      >
        <Radio.Button
          onClick={() => changeSort({ key: keyName, order: "DESC" })}
          value="DESC"
        >
          Descending
        </Radio.Button>
        <Radio.Button
          onClick={() => changeSort({ key: keyName, order: "ASC" })}
          value="ASC"
        >
          Ascending
        </Radio.Button>
      </Radio.Group>
      <div className="section-label">Filter</div>
      <div className="filter-container">
        <div className="filter-option">
          <Checkbox
            onClick={() => {
              if (localFilter) {
                clearFilter();
                return;
              }
              addFilter(keyName, []);
            }}
            checked={!localFilter}
          >
            Select All
          </Checkbox>
        </div>
        {filterOptions
          .sort(
            (a, b) =>
              ({
                numbernumber: (a as number) - (b as number),
                stringnumber: 1,
                numberstring: -1,
                stringstring: (a as string) > (b as string) ? 1 : -1,
              })[
                (typeof a + typeof b) as
                  | "numbernumber"
                  | "stringnumber"
                  | "numberstring"
                  | "stringstring"
              ] || (a as any) - (b as any)
          )
          .map(option => (
            <div className="filter-option">
              <Checkbox
                onChange={() => {
                  if (!localFilter) {
                    addFilter(
                      keyName,
                      filterOptions.filter(_option => option !== _option)
                    );
                    return;
                  }
                  if (localFilter?.values.includes(option)) {
                    removeFilter(keyName, option);
                    return;
                  }
                  addFilter(keyName, [option]);
                }}
                checked={!localFilter || localFilter?.values.includes(option)}
              >
                {(formatters?.[keyName]
                  ? formatters[keyName](option)
                  : option) || `(blank)`}
              </Checkbox>
            </div>
          ))}
      </div>
      <Button className="clear-button" onClick={() => clearFilter()}>
        Clear
      </Button>
    </div>
  );
};

export default TooltipContent;
