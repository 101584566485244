import { TeamMemberFragment } from "_graphql-types/graphql";
import About from "../about.js";
import Biography from "../biography.jsx";
import EducationInfo from "../education_info.jsx";
import { hasWords } from "../helpers.js";
import WorkExperience from "../work_experience.jsx";

function Profile({ person }: { person: TeamMemberFragment }) {
  const {
    biography,
    firmEmployment,
    education,
    workExperience,
    workExperienceSummary,
    educationSummary,
  } = person;

  const jobDescription = firmEmployment[0].jobDesc ?? "";

  const aggregateSummary = [
    jobDescription,
    biography,
    workExperienceSummary,
    educationSummary,
  ]
    .filter(summary => typeof summary === "string" && hasWords(summary))
    .join(" ");

  return (
    <div data-cy="team-modal" className="main-content">
      <div
        style={{ minHeight: "300px" }}
        className="team-member team-member--extended"
      >
        <div className="team-member__col team-member__col--left">
          <About person={person} />
        </div>
        <div className="team-member__col">
          <div className="hidden-md-down">
            <Biography biography={aggregateSummary} />
            <WorkExperience workExperience={workExperience} />

            <EducationInfo education={education} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
