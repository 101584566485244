import { getSearchResultDetails } from "Helpers/investment_helpers";

import PreviewFooter from "./previewFooter";
import { DefaultTileFooter } from "./defaultTileFooter";
import { PortfolioTileFooter } from "./portfolioTileFooter";
import { useContext } from "react";
import { InvestmentTileContext } from "../context";

export function InvestmentTileFooter() {
  const { details, investment } = useContext(InvestmentTileContext);

  if (!details.readAccess) return <PreviewFooter />;
  if (investment.isPortfolio) {
    return <PortfolioTileFooter />;
  }

  return <DefaultTileFooter />;
}
