/* eslint-disable max-len */
import { gql } from "_graphql-types/gql";

export const INVESTMENT_SEARCH = gql(/* GraphQL */ `
  query InvestmentSearch(
    $page: PageInput
    $sort: [InvestmentSort!]
    $searchFilters: [InvestmentSearchFilters!]!
    $firmSearchFilters: [FirmSearchFilters!]
  ) {
    investmentList(
      page: $page
      sort: $sort
      searchFilters: $searchFilters
      firmSearchFilters: $firmSearchFilters
    ) {
      items {
        ...InvestmentTile
      }
      total
      nextPage {
        limit
        offset
        hasMore
      }
    }
  }
`);

export const INVESTMENT_PRELOAD_IDS = gql(/* GraphQL */ `
  query InvestmentPreloadIds(
    $limit: Int!
    $sort: [InvestmentSort!]
    $searchFilters: [InvestmentSearchFilters!]!
    $firmSearchFilters: [FirmSearchFilters!]
  ) {
    investmentBulk(
      limit: $limit
      sort: $sort
      searchFilters: $searchFilters
      firmSearchFilters: $firmSearchFilters
    ) {
      id
      name
    }
  }
`);

export const COMPANY_SEARCH = gql(/* GraphQL */ `
  query CompanySearch(
    $page: PageInput
    $sort: [CompanySort!]
    $searchFilters: [CompanySearchFilters!]!
  ) {
    companyList(page: $page, sort: $sort, searchFilters: $searchFilters) {
      items {
        ...CompanyTile
      }
      total
      nextPage {
        limit
        offset
        hasMore
      }
    }
  }
`);

export const MODIFY_USER_SETTINGS = gql(/* GraphQL */ `
  mutation ModifyUserSettings($input: UserSettingsInput!) {
    addOrUpdateUserSettings(input: $input) {
      ...UserSettingsAttributes
    }
  }
`);
