/* eslint-disable react/button-has-type */
import { useMutation, useQuery } from "@apollo/client";
import { asFullDate } from "Helpers/index";
import { abbreviation } from "Helpers/text_formatting";
import * as types from "_graphql-types/graphql";
import { DatePicker, Select } from "antd";
import classNames from "classnames";
import { RCGReactQuillInput } from "frontend/src/components/RCGReactQuillInput";
import { formatDate } from "frontend/src/utils/date";
import { useCurrentUser } from "frontend/src/utils/hooks";
import i18n from "i18next";
import { truncate } from "lodash";
import dayjs from "dayjs";
import React, { useContext, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import { notesIndexContext } from "./NotesIndex.context";
import { DELETE_NOTE, NOTES_META, NOTES_SEARCH, UPDATE_NOTE } from "./graphql";
import { NotesIndexContextInterface } from "./types";

const { Option } = Select;

const accessLevels = [
  { id: 1, name: "Public" },
  { id: 2, name: "Organization" },
];

function AccessLevelMessage({ accessLevelId }: { accessLevelId: number }) {
  const text = useMemo(() => {
    switch (accessLevelId) {
      case 1:
        return i18n.t("fund_notes.public_message");
      default:
        return i18n.t("fund_notes.team_message");
    }
  }, [accessLevelId]);

  return <>{text}</>;
}

const TRUNCATE_LENGTH = 512;

type TNotes = types.FetchNotesListQuery["notesList"]["items"][number];

// eslint-disable-next-line max-lines-per-function
function Note({ note }: { note: TNotes }) {
  const { notesFilters, notesSort } = useContext(notesIndexContext);
  const user = useCurrentUser();
  const canDelete = note.createUser === user.email || user.userName;
  const [expanded, setExpanded] = useState(false);
  const { data } = useQuery(NOTES_META);
  const noteMetaEnums = useMemo(
    () =>
      data?.noteCategoryEnum?.noteTypes.flatMap(
        noteType => noteType.noteMetaEnums
      ),
    [data?.noteCategoryEnum]
  );

  // Edit Concerns
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState(
    note.editedText || note.text || undefined
  );
  const [noteMetaId, setNoteMetaId] = useState<number>(note.noteMetaId);
  const [accessLevelId, setAccessLevelId] = useState<number>(
    note.accessLevelId
  );
  const [noteDate, setNoteDate] = useState(note.noteDate);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [updateNote, { loading: updateLoading }] = useMutation(UPDATE_NOTE, {
    variables: {
      id: note.id,
      input: {
        editedText,
        investmentId: note.investmentId,
        firmId: note.firmId,
        companyId: note.companyId,
        noteMetaId,
        noteDate: formatDate(noteDate, "yyyy-MM-dd"),
        accessLevelId,
      },
    },
  });

  const [deleteNote, { loading: deleteLoading }] = useMutation(DELETE_NOTE, {
    variables: { id: note.id },
    refetchQueries: [
      {
        query: NOTES_SEARCH,
        variables: {
          filters: notesFilters,
          page: { offset: 0, limit: 15 },
          sort: [notesSort],
        },
      },
    ],
  });

  const truncated = useMemo(
    () => note.text && note.text.length > TRUNCATE_LENGTH,
    [note.text]
  );

  const [focus, changeFocus] = useState(expanded);

  return (
    <div id={`note-${note.id}`} className="fund-note">
      <div className="fund-note__wrap">
        <div className="fund-note__items">
          <div className="fund-note__items-header clearfix">
            <div className="float-right" />

            <div className="fund-note__items-header-left-col">
              <p className="note__alert">
                {i18n.t(`fund_notes.note_deleted_message`)}
              </p>

              <p className="fund-note__title">
                <AccessLevelMessage accessLevelId={note.accessLevelId} />
              </p>

              <i
                className={classNames("fund-note__type", "icon", "icon--20", {
                  "icon-team": note.accessLevelId !== 1,
                  "icon-earth": note.accessLevelId === 1,
                })}
              />
              <span className="fund-note__date">
                {asFullDate(note.noteDate || note.modifyDate)}
              </span>

              <i
                className={classNames("icon font-20", {
                  "icon-edit": !editMode,
                  "icon-eye": editMode,
                })}
                onClick={() => setEditMode(!editMode)}
                style={{ color: "#1890ff", cursor: "pointer", float: "right" }}
                data-cy={`note-edit-toggle-${note.id}`}
              />

              <div className="clearfix pt-4">
                <p className="fund-note__author-img main-userpic main-userpic--24">
                  {abbreviation(note.createUser)}
                </p>

                <span className="fund-note__author">{note.createUser}</span>

                <span className="fund-note__tag ant-tag">
                  {note.investment?.name ||
                    note.firm?.name ||
                    note.company?.name}
                </span>

                {!editMode && (
                  <span className="fund-note__tag ant-tag">
                    {note.noteMeta.noteType.noteType}
                  </span>
                )}
              </div>
            </div>
          </div>

          {!editMode && (
            <p className="fund-note__body">
              <ReactQuill
                theme="bubble"
                value={
                  expanded
                    ? note.editedText || note.text || undefined
                    : truncate(note.editedText || note.text || undefined, {
                        length: TRUNCATE_LENGTH,
                        separator: " ",
                      })
                }
                readOnly
              />
              {truncated && (
                <span>
                  <span
                    className={classNames({
                      "fund-note__show_more-link": !expanded,
                      "fund-note__show_less-link": expanded,
                    })}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded && i18n.t(`fund_notes.show_less`)}
                    {!expanded && i18n.t(`fund_notes.show_more`)}
                  </span>
                </span>
              )}
            </p>
          )}

          {editMode && (
            <p className="fund-note__body">
              <div
                style={{
                  border: "1px solid",
                  borderColor: focus ? "#0078D4" : "grey",
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "stretch",
                  backgroundColor: "white",
                }}
                data-cy={`note-body-${note.id}`}
                onFocus={() => {
                  changeFocus(true);
                }}
                onBlur={() => {
                  changeFocus(false);
                }}
              >
                <RCGReactQuillInput
                  toolbarId={note.id}
                  toolbarStyle={{
                    border: "none",
                    borderBottom: "1px solid grey",
                  }}
                  textValue={editedText || undefined}
                  onChange={setEditedText}
                  style={{
                    overflow: "auto",
                    lineHeight: "1.6em",
                    minHeight: "3.2em",
                    maxHeight: "32em",
                  }}
                />
              </div>
              <div className="d-flex  flex-wrap">
                <div className="fund-notes__category-field main-input">
                  <p className="main-input__label">
                    {i18n.t("fund_notes.category")}
                  </p>
                  <Select
                    value={
                      noteMetaEnums?.find(
                        ({ id }: { id: number }) => id === noteMetaId
                      )?.label || "unknown"
                    }
                    onChange={v => setNoteMetaId(Number(v))}
                  >
                    {noteMetaEnums?.map(meta => (
                      <Option key={meta.id} value={meta.id}>
                        {meta.label || "unknown"}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="fund-notes__type-field main-input">
                  <p className="main-input__label">Access</p>
                  <Select
                    value={
                      accessLevels.find(({ id }) => id === accessLevelId)?.name
                    }
                    onChange={v => setAccessLevelId(Number(v))}
                  >
                    {accessLevels.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="fund-notes__date-field main-input">
                  <p className="main-input__label">
                    {i18n.t("fund_notes.effective_date")}
                  </p>
                  <DatePicker
                    onChange={date => date && setNoteDate(date.toDate())}
                    allowClear={false}
                    value={dayjs(noteDate)}
                  />
                </div>
              </div>

              <div className="text-right">
                {!deleteConfirmation ? (
                  <>
                    {canDelete && (
                      <button
                        onClick={async () => {
                          setDeleteConfirmation(true);
                        }}
                        className="rounded-btn rounded-btn--red mr-16"
                        data-cy={`note-edit-submit-${note.id}`}
                      >
                        {!deleteLoading ? (
                          "Delete Note"
                        ) : (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                      </button>
                    )}
                    <button
                      onClick={async () => {
                        await updateNote();
                        setEditMode(false);
                      }}
                      disabled={!editedText || !noteMetaId}
                      className="rounded-btn rounded-btn--blue mr-16"
                      data-cy={`note-edit-submit-${note.id}`}
                    >
                      {!updateLoading ? (
                        "Update Note"
                      ) : (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    {!deleteLoading && (
                      <button
                        onClick={async () => {
                          setDeleteConfirmation(false);
                        }}
                        className="rounded-btn rounded-btn--outline-red mr-16"
                        data-cy={`note-edit-submit-${note.id}`}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      onClick={async () => {
                        await deleteNote();
                      }}
                      className="rounded-btn rounded-btn--red mr-16"
                      data-cy={`note-edit-submit-${note.id}`}
                    >
                      {!deleteLoading ? (
                        "Delete Note"
                      ) : (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                    </button>
                  </>
                )}
              </div>
            </p>
          )}

          <div className="fund-note__footer" />
        </div>
      </div>
    </div>
  );
}

export default function NotesList({
  notes,
}: {
  notes: NotesIndexContextInterface["notes"];
}) {
  return (
    <div className="fund-notes note-list">
      {(notes || []).map(note => (
        <Note note={note} key={note.id} />
      ))}
    </div>
  );
}
