import { useMutation, useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import * as types from "_graphql-types/graphql";
import { Spin } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";

import { INVESTMENT_PATHS } from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import { FavoriteInvestment } from "./FavoriteInvestment";
import { useDispatch } from "src/react-redux";

type Investment = NonNullable<
  types.Recent_InvestmentsQuery["investmentMany"][number]
>;

export const ADD_RECENT_INVESTMENT = gql(/* GraphQL */ `
  mutation addRecentlyViewedInvestment($investmentId: Int!) {
    addRecentlyViewedInvestment(input: { investmentId: $investmentId }) {
      investmentId
    }
  }
`);

export const QUERY_RECENT_INVESTMENTS = gql(/* GraphQL */ `
  query recentlyViewedInvestmentList {
    recentlyViewedInvestmentList(
      sort: [{ field: createDate, order: DESC }]
      page: { offset: 0, limit: 10 }
    ) {
      items {
        investmentId
      }
    }
  }
`);

export const FETCH_INVESTMENTS = gql(/* GraphQL */ `
  query RECENT_INVESTMENTS($ids: [Int!]!) {
    investmentMany(ids: $ids) {
      ...InvestmentTile
    }
  }
`);

export function RecentInvestments() {
  const params = useParams<{ investmentId?: string }>();

  const [expanded, setExpanded] = useState(true);

  const { data, loading, error, refetch } = useQuery(FETCH_INVESTMENTS, {
    variables: { ids: [] as number[] },
  });

  const { data: recentData, refetch: recentRefetch } = useQuery(
    QUERY_RECENT_INVESTMENTS
  );

  useEffect(() => {
    refetch({
      ids:
        recentData?.recentlyViewedInvestmentList.items.map(
          item => item.investmentId
        ) ?? [],
    });
  }, [recentData]);

  const [addRecentInvestment] = useMutation(ADD_RECENT_INVESTMENT, {
    onCompleted: () => {
      recentRefetch();
    },
  });

  useEffect(() => {
    const investmentId = params?.investmentId;
    if (investmentId) {
      addRecentInvestment({
        variables: { investmentId: Number(investmentId) },
      });
    }
  }, [params?.investmentId]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  if (!recentData?.recentlyViewedInvestmentList.items.length) return null;

  return (
    <div className="main-tree__item">
      <div className="main-tree__item-header">
        <span
          className={classNames("main-tree__item-header-toggle", "icon", {
            "icon-arrow": expanded,
            "icon-arrow-right": !expanded,
          })}
          data-testid="recent-investments-toggle"
          data-cy="investment-set-toggle"
          onClick={() => setExpanded(!expanded)}
        />
        <button
          className="main-tree__item-header-switcher"
          data-cy="recent-investments-header"
          type="button"
          onClick={() => {
            navigate(INVESTMENT_PATHS[0]);
            dispatch(
              updateFilter({
                type: "investment",
                data: {
                  type: "investment",
                  data: {
                    type: "INVESTMENT_ENTITY",
                    data: {
                      values:
                        data?.investmentMany
                          ?.filter(
                            (investment): investment is Investment =>
                              !!investment
                          )
                          .map(
                            ({ id, name }) =>
                              ({ id, label: name }) as types.EntityKey
                          ) ?? [],
                    },
                  },
                },
              })
            );
          }}
        >
          <p className="main-tree__item-header-switcher-text">
            My Recent Investments (
            {recentData.recentlyViewedInvestmentList.items.length})
          </p>
        </button>
      </div>
      {error && (
        <div className="main-tree__item">
          Something went wrong loading investments
        </div>
      )}
      {loading && !data && (
        <div className="text-center">
          <Spin />
        </div>
      )}
      {expanded && data && (
        <div className="main-tree__item-content bg-grey-10">
          {data.investmentMany
            .filter((investment): investment is Investment => !!investment)
            .map(investment => (
              <FavoriteInvestment investment={investment} key={investment.id} />
            ))}
        </div>
      )}
    </div>
  );
}
