import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types/gql";
import { Menu, MenuProps, Spin } from "antd";
import i18n from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getTagEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { FilterInterface } from "../FilterInterface";
import { useDispatch } from "src/react-redux";

type SelectEventHandler = NonNullable<MenuProps["onSelect"]>;

const INDUSTRY_TAG_CLASS_ID = 22;

const GetIndustryTags = gql(/* GraphQL */ `
  query GetIndustryTags($filter: TagFilterInput) {
    tagList(filter: $filter, sort: [{ field: tagClassName, order: ASC }]) {
      items {
        id
        name
      }
    }
  }
`);

function IndustryTags({
  tagList,
  updateTags,
  selectedTags,
}: {
  tagList: { items: { id: number; name: string }[] };
  updateTags: SelectEventHandler;
  selectedTags: string[];
}) {
  return (
    <Menu
      onSelect={updateTags}
      onDeselect={updateTags}
      mode="inline"
      multiple
      selectedKeys={selectedTags}
    >
      {tagList.items.map(tag => (
        <Menu.Item key={tag.id}>{tag.name}</Menu.Item>
      ))}
    </Menu>
  );
}

export function IndustryFilter() {
  const globalTagsFilter = useSelector(getTagEntityFilter("company"));

  const { loading, data, error } = useQuery(GetIndustryTags, {
    variables: { filter: { tagClassIds: [INDUSTRY_TAG_CLASS_ID] } },
  });

  const industryTagMap = useMemo(
    () => new Map(data?.tagList.items.map(p => [String(p.id), p]) ?? []),
    [data]
  );

  const globalSelectedTags = useMemo(
    () =>
      globalTagsFilter?.values
        .map(({ id }) => String(id))
        .filter(id => industryTagMap.has(id)) ?? [],
    [industryTagMap, globalTagsFilter]
  );

  const [selectedTags, setSelectedTags] = useState(globalSelectedTags);

  useEffect(() => {
    setSelectedTags(globalSelectedTags);
  }, [globalSelectedTags]);

  const dispatch = useDispatch();

  const toEntityKey = (tagId: string) => {
    const tag = industryTagMap.get(tagId);
    if (!tag) throw Error(`missing tag: ${tagId}`);
    return { id: tag.id, label: tag.name };
  };

  const setGlobal = () => {
    if (!data) return;

    const tags = new Set(selectedTags);
    globalTagsFilter?.values.forEach(tag => {
      const id = String(tag.id);
      if (!industryTagMap.has(id)) tags.add(id);
    });
    const values = [...tags].map(toEntityKey);

    dispatch(
      updateFilter({
        type: "company",
        data: {
          type: "TAG_ENTITY",
          data: {
            values,
          },
        },
      })
    );
  };

  const resetGlobal = () => {
    if (!data) return;

    const tags =
      globalTagsFilter?.values
        .map(({ id }) => String(id))
        .filter(id => !industryTagMap.has(id)) ?? [];
    const values = tags.map(toEntityKey);

    const action = values.length ? updateFilter : removeFilter;
    dispatch(
      action({
        type: "company",
        data: {
          type: "TAG_ENTITY",
          data: {
            values,
          },
        },
      })
    );
  };

  return (
    <FilterInterface
      label={i18n.t("search_discovery.filters.labels.industry")}
      count={0}
      reset={resetGlobal}
      set={setGlobal}
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        {loading && <Spin size="small" />}
        {error && <span>Impact Tags failed to load.</span>}
        {data && (
          <IndustryTags
            selectedTags={selectedTags}
            tagList={data.tagList}
            updateTags={({ selectedKeys }) => setSelectedTags(selectedKeys)}
          />
        )}
      </div>
    </FilterInterface>
  );
}
