/* eslint-disable camelcase */
import {
  Get_Regional_ExposureQuery,
  Get_Assetclass_ExposureQuery,
  Get_Sector_ExposureQuery,
} from "_graphql-types/graphql";
import { ensureDefined } from "frontend/src/utils/helpers";
import { ExposureTypes } from "./index";

export function isRegionalExposure(
  _data:
    | Get_Regional_ExposureQuery
    | Get_Assetclass_ExposureQuery
    | Get_Sector_ExposureQuery,
  exposureType: ExposureTypes
): _data is Get_Regional_ExposureQuery {
  return (
    exposureType === ExposureTypes.regional &&
    "regional" in ensureDefined(_data.investment.exposure)
  );
}

export function isSectorExposure(
  _data:
    | Get_Regional_ExposureQuery
    | Get_Assetclass_ExposureQuery
    | Get_Sector_ExposureQuery,
  exposureType: ExposureTypes
): _data is Get_Sector_ExposureQuery {
  return (
    exposureType === ExposureTypes.sector &&
    "sector" in ensureDefined(_data.investment.exposure)
  );
}

export function isAssetClassExposure(
  _data:
    | Get_Regional_ExposureQuery
    | Get_Assetclass_ExposureQuery
    | Get_Sector_ExposureQuery,
  exposureType: ExposureTypes
): _data is Get_Assetclass_ExposureQuery {
  return (
    exposureType === ExposureTypes.assetClass &&
    "assetClass" in ensureDefined(_data.investment.exposure)
  );
}
