/* eslint-disable max-lines-per-function */
import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import AddBenchmark from "Components/AddBenchmark";
import { BenchmarkData } from "_graphql-types/graphql";
import classNames from "classnames";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import i18n, { use } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import CumulativeChart from "./cumulativeChart/CumulativeChart.component";
import GrowthChart from "./growthOf1000/GrowthChart.component";

import { GET_CUMULATIVE_RETURNS, GET_GROWTH_OF_1000 } from "./graphql";

type TimeSeriesData = { year: number; month: number; value?: number | null };

const formatChartData = ({
  name,
  records,
}: {
  name: string;
  records: TimeSeriesData[];
}) => {
  if (!records) {
    throw Error("data handling data");
  }
  return {
    name,
    data: records
      .filter(rec => rec?.value)
      .map(
        rec =>
          [new Date(rec.year, rec.month - 1).getTime(), rec?.value || 0] as [
            number,
            number
          ]
      ),
  };
};

const config = {
  growthOf1000: {
    query: GET_GROWTH_OF_1000,
    ChartComponent: GrowthChart,
    title: "Growth Of 1000",
  },
  cumulative: {
    query: GET_CUMULATIVE_RETURNS,
    ChartComponent: CumulativeChart,
    title: "Cumulative Returns",
  },
};

interface BenchmarkTimeSeriesChartProps {
  investmentId: number;
  isThumbnail?: boolean;
  type: keyof typeof config;
}

function BenchmarkTimeSeriesChart({
  type,
  investmentId,
  isThumbnail = false,
}: BenchmarkTimeSeriesChartProps): JSX.Element {
  const { query, ChartComponent, title } = config[type];

  const [benchmarkIds, changeBenchmarkIds] = useState<
    { id: number; name: string; visible: boolean; isAssociated?: boolean }[]
  >([]);
  const [priorTrackRecordForGrowth1000, changePriorTrackRecordForGrowth1000] =
    useState<boolean>(false);
  const [priorTrackRecordForCumulative, changePriorTrackRecordForCumulative] =
    useState<boolean>(false);

  const addBenchmark = (
    addIds: { id: number; name: string; isAssociated?: boolean }[]
  ) => {
    const newIds = addIds.filter(
      add => !benchmarkIds.find(({ id }) => add.id === id)
    );
    if (newIds.length === 0) return;
    changeBenchmarkIds(
      [
        ...benchmarkIds,
        ...newIds.map(({ id, name, isAssociated }) => ({
          id,
          name,
          visible: true,
          isAssociated: !!isAssociated,
        })),
      ].sort((a, b) => a.id - b.id)
    );
  };

  const visibleBenchmarks = () =>
    benchmarkIds.filter(({ visible }) => visible).map(({ id }) => id);

  const { data } = useQuery(query, {
    variables: {
      investmentId,
      priorTrackRecord:
        type === "growthOf1000"
          ? priorTrackRecordForGrowth1000
          : priorTrackRecordForCumulative,
      benchmarkIds: benchmarkIds
        .filter(({ isAssociated }) => !isAssociated)
        .map(({ id }) => id),
    },
    onCompleted: data_ => {
      if (data_.investment.performanceAnalysis?.timeSeriesData) {
        addBenchmark(
          data_.investment.performanceAnalysis.timeSeriesData.benchmarks
            .filter((b): b is BenchmarkData & { name: string } =>
              // name would only be undefined if the user doesn't have access to the benchmark
              isDefinedAndNotNull(b.name)
            )
            .map(({ id, name }) => ({
              id,
              name,
              isAssociated: true,
            }))
        );
      } else {
        Sentry.captureException(
          new Error(
            `No data returned from query:
              ${JSON.stringify({ data_, query }, null, 2)}
            `
          )
        );
      }
    },
  });

  const chartData = useMemo(() => {
    if (
      !data ||
      !data.investment.performanceAnalysis ||
      !data.investment.performanceAnalysis.timeSeriesData
    )
      return undefined;

    return [
      formatChartData({
        name: data.investment.name,
        records: data.investment.performanceAnalysis.timeSeriesData.root,
      }),
      ...data.investment.performanceAnalysis.timeSeriesData.benchmarks
        .filter(
          (_b): _b is BenchmarkData & { name: string } =>
            visibleBenchmarks().includes(_b.id) && isDefinedAndNotNull(_b.name)
        )
        .map(formatChartData),
    ];
  }, [data, benchmarkIds]);

  if (isThumbnail) {
    return <ChartComponent chartData={chartData} />;
  }

  return (
    <div className="invt-tab__section overview-page__card">
      <h3 className="heading" data-cy="growth-of-1000-heading">
        {title}
      </h3>
      {type === "growthOf1000" && (
        <div
          className={classNames("main-checkbox", classNames("mb-10", "mr-16"))}
        >
          <input
            id="control-checkbox-growth1000"
            type="checkbox"
            className={classNames("main-checkbox__input", {
              "main-checkbox__input--only-checkbox": false,
            })}
            onChange={() =>
              changePriorTrackRecordForGrowth1000(
                !priorTrackRecordForGrowth1000
              )
            }
            checked={priorTrackRecordForGrowth1000}
          />
          <label
            className="main-checkbox__label"
            htmlFor="control-checkbox-growth1000"
          >
            {i18n.t("analytics.performance.prior_track_record")}
          </label>
        </div>
      )}
      {type === "cumulative" && (
        <div
          className={classNames("main-checkbox", classNames("mb-10", "mr-16"))}
        >
          <input
            id="control-checkbox-cumulative"
            type="checkbox"
            className={classNames("main-checkbox__input", {
              "main-checkbox__input--only-checkbox": false,
            })}
            onChange={() =>
              changePriorTrackRecordForCumulative(
                !priorTrackRecordForCumulative
              )
            }
            checked={priorTrackRecordForCumulative}
          />
          <label
            className="main-checkbox__label"
            htmlFor="control-checkbox-cumulative"
          >
            {i18n.t("analytics.performance.prior_track_record")}
          </label>
        </div>
      )}
      <ChartComponent chartData={chartData} />
      <AddBenchmark addBenchmark={addBenchmark} investmentId={investmentId} />
    </div>
  );
}

BenchmarkTimeSeriesChart.defaultProps = {
  isThumbnail: false,
};

export default BenchmarkTimeSeriesChart;
