import { useNavigate } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import AddNoteLink from "Components/show/notes/AddNoteLink";
import AddNoteLinkMobile from "Components/show/notes/AddNoteLinkMobile";

const AddNoteButtonPresenter = ({ investmentId }) => {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/investments/${investmentId}/notes`);
  }

  return (
    <span>
      <AddNoteLink className="hidden-lg-down" onClick={handleClick} />
      <AddNoteLinkMobile onClick={handleClick} />
    </span>
  );
};

AddNoteButtonPresenter.propTypes = {
  investmentId: PropTypes.number.isRequired,
};

export default AddNoteButtonPresenter;
