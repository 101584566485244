import { ActionType } from "../actions/action_types";

export interface State {
  id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  organization_name?: string | null;
  email?: string | null;
  fullName?: string | null;
  organizationId?: number | null;
}

export interface Action {
  type: ActionType.UPDATE_USER;
  user: State;
}

const defaultState = Object.freeze({
  id: null,
  first_name: null,
  last_name: null,
  organization_name: null,
});

function userReducer(state: State, action: Action) {
  if (!state) return defaultState;
  switch (action.type) {
    case ActionType.UPDATE_USER:
      return { ...state, ...action.user };
    default:
      return state;
  }
}

export default userReducer;
