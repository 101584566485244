import { gql } from "_graphql-types/gql";

export const INVESTMENT_ATTRIBUTES = gql(/* GraphQL */ `
  query GetInvestmentClassifications($id: Int!) {
    investment(id: $id) {
      id
      isLiquidated
      isPortfolio
      liquidationDate
      liquidationNote
      strategy {
        id
        name
      }
      peStrategy {
        id
        name
      }
      inceptionDate
      assetClass {
        id
        name
      }
      market {
        id
        name
      }
      vehicleType {
        id
        name
      }
      isSubAdvised
      isImpact
      list {
        id
        name
      }
      latestInvestmentAUM {
        value
        date
        currency {
          id
          name
        }
      }
      latestStrategyAUM {
        value
        date
        currency {
          id
          name
        }
      }
      primaryBenchmarkId
      primaryBenchmark {
        id
        name
      }
      secondaryBenchmarkId
      secondaryBenchmark {
        id
        name
      }
      maxGrossExposure
      minGrossExposure
      maxNetExposure
      minNetExposure
      maxExpectedVolatility
      minExpectedVolatility
      primaryGeographicFocus {
        id
        name
      }
      tagAttributes {
        investmentId
        marketFocus {
          id
          name
        }
        regionalFocus {
          id
          name
        }
        countryFocus {
          id
          name
        }
        styleBias {
          id
          name
        }
        marketCap {
          id
          name
        }
        sectorFocus {
          id
          name
        }
        realEstateFocus {
          id
          name
        }
        exposureBias {
          id
          name
        }
        decisionMakingProcess {
          id
          name
        }
        durationFocus {
          id
          name
        }
        creditQualityFocus {
          id
          name
        }
        creditSectorFocus {
          id
          name
        }
        geographicFocus {
          id
          name
        }
      }
      investmentTags {
        id
        tagId
        tag {
          id
          name
        }
        tagClassId
        investmentId
      }
    }
  }
`);
