import { gql } from "_graphql-types/gql";

export const HF_GET_SHARE_CLASSES = gql(/* GraphQL */ `
  query HfGetShareClasses($investmentId: Int!) {
    shareClassList(
      sort: [{ field: name, order: ASC }]
      filter: { investmentId: $investmentId }
    ) {
      items {
        id
        name
        status
        isRcgNegotiated
        investmentFees {
          mgmtFee
          incentiveFee
          highWaterMark
          annualReset
          hurdle
          hurdleBenchmark
        }
        liquidity {
          subscriptionValue
          subscriptionUnit {
            name
          }
          liquidityValue
          liquidityUnit {
            name
          }
          noticeValue
          lockupValue
          lockupEndValue {
            id
            name
          }
          secondLockupEndValue {
            id
            name
          }
          hasGate
          hasLockup
          gatePercentCapital
          gatePercentNav
          cashAvailableDays
          workout {
            name
          }
          softLockups {
            id
            cashAvailableDays
            lockupEndRedemptionOn {
              id
              name
            }
            holdBackPercent
            noticeValue
            liquidityValue
            liquidityUnit {
              name
            }
            noticeBizDays
            redemptionFee
            redemptionOnBizDays
            secondLockupEndRedemptionOn {
              id
              name
            }
            termBeginMonth
            termEndMonth
            hasRedemptionFee
          }
        }
      }
    }
  }
`);

export const PRIVATE_GET_SHARE_CLASSES = gql(/* GraphQL */ `
  query PrivateGetShareClasses($investmentId: Int!) {
    investment(id: $investmentId) {
      id
      name
      peLegalLifeYrs
      extensionPeriodYrs
      investmentPeriodYrs
      harvestPeriodYrs
    }
    shareClassList(
      sort: [{ field: name, order: ASC }]
      filter: { investmentId: $investmentId }
    ) {
      items {
        id
        name
        isRcgNegotiated
        status
        restricted
        investmentFees {
          mgmtFee
          peMgmtFeeCalc {
            id
            name
          }
          mgmtFeeFreq {
            id
            name
          }
          incentiveFee
          peIncentiveFeeCalc {
            id
            name
          }
          incentiveFeeFreq {
            id
            name
          }
          peMgmtFeeDuringCommitment
          peMgmtFeeDuringCommitmentCalc {
            id
            name
          }
          peMgmtFeeDuringHarvest
          peMgmtFeeDuringHarvestCalc {
            id
            name
          }
          hasMgmtFeeStepDown
          peMgmtFeeStepDownPercent
          peMgmtFeeStepDownCalc {
            id
            name
          }
        }
        peLiquidity {
          preferredReturn
          catchupPercent
          isCompounded
          compoundingPeriod {
            id
            name
          }
          optionOfExtension {
            id
            name
          }
        }
      }
    }
  }
`);
