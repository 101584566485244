import { gql } from "_graphql-types/gql";

export const FETCH_INVESTMENT_PRIVATE_POSITIONS = gql(/* GraphQL */ `
  query InvestmentPrivatePositions(
    $id: Int!
    $positionFilter: PositionFilterInput
    $page: PageInput
    $sort: [PositionSort!]
  ) {
    investment(id: $id) {
      id
      name
      positions(filter: $positionFilter, page: $page, sort: $sort) {
        items {
          id
          investmentId
          name
          weight
          ticker
          totalValue
          initialInvestmentDate
          reportDate
          sourceDate
          investedAmount
          unrealizedAmount
          realizedAmount
          MOIC
        }
        total
      }
    }
  }
`);

export const POSITION_REPORT_DATES = gql(/* GraphQL */ `
  query getPositionReportDates($investmentId: Int!) {
    positionReportDates(investmentId: $investmentId)
  }
`);
