import { gql } from "_graphql-types/gql";

export const LINK_LIST = gql(/* GraphQL */ `
  fragment LinkAttributes on Links {
    id
    id
    name
    url
    iconEnum {
      name
    }
    iconEnumId
    orgLinks {
      id
      organizationId
      linkId
    }
  }
`);

export const FETCH_LINKS = gql(/* GraphQL */ `
  query getOrgLinks(
    $page: PageInput
    $sort: [LinksSort!]
    $filter: LinksFilter!
  ) {
    linksList(page: $page, sort: $sort, filter: $filter) {
      items {
        ...LinkAttributes
      }
    }
  }
`);
