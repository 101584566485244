import { ApolloClient } from "@apollo/client";
import { DOCUMENT_MANY } from "Components/show/documents/graphql";
import { extname } from "path";
import { FieldOwnerType } from "src/graphql-types/graphql";

const AMZ_DATE = /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/;
const AMZ_EXPIRES = /\d+/;

export class RefreshingSignedUrl {
  constructor(
    public signedUrl: string | null | undefined,
    /** Fetches the new signed URL if is is expred or close to expiring.
     * Remember to disable apollo client caching for this request.
     */
    public readonly refresh: () => Promise<string | null | undefined>
  ) {}
  get current(): string | null | undefined {
    return this.signedUrl;
  }

  async getActive(force = false): Promise<string | null | undefined> {
    if (!force) {
      // assume that if we didn't have a signed URL before, we won't get one now
      if (!this.signedUrl) return;
      const url = new URL(this.signedUrl);
      const date = url.searchParams.get("X-Amz-Date"); // e.g. 20240304T201610Z
      const expires = url.searchParams.get("X-Amz-Expires"); // e.g. 600
      if (date && expires) {
        const dateMatch = AMZ_DATE.exec(date);
        const expiresMatch = AMZ_EXPIRES.exec(expires);
        if (dateMatch && expiresMatch) {
          const [, year, month, day, hour, minute, second] = dateMatch;
          const expiresMillis = Date.UTC(
            +year,
            +month - 1,
            +day,
            +hour,
            +minute,
            +second + +expires
          );
          if (expiresMillis < Date.now() - 30_000) {
            return this.signedUrl;
          }
        }
      }
    }
    this.signedUrl = await this.refresh();
    return this.signedUrl;
  }
}

export class RefreshingDocumentSignedUrl extends RefreshingSignedUrl {
  constructor(
    documentId: number,
    signedUrl: string | null | undefined,
    apolloClient: ApolloClient<unknown>
  ) {
    super(signedUrl, async () => {
      const { data } = await apolloClient.query({
        query: DOCUMENT_MANY,
        variables: { documentIds: [documentId] },
        fetchPolicy: "no-cache",
      });
      const document = data.documentMany[0];
      this._name = document?.name;
      this._contentType = this._name && extname(this._name);
      return document?.signedUrl;
    });
  }

  private _name?: string | null;
  get name() {
    return this._name;
  }

  private _contentType?: string | null;
  get contentType() {
    return this._contentType;
  }
}

export function pluralizeOwnerType(
  ownerType: "investment" | "firm" | "company" | "deal"
) {
  if (ownerType === "company" || ownerType[ownerType.length - 1] === "s")
    return ownerType;
  return ownerType + "s";
}

export function singularizeOwnerType(ownerType: string) {
  if (ownerType === "company" || ownerType[ownerType.length - 1] !== "s")
    return ownerType;
  return ownerType.slice(0, -1);
}
