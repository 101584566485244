import { gql } from "_graphql-types/gql";

export const PRIVATE_INVESTMENT_ATTRIBUTES = gql(/* GraphQL */ `
  query GetPrivateInvestment($id: Int!) {
    investment(id: $id) {
      id
      vintageYear
      peFundNumberEnumId
      peFundNumber {
        id
        name
      }
      maxTargetNetIRR
      minTargetNetIRR
      maxTargetNetMOIC
      minTargetNetMOIC
      targetRaise
      hardCap
      strategy {
        id
        name
      }
      family {
        id
        name
      }
      inceptionDate
      isSubAdvised
      isImpact
      list {
        id
        name
      }
      latestInvestmentAUM {
        value
        date
      }
      latestStrategyAUM {
        value
        date
      }
      primaryBenchmark {
        id
        name
      }
      secondaryBenchmark {
        id
        name
      }
      performancePrivate(
        page: { limit: 1 }
        sort: [{ field: asOfDate, order: DESC }]
      ) {
        investmentId
        returnYear
        returnQuarter
        netIRR
        dpi
        tvpi
      }
      maxGrossExposure
      minGrossExposure
      maxNetExposure
      minNetExposure
      maxExpectedVolatility
      minExpectedVolatility
      committedCapital
      committedCapitalCurrency {
        id
        name
      }
      gpCommitted
      gpCommittedCurrency {
        id
        name
      }
      primaryGeographicFocus {
        id
        name
      }
    }
  }
`);
