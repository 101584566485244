const Forbidden = props => {
  const additionalErrors =
    props.location &&
    props.location.query.errors &&
    _.flatten(Array(props.location.query.errors)).map(e => `${e}\n`);

  return (
    <div className="main-content">
      <div className="sub-header">
        <p>{I18n.t("errors.403.forbidden")}</p>
      </div>
      <div className="overview-page__card mt-20">
        <div className="section-card__content">
          <p className="font-16">{I18n.t("errors.403.details")}</p>
          {additionalErrors && (
            <p className="font-14 text-center">{additionalErrors}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
