/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { useApolloClient } from "@apollo/client";
import { updateSelectedTabName as dispatchUpdateSelectedTabName } from "_sirius/src/actions/investment_profile_actions/selected_tab_name_actions";
import { INVESTMENT_SUMMARY_FRAGMENT } from "Components/graphql/fragments/Investment";
import { InvestmentNotesComponent } from "Components/InvestmentNotes";
import InvestmentTeam from "Components/InvestmentTeam";
import DocumentsPage from "Components/show/documents";
import InvestmentGallery from "Components/show/overview/investmentGallery";
import SummaryContainer from "Components/show/SummaryContainer";
import {
  FieldOwnerType,
  FieldSetType,
} from "frontend/src/graphql-types/graphql.js";
import { lazy, Suspense, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { anchorScrollOffsetTop as getAnchorScrollOffsetTop } from "Selectors/header_selectors";
import { editMode as investmentEditMode } from "Selectors/investment_edit_mode_selectors";
import { PrivateAnalytics } from "../analytics/index.js";
import { Spinner } from "../spinner.js.jsx";
import PrivateInvestmentOverview from "./overview/PrivateInvestmentOverview.js";

export default function PrivateInvestmentSummaryComponent({
  investmentId,
  code,
  portfolioDate,
  fundRatings,
  investmentName,
  ...rest
}: {
  investmentId: number;
  code: string;
  portfolioDate: string;
  fundRatings: any[];
  investmentName: string;
  isInActive: boolean;
  profileType: "PrivateInvestment" | "PublicInvestment";
}) {
  const client = useApolloClient();
  const Template = useMemo(
    () => lazy(() => import("frontend/src/components/Template")),
    []
  );

  const investment = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_SUMMARY_FRAGMENT,
  });
  if (!investment) return null;

  const { isImpact, firmId, portalSubmitted } = investment;

  const dispatch = useDispatch();
  const updateSelectedTabName = (selectedTabName: string) => {
    dispatch(dispatchUpdateSelectedTabName(selectedTabName));
  };

  const editMode = useSelector(state => investmentEditMode(state));
  const anchorScrollOffsetTop = useSelector(state =>
    getAnchorScrollOffsetTop(state)
  );

  function defID(tab: string) {
    return `${code}.${tab}`;
  }

  // this is not clojured child component need to know about each prop_type currently
  const _tabContents = () => {
    //const { investmentId, firmId, code, overviewEditMode } = this.props;
    const commonTabs = {
      overview: () => <PrivateInvestmentOverview investmentId={investmentId} />,

      investment: () => (
        <Suspense fallback={<Spinner />}>
          <Template
            editMode={editMode}
            ownerType={FieldOwnerType.Investment}
            ownerId={investmentId}
            name={FieldSetType.Investment}
            key={`${"investment::"}${investmentId}`}
          />
        </Suspense>
      ),

      operations: () => (
        <Suspense fallback={<Spinner />}>
          <Template
            editMode={editMode}
            ownerType={FieldOwnerType.Investment}
            ownerId={investmentId}
            name={FieldSetType.Operations}
            key={`${"operations::"}${investmentId}`}
          />
        </Suspense>
      ),

      impact: () => (
        <Suspense fallback={<Spinner />}>
          <Template
            editMode={editMode}
            ownerType={FieldOwnerType.Investment}
            ownerId={investmentId}
            name={FieldSetType.Impact}
            key={`${"impact::"}${investmentId}`}
          />
        </Suspense>
      ),

      hidden_investment: (() => {
        // leaving as it is useful for dynamic tab creation
        const tab = () => null;
        tab.hidden = true;
        return tab;
      })(),

      analytics: () => <PrivateAnalytics investmentId={investmentId} />,

      team: () => <InvestmentTeam investmentId={investmentId} />,
      gallery: () => <InvestmentGallery investmentId={investmentId} />,
      notes: () => <InvestmentNotesComponent investmentId={investmentId} />,

      documents: () => (
        <DocumentsPage
          investmentId={investmentId}
          firmId={firmId || undefined}
          showChat
          defaultTab="search"
        />
      ),
    };

    return commonTabs;
  };

  return (
    <SummaryContainer
      tabContents={_tabContents()}
      defaultSelectedTab="overview"
      isImpact={!!isImpact}
      odcSourced={!!portalSubmitted}
      investmentName={investmentName}
      updateSelectedTabName={updateSelectedTabName}
      investmentId={investmentId}
      {...rest}
    />
  );
}
