import { gql } from "_graphql-types/gql";

export const INVESTMENT_SUMMARY = gql(/* GraphQL */ `
  query GetInvestmentSummary($id: Int!) {
    investment(id: $id) {
      id
      summary
      isPortfolio
      impactTags {
        id
        name
        impactEnumCategoryId
        impactCategory {
          id
          name
        }
      }
    }
  }
`);
