import { ActionType } from "_sirius/src/actions/action_types";
import { combineReducers } from "redux";

const state = (
  state = "unfixed",
  action: {
    type: ActionType.UPDATE_NAVBAR_STATE;
    payload?: { state?: string };
  }
) => {
  switch (action.type) {
    case ActionType.UPDATE_NAVBAR_STATE:
      return action?.payload?.state ?? state;
    default:
      return state;
  }
};

const height = (
  state = "100px",
  action: {
    type: ActionType.STORE_NAVBAR_HEIGHT;
    payload?: { height?: string };
  }
) => {
  switch (action.type) {
    case ActionType.STORE_NAVBAR_HEIGHT:
      return action?.payload?.height ?? state;
    default:
      return state;
  }
};

const isMainHeaderHidden = (
  state = false,
  action:
    | {
        type: ActionType.MAIN_HEADER_HIDDEN_SET;
        payload?: { isHidden?: boolean };
      }
    | {
        type: ActionType.INVESTMENT_EDIT_MODE_SET;
        payload?: { editMode?: boolean };
      }
) => {
  switch (action.type) {
    case ActionType.MAIN_HEADER_HIDDEN_SET:
      return action?.payload?.isHidden ?? state;

    case ActionType.INVESTMENT_EDIT_MODE_SET:
      return action?.payload?.editMode ?? state;

    default:
      return state;
  }
};

export default combineReducers({
  state,
  height,
  isMainHeaderHidden,
});
