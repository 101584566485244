import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import i18n from "i18next";
import { get } from "lodash";
import * as types from "_graphql-types/graphql";
import InvestmentLink from "Components/InvestmentLink";
import LoadingPanel from "../../../../loading_panel";
import StatisticsTable from "../../../overview/statistics_table";
import { FETCH_FAMILY_PERFORMANCE } from "./graphql";

const headers = ["Investment", "Vintage", "Net IRR", "TVPI", "DPI"];
const defaultPrivatePerformance = { netIRR: null, tvpi: null, dpi: null };

const FnInvestmentLink = (id: number, name: string, isEntitled: boolean) => (
  <InvestmentLink investmentId={id} isEntitled={isEntitled}>
    {name}
  </InvestmentLink>
);

type Investment =
  | NonNullable<
      NonNullable<
        types.FamilyPerformanceQuery["investment"]["firm"]["investments"]
      >["items"][number]
    >
  | NonNullable<
      NonNullable<
        types.FamilyPerformanceQuery["investment"]["family"]
      >["investmentsFamily"][number]["investment"]
    >;

const getVintageYear = (investment: Investment) => {
  if (investment.vintageYear) return investment.vintageYear;
  if (investment.inceptionDate)
    return new Date(investment.inceptionDate).getFullYear();
  return "-";
};

function mapData(records: Investment[]) {
  return records.map(investment => {
    const { netIRR, tvpi, dpi } = get(
      investment,
      ["performancePrivate", "0"],
      defaultPrivatePerformance
    );
    return {
      Investment: FnInvestmentLink(
        investment.id,
        investment.name,
        !!investment.isEntitled
      ),
      Vintage: getVintageYear(investment),
      "Net IRR": netIRR ? `${netIRR.toFixed(1)}%` : undefined,
      TVPI: tvpi ? `${tvpi.toFixed(2)}x` : undefined,
      DPI: dpi ? `${dpi.toFixed(2)}x` : undefined,
    };
  });
}

const FamilyPerformance = ({ investmentId }: { investmentId: number }) => {
  const { data, loading } = useQuery(FETCH_FAMILY_PERFORMANCE, {
    variables: {
      id: investmentId,
    },
  });

  const familyInvestments = useMemo(
    () =>
      data?.investment.family?.investmentsFamily?.flatMap(
        x => x.investment ?? []
      ) ?? [],
    [data, loading]
  );

  const firmInvestments = useMemo(
    () => data?.investment.firm.investments?.items ?? [],
    [data, loading]
  );

  let dataMapping: any[] = [];
  if (familyInvestments.length) {
    dataMapping = mapData(familyInvestments);
  } else {
    dataMapping = mapData(firmInvestments);
  }

  return (
    <LoadingPanel loading={loading}>
      <div className="summary-heading" id="performance">
        <div className="heading" data-cy="family-performance-heading">
          {i18n.t("overview.family_performance")}
        </div>
      </div>
      <div
        className="performance-statistics-table"
        data-cy="family-performance"
      >
        <StatisticsTable
          headers={headers}
          dataMapping={dataMapping}
          keyOrder={headers}
          fileName={`${data?.investment?.name} Family Performance`}
          sortableKeys={headers.slice(1)}
        />
      </div>
    </LoadingPanel>
  );
};

export default FamilyPerformance;
