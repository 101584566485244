import * as types from "_graphql-types/graphql";
import { Tag } from "antd";
import { removeFromEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.actions";
import { InvestmentEntityFilterType } from "Reducers/globalSearchV2/globalSearchV2.types";
import { useDispatch } from "src/react-redux";

export interface Props {
  type: InvestmentEntityFilterType;
  filter: types.EntitySearchFilter;
}

export function EntityFilterTag({ type, filter }: Props) {
  const dispatch = useDispatch();
  return (
    <>
      {filter.values.map(({ id, label }) => {
        if (!id || !label) return null;
        return (
          <Tag
            className="ant-tag--blue ant-tag--close"
            data-cy="entity-filter-tag"
            data-type={type}
            key={JSON.stringify([type, id])}
            onClose={() => {
              dispatch(
                removeFromEntityFilter({
                  type: "investment",
                  data: {
                    type,
                    data: {
                      id,
                      label,
                    },
                  },
                })
              );
            }}
            closable
          >
            {label}
          </Tag>
        );
      })}
    </>
  );
}
