/* eslint-disable max-lines-per-function */
import { InvestmentTile } from "Components/InvestmentTile";
import PerformancePrivateGraph from "Components/Performance/PrivatePerformance/Graph";
import BenchmarktTimeSeries from "Components/show/analytics/components/BenchmarkTimeSeriesChart";
import Models from "Models/index.js";
import RcTooltip, { TooltipRef } from "rc-tooltip";
import React, { forwardRef, useRef } from "react";

type InvestmentPreviewTooltipProps = {
  fund: {
    id: number;
    name?: string | null;
    badgeColor?: string;
    market?: {
      __typename?: "AssetClassEnum" | undefined;
      id: number;
      name: string;
    } | null;
    hasIRRPerformance?: boolean | null;
    hasMTDPerformance?: boolean | null;
  };
  alternatives: { id: string; name: string }[];
  children: React.ReactElement;
};

const InvestmentPreviewTooltip = forwardRef(
  (
    { alternatives = [], fund, children }: InvestmentPreviewTooltipProps,
    ref: React.Ref<TooltipRef>
  ) => {
    const tooltipArrow: React.Ref<unknown> = useRef(null);

    function toAlternative(alternative: { id: string; name: string }) {
      return <li key={alternative.id}>{alternative.name}</li>;
    }

    function overlay() {
      const { badgeColor } = fund || new Models.Fund();

      console.log("fund", fund);

      if (fund && fund.name) {
        return (
          <div className={`fund-tooltip fund-tooltip--${badgeColor}`}>
            <div className="fund-tooltip__col fund-tooltip__col--left">
              <InvestmentTile id={fund.id} />
            </div>

            <div className="fund-tooltip__col fund-tooltip__col--right">
              {(fund.hasIRRPerformance || fund.hasMTDPerformance) && (
                <div className={`fund-tooltip__chart ${fund.badgeColor}`}>
                  {fund.market?.id === 1 &&
                    (fund.hasMTDPerformance ? (
                      <BenchmarktTimeSeries
                        investmentId={fund.id}
                        isThumbnail
                        type="growthOf1000"
                      />
                    ) : (
                      <PerformancePrivateGraph id={fund.id} />
                    ))}
                  {fund.market?.id === 2 &&
                    (fund.hasIRRPerformance ? (
                      <PerformancePrivateGraph id={fund.id} />
                    ) : (
                      <BenchmarktTimeSeries
                        investmentId={fund.id}
                        isThumbnail
                        type="growthOf1000"
                      />
                    ))}
                </div>
              )}

              <p className="fund-tooltip__offer-title">You May Also Like</p>
              <ul className="fund-tooltip__offer-list">
                {alternatives.map(toAlternative)}
              </ul>
            </div>
          </div>
        );
      }
      return (
        <div className="fund-tooltip fund-tooltip--loading">
          <div className="main-spinner">
            <i className="main-spinner__icon" />
          </div>
        </div>
      );
    }

    return (
      <RcTooltip
        ref={ref}
        trigger={["hover"]}
        prefixCls="rc-tooltip"
        placement="leftTop"
        mouseLeaveDelay={0.1}
        mouseEnterDelay={0.25}
        overlay={overlay}
        overlayClassName="fund-tooltip-wrap"
        destroyTooltipOnHide={false}
        arrowContent={
          <div
            ref={tooltipArrow as React.Ref<HTMLDivElement>}
            className="tile-tooltip-arrow-inner"
          />
        }
      >
        {children}
      </RcTooltip>
    );
  }
);

export default InvestmentPreviewTooltip;
