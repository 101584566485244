import React from "react";
import i18n from "i18next";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { INVESTMENT_ATTRIBUTES } from "./graphql";

function Status({ investmentId }: { investmentId: number }) {
  const { data } = useQuery(INVESTMENT_ATTRIBUTES, {
    variables: { id: investmentId },
  });

  return (
    <div className="invt-tab__section" id="status" data-cy="status">
      <h3 className="invt-tab__title">{i18n.t("investment.status.title")}</h3>

      <div className="overview-page__card">
        <FieldInlinePreview
          content={get(data, "investment.list.name", "")}
          displayName="Fund List"
        />
      </div>
    </div>
  );
}

export default Status;
