import { ActionType } from "./action_types";

export const updateNavBarState = (state: string) => ({
  type: ActionType.UPDATE_NAVBAR_STATE as const,
  payload: { state },
});

export const storeNavBarHeight = (height: string) => ({
  type: ActionType.STORE_NAVBAR_HEIGHT as const,
  payload: { height },
});

export const setMainHeaderHiddenState = (isHidden: boolean) => ({
  type: ActionType.MAIN_HEADER_HIDDEN_SET as const,
  payload: { isHidden },
});
