import { connect } from "react-redux";
import { fullName, State } from "Selectors/user_selectors";
import { isExpanded } from "Selectors/navigation_sidebar_selectors";
import { isRockCreek } from "Selectors/organization_selectors";
import type { useDispatch } from "src/react-redux";
import { setIsExpanded } from "_sirius/src/actions/navigation_sidebar_actions";
import NavigationSidebarComponent from "./component";

type Dispatch = ReturnType<typeof useDispatch>;

const mapStateToProps = (state: State) => ({
  userName: fullName(state),
  isRockCreek: isRockCreek(state),
  isExpanded: isExpanded(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsExpanded(isExpandedValue: boolean) {
    dispatch(setIsExpanded(isExpandedValue));
  },
});

export const NavigationSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSidebarComponent);
