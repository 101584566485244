/*
 * action types
 */

export enum ActionType {
  TOGGLE_EXPANDED_FAVORITES_SIDEBAR = "TOGGLE_EXPANDED_FAVORITES_SIDEBAR",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  SET_NAVIGATION_SIDEBAR_IS_EXPANDED = "SET_NAVIGATION_SIDEBAR_IS_EXPANDED",
  UPDATE_NAVBAR_STATE = "UPDATE_NAVBAR_STATE",
  STORE_NAVBAR_HEIGHT = "STORE_NAVBAR_HEIGHT",
  INVESTMENT_EDIT_MODE_SET = "INVESTMENT_EDIT_MODE_SET",
  MAIN_HEADER_HIDDEN_SET = "MAIN_HEADER_HIDDEN_SET",
  SELECTED_TAB_NAME_SET = "SELECTED_TAB_NAME_SET",
}
