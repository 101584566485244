import React, { useMemo, useState } from "react";
import { DocumentsPage as DocumentSearchView } from "./documentsPage";
import { CategoryView } from "./categoryView";
import { DocumentPageProps } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import i18next from "i18next";

const { TabPane } = Tabs;

const DOCUMENT_CATEGORY = {
  periodic: 1,
  diligence: 2,
};

const DOCUMENT_ACCESS_LEVEL = {
  public: 1,
  organization: 2,
  private: 3,
  client: 4,
};

export default function DocumentsView({
  showCategoryViews = true,
  investmentId,
  firmId,
  companyId,
  dealId,
  investmentFilters,
  firmFilters,
  companyFilters,
  showChat = false,
  defaultTab = "periodic",
  showOnlyClientDocs,
}: DocumentPageProps) {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const subtabRoute = (subtab: string) => {
    navigate(`${pathname}#${subtab}`);
  };
  const investmentOrFirmId = useMemo(() => {
    if (investmentId && firmId) {
      return { investmentId, firmId };
    }
    return undefined;
  }, [firmId, investmentId]);

  const companyOrDealId = useMemo(() => {
    if (companyId && dealId) {
      return { companyId, dealId };
    }
    return undefined;
  }, [dealId, companyId]);

  const clientDocFilters = useMemo(() => {
    if (showOnlyClientDocs) {
      return {
        accessLevel: DOCUMENT_ACCESS_LEVEL.client,
      };
    }
  }, [showOnlyClientDocs]);

  if (!showCategoryViews) {
    return (
      <div className="main-content main-content--lg">
        <DocumentSearchView
          {...{
            dealId,
            companyId,
            investmentId,
            firmId,
            firmFilters,
            investmentFilters,
            companyFilters,
            investmentOrFirmId,
            companyOrDealId,
            showChat,
            clientDocFilters,
          }}
        />
      </div>
    );
  }

  return (
    <div className="main-content main-content--lg">
      <Tabs
        activeKey={!hash ? defaultTab : hash.replace("#", "")}
        data-cy="document-tabs"
        onChange={subtabRoute}
      >
        <TabPane tab={i18next.t("documents.categories.search")} key="search">
          <DocumentSearchView
            {...{
              dealId,
              companyId,
              investmentId,
              firmId,
              firmFilters,
              investmentFilters,
              companyFilters,
              investmentOrFirmId,
              companyOrDealId,
              showChat,
              clientDocFilters,
            }}
          />
        </TabPane>
        <TabPane
          tab={i18next.t("documents.categories.diligence")}
          key="diligence"
        >
          <CategoryView
            {...{
              companyId,
              investmentId,
              firmId,
              investmentOrFirmId,
              companyOrDealId,
            }}
            categoryId={DOCUMENT_CATEGORY.diligence}
            showChat={showChat}
            clientDocFilters={clientDocFilters}
          />
        </TabPane>
        <TabPane
          tab={i18next.t("documents.categories.periodic")}
          key="periodic"
        >
          <CategoryView
            {...{
              companyId,
              investmentId,
              firmId,
              investmentOrFirmId,
              companyOrDealId,
            }}
            categoryId={DOCUMENT_CATEGORY.periodic}
            showChat={showChat}
            clientDocFilters={clientDocFilters}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
