import { useQuery } from "@apollo/client";
import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import { useDispatch } from "react-redux";
import { PRIVATE_GET_SHARE_CLASSES } from "./graphql/index.js";
import { PrivateInvestmentTermsList } from "./private_investment_terms_list.js";

export function PrivateContainer({ investmentId }: { investmentId: number }) {
  const { loading, error, data } = useQuery(PRIVATE_GET_SHARE_CLASSES, {
    variables: {
      investmentId,
    },
    skip: !investmentId,
  });

  const dispatch = useDispatch();
  if (error) displayErrorFlash();

  return (
    <PrivateInvestmentTermsList
      peLegalLifeYrs={data?.investment?.peLegalLifeYrs}
      extensionPeriodYrs={data?.investment?.extensionPeriodYrs}
      investmentPeriodYrs={data?.investment?.investmentPeriodYrs}
      harvestPeriodYrs={data?.investment?.harvestPeriodYrs}
      shareClasses={data?.shareClassList.items || []}
      loading={loading}
    />
  );
}
