import { gql } from "_graphql-types/gql";

export const GET_REGIONAL_EXPOSURE = gql(/* GraphQL */ `
  query GET_REGIONAL_EXPOSURE(
    $investmentId: Int!
    $categoryId: Int!
    $positionType: PositionType!
    $endDate: DateTime!
  ) {
    investment(id: $investmentId) {
      id
      name
      exposure(
        categoryId: $categoryId
        positionType: $positionType
        endDate: $endDate
      ) {
        regional {
          name
          year
          month
          weight
        }
      }
    }
  }
`);

export const GET_ASSETCLASS_EXPOSURE = gql(/* GraphQL */ `
  query GET_ASSETCLASS_EXPOSURE(
    $investmentId: Int!
    $categoryId: Int!
    $positionType: PositionType!
    $endDate: DateTime!
  ) {
    investment(id: $investmentId) {
      id
      name
      exposure(
        categoryId: $categoryId
        positionType: $positionType
        endDate: $endDate
      ) {
        assetClass {
          name
          year
          month
          weight
        }
      }
    }
  }
`);

export const GET_SECTOR_EXPOSURE = gql(/* GraphQL */ `
  query GET_SECTOR_EXPOSURE(
    $investmentId: Int!
    $categoryId: Int!
    $positionType: PositionType!
    $endDate: DateTime!
  ) {
    investment(id: $investmentId) {
      id
      name
      exposure(
        categoryId: $categoryId
        positionType: $positionType
        endDate: $endDate
      ) {
        sector {
          name
          year
          month
          weight
        }
      }
    }
  }
`);

export const PORTFOLIO_EXPOSURE_FRAGMENT = gql(/* GraphQL */ `
  fragment PortfolioExposureFragment on Exposure {
    portfolio {
      name
      year
      month
      weight
    }
  }
`)

export const PORTFOLIO_EXPOSURE = gql(/* GraphQL */ `
  query PORTFOLIO_EXPOSURE($investmentId: Int!) {
    investment(id: $investmentId) {
      id
      name
      long: exposure(categoryId: 1, positionType: LONG) {
        ...PortfolioExposureFragment
      }
      short: exposure(categoryId: 1, positionType: SHORT) {
        ...PortfolioExposureFragment
      }
      net: exposure(categoryId: 1, positionType: NET) {
        ...PortfolioExposureFragment
      }
      gross: exposure(categoryId: 1, positionType: GROSS) {
        ...PortfolioExposureFragment
      }
    }
  }
`);
