import React from "react";
import DocumentsPage from "Components/show/documents";
import { useGlobalCompanySearch } from "Components/GlobalSearchWorkspace/CompanySearch.hooks";
import { useGlobalInvestmentSearch } from "Components/GlobalSearchWorkspace/InvestmentSearch.hooks";
import { useCurrentUser } from "frontend/src/utils/hooks";

export const GlobalDocuments = () => {
  const {
    variables: {
      searchFilters: investmentFilters,
      firmSearchFilters: firmFilters,
    },
  } = useGlobalInvestmentSearch();
  const {
    variables: { searchFilters: companyFilters },
  } = useGlobalCompanySearch();

  const { clientView } = useCurrentUser();

  return (
    <>
      <DocumentsPage
        investmentFilters={
          Array.isArray(investmentFilters) ? investmentFilters : undefined
        }
        firmFilters={Array.isArray(firmFilters) ? firmFilters : undefined}
        companyFilters={companyFilters}
        showCategoryViews={false}
        showOnlyClientDocs={!!clientView}
      />
    </>
  );
};
