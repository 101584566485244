/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */

const isNumber = (n: number | null): n is number => typeof n === "number";

const calculateRelativeError = (actual: number) => {
  const up = Math.ceil(actual);
  const down = Math.floor(actual);
  const relUpError = Math.abs(up - actual) / actual;
  const relDownError = Math.abs(down - actual) / actual;

  return {
    up,
    down,
    relUpError,
    relDownError,
    leastRelativeError: relUpError > relDownError ? down : up,
  };
};

type ErrorRank = ReturnType<typeof calculateRelativeError>;

function roundToCapByRanking(
  rankings: ErrorRank[],
  sumOfRounded: number,
  roundedValues: number[],
  cap: number
) {
  const countToRound = Math.abs(sumOfRounded - cap);
  const finalValues = roundedValues;

  let rankedErrors;
  if (sumOfRounded > cap) {
    rankedErrors = rankings.sort((a, b) => a.relDownError - b.relDownError);
  } else {
    rankedErrors = rankings.sort((a, b) => a.relUpError - b.relUpError);
  }

  const topErrors = rankedErrors
    .filter(e => e.up !== e.down)
    .slice(0, countToRound);

  for (const err of topErrors) {
    const iValueToChange = roundedValues.findIndex(
      value => err.leastRelativeError === value
    );
    if (sumOfRounded > cap) {
      finalValues[iValueToChange] = err.down;
    } else {
      finalValues[iValueToChange] = err.up;
    }
  }

  return finalValues;
}

export function roundPercentageWithCap(values: number[], cap: number = 100) {
  if (values.length < 1) return [];

  const rounded = values.map(value => Math.round(value));
  if (rounded.reduce((a, b) => a + b, 0) === cap) return rounded;

  const relativeErrors = values.map(actualValue =>
    calculateRelativeError(actualValue)
  );

  const roundedByLeastRelErr = relativeErrors.map(
    err => err.leastRelativeError
  );
  const sumOfRoundedByLeastError = roundedByLeastRelErr.reduce(
    (a, b) => a + b,
    0
  );

  if (sumOfRoundedByLeastError === cap) return roundedByLeastRelErr;

  const finalValues = roundToCapByRanking(
    relativeErrors,
    sumOfRoundedByLeastError,
    roundedByLeastRelErr,
    cap
  );

  return finalValues;
}
