import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getCompanyFilters,
  getCompanySort,
  untagged,
} from "Reducers/globalSearchV2/globalSearchV2.selectors";
import {
  CompanyFilter,
  CompanySort,
  GlobalSearchState,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { COMPANY_SEARCH } from "./graphql";

function formatCompanyVariablesForGQL(
  companyFilters: CompanyFilter[],
  companySort: CompanySort | undefined
) {
  const sort = companySort;
  const searchFilters = companyFilters.map(filter =>
    untagged<types.CompanySearchFilters>(filter)
  );

  const variables = {
    page: {
      offset: 0,
      limit: 24,
    },
    sort: [
      {
        field: types.CompanySortEnum.LatestUpdate,
        order: types.SortInput.Desc,
      },
    ],
    searchFilters,
  };
  return variables;
}

export function useGlobalCompanySearch() {
  const companySearchFilters = useSelector(state =>
    getCompanyFilters(state as { globalSearchV2: GlobalSearchState })
  );
  const companySort = useSelector(state =>
    getCompanySort(state as { globalSearchV2: GlobalSearchState })
  );

  const variables = useMemo(
    () => formatCompanyVariablesForGQL(companySearchFilters, companySort),
    [companySearchFilters, companySort]
  );
  const { data, error, loading, fetchMore } = useQuery(COMPANY_SEARCH, {
    variables,
  });

  const result = useMemo(() => {
    if (data) {
      const list = data.companyList;
      return {
        ...list,
        fetchMore,
        companyIds: list.items.map(({ id }) => id),
      };
    }
  }, [data]);

  return {
    variables,
    loading,
    error,
    result,
  };
}
