/* eslint-disable max-lines-per-function */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import FieldInlinePreview from "Components/show/overview/field_inline_preview.js";
import { booleanToAnswer } from "Helpers/text_formatting";
import i18n from "i18next";
import { camelCase, partition } from "lodash";
import { INVESTMENT_ATTRIBUTES } from "./graphql";

const charCategories = [
  "styleBias",
  "marketCap",
  "sectorFocus",
  "decisionMakingProcess",
  "realEstateFocus",
  "creditQualityFocus",
  "creditSectorFocus",
  "durationFocus",
  "exposureBias",
  "geographicFocus",
] as const;

type Tags = NonNullable<
  types.GetInvestmentClassificationsQuery["investment"]["tagAttributes"]
>["marketFocus"];

function Classifications({ investmentId }: { investmentId: number }) {
  const { data } = useQuery(INVESTMENT_ATTRIBUTES, {
    variables: { id: investmentId },
  });
  const tagAttributes = data?.investment.tagAttributes;

  function tagsToList(tags: Tags) {
    if (!tags || tags.length < 1) return "-";
    return tags.map(tag => tag && tag.name).join(", ");
  }

  function displayCharacteristics() {
    return charCategories
      .flatMap(
        category => tagAttributes?.[category]?.map(tag => tag.name) ?? []
      )
      .join(", ");
  }

  const cardFields = [
    {
      displayName: "Strategy",
      content: data?.investment.strategy?.name,
    },
    {
      displayName: "Asset Class",
      content: data?.investment.assetClass?.name,
    },
    {
      displayName: "Vehicle Type",
      content: data?.investment.vehicleType?.name,
    },
    {
      displayName: "Impact",
      content: booleanToAnswer(!!data?.investment.isImpact),
    },
    {
      displayName: "Market Focus",
      content: tagsToList(tagAttributes?.marketFocus),
    },
    {
      displayName: "Characteristics",
      content: displayCharacteristics(),
    },
    {
      displayName: "Primary Geographic Focus",
      content: data?.investment.primaryGeographicFocus?.name ?? "-",
    },
    {
      displayName: "Regional Focus",
      content: tagsToList(tagAttributes?.regionalFocus),
    },
    {
      displayName: "Country Focus",
      content: tagsToList(tagAttributes?.countryFocus),
    },
  ];

  const columnize = (nodes: JSX.Element[]) => (
    <div className="overview-page__classifications">
      {partition(nodes, item => nodes.indexOf(item) % 2 === 0).map(
        (node, index) => (
          <div className="overview-page__classifications-col" key={index}>
            {node}
          </div>
        )
      )}
    </div>
  );

  return (
    <div
      className="invt-tab__section"
      id="classifications"
      data-cy="classifications"
    >
      <h3 className="invt-tab__title">
        {i18n.t("investment.classifications.title")}
      </h3>

      <div className="overview-page__card">
        {columnize(
          cardFields.map(({ displayName, content }) => (
            <FieldInlinePreview
              key={`${camelCase(displayName)}::Field`}
              displayName={displayName}
              content={content}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Classifications;
