import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getTagEntityFilter } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { GlobalSearchOptions } from "./GlobalSearchTagOptions.component";
import { GET_GLOBAL_INVESTMENT_TAGS } from "./graphql";

type EntityDropdownProps = {
  searchTerm: string;
};

export function InvestmentTagDropdown({ searchTerm }: EntityDropdownProps) {
  const filterTags = useSelector(getTagEntityFilter("investment"));

  const otherTags = useMemo(
    () => filterTags?.values.map(({ id }) => id) ?? [],
    [filterTags]
  );

  const { data, loading, fetchMore } = useQuery(GET_GLOBAL_INVESTMENT_TAGS, {
    variables: {
      filter: {
        name: searchTerm,
        otherTags,
        investmentCountGTE: 1,
      },
      page: {
        limit: 25,
        offset: 0,
      },
    },
  });

  return (
    <GlobalSearchOptions
      filterType="investment"
      searchTerm={searchTerm}
      results={data}
      loading={loading}
      fetchMore={fetchMore}
    />
  );
}
