import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import i18n from "i18next";
import React, { useMemo } from "react";
import { FETCH_SUBSCRIBED_INVESTMENT_SETS } from "./graphql";
import { RecentInvestments as RecentlyViewedInvestments } from "./RecentlyViewedInvestments";
import { CuratedSets } from "./CuratedSets";
import { InvestmentSetGroup } from "./InvestmentSets";

function FavoriteList() {
  const { data, loading, error } = useQuery(FETCH_SUBSCRIBED_INVESTMENT_SETS);

  const groupedLists = useMemo(() => {
    if (!data) return null;

    return data.investmentSetList.items.reduce((accu: any, item) => {
      if (!item.accessLevel || !item.accessLevel.name) {
        console.warn("Missing Investment Access Level skipping...");
        return accu;
      }

      return {
        ...accu,
        [item.accessLevel.name]: (accu[item.accessLevel.name] || []).concat([
          item,
        ]),
      };
    }, {});
  }, [data]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  if (loading) {
    return (
      <div className="text-center">
        <Spin />
      </div>
    );
  }

  return (
    <ul className="main-tree">
      <RecentlyViewedInvestments />
      <CuratedSets />
      {Object.entries(groupedLists).map(
        ([accessLevel, investmentSets]: any) => (
          <InvestmentSetGroup
            title={i18n.t(`lists.${accessLevel}.title`)}
            key={accessLevel}
            total={investmentSets.length}
            investmentSets={investmentSets}
          />
        )
      )}
    </ul>
  );
}

export default FavoriteList;
