import i18n from "i18next";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { displayEducation } from "./helpers.js";

const EducationInfo = ({ education }) => (
  <div data-cy="person-card-education" className="team-member__info">
    <p key="title" className="team-member__info-title">
      {i18n.t("team_summary.education")}
    </p>
    <div key="info" className="team-member__info-value">
      {!isEmpty(education) &&
        education.map(({ school, degree, graduationYear, id }) => (
          <p key={id}>{displayEducation({ school, degree, graduationYear })}</p>
        ))}
    </div>
  </div>
);

EducationInfo.defaultProps = {
  education: null,
};

EducationInfo.propTypes = {
  education: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      school: PropTypes.shape({
        name: PropTypes.string,
      }),
      degree: PropTypes.shape({
        name: PropTypes.string,
      }),
      graduationYear: PropTypes.number,
    })
  ),
};

export default EducationInfo;
