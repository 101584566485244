import FirmOrCompanyInvestments from "Components/FirmOrCompanyInvestments";
import DocumentsPage from "Components/show/documents";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import FirmOrCompanyVisualizations from "../Visualizations";
import CompanyDeals from "./CompanyDeals";
import CompanyOverview from "./CompanyOverview";
import CompanyPageNavBar from "./CompanyPageNavbar";
import CompanyNotes from "./CompanyPageNotes";
import CompanyPageTabs from "./CompanyPageTabs";
import CompanyTeam from "./CompanyTeam";

function componentRouter(): JSX.Element | null {
  const { companyId: companyIdPath } = useParams();

  const companyId = Number(companyIdPath);

  return (
    <>
      <CompanyPageNavBar companyId={companyId} />
      <CompanyPageTabs companyId={companyId} />
      <Routes>
        <Route path="team/*" element={<CompanyTeam companyId={companyId} />} />
        <Route path="deals" element={<CompanyDeals companyId={companyId} />} />
        <Route
          path="documents"
          element={<DocumentsPage companyId={companyId} showChat={true} />}
        />
        <Route path="notes" element={<CompanyNotes companyId={companyId} />} />
        <Route
          path="investments"
          element={<FirmOrCompanyInvestments entityId={companyId} isCompany />}
        />
        <Route
          path="DEI"
          element={
            <FirmOrCompanyVisualizations entityId={companyId} isCompany />
          }
        />
        <Route path="" element={<CompanyOverview companyId={companyId} />} />
      </Routes>
    </>
  );
}

export default componentRouter;
