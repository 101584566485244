import { notification } from "antd";

notification.config({
  placement: "bottomRight",
  bottom: 50,
  duration: 5,
});

type ArgsProps = Parameters<typeof notification["info"]>[0];

interface NotificationOptions extends ArgsProps {
  message: string;
  title?: string;
}

export const addFlashNotification = (
  notificationOptions: NotificationOptions
) => {
  const {
    title: message,

    type = "info",
    message: description,
    ...rest
  } = notificationOptions;

  notification[type]({ message, description, ...rest });

  return { type: "DO_NOTHING" };
};
