import { gql } from "_graphql-types/gql";

export const FETCH_PERFORMANCE = gql(/* GraphQL */ `
  query FetchInvestmentPerformance(
    $id: Int!
    $priorTrackRecord: Boolean
    $returnType: ReturnTypes
  ) {
    investment(id: $id) {
      ...PerformanceInvestmentAttributeFragment
      latestNote(noteMetaId: 9) {
        id
        createDate
        createUser
        text
      }
      performanceMTD(
        sort: [{ field: asOfDate, order: DESC }]
        filter: { priorTrackRecord: $priorTrackRecord, returnType: $returnType }
      ) {
        returnYear
        returnMonth
        return
        currencyId
        currency {
          id
          name
        }
      }
      performanceQuarterly(
        sort: [{ field: asOfDate, order: DESC }]
        filter: { priorTrackRecord: $priorTrackRecord, returnType: $returnType }
      ) {
        returnYear
        returnQuarter
        return
      }
    }
  }
`);

export const FETCH_CURRENCY_ENUM_LIST = gql(/* GraphQL */ `
  query FetchCurrencyEnumList($convertibleFromCurrency: String) {
    currencyDenominationEnumList(
      sort: [{ field: name, order: ASC }]
      filter: {
        isPerformanceCurrency: true
        convertibleFromCurrency: $convertibleFromCurrency
      }
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const FETCH_PERFORMANCE_CURRENCY_CONVERTED = gql(/* GraphQL */ `
  query FetchPerformanceCurrencyConverted(
    $id: Int!
    $currencyId: Int!
    $priorTrackRecord: Boolean
    $returnType: ReturnTypes
  ) {
    investment(id: $id) {
      performanceMTDCurrencyConverted(
        sort: [{ field: asOfDate, order: DESC }]
        filter: { priorTrackRecord: $priorTrackRecord, returnType: $returnType }
        toCurrencyId: $currencyId
      ) {
        returnYear
        returnMonth
        return
        currencyId
      }
    }
  }
`);
