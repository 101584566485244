import { FloatRangeFilter } from "_graphql-types/graphql";
import { Input } from "antd";
import i18n from "i18next";
import { round } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  removeFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import { getInvestmentFilters } from "Reducers/globalSearchV2/globalSearchV2.selectors";
import { useDispatch } from "src/react-redux";
import { ifNumber, isNumber } from "../..";
import { FilterInterface } from "../FilterInterface";

export function SizeFilter() {
  const investmentFilters = useSelector(getInvestmentFilters);

  const sizeFilter = useMemo(() => {
    for (const filter of investmentFilters) {
      if (filter.type === "investment" && filter.data.type === "SIZE") {
        return filter.data.data;
      }
    }
  }, [investmentFilters]);

  const defaultFilter: FloatRangeFilter = {};

  const [localFilter, setLocalFilter] = useState(sizeFilter ?? defaultFilter);

  const dispatch = useDispatch();

  const set = () => {
    console.log("MIN", localFilter.min, "MAX", localFilter.max);
    if (isNumber(localFilter.min) || isNumber(localFilter.max)) {
      console.log("DISPATCH!");
      dispatch(
        updateFilter({
          type: "investment",
          data: {
            type: "investment",
            data: {
              type: "SIZE",
              data: localFilter,
            },
          },
        })
      );
    }
  };

  const reset = () => {
    dispatch(
      removeFilter({
        type: "investment",
        data: {
          type: "investment",
          data: {
            type: "SIZE",
            data: defaultFilter,
          },
        },
      })
    );
  };

  return (
    <FilterInterface
      label="Size"
      count={sizeFilter ? 1 : 0}
      reset={reset}
      set={set}
      menuClass="filters__menu-dropdown"
    >
      <div className="main-dropdown__menu-body">
        <label className="main-input__label font-12">
          {i18n.t("search_discovery.filters.size.size_between")}
        </label>
        <div className="main-range-inputs">
          <Input
            value={ifNumber(localFilter.min, min => round(min / 1e6, 2))}
            prefix="$"
            suffix="m"
            size="small"
            style={{ width: 110 }}
            onChange={event => {
              setLocalFilter({
                ...localFilter,
                min: ifNumber(event.target.value, min => min * 1e6),
              });
            }}
          />

          <Input
            value={ifNumber(localFilter.max, max => round(max / 1e6, 2))}
            prefix="$"
            suffix="m"
            size="small"
            style={{ width: 110 }}
            onChange={event => {
              setLocalFilter({
                ...localFilter,
                max: ifNumber(event.target.value, max => max * 1e6),
              });
            }}
          />
        </div>
      </div>
    </FilterInterface>
  );
}
