import React from "react";
import Tabs from "Components/layout/Tabs";
import { NavLink } from "react-router-dom";

export default function CompanyPageTabs({
  companyId,
}: {
  companyId: number;
}): JSX.Element {
  return (
    <Tabs>
      <NavLink
        to={`/company/${companyId}/`}
        data-tab-name="Overview"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Overview
      </NavLink>
      <NavLink
        to={`/company/${companyId}/deals`}
        data-tab-name="Deals"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Deals
      </NavLink>
      <NavLink
        to={`/company/${companyId}/investments`}
        data-tab-name="Investments"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Investments
      </NavLink>
      <NavLink
        to={`/company/${companyId}/DEI`}
        data-tab-name="DEI"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        DEI
      </NavLink>
      <NavLink
        to={`/company/${companyId}/team`}
        data-tab-name="Teams"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Teams
      </NavLink>
      <NavLink
        to={`/company/${companyId}/documents`}
        data-tab-name="Documents"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Documents
      </NavLink>
      <NavLink
        to={`/company/${companyId}/notes`}
        data-tab-name="Notes"
        className={({ isActive }) =>
          isActive ? "nav-tab nav-tab--selected" : "nav-tab"
        }
      >
        Notes
      </NavLink>
    </Tabs>
  );
}
