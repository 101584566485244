import React from "react";
import { useQuery } from "@apollo/client";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { FETCH_LINKS } from "./graphql";

export const DynamicLinks = ({ handleClose }: { handleClose: () => void }) => {
  const orgId = useCurrentUser().organizationId as number;
  const { data } = useQuery(FETCH_LINKS, {
    variables: { filter: { organizationIds: [orgId] } },
  });
  if (data && data.linksList) {
    return (
      <>
        {data.linksList.items.map((link, ilink) => (
          <a
            key={String([ilink, link.id])}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
            className="nav-sidebar__list-item nav-sidebar__list-item--solutions"
          >
            <span
              className={`nav-sidebar__list-item-icon icon ${link.iconEnum?.name}`}
            />
            <span className="nav-sidebar__list-item-text">{link.name}</span>
          </a>
        ))}
      </>
    );
  }

  return <></>;
};
