import { isEmpty, isEqual, isObject, transform, get, merge } from "lodash";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as Sentry from "@sentry/react";
import { abbreviation } from "Helpers/text_formatting.js";

export const FIELD_DATE_FORMAT = "YYYY-MM-DD";

export const toTitleCase = str => {
  str = str.split("_").join(" ");
  return str.replace(/\w\S*/g, txt => {
    txt = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    // replace exceptions to rule
    txt = txt.replace(/of|and/gi, word => word.toLowerCase());
    return txt;
  });
};

export const asDisplayDate = date => {
  if (!date) {
    return "";
  }
  return moment.utc(new Date(date)).format("MMM-YYYY");
};

export const toMoment = date => {
  if (moment(date, moment.ISO_8601, true).isValid()) {
    // returns true for:
    // 2018-04-04T00:00:00.000Z
    // 2018-04-04
    return moment(date);
  }

  const year = "\\d\\d\\d\\d";
  const month = "([0]?[1-9]|1[0-2])";
  const day = "(([0]?[1-9])|([1-2][0-9])|(3[01]))";
  const separator = "(-|\\/|\\.|\\W)";

  const formatter = () => {
    switch (true) {
      case new RegExp(day + separator + month + separator + year).test(date):
        return "DD-MM-YYYY";
      case new RegExp(month + separator + day + separator + year).test(date):
        return "MM-DD-YYYY";
      case new RegExp(month + separator + year).test(date):
        return "MM-YYYY";
      case new RegExp(year).test(date):
        return "YYYY";
      default:
        throw new Error(`Can't define the date format for ${date}`);
    }
  };

  return moment(date, formatter());
};

export const withoutTimezone = momentInstance => {
  if (momentInstance._tzm === 0) {
    return momentInstance.utc();
  }

  return moment(momentInstance).add(momentInstance.utcOffset(), "m").utc();
};

export const asFullDate = date => {
  if (!date) {
    return "";
  }
  return withoutTimezone(toMoment(date)).format("DD-MMM-YYYY");
};

export const asPercent = value => {
  if (!value && value !== 0) return "-";
  return toPercent(value / 100);
};

export const toPercent = (value, precision) => {
  let formatString;

  if (typeof precision === "undefined") {
    precision = 2;
  }

  if (precision < 1) {
    formatString = "0%";
  } else {
    formatString = `0.${"0".repeat(precision)}%`;
  }

  return numeral(value).format(formatString);
};

// eslint-disable-next-line max-lines-per-function
export const logException = (ex, context) => {
  let error;
  const unknownFallback = new Error(
    `Unknown Error: ${JSON.stringify(context)}`
  );
  let errorObject = {};

  if (!ex && ["ResizeObserver loop limit exceeded"].includes(context.message)) {
    console.warn("skipping error", context.message);
    return;
  }

  Sentry.withScope(scope => {
    scope.setExtra("context", context);
    if (ex?.path) {
      scope.addBreadcrumb({
        message: ex.path.join(" > "),
        level: Sentry.Severity.Debug,
      });
    }
    Sentry.captureException(ex || unknownFallback);
  });

  const getResponseError = function () {
    return get(ex, ["data", "detail"]);
  };

  if (ex instanceof Error) {
    error = ex;
  } else if (ex !== null && typeof ex === "object") {
    if (getResponseError()) {
      error = new Error(
        `Response Error: ${JSON.stringify(getResponseError())}`
      );
    }
    errorObject = ex;
  }

  let extraDetails = {};
  if (!isEmpty(context)) {
    extraDetails = merge(
      {
        extra: context,
      },
      errorObject
    );
  }

  if (
    window.console &&
    console.error &&
    process.env.NODE_ENV === "development"
  ) {
    const css = "background: #000000; color: #bada56;";
    console.warn("%c %s", css, error);
    if (!isEmpty(extraDetails)) {
      console.warn("%cdetails:", css, extraDetails);
    }
  }
};

export const asMultiple = value => `${numeral(value).format("0.00")}x`;

export const onElementHeightChange = (elm, callback) => {
  let lastHeight = elm.clientHeight;

  let newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight != newHeight) {
      callback();
    }
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
};

export const formatBigNumber = number => {
  const labels = ["", "k", "m", "b"];
  const maxPeriod = 3;
  if (number === 0) return number;

  const period = parseInt(Math.floor(Math.log(number) / Math.log(1000)), 10);

  if (period === 0) {
    return number;
  }

  const periodToConver = period > maxPeriod ? maxPeriod : period;
  const converted = (number / Math.pow(1000, periodToConver)).toFixed(2);

  return `${converted} ${labels[periodToConver]}`;
};

export const formatCurrency = (value, currency) => {
  if (!value) return "-";
  const result = numeral(value).format("0,0");
  return currency ? result + " " + currency : "$" + result;
};

export const formatLargeCurrency = (value, currency) => {
  if (!value) return "-";
  const result = formatBigNumber(value);
  return currency ? result + " " + currency : "$" + result;
};

export const numberFromString = string =>
  Number(string.replace(/[^\d.-]/g, ""));

export const genUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;

    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const isUUID = string => {
  const pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return pattern.test(string);
};

export const getInitials = sentence => {
  const fullName = _.replace(sentence, "Mr.", "");
  const words = _.words(fullName);

  return _.join(_.map(words, _.head), "");
};

export const getTagInitials = tag => abbreviation(tag);

export const truncateInvestmentTag = (name, length) =>
  _.truncate(name, { length });

const getNumber = value =>
  Number(
    String(value ?? "")
      .replace(/[%x]/g, "")
      .trim()
  );
export const isNegative = value => getNumber(value) < 0;
