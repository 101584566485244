/* eslint-disable max-len */
import { useQuery } from "@apollo/client";
import { renderIconGrid } from "frontend/src/components/SDGIcons";
import i18n from "i18next";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { INVESTMENT_SUMMARY } from "./graphql";
import TextStatic, {
  textOrRichText,
} from "frontend/src/components/Template/Static/TextStatic";

function SDGTags({
  sdgTags,
}: {
  sdgTags: { id: number; name: string }[];
}): JSX.Element {
  if (sdgTags.length) {
    return (
      <div className="overview-page__card">
        <div className="flex-row">
          <div className="flex-1" />
        </div>
        <div style={{ width: "100%" }}>
          {renderIconGrid({
            impactTags: sdgTags,
            chunkSize: 12,
          })}
        </div>
      </div>
    );
  }
  return <></>;
}

function InvestmentSummaryTile({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [content, setContent] = useState<string>("");
  const { data, loading } = useQuery(INVESTMENT_SUMMARY, {
    variables: { id: investmentId },
  });

  const sdgTags =
    data?.investment?.impactTags?.filter(
      tag => tag.impactCategory?.name === "SDGs"
    ) ?? [];

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setContent(get(data, "investment.summary", ""));
    }

    return () => {
      mounted = false;
    };
  }, [data, loading]);

  return (
    <>
      <SDGTags sdgTags={sdgTags} />
      <div className="invt-tab__section" data-cy="investment-summary-tile">
        <h3 className="invt-tab__title">
          {data?.investment.isPortfolio
            ? "Portfolio Summary"
            : i18n.t("investment_summary.title")}
        </h3>

        <div className="overview-page__card">
          <div className="overview-page__card-text">
            <div className="rating-txt">
              <div className="cms-updates-wrap">
                <div className="cms-updates__field cms-updates__field--history cms-updates__field--note">
                  <TextStatic value={content} formatter={textOrRichText} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestmentSummaryTile;
