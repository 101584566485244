/* eslint-disable max-lines-per-function */
import { Table } from "antd";
import LoadingPanel from "Components/loading_panel";
import { formatStatValue } from "Helpers/investment_helpers";
import React, { useMemo, useContext } from "react";
import * as types from "_graphql-types/graphql";
import { RelativePerformanceContext } from "./context";

const columns: Parameters<typeof Table>[0]["columns"] = [
  {
    title: "",
    dataIndex: "benchmark",
    key: "benchmark",
  },
  {
    title: "Alpha",
    dataIndex: "alpha",
    key: "alpha",
  },
  {
    title: "Ann. Alpha",
    dataIndex: "alphaAnnualized",
    key: "alphaAnnualized",
  },
  {
    title: "Beta",
    dataIndex: "beta",
    key: "beta",
  },
  {
    title: "R",
    dataIndex: "correlation",
    key: "correlation",
  },
  {
    title: "R\u00B2",
    dataIndex: "determination",
    key: "determination",
  },
  {
    title: "Tracking Error",
    dataIndex: "trackingError",
    key: "trackingError",
  },
  {
    title: "Info. Ratio",
    dataIndex: "infoRatio",
    key: "infoRatio",
  },
];

type DataType = {
  id?: number;
  benchmark?: string;
  alpha?: number | string | null;
  alphaAnnualized?: number | string | null;
  beta?: number | string | null;
  correlation?: number | string | null;
  determination?: number | string | null;
  trackingError?: number | string | null;
  infoRatio?: number | string | null;
};

type StatsType =
  types.GetRelativeStatisticsQuery["investment"]["riskStatsBenchmarkPrimary"]["stats"];

function formatStats(
  id: number | undefined,
  name: string | undefined,
  stats: StatsType
) {
  return {
    id,
    benchmark: `to ${name}`,
    alpha: formatStatValue(stats?.alpha?.[0], "0.00%"),
    alphaAnnualized: formatStatValue(stats?.alphaAnnualized?.[0], "0.00%"),
    beta: formatStatValue(stats.beta[0], "0.00"),
    correlation: formatStatValue(stats.correlation[0], "0.00"),
    determination: formatStatValue(
      stats.correlation[0]
        ? Math.pow(stats.correlation[0] as number, 2)
        : undefined,
      "0.00"
    ),
    trackingError: formatStatValue(stats.trackingError[0], "0.00%"),
    infoRatio: formatStatValue(stats.infoRatio[0], "0.00"),
  };
}

function ComparitiveStatsChart(): JSX.Element {
  const { investment, loading } = useContext(RelativePerformanceContext);

  const chartData: DataType[] | null = useMemo(() => {
    if (!investment) return null;

    return (
      [investment.primaryBenchmark, investment.secondaryBenchmark]
        .filter(Boolean)
        .map((item, itemIndex) => {
          if (!item)
            throw new Error("This should never happen no item after filtering");

          return formatStats(
            item.id,
            item.name,
            [
              investment?.riskStatsBenchmarkPrimary.stats,
              investment?.riskStatsBenchmarkSecondary.stats,
            ][itemIndex]
          ); // We need to feed in alpha with the relating benchmark for calcs here, so requested separately
        }) || null
    );
  }, [investment]);

  if (!investment?.primaryBenchmark) return <></>;

  return (
    <>
      <div className="summary-heading">
        <h3 className="invt-tab__title" id="risk_statistics">
          {I18n.t("overview.risk_statistics")}
        </h3>
      </div>
      <LoadingPanel loading={loading}>
        <Table
          columns={columns}
          dataSource={chartData ?? []}
          pagination={false}
          className="mb-24"
        />
      </LoadingPanel>
    </>
  );
}

export default ComparitiveStatsChart;
