export { InvestmentSearchWorkspace } from "./InvestmentSearchWorkspace.component";
export { CompanySearchWorkspace } from "./CompanySearchWorkspace.component";
export { PortfolioWorkspace } from "./PortfolioWorkspace.component";

export function isNumber(value: any): value is number {
  return !!String(value).trim().length && !isNaN(Number(value));
}

export function ifNumber<T>(value: any, map: (value: number) => T) {
  if (isNumber(value)) {
    return map(Number(value));
  }
}
