import { gql } from "_graphql-types/gql";

export const ADDRESS_SEARCH = gql(/* GraphQL */ `
  query AddressSearch($q: String, $isPrimary: Boolean, $isCompany: Boolean) {
    addressSearch(q: $q, isPrimary: $isPrimary, isCompany: $isCompany) {
      city
      state
      stateEnumId
      country
      countryEnumId
      count
    }
  }
`);

export const FETCH_TYPE_ASSET_CLASSES_STRATEGIES = gql(/* GraphQL */ `
  query GetAssetClassStrategiesForFilter {
    typeAssetClassStrategyOptionsList {
      items {
        id
        assetClass {
          name
          id
        }
        strategy {
          name
          id
          subStrategies {
            name
            id
          }
        }
        vehicleType {
          name
          id
        }
      }
    }
  }
`);

export const FETCH_AVAILABLE_GEOGRAPHIES = gql(/* GraphQL */ `
  query GetAvailableGeographiesForFilter {
    geographicFocusEnumList {
      items {
        id
        name
      }
    }
  }
`);
